import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useToggle = (
  currentBalance: number,
  dateOfReward: string,
  previousBalance: number,
) => {
  const reasonRef = useRef<HTMLDivElement | null>(null);
  const [toggle, setToggle] = useState(false);
  const [animating, setAnimating] = useState(false);

  const difference = useMemo(
    () => currentBalance - previousBalance,
    [currentBalance, previousBalance],
  );
  const date = useMemo(
    () => dayjs(dateOfReward).format('DD MMM YY'),
    [dateOfReward],
  );

  const handleClick = useCallback(
    () => setToggle(!toggle),
    [setToggle, toggle],
  );

  const handleTransitionEnd = useCallback(
    (e: React.TransitionEvent<HTMLDivElement>) => {
      if (!toggle && reasonRef.current && e.propertyName === 'height') {
        setAnimating(false);
      }
    },
    [toggle],
  );

  useEffect(() => {
    if (reasonRef.current) {
      if (toggle) {
        setAnimating(true);
        reasonRef.current.style.height = `${reasonRef.current.scrollHeight}px`;
      } else {
        reasonRef.current.style.height = '0px';
      }
    }
  }, [toggle]);

  return {
    animating,
    date,
    difference,
    handleClick,
    handleTransitionEnd,
    reasonRef,
    toggle,
  };
};
