import React, { useRef } from 'react';
import clsx from 'clsx';

import { getAnimationOut } from '../../utils';
import { ModalOverlayProps } from './types';
import { useFocusLock } from 'modules/hooks/useFocusLock';
import { useModal } from './hooks';
import { useScreenLock } from 'modules/hooks/useScreenLock';

import styles from './styles.module.scss';

type ModalOverlayChildrenProps = { active?: boolean };

const ModalOverlay: React.FunctionComponent<ModalOverlayProps> = ({
  animation,
  children,
  destroyModal,
  disableEscapeKey,
  id,
  onClose,
  open,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const { animating } = useModal({
    animationIn: styles[`${animation}`],
    animationOut: styles[`${getAnimationOut(animation)}`],
    destroyModal,
    disableEscapeKey,
    onClose,
    ref,
  });

  useFocusLock({ isLocked: animating || open, rootNode: ref });
  useScreenLock({ trigger: open });

  const classNames = clsx(
    styles['modal-overlay'],
    styles[`${animation}`],
    open && styles.open,
    !open && styles.closing,
    animating && styles.animating,
  );
  return (
    <div className={classNames} id={`modal-${id}`} ref={ref} tabIndex={0}>
      <div>
        {React.Children.map(children, child => {
          const drawerChild = child as React.ReactElement<
            React.PropsWithChildren<ModalOverlayChildrenProps>
          >;
          return React.isValidElement(child)
            ? React.cloneElement(drawerChild, { active: animating || open })
            : child;
        })}
      </div>
    </div>
  );
};

export default React.memo(ModalOverlay);
