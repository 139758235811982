import React from 'react';

import { EngagementAdditionalDetailsUserData } from 'modules/redux/states/engagement/additionalDetails/types';
import { Paging } from 'modules/pagination';
import AdditionalDetailField from './AdditionalDetailField';
import Pagination from 'modules/pagination';

import styles from './styles.module.scss';

type AdditionalDetailsProps = {
  activeAdditionalDetail: string | null;
  additionalDetails: EngagementAdditionalDetailsUserData[];
  dataKeyName?: string;
  handlePaging: (type: Paging) => void;
  handleUpdateField: (dataKeyName: string, value: string) => void;
  isLoading: boolean;
  isSending: boolean;
  isSendingError: Error | false;
  page?: number;
  pageSize?: number;
  pagingMessage?: string;
  setActiveAdditionalDetail: React.Dispatch<
    React.SetStateAction<string | null>
  >;
};

const AdditionalDetails: React.FunctionComponent<AdditionalDetailsProps> = ({
  activeAdditionalDetail,
  additionalDetails,
  dataKeyName,
  handleUpdateField,
  handlePaging,
  isLoading,
  isSending,
  isSendingError,
  page,
  pageSize,
  pagingMessage,
  setActiveAdditionalDetail,
}) => {
  return (
    <div className={styles['drawer-template-additional-details']}>
      <header>
        <div>Name</div>
        <div>Value</div>
      </header>
      <div>
        {additionalDetails && additionalDetails.length > 0 && (
          <ul>
            {additionalDetails.map((additionalDetail, index) => {
              return (
                <AdditionalDetailField
                  active={
                    activeAdditionalDetail === additionalDetail.keyName ||
                    (!!isSending && additionalDetail.keyName === dataKeyName)
                  }
                  disabled={
                    !!activeAdditionalDetail &&
                    activeAdditionalDetail !== additionalDetail.keyName
                  }
                  handleUpdateField={handleUpdateField}
                  isSending={isSending}
                  isSendingError={isSendingError}
                  key={`additionalDetail-${index}`}
                  setActiveAdditionalDetail={setActiveAdditionalDetail}
                  {...additionalDetail}
                />
              );
            })}
          </ul>
        )}
      </div>
      {page && pageSize && pagingMessage && (
        <Pagination
          handlePaging={handlePaging}
          isLoading={isLoading}
          page={page}
          pageSize={pageSize}
          totalCount={Number(pagingMessage.split('of')[1])}
        />
      )}
    </div>
  );
};

export default React.memo(AdditionalDetails);
