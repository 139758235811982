import { useCallback } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { DrawerCustomProps } from '../types';
import { drawerHideAction, drawerShowAction } from '../actions';
import store from 'modules/redux';

export type Drawer = Omit<
  DrawerCustomProps,
  'destroyDrawer' | 'hideDrawer' | 'id' | 'open'
>;

const useDrawerAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    drawerHide: useCallback(
      (id: string) => {
        store
          .getState()
          .drawers.find(drawer => drawer.id === id && drawer.open) &&
          dispatch(drawerHideAction(id));
      },
      [dispatch],
    ),
    drawerShow: useCallback(
      (drawer: Drawer) => {
        return dispatch(drawerShowAction(drawer));
      },
      [dispatch],
    ),
  };
};

export default useDrawerAction;
