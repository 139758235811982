import { DrawerCustomTemplateProps } from '..';
import Template from './Template';

const template: DrawerCustomTemplateProps = {
  ariaLabel: 'Additional Details',
  disableEscapeKey: true,
  renderContent: props => <Template {...props} />,
};

export default template;
