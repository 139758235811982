import { useCallback, useEffect } from 'react';

import { ModalErrorText } from 'modules/modals/components/ModalErrorMessage';
import { ModalInfoTemplate } from 'modules/modals/constants';
import { useModalInfo } from 'modules/modals/hooks';
import { UseEvent } from '../useEvent/types';

type UseHandleError = (isError: UseEvent['isError']) => void;

export const useHandleError: UseHandleError = isError => {
  const { showModalInfo } = useModalInfo();

  const handleError = useCallback(() => {
    if (showModalInfo)
      showModalInfo({
        onClose: () => window.location.reload(),
        renderContent: () => (
          <ModalErrorText error={isError ? isError : undefined} />
        ),
        template: ModalInfoTemplate.EVENTS_ERROR,
      });
  }, [isError, showModalInfo]);

  useEffect(() => {
    if (isError && showModalInfo) handleError();
  }, [handleError, isError, showModalInfo]);
};
