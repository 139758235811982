import { API } from 'modules/api';
import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import {
  EngagementPostAdditionalDetailsAction,
  EngagementPostAdditionalDetailsStartedAction,
  EngagementPostAdditionalDetailsSuccessAction,
  EngagementPostAdditionalDetailsFailureAction,
  EngagementPostAdditionalDetailsResetErrorAction,
} from './types';

export type { EngagementPostAdditionalDetailsActionApiRequest } from './types';

export const engagementPostAdditionalDetailsAction: EngagementPostAdditionalDetailsAction =
  request =>
    createDefaultAction({
      actionStarted: engagementPostAdditionalDetailsStartedAction,
      actionSuccess: engagementPostAdditionalDetailsSuccessAction,
      actionFailure: engagementPostAdditionalDetailsFailureAction,
      endpoint: API.POST_ENGAGEMENT_ADDITIONAL_DETAILS,
      isActive: state => !!state.engagement.additionalDetails.isSending,
      isAuthorized: state => state.auth.isAuthenticated,
      isPresent: (actionId, state) =>
        actionId === state.engagement.additionalDetails.actionId &&
        request.endUserExternalId ===
          state.engagement.details.data?.data.endUserId,
      request: request,
    });

const engagementPostAdditionalDetailsStartedAction: EngagementPostAdditionalDetailsStartedAction =
  payload => ({
    type: EngagementAction.POST_ADDITIONAL_DETAILS_STARTED,
    ...payload,
  });

const engagementPostAdditionalDetailsSuccessAction: EngagementPostAdditionalDetailsSuccessAction =
  payload => ({
    type: EngagementAction.POST_ADDITIONAL_DETAILS_SUCCESS,
    ...payload,
  });

const engagementPostAdditionalDetailsFailureAction: EngagementPostAdditionalDetailsFailureAction =
  payload => ({
    type: EngagementAction.POST_ADDITIONAL_DETAILS_FAILURE,
    ...payload,
  });

export const engagementPostAdditionalDetailsResetErrorAction: EngagementPostAdditionalDetailsResetErrorAction =
  () => ({
    type: EngagementAction.POST_ADDITIONAL_DETAILS_RESET_ERROR,
  });
