import { useCallback, useEffect, useRef, useState } from 'react';

import { getTemplate } from '../templates/info';
import { ModalInfoTemplate, ModalType } from '../constants';
import { ModalInfoProps } from '../types';
import useModalAction from './useModalAction';

export type ShowModalInfoProps = Omit<
  ModalInfoProps,
  'destroyModal' | 'hideModal' | 'id' | 'open'
> & { template?: ModalInfoTemplate };

export type UseModalInfo = () => {
  hideModalInfo: () => void;
  showModalInfo?: (props: ShowModalInfoProps) => void;
};

const useModalInfo: UseModalInfo = () => {
  const { modalShow, modalHide } = useModalAction();
  const [modal, setModal] = useState<string | null>();
  const modalRef = useRef<() => void>();

  useEffect(() => {
    modalRef.current = () => {
      modal && modalHide(modal);
      setModal(null);
    };
  }, [modal, modalHide]);

  const hideModal = useCallback(
    () => modalRef.current && modalRef.current(),
    [],
  );

  const showModal = useCallback(
    ({ onClose, template, ...props }: ShowModalInfoProps) => {
      if (!modal) {
        setModal(
          modalShow({
            onClose: () => {
              onClose && onClose();
              hideModal();
            },
            type: ModalType.INFO,
            ...getTemplate(template),
            ...props,
          }),
        );
      }
    },
    [hideModal, modal, modalShow],
  );

  useEffect(() => {
    return hideModal;
  }, [hideModal]);

  return {
    hideModalInfo: hideModal,
    showModalInfo: !modal ? showModal : undefined,
  };
};

export default useModalInfo;
