import React from 'react';

import { HistoryType } from './constants';
import Button from 'components/Button';

import styles from './styles.module.scss';

type HistoryButtonsProps = {
  handleClick: (historyType: HistoryType) => void;
  historyType: HistoryType;
  isLoading: boolean;
};

const Buttons: React.FunctionComponent<HistoryButtonsProps> = ({
  handleClick,
  historyType,
  isLoading,
}) => {
  return (
    <div>
      <Button
        className={
          historyType === HistoryType.REWARDS ? styles.selected : undefined
        }
        disabled={isLoading}
        label="Rewards"
        onClick={() => handleClick(HistoryType.REWARDS)}
      />
      <Button
        className={
          historyType === HistoryType.MESSAGES ? styles.selected : undefined
        }
        disabled={isLoading}
        label="Messages"
        onClick={() => handleClick(HistoryType.MESSAGES)}
      />
    </div>
  );
};

export default React.memo(Buttons);
