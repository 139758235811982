import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { isAdmin } from 'modules/admin';
import { ReactComponent as Engagement } from 'modules/theme/images/icons/navigation/engagement.svg';
import { ReactComponent as Logo } from 'modules/theme/images/logos/logo-small-white.svg';
import { ReactComponent as Policies } from 'modules/theme/images/icons/navigation/policies.svg';
import { ReactComponent as SignOut } from 'modules/theme/images/icons/navigation/log-out.svg';
import { ROUTE } from 'modules/router/constants';
import { useAuthAccount } from 'modules/auth/hooks';
import { useConfigSelector } from 'modules/redux/states/config/hooks';
import Account from './Account';
import NavItem from './NavItem';

import styles from './styles.module.scss';

type GlobalNavigationProps = {
  isAuthenticated: boolean;
  handleClose: () => void;
};

const GlobalNavigation: React.FunctionComponent<GlobalNavigationProps> = ({
  handleClose,
  isAuthenticated,
}) => {
  const { email, handleLogout, name } = useAuthAccount();
  const { eventsApplication, tripsApplication } = useConfigSelector();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    handleClose();
    navigate(ROUTE.DEBUG);
  }, [handleClose, navigate]);

  const classNames = clsx(
    styles['global-nav'],
    isAdmin && isAuthenticated && styles.admin,
  );
  return (
    <div className={classNames}>
      <Logo onClick={isAuthenticated ? () => handleClick() : undefined} />
      {isAuthenticated && (
        <React.Fragment>
          <Account email={email} name={name} />
          <nav aria-label="Global Menu" role="navigation">
            <ul>
              {tripsApplication.enabled && (
                <NavItem
                  label="Policies"
                  icon={() => <Policies />}
                  route={ROUTE.POLICY_SEARCH}
                />
              )}
              {eventsApplication.enabled && (
                <NavItem
                  label="Speed Validation"
                  icon={() => <Policies />}
                  route={ROUTE.EVENTS}
                />
              )}
              <NavItem
                label="Engagement"
                icon={() => <Engagement />}
                route={ROUTE.ENGAGEMENT_SEARCH}
              />
              <NavItem
                label="Sign Out"
                icon={() => <SignOut />}
                onClick={handleLogout}
              />
            </ul>
          </nav>
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo(GlobalNavigation);
