import { useCallback, useEffect, useRef, useState } from 'react';

export const useToggle = () => {
  const descRef = useRef<HTMLElement | null>(null);
  const [toggle, setToggle] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [animating, setAnimating] = useState(false);

  const handleClick = useCallback(() => {
    setAnimating(true);
    setToggle(!toggle);
  }, [setToggle, toggle]);

  const handleTransitionEnd = useCallback(
    (e: React.TransitionEvent<HTMLElement>) => {
      if (descRef.current && e.propertyName === 'height') {
        setAnimating(false);
        if (descRef.current.style.height === '15px') {
          descRef.current.style.whiteSpace = 'nowrap';
        }
      }
    },
    [],
  );

  const toggable = useCallback(() => {
    setToggle(false);
    if (descRef.current) {
      descRef.current.style.whiteSpace = 'normal';
      if (descRef.current.scrollHeight === 15) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      descRef.current.style.whiteSpace = '';
    }
  }, []);

  useEffect(() => {
    if (descRef.current) {
      if (toggle) {
        descRef.current.style.whiteSpace = 'normal';
        descRef.current.style.height = `${descRef.current.scrollHeight}px`;
      } else {
        descRef.current.style.height = '15px';
      }
    }
  }, [animating, toggle]);

  useEffect(() => {
    toggable();
    window.addEventListener('resize', toggable);
    window.addEventListener('orientationchange', toggable);
    return () => {
      window.removeEventListener('resize', toggable);
      window.removeEventListener('orientationchange', toggable);
    };
  }, [toggable]);

  return {
    descRef,
    disabled,
    handleClick,
    handleTransitionEnd,
    toggle,
  };
};
