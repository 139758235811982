import { ModalInfoTemplateProps } from '.';
import { ModalErrorTitle } from 'modules/modals/components/ModalErrorMessage';

const template: ModalInfoTemplateProps = {
  ariaLabel: 'Add Points Error',
  closeLabel: 'Close',
  renderHeader: () => <ModalErrorTitle />,
};

export default template;
