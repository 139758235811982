import React from 'react';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <span data-nosnippet className="visually-hidden">
        Loading policy number
      </span>
      <div className={styles.skeleton} aria-hidden="true">
        <span></span>
        <span></span>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Skeleton);
