import React from 'react';
import clsx from 'clsx';

import { EndUser } from 'modules/redux/states/engagement/users/types';
import { UsePolicyHolderUsers } from 'modules/redux/states/engagement/hooks';
import { useRelativeScrollbar } from '../hooks';
import ChevronSymbol from './ChevronSymbol';
import Pagination from 'modules/pagination';
import PolicyHoldersItem from './PolicyHoldersItem';

import styles from './styles.module.scss';

type PolicyHoldersListProps = {
  endUsers: EndUser[];
  handleClick: UsePolicyHolderUsers['handleClick'];
  handlePaging: UsePolicyHolderUsers['handlePaging'];
  isLoading: UsePolicyHolderUsers['isLoading'];
  page: UsePolicyHolderUsers['page'];
  pageSize: UsePolicyHolderUsers['pageSize'];
  totalCount: number;
};

const PolicyHoldersList: React.FunctionComponent<PolicyHoldersListProps> = ({
  endUsers,
  handleClick,
  handlePaging,
  isLoading,
  page,
  pageSize,
  totalCount,
}) => {
  const { isRelativeScrollbar, rootRef, scrollableRef } =
    useRelativeScrollbar();
  const classNames = clsx(
    styles['policy-holders-list'],
    isRelativeScrollbar && styles['relative-scrollbar'],
  );
  return (
    <div className={classNames} ref={rootRef}>
      <ChevronSymbol />
      <header>
        <div>First Name</div>
        <div>Last Name</div>
        <div>Email Address</div>
        <div>Active</div>
        <div>Policy Number</div>
        <div>Rewards ID</div>
      </header>
      <ul ref={scrollableRef}>
        {endUsers.map((endUser, index) => {
          return (
            <li key={`item-${index}-${endUser.endUserId}`}>
              <PolicyHoldersItem
                email={endUser.emailAddress}
                firstName={endUser.firstName}
                handleClick={handleClick}
                isLoading={isLoading}
                policyNumber={endUser.policyNumber}
                lastName={endUser.lastName}
                active={endUser.active ? 'Yes' : 'No'}
                rewardId={endUser.endUserId}
              />
            </li>
          );
        })}
      </ul>
      {page && pageSize && totalCount && (
        <Pagination
          handlePaging={handlePaging}
          isLoading={isLoading}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
        />
      )}
    </div>
  );
};

export default React.memo(PolicyHoldersList);
