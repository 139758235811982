import { EngagementAction } from './constants';
import { EngagementLogoutAction, EngagementResetAction } from './types';

export { engagementGetUsersAction } from './users/actions';

export const engagementLogoutAction: EngagementLogoutAction = () => ({
  type: EngagementAction.LOGOUT,
});

export const engagementResetAction: EngagementResetAction = () => ({
  type: EngagementAction.RESET,
});
