import React from 'react';

const ChevronSymbol: React.FunctionComponent = () => {
  return (
    <svg aria-hidden="true" height="0" width="0">
      <symbol id="policy-holders-chevron" fill="none" viewBox="0 0 8 14">
        {/* prettier-ignore */}
        <path d="M1 13L7 7L1 1" stroke="#0E8CF8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </symbol>
    </svg>
  );
};

export default React.memo(ChevronSymbol);
