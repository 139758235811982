import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { isAdmin } from 'modules/admin';
import { ReactComponent as ErrorSVG } from 'modules/theme/images/icons/inputs/error.svg';
import { UsePolicyTrips } from 'modules/redux/states/policy/hooks/usePolicySummary';
import Button from 'components/Button';
import Card from 'components/Card';

import styles from './styles.module.scss';

type TripsListErrorProps = {
  handleError: UsePolicyTrips['handleError'];
  isError: UsePolicyTrips['isError'];
};

const ErrorMessage: React.FunctionComponent<TripsListErrorProps> = ({
  handleError,
  isError,
}) => {
  return (
    <Card
      className={styles['trips-list-error']}
      scrollable={true}
      title="Trips summary">
      <div>
        <ErrorSVG />
        <h2>{isAdmin ? 'Error' : 'Something went wrong'}</h2>
        {isAdmin && !!isError && isError.message && <p>{isError.message}</p>}
        <Button
          color={ButtonColor.BLUE}
          label="Retry"
          onClick={handleError}
          style={ButtonStyle.PRIMARY}
        />
      </div>
    </Card>
  );
};

export default React.memo(ErrorMessage);
