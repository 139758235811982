import React from 'react';
import clsx from 'clsx';

import {
  UsePolicyHolderDetails,
  UsePolicyHolderMessages,
  UsePolicyHolderReport,
  UsePolicyHolderRewards,
  UsePolicyHolderPoints,
} from 'modules/redux/states/engagement/hooks';
import { WidthPercentage } from 'components/Layout/constants';
import * as Layout from 'components/Layout';
import History from './components/History';
import PageTitle from 'components/PageTitle';
import PointsBalance from './components/PointsBalance';
import PolicyDetails from './components/PolicyDetails';
import Report from './components/Report';

import styles from './components/styles.module.scss';

type TemplateProps = {
  details: UsePolicyHolderDetails;
  messages: UsePolicyHolderMessages;
  points: UsePolicyHolderPoints;
  report: UsePolicyHolderReport;
  rewards: UsePolicyHolderRewards;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  details,
  messages,
  points,
  report,
  rewards,
}) => {
  const classNames = clsx(
    styles['policy-holders-page-title'],
    styles['details-loading'],
  );
  return (
    <React.Fragment>
      <PageTitle
        className={classNames}
        title={
          <React.Fragment>
            Details for{' '}
            {details.isLoading ? (
              <React.Fragment>
                <span></span> <span></span>
              </React.Fragment>
            ) : (
              `${details.data?.data.firstName} ${details.data?.data.lastName}`
            )}
          </React.Fragment>
        }>
        <Report
          handleDownload={report.handleDownload}
          isError={report.isError}
          isLoading={report.isLoading}
        />
      </PageTitle>
      <Layout.Container>
        <Layout.Column width={WidthPercentage.FIFTY}>
          <PolicyDetails
            data={details.data}
            handleClick={details.handleClick}
            handleError={details.handleError}
            isError={details.isError}
            isLoading={details.isLoading}
          />
          <PointsBalance {...points} />
        </Layout.Column>
        <Layout.Column scrollable={true} width={WidthPercentage.FIFTY}>
          <History messages={messages} rewards={rewards} />
        </Layout.Column>
      </Layout.Container>
    </React.Fragment>
  );
};

export default React.memo(Template);
