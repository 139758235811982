import React from 'react';

import { PolicyEventDetails } from 'modules/redux/states/policy/trip/types';
import { UsePolicyTrip } from 'modules/redux/states/policy/hooks/usePolicySummary';
import Map from './components/Map';
import Skeleton from './Skeleton';

type TripMapWrapperProps = {
  events?: PolicyEventDetails[];
  geometry?: string;
  isLoading: UsePolicyTrip['isLoading'];
};

const TripMap: React.FunctionComponent<TripMapWrapperProps> = ({
  events,
  geometry,
  isLoading,
}) => {
  return !isLoading && events && geometry ? (
    <Map events={events} geometry={geometry} />
  ) : (
    <Skeleton />
  );
};

export default React.memo(TripMap);
