import React from 'react';
import clsx from 'clsx';

import { useRelativeScrollbar } from '../hooks';

import styles from './styles.module.scss';

type PolicyHoldersListSkeletonProps = {
  isLoading?: boolean;
};

const PolicyHoldersListSkeleton: React.FunctionComponent<
  PolicyHoldersListSkeletonProps
> = () => {
  const { isRelativeScrollbar, rootRef, scrollableRef } =
    useRelativeScrollbar();
  const classNames = clsx(
    styles.skeleton,
    isRelativeScrollbar && styles['relative-scrollbar'],
  );

  return (
    <React.Fragment>
      <span data-nosnippet className="visually-hidden">
        Loading policy holders
      </span>
      <div aria-hidden="true" className={classNames} ref={rootRef}>
        <header>
          <div>First Name</div>
          <div>Last Name</div>
          <div>Email Address</div>
          <div>Active</div>
          <div>Policy Number</div>
          <div>Rewards ID</div>
        </header>
        <ul ref={scrollableRef}>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>
                <span></span>
              </span>
            </div>
          </li>
        </ul>
        <footer>
          <div>
            <strong></strong>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default React.memo(PolicyHoldersListSkeleton);
