import React from 'react';

import { MessageMediumType } from 'modules/redux/states/engagement/messages/constants';
import { ReactComponent as Email } from 'modules/theme/images/icons/history/email.svg';
import { ReactComponent as PushNotification } from 'modules/theme/images/icons/history/push-notification.svg';
import { ReactComponent as SMS } from 'modules/theme/images/icons/history/sms.svg';

type Props = {
  type: MessageMediumType;
};

const Icon: React.FunctionComponent<Props> = ({ type }) => {
  switch (type) {
    case MessageMediumType.EMAIL:
      return <Email />;
    case MessageMediumType.PUSH_NOTIFICATION:
      return <PushNotification />;
    case MessageMediumType.SMS:
      return <SMS />;
    default:
      return null;
  }
};

export default React.memo(Icon);
