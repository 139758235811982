import { API } from 'modules/api';
import { createDefaultAction } from '../../../../factories';
import { EventsAction } from '../../constants';
import {
  EventsGetAction,
  EventsGetFailureAction,
  EventsGetStartedAction,
  EventsGetSuccessAction,
} from './types';

export type {
  EventsGetActionApiRequest,
  EventsGetActionApiResponse,
  EventsGetActionType,
} from './types';

export const eventsGetAction: EventsGetAction = request =>
  createDefaultAction({
    actionStarted: eventsGetStartedAction,
    actionSuccess: eventsGetSuccessAction,
    actionFailure: eventsGetFailureAction,
    endpoint: API.GET_EVENT,
    isActive: state => !!state.events.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) => actionId === state.events.actionId,
    request: request,
  });

const eventsGetStartedAction: EventsGetStartedAction = payload => ({
  type: EventsAction.GET_STARTED,
  ...payload,
});

const eventsGetSuccessAction: EventsGetSuccessAction = payload => ({
  type: EventsAction.GET_SUCCESS,
  ...payload,
});

const eventsGetFailureAction: EventsGetFailureAction = payload => ({
  type: EventsAction.GET_FAILURE,
  ...payload,
});
