import React, { useCallback } from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { Padding } from 'components/Card/constants';
import { DrawerCustomTemplate, useDrawerCustom } from 'modules/drawers/hooks';
import Button from 'components/Button';
import Card from 'components/Card';

import styles from '../styles.module.scss';

const Drawers: React.FunctionComponent = () => {
  const { showDrawerCustom } = useDrawerCustom();
  const handleShowDrawerCustom = useCallback(() => {
    if (showDrawerCustom)
      showDrawerCustom({
        renderContent: ({ active, onClose }) => (
          <React.Fragment>
            <header>
              <h2>Custom Drawer</h2>
            </header>
            <div></div>
            <footer>
              <Button
                color={ButtonColor.BLUE}
                disabled={!active}
                label="Close"
                onClick={onClose}
                style={ButtonStyle.PRIMARY}
              />
            </footer>
          </React.Fragment>
        ),
      });
  }, [showDrawerCustom]);

  const handleAdditionalDetailsDrawer = useCallback(() => {
    if (showDrawerCustom)
      showDrawerCustom({
        template: DrawerCustomTemplate.DETAILS,
      });
  }, [showDrawerCustom]);

  return (
    <Card className={styles.buttons} padding={Padding.LARGE} title="Drawers">
      <div>
        <Button
          color={ButtonColor.BLUE}
          label="Custom Drawer"
          onClick={handleShowDrawerCustom}
          style={ButtonStyle.PRIMARY}
        />
        <Button
          color={ButtonColor.RED}
          label="Additional Details Drawer"
          onClick={handleAdditionalDetailsDrawer}
          style={ButtonStyle.PRIMARY}
        />
      </div>
    </Card>
  );
};

export default React.memo(Drawers);
