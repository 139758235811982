import React from 'react';

const Chevron: React.FunctionComponent = () => {
  return (
    <svg aria-hidden="true">
      <use href="#policy-holders-chevron" />
    </svg>
  );
};

export default React.memo(Chevron);
