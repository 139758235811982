import React, { useCallback } from 'react';
import clsx from 'clsx';

import { ModalAnimation } from '../../constants';
import { ModalCustomProps } from '../../types';
import ModalOverlay from '../ModalOverlay';

import templateStyles from '../Modals/styles.module.scss';

type ModalProps = Omit<
  ModalCustomProps,
  | 'animation'
  | 'destroyModal'
  | 'disableEscapeKey'
  | 'hideModal'
  | 'id'
  | 'onClose'
  | 'open'
> & {
  active?: boolean;
  onClose: () => void;
  payload?: Record<string, unknown>;
};

const Modal = React.memo<ModalProps>(
  ({ active, ariaLabel, onClose, payload, renderContent }) => {
    const classNames = clsx(templateStyles['modal-template']);
    return (
      <div aria-label={ariaLabel} className={classNames} role="dialog">
        {!!renderContent &&
          renderContent({ active: !!active, onClose, payload })}
      </div>
    );
  },
);

const ModalCustom: React.FunctionComponent<ModalCustomProps> = ({
  animation = ModalAnimation.SLIDE_UP,
  ariaLabel,
  destroyModal,
  disableEscapeKey = true,
  hideModal,
  id,
  onClose,
  open,
  payload,
  renderContent,
}) => {
  const handleClose = useCallback(() => {
    onClose ? open && onClose() : open && hideModal();
  }, [hideModal, onClose, open]);
  return (
    <ModalOverlay
      animation={animation}
      destroyModal={destroyModal}
      disableEscapeKey={disableEscapeKey}
      id={id}
      onClose={handleClose}
      open={open}>
      <Modal
        ariaLabel={ariaLabel}
        onClose={handleClose}
        payload={payload}
        renderContent={renderContent}
      />
    </ModalOverlay>
  );
};

export default React.memo(ModalCustom);
