import React from 'react';

import Button from 'components/Button';
import {
  ButtonColor,
  ButtonStyle,
  ButtonType,
} from 'components/Button/constants';
import { defaultValues, schema } from './model';
import Form from 'components/Form';
import { Model } from './model';
import { QuestionSize } from 'components/Questions/constants';
import { QuestionDate } from 'components/Questions';

import styles from './styles.module.scss';

export type { Model } from './model';

type DateRangeProps = {
  active: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: Model) => void;
  title: string;
};

const DateRange: React.FunctionComponent<DateRangeProps> = ({
  active,
  isLoading,
  onCancel,
  onSubmit,
  title,
}) => {
  return (
    <React.Fragment>
      <header>
        <h2>{title}</h2>
      </header>
      <Form<Model>
        className={styles['modal-template-date-range']}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        schema={schema}>
        {({ control, isFormSubmitting, isValid }) => {
          return (
            <React.Fragment>
              <div>
                <QuestionDate
                  control={control}
                  label="From"
                  name="startDate"
                  placeholder="DD/MM/YYYY"
                  readOnly={!active || isLoading}
                  size={QuestionSize.SMALL}
                />
                <QuestionDate
                  control={control}
                  label="To"
                  name="endDate"
                  placeholder="DD/MM/YYYY"
                  readOnly={!active || isLoading}
                  size={QuestionSize.SMALL}
                />
              </div>
              <footer>
                <Button
                  color={ButtonColor.BLUE}
                  disabled={!active || isFormSubmitting || isLoading}
                  label="Cancel"
                  onClick={onCancel}
                  style={ButtonStyle.SECONDARY}
                />
                <Button
                  color={ButtonColor.BLUE}
                  disabled={!active || !isValid}
                  label="Generate"
                  loading={isFormSubmitting || isLoading}
                  style={ButtonStyle.PRIMARY}
                  type={ButtonType.SUBMIT}
                />
              </footer>
            </React.Fragment>
          );
        }}
      </Form>
    </React.Fragment>
  );
};
export default React.memo(DateRange);
