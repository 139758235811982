import React from 'react';
import clsx from 'clsx';

import ErrorMessage from './ErrorMessage';
import InfiniteLoader from './InfiniteLoader';
import NoMoreTrips from './NoMoreTrips';
import TripsListItem from './TripsListItem';
import {
  UsePolicyTrip,
  UsePolicyTrips,
} from 'modules/redux/states/policy/hooks/usePolicySummary';
import useTripsList from './hooks';

import styles from './styles.module.scss';

type TripsListProps = {
  data: UsePolicyTrips['data'];
  handleClick: UsePolicyTrips['handleClick'];
  handleError: UsePolicyTrips['handleError'];
  handlePaging: UsePolicyTrips['handlePaging'];
  isError: UsePolicyTrips['isSendingError'];
  policyId?: string;
  tripId: UsePolicyTrip['tripId'];
};

const TripsList: React.FunctionComponent<TripsListProps> = ({
  data,
  handleClick,
  handleError,
  handlePaging,
  isError,
  policyId,
  tripId,
}) => {
  const { inView, ref } = useTripsList({
    handlePaging,
    policyId,
  });
  const classNames = clsx(styles['trips-list'], inView && styles['in-view']);
  return (
    <ul className={classNames}>
      {data?.results.map(trip => (
        <TripsListItem
          key={trip.tripId}
          handleClick={handleClick}
          selected={trip.tripId === tripId}
          {...trip}
        />
      ))}
      {!isError ? (
        <React.Fragment>
          {handlePaging ? (
            <InfiniteLoader inView={inView} forwardedRef={ref} />
          ) : (
            <NoMoreTrips />
          )}
        </React.Fragment>
      ) : (
        <ErrorMessage handleError={handleError} />
      )}
    </ul>
  );
};

export default React.memo(TripsList);
