import produce from 'immer';

import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from 'modules/redux/states/engagement/constants';

function additionalDetailsPostReducer(
  state: Engagement,
  action: EngagementActionType,
) {
  switch (action.type) {
    case EngagementAction.POST_ADDITIONAL_DETAILS_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.additionalDetails.actionId = null;
        draftState.additionalDetails.isSendingError = action.error;
        draftState.additionalDetails.isSending = false;
      });

    case EngagementAction.POST_ADDITIONAL_DETAILS_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.additionalDetails.actionId = action.actionId;
        draftState.additionalDetails.isSendingError = false;
        draftState.additionalDetails.isSending = action.controller || true;

        draftState.additionalDetails.dataKeyName = action.request.dataKeyName;
      });

    case EngagementAction.POST_ADDITIONAL_DETAILS_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.additionalDetails.actionId = null;
        draftState.additionalDetails.isSendingError = false;
        draftState.additionalDetails.isSending = false;

        if (draftState.additionalDetails) {
          draftState.additionalDetails.data?.data.userData.map(row => {
            if (row.keyName === action.request.dataKeyName) {
              draftState.report.data = null;
              return (row.value = action.request.value);
            } else {
              return row;
            }
          });
        }
      });

    case EngagementAction.POST_ADDITIONAL_DETAILS_RESET_ERROR:
      return produce(state, (draftState: Engagement) => {
        draftState.additionalDetails.isSendingError = false;
      });

    default:
      return state;
  }
}

export default additionalDetailsPostReducer;
