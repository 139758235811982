import { Policy, PolicyActionType } from './types';
import { PolicyAction } from './constants';
import scoresReducer from './scores/reducer';
import searchReducer from './search/reducer';
import policyReducer from './details/reducer';
import tripReducer from './trip/reducer';
import tripsReducer from './trips/reducer';

const initialState = (): Policy => {
  return {
    details: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
    },
    scores: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
    },
    trip: [],
    trips: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
      isSending: false,
      isSendingError: false,
    },
  };
};

function reducer(state = initialState(), action: PolicyActionType) {
  switch (action.type) {
    case PolicyAction.LOGOUT:
    case PolicyAction.RESET:
      return initialState();

    case PolicyAction.GET_DETAILS_FAILURE:
    case PolicyAction.GET_DETAILS_STARTED:
    case PolicyAction.GET_DETAILS_SUCCESS:
      return policyReducer(state, action);

    case PolicyAction.GET_SEARCH_FAILURE:
    case PolicyAction.GET_SEARCH_STARTED:
    case PolicyAction.GET_SEARCH_SUCCESS:
      return searchReducer(state, action);

    case PolicyAction.GET_SCORES_FAILURE:
    case PolicyAction.GET_SCORES_STARTED:
    case PolicyAction.GET_SCORES_SUCCESS:
      return scoresReducer(state, action);

    case PolicyAction.GET_TRIP_FAILURE:
    case PolicyAction.GET_TRIP_STARTED:
    case PolicyAction.GET_TRIP_SUCCESS:
    case PolicyAction.PUT_TRIP_FAILURE:
    case PolicyAction.PUT_TRIP_STARTED:
    case PolicyAction.PUT_TRIP_SUCCESS:
      return tripReducer(state, action);

    case PolicyAction.GET_TRIPS_FAILURE:
    case PolicyAction.GET_TRIPS_STARTED:
    case PolicyAction.GET_TRIPS_SUCCESS:
    case PolicyAction.PAGING_TRIPS_FAILURE:
    case PolicyAction.PAGING_TRIPS_STARTED:
    case PolicyAction.PAGING_TRIPS_SUCCESS:
      return tripsReducer(state, action);

    default:
      return state;
  }
}

export default reducer;
