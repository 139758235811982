import { shallowEqual, useSelector } from 'modules/redux';

import {
  ConfigEventsApplication,
  ConfigTripsApplication,
  ConfigUnits,
} from '../types';
import { ConfigUnitsDistance } from '../constants';

export type UseConfigSelector = {
  eventsApplication: ConfigEventsApplication;
  tripsApplication: ConfigTripsApplication;
  units: ConfigUnits;
};

const useConfigSelector = (): UseConfigSelector => {
  const { data } = useSelector(state => state.config, shallowEqual);
  return {
    eventsApplication: {
      enabled: data?.eventsApplication.enabled || false,
      invalidation: {
        enabled: data?.eventsApplication.invalidation.enabled || false,
        reasons: data?.eventsApplication.invalidation.reasons || [],
      },
      showGeometricLine: data?.eventsApplication.showGeometricLine || false,
    },
    tripsApplication: {
      enabled: data?.tripsApplication.enabled || false,
      showGeometricLine: data?.tripsApplication.showGeometricLine || false,
      scores: {
        aggregateTypes: {
          daily: data?.tripsApplication.scores.aggregateTypes.daily || false,
          weekly: data?.tripsApplication.scores.aggregateTypes.weekly || false,
          monthly:
            data?.tripsApplication.scores.aggregateTypes.monthly || false,
          ytd: data?.tripsApplication.scores.aggregateTypes.ytd || false,
        },
        types: data?.tripsApplication.scores.types || [],
      },
      reports: {
        enabled: data?.tripsApplication.reports.enabled || false,
        reportTypes: data?.tripsApplication.reports.reportTypes || [],
      },
      invalidation: {
        enabled: data?.tripsApplication.invalidation.enabled || false,
      },
    },
    units: { distance: data?.units?.distance || ConfigUnitsDistance.KM },
  };
};

export default useConfigSelector;
