import React, { useMemo } from 'react';

type AccountProps = { email?: string; name?: string };

const getInitials = (email?: string, name?: string) => {
  const array = (name ? name : email || '').trim().split(' ');
  const first = array[0]?.charAt(0).trim().toUpperCase();
  const second =
    array.length > 1
      ? array[array.length - 1]?.charAt(0).trim().toUpperCase()
      : array[0]?.charAt(1).trim().toUpperCase();
  return !!first && !!second ? `${first}${second}` : first ? first : '!';
};

const Account: React.FunctionComponent<AccountProps> = ({ email, name }) => {
  const initials = useMemo(() => getInitials(email, name), [email, name]);
  return !!email || !!name ? (
    <div>
      <div className="visually-hidden">
        {!!name && email !== name ? (
          <React.Fragment>
            Account: {name} ({email})
          </React.Fragment>
        ) : (
          <React.Fragment>Account: {email}</React.Fragment>
        )}
      </div>
      <span aria-hidden="true">{initials}</span>
      <div aria-hidden="true">
        <span>Account:</span>
        {!!name && email !== name && (
          <React.Fragment>
            <br />
            {name}
          </React.Fragment>
        )}
        {!!email && (
          <React.Fragment>
            <br />
            {email}
          </React.Fragment>
        )}
        <br />
      </div>
    </div>
  ) : null;
};

export default React.memo(Account);
