import React, { useCallback } from 'react';
import clsx from 'clsx';
import { UseFormReset } from 'react-hook-form';

import { ButtonType } from 'components/Button/constants';
import { defaultValues, Model, schema } from './model';
import { QuestionPolicyHoldersSearch } from 'components/Questions';
import { ReactComponent as ClearSVG } from 'modules/theme/images/icons/inputs/clear.svg';
import { ReactComponent as SearchSVG } from 'modules/theme/images/icons/inputs/search.svg';
import { UsePolicyHolderUsers } from 'modules/redux/states/engagement/hooks';
import Button from 'components/Button';
import Form from 'components/Form';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

type PolicyHoldersFormProps = {
  handleSearch: UsePolicyHolderUsers['handleSearch'];
  isLoading: boolean;
};

const PolicyHoldersForm: React.FunctionComponent<PolicyHoldersFormProps> = ({
  handleSearch,
  isLoading,
}) => {
  const classNames = clsx(
    styles['policy-holders-form'],
    isLoading && styles.loading,
  );

  const handleReset = useCallback(
    (reset: UseFormReset<Model>) => {
      reset();
      handleSearch('');
    },
    [handleSearch],
  );

  const handleSubmit = useCallback(
    (values: Model) => {
      handleSearch(values.policyHoldersSearch);
    },
    [handleSearch],
  );
  return (
    <Form<Model>
      className={classNames}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      schema={schema}>
      {({ control, isFormSubmitting, reset, values }) => {
        return (
          <React.Fragment>
            <QuestionPolicyHoldersSearch
              control={control}
              name="policyHoldersSearch"
              placeholder="Search by email address, policy number or rewards ID"
              readOnly={isLoading}>
              {!!values.policyHoldersSearch && (
                <Button
                  label="Clear"
                  disabled={isFormSubmitting || isLoading}
                  onClick={() => handleReset(reset)}>
                  <ClearSVG />
                </Button>
              )}
              <Button
                ariaLabel="Search"
                disabled={isFormSubmitting || isLoading}
                type={ButtonType.SUBMIT}>
                <SearchSVG />
              </Button>
              {isLoading && <Spinner />}
            </QuestionPolicyHoldersSearch>
          </React.Fragment>
        );
      }}
    </Form>
  );
};
export default React.memo(PolicyHoldersForm);
