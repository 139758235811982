import { useEffect } from 'react';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { engagementGetBrandAction } from 'modules/redux/states/engagement/brand/actions';
import { UsePolicyHolderBrand } from '../types';
import useHandleError from './hooks';

const usePolicyHolderBrand = (): UsePolicyHolderBrand => {
  const dispatch: Dispatch = useDispatch();
  const { data, isError, isLoading } = useSelector(
    state => state.engagement.brand,
    shallowEqual,
  );

  useEffect(() => {
    if (!data) dispatch(engagementGetBrandAction());
  }, [data, dispatch]);

  useHandleError({ dispatch, isError });

  return {
    data,
    isError,
    isLoading: !!isLoading,
  };
};

export default usePolicyHolderBrand;
