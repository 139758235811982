import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

type InfiniteLoaderProps = {
  inView: boolean;
  forwardedRef: (node?: Element | null | undefined) => void;
};

const InfiniteLoader: React.FunctionComponent<InfiniteLoaderProps> = ({
  inView,
  forwardedRef,
}) => {
  const classNames = clsx(
    styles['infinite-loader'],
    inView && styles['in-view'],
  );
  return (
    <li className={classNames} ref={forwardedRef}>
      <div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </li>
  );
};

export default React.memo(InfiniteLoader);
