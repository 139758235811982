import { useCallback, useEffect, useRef, useState } from 'react';

import { DrawerCustomTemplate } from '../constants';
import { DrawerCustomProps } from '../types';
import { getTemplate } from '../template';
import useDrawerAction from './useDrawerAction';

export type ShowDrawerCustomProps = Omit<
  DrawerCustomProps,
  'destroyDrawer' | 'hideDrawer' | 'id' | 'open'
> & { template?: DrawerCustomTemplate };

export type UseDrawerCustom = () => {
  hideDrawerCustom: () => void;
  showDrawerCustom?: (props: ShowDrawerCustomProps) => void;
};

const useDrawerCustom: UseDrawerCustom = () => {
  const { drawerHide, drawerShow } = useDrawerAction();
  const [drawer, setDrawer] = useState<string | null>();
  const drawerRef = useRef<() => void>();

  useEffect(() => {
    drawerRef.current = () => {
      drawer && drawerHide(drawer);
      setDrawer(null);
    };
  }, [drawer, drawerHide]);

  const hideDrawer = useCallback(
    () => drawerRef.current && drawerRef.current(),
    [],
  );

  const showDrawer = useCallback(
    ({ onClose, template, ...props }: ShowDrawerCustomProps) => {
      if (!drawer) {
        setDrawer(
          drawerShow({
            onClose: () => {
              onClose && onClose();
              hideDrawer();
            },
            ...getTemplate(template),
            ...props,
          }),
        );
      }
    },
    [drawer, drawerShow, hideDrawer],
  );

  useEffect(() => {
    return hideDrawer;
  }, [hideDrawer]);

  return {
    hideDrawerCustom: hideDrawer,
    showDrawerCustom: !drawer ? showDrawer : undefined,
  };
};

export default useDrawerCustom;
