import React from 'react';

import {
  ButtonColor,
  ButtonStyle,
  ButtonType,
} from 'components/Button/constants';
import { Model as AddPointsModel } from './Add/model';
import { Model as RemovePointsModel } from './Remove/model';
import { PointType } from '../../modalAddRemovePoints/Template/constants';
import { ReactComponent as Cross } from 'modules/theme/images/icons/drawer/cross.svg';
import { ReactComponent as WarningSVG } from 'modules/theme/images/icons/points/warning.svg';
import Add from './Add';
import Button from 'components/Button';
import Buttons from '../../components/Points/Buttons';
import Remove from './Remove';

import styles from './styles.module.scss';

type PointsProps = {
  active: boolean;
  bankedPoints?: number;
  forwardedRef: React.MutableRefObject<HTMLDivElement | null>;
  isSending: boolean;
  onAddPoints: (values: AddPointsModel) => void;
  onCancel: () => void;
  onClick: (pointType: PointType) => void;
  onRemovePoints: (values: RemovePointsModel) => void;
  onTransitionEnd: (event: React.TransitionEvent<HTMLDivElement>) => void;
  pointType: PointType;
};

const Points: React.FunctionComponent<PointsProps> = ({
  active,
  bankedPoints,
  forwardedRef,
  isSending,
  onAddPoints,
  onCancel,
  onClick,
  onRemovePoints,
  onTransitionEnd,
  pointType,
}) => {
  return (
    <React.Fragment>
      <header className={styles['add-remove-points']}>
        <h2>Add / Remove Points</h2>
        <Button
          ariaLabel="Close Add / Remove Points Modal"
          disabled={!active || isSending}
          onClick={onCancel}>
          <Cross />
        </Button>
      </header>
      <div>
        <Buttons
          bankedPoints={bankedPoints ?? 0}
          handleClick={onClick}
          isSending={isSending}
          pointType={pointType}
        />
        <div
          className={styles.warning}
          ref={forwardedRef}
          onTransitionEnd={onTransitionEnd}>
          <div>
            <WarningSVG />
            <p>
              Points will need to be banked by the driver for this to show on
              their total points balance.
            </p>
          </div>
        </div>
        {pointType === PointType.ADD && onAddPoints && (
          <Add
            active={active}
            handleAddPoints={onAddPoints}
            isSending={isSending}
            pointType={pointType}
          />
        )}
        {pointType === PointType.REMOVE && (
          <Remove
            active={active}
            bankedPoints={bankedPoints ?? 0}
            handleRemovePoints={onRemovePoints}
            isSending={isSending}
            pointType={pointType}
          />
        )}
      </div>
      <footer>
        <Button
          color={ButtonColor.BLUE}
          disabled={!active || isSending}
          label="Cancel"
          onClick={onCancel}
          style={ButtonStyle.SECONDARY}
        />
        <Button
          color={ButtonColor.BLUE}
          disabled={!active || isSending}
          form="points-form"
          label="Confirm"
          loading={isSending}
          style={ButtonStyle.PRIMARY}
          type={ButtonType.SUBMIT}
        />
      </footer>
    </React.Fragment>
  );
};
export default React.memo(Points);
