import { EngagementMessagesEmailTemplateOpenAction } from '../types';
import { EngagementAction } from '../../constants';

export {
  engagementGetMessagesAction,
  type EngagementGetMessagesActionApiRequest,
} from './engagementGetMessagesAction';

export {
  engagementGetMessagesEmailTemplateAction,
  type EngagementGetMessagesEmailTemplateActionApiRequest,
} from './engagementGetMessagesEmailTemplateAction';

export const engagementMessagesEmailTemplateOpenAction: EngagementMessagesEmailTemplateOpenAction =
  (endUserId, id) => ({
    type: EngagementAction.OPEN_MESSAGES_EMAIL_TEMPLATE,
    endUserId,
    id,
  });
