import { AggregateType } from './constants';

export const sortDates = (data: {
  results: {
    aggregateType: AggregateType;
    score: Record<string, unknown>;
    scoreDate: string;
  }[];
}) => {
  const dateSortedData = {
    results: data.results.sort((a, b) =>
      a.scoreDate.localeCompare(b.scoreDate),
    ),
  };
  return dateSortedData;
};
