import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { getExpireDate } from 'modules/utils/date';
import { ReactComponent as WalletIcon } from 'modules/theme/images/icons/card/wallet.svg';
import { UsePolicyHolderPoints } from 'modules/redux/states/engagement/hooks';
import Button from 'components/Button';
import Card from 'components/Card';

import styles from './styles.module.scss';

type Props = {
  data: UsePolicyHolderPoints['data'];
  handleClick: () => void;
};

const Component: React.FunctionComponent<Props> = ({ data, handleClick }) => {
  return (
    <Card
      className={styles['points-balance']}
      renderHeader={() => <WalletIcon />}
      title="Points Balance">
      <ul>
        <li>
          <strong>Banked Points Balance</strong>
          <p>
            <strong>{data?.data.bankedPoints}</strong> points
          </p>
        </li>
        <li>
          <strong>Unbanked Points Balance</strong>
          <p>
            <strong>{data?.data.currentPoints}</strong> points
          </p>
          {data?.data?.unbankedPoints &&
            data?.data?.unbankedPoints.length > 0 &&
            data?.data?.unbankedPoints[0]?.expireOn && (
              <span>
                Expires on{' '}
                {getExpireDate(data?.data?.unbankedPoints[0]?.expireOn)}
              </span>
            )}
        </li>
      </ul>
      <Button
        color={ButtonColor.BLUE}
        disabled={!handleClick}
        label="Add or remove points"
        onClick={handleClick}
        style={ButtonStyle.SECONDARY}
      />
    </Card>
  );
};

export default React.memo(Component);
