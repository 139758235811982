import React from 'react';
import clsx from 'clsx';

import { NotificationProps as Props } from '../../types';
import { ReactComponent as CloseSVG } from 'modules/theme/images/icons/notifications/close.svg';
import { useNotification } from './hooks';
import Icon from './Icon';

import styles from '../Notifications/styles.module.scss';

type NotificationProps = Omit<Props, 'destroyNotification' | 'visible'> & {
  forwardedClassNames: string;
  forwardedRef: React.MutableRefObject<HTMLDivElement | null>;
};

const Notification = React.memo<NotificationProps>(
  ({
    ariaLabel,
    hideNotification,
    forwardedClassNames,
    forwardedRef,
    id,
    message,
    severity,
  }) => {
    const classNames = clsx(
      styles['notifications-template'],
      styles[`${severity}`],
      forwardedClassNames,
    );

    return (
      <div
        aria-label={ariaLabel}
        className={classNames}
        id={`notification-${id}`}
        onClick={hideNotification}
        ref={forwardedRef}
        role="alert">
        <Icon type={severity} />
        {message && <p>{message}</p>}
        <CloseSVG />
      </div>
    );
  },
);

const NotificationInfo: React.FunctionComponent<Props> = ({
  ariaLabel,
  destroyNotification,
  hideNotification,
  id,
  index,
  message,
  pause,
  severity,
  total,
  visible,
}) => {
  const { classNames, ref } = useNotification(
    destroyNotification,
    hideNotification,
    visible,
    index,
    pause,
    total,
  );
  return (
    <Notification
      ariaLabel={ariaLabel}
      forwardedClassNames={classNames}
      forwardedRef={ref}
      hideNotification={hideNotification}
      id={id}
      message={message}
      severity={severity}
    />
  );
};

export default React.memo(NotificationInfo);
