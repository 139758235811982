import { useEffect } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { eventsGetAction } from '../../actions';
import { UseEvent } from './types';
import { useModalConfirm, useModalCustom } from 'modules/modals/hooks';

import {
  useEventSelector,
  useHandleApproveEvent,
  useHandleDeclineEvent,
  useHandleError,
  useHandleSessionTimeout,
} from './hooks';

const useEvent = (): UseEvent => {
  const { showModalConfirm } = useModalConfirm();
  const { showModalCustom } = useModalCustom();
  const dispatch: Dispatch = useDispatch();

  const { approved, data, enabled, eventId, isError, isLoading, isSending } =
    useEventSelector(showModalCustom);

  useEffect(() => {
    if (!data) dispatch(eventsGetAction({ eventId }));
  }, [data, dispatch, eventId]);

  const handleApproveEvent = useHandleApproveEvent({
    approved,
    enabled,
    eventId,
    isSending,
    showModalCustom,
  });

  const handleDeclineEvent = useHandleDeclineEvent({
    approved,
    enabled,
    eventId,
    isSending,
    showModalCustom,
  });

  const handleSessionTimeout = useHandleSessionTimeout();

  useHandleError({ dispatch, eventId, isError, showModalConfirm });

  return {
    approved,
    data,
    eventId,
    handleApproveEvent,
    handleDeclineEvent,
    handleSessionTimeout,
    isError,
    isLoading,
    isSending,
  };
};

export default useEvent;
