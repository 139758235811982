import { API } from 'modules/api';
import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from '../../../constants';
import {
  EngagementGetPointsAction,
  EngagementGetPointsStartedAction,
  EngagementGetPointsSuccessAction,
  EngagementGetPointsFailureAction,
} from './types';

export const engagementGetPointsAction: EngagementGetPointsAction = request =>
  createDefaultAction({
    actionStarted: engagementGetPointsStartedAction,
    actionSuccess: engagementGetPointsSuccessAction,
    actionFailure: engagementGetPointsFailureAction,
    endpoint: API.GET_ENGAGEMENT_POINTS,
    isActive: state => !!state.engagement.points.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      actionId === state.engagement.points.actionId,
    request: request,
  });

const engagementGetPointsStartedAction: EngagementGetPointsStartedAction =
  payload => ({
    type: EngagementAction.GET_POINTS_STARTED,
    ...payload,
  });

const engagementGetPointsSuccessAction: EngagementGetPointsSuccessAction =
  payload => ({
    type: EngagementAction.GET_POINTS_SUCCESS,
    ...payload,
  });

const engagementGetPointsFailureAction: EngagementGetPointsFailureAction =
  payload => ({
    type: EngagementAction.GET_POINTS_FAILURE,
    ...payload,
  });
