import { API } from 'modules/api';

import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import {
  EngagementGetDetailsAction,
  EngagementGetDetailsFailureAction,
  EngagementGetDetailsStartedAction,
  EngagementGetDetailsSuccessAction,
} from './types';

export type { EngagementGetDetailsActionApiRequest } from './types';

export const engagementGetDetailsAction: EngagementGetDetailsAction = request =>
  createDefaultAction({
    actionStarted: engagementGetDetailsStartedAction,
    actionSuccess: engagementGetDetailsSuccessAction,
    actionFailure: engagementGetDetailsFailureAction,
    endpoint: API.GET_ENGAGEMENT_DETAILS,
    isActive: state => !!state.engagement.details.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      actionId === state.engagement.details.actionId,
    request: request,
  });

const engagementGetDetailsStartedAction: EngagementGetDetailsStartedAction =
  payload => ({
    type: EngagementAction.GET_DETAILS_STARTED,
    ...payload,
  });

const engagementGetDetailsSuccessAction: EngagementGetDetailsSuccessAction =
  payload => ({
    type: EngagementAction.GET_DETAILS_SUCCESS,
    ...payload,
  });

const engagementGetDetailsFailureAction: EngagementGetDetailsFailureAction =
  payload => ({
    type: EngagementAction.GET_DETAILS_FAILURE,
    ...payload,
  });
