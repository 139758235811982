import React from 'react';

import styles from './styles.module.scss';

const Spinner: React.FunctionComponent = () => {
  return (
    <span className={styles.spinner}>
      <span></span>
    </span>
  );
};

export default React.memo(Spinner);
