import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import {
  useHandleError,
  useHandlePaging,
  useHandleGet,
  useHandleEmailTemplate,
} from './hooks';
import { UsePolicyHolderMessages } from '../types';

const usePolicyHolderMessages = (): UsePolicyHolderMessages => {
  const dispatch: Dispatch = useDispatch();
  const { endUserId } = useParams() as { endUserId: string };

  const {
    data,
    id,
    isError,
    isLoading,
    isSending,
    isSendingError,
    page,
    pageSize,
  } = useSelector(state => state.engagement.messages, shallowEqual);

  const handleEmailTemplate = useHandleEmailTemplate({
    data,
    endUserId,
    dispatch,
  });

  const handleError = useHandleError({ dispatch, endUserId, page, pageSize });

  const handlePaging = useHandlePaging({
    dispatch,
    endUserId,
    page,
    pageSize,
  });

  const handleGet = useHandleGet({
    dispatch,
    endUserId,
  });

  useEffect(() => {
    if (!data) {
      handleGet();
    }
  }, [data, dispatch, handleGet]);

  return {
    data,
    handleEmailTemplate,
    handleError,
    handleGet,
    handlePaging,
    id,
    isError,
    isLoading: !!isLoading,
    isSending: !!isSending,
    isSendingError,
    page,
    pageSize,
  };
};

export default usePolicyHolderMessages;
