import React, { useCallback, useState } from 'react';
import { UseFormReset } from 'react-hook-form';

import {
  ButtonColor,
  ButtonStyle,
  ButtonType,
} from 'components/Button/constants';
import { defaultValues, Model, schema } from './model';
import { QuestionSize } from 'components/Questions/constants';
import { QuestionTextArea } from 'components/Questions';
import { ModalInfoTemplate, useModalInfo } from 'modules/modals/hooks';
import Button from 'components/Button';
import Fieldset from 'components/Fieldset';
import Form from 'components/Form';
import logger from 'modules/logger';

const QuestionsTextAreas: React.FunctionComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showModalInfo } = useModalInfo();

  const handleSubmit = useCallback(
    async (data: Model, reset: UseFormReset<Model>) => {
      logger.debug('Submit:', data);
      setIsSubmitting(true);
      setTimeout(
        () =>
          !!showModalInfo &&
          showModalInfo({
            onClose: () => {
              setIsSubmitting(false);
              reset();
            },
            template: ModalInfoTemplate.DEBUG_SUCCESSFUL_SUBMISSION,
          }),
        2000,
      );
    },
    [showModalInfo],
  );

  return (
    <Form<Model>
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      schema={schema}>
      {({ control, isFormSubmitting }) => (
        <React.Fragment>
          <Fieldset title="TextAreas">
            <QuestionTextArea
              control={control}
              label="Label"
              name="debugTextArea"
              placeholder="Placeholder"
              readOnly={isSubmitting}
            />
            <QuestionTextArea
              control={control}
              label="Label"
              name="debugTextArea"
              placeholder="Placeholder"
              size={QuestionSize.SMALL}
              readOnly={isSubmitting}
            />
            <QuestionTextArea
              control={control}
              label="Label"
              name="debugTextAreaEmpty"
              placeholder="Placeholder"
              readOnly={isSubmitting}
            />
            <QuestionTextArea
              control={control}
              label="Label"
              name="debugTextAreaEmpty"
              placeholder="Placeholder"
              readOnly={isSubmitting}
              size={QuestionSize.SMALL}
            />
          </Fieldset>

          <Button
            color={ButtonColor.BLUE}
            label="Submit"
            loading={isFormSubmitting || isSubmitting || !showModalInfo}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.SUBMIT}
          />

          <Fieldset title="TextAreas - Disabled">
            <QuestionTextArea
              control={control}
              disabled={true}
              label="Label"
              placeholder="Placeholder"
              name="debugTextAreaDisabled"
              readOnly={isSubmitting}
            />
            <QuestionTextArea
              control={control}
              disabled={true}
              label="Label"
              name="debugTextAreaDisabled"
              placeholder="Placeholder"
              readOnly={isSubmitting}
              size={QuestionSize.SMALL}
            />
            <QuestionTextArea
              control={control}
              disabled={true}
              label="Label"
              placeholder="Placeholder"
              name="debugTextAreaDisabledEmpty"
              readOnly={isSubmitting}
            />
            <QuestionTextArea
              control={control}
              disabled={true}
              label="Label"
              name="debugTextAreaDisabledEmpty"
              placeholder="Placeholder"
              readOnly={isSubmitting}
              size={QuestionSize.SMALL}
            />
          </Fieldset>

          <Fieldset title="TextAreas - Read Only">
            <QuestionTextArea
              control={control}
              label="Label"
              name="debugTextAreaReadOnly"
              placeholder="Placeholder"
              readOnly={true}
            />
            <QuestionTextArea
              control={control}
              label="Label"
              name="debugTextAreaReadOnly"
              placeholder="Placeholder"
              readOnly={true}
              size={QuestionSize.SMALL}
            />
            <QuestionTextArea
              control={control}
              label="Label"
              name="debugTextAreaEmptyReadOnly"
              placeholder="Placeholder"
              readOnly={true}
            />
            <QuestionTextArea
              control={control}
              label="Label"
              name="debugTextAreaEmptyReadOnly"
              placeholder="Placeholder"
              readOnly={true}
              size={QuestionSize.SMALL}
            />
          </Fieldset>
        </React.Fragment>
      )}
    </Form>
  );
};

export default React.memo(QuestionsTextAreas);
