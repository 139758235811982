import React from 'react';

import { UseEvent } from 'modules/redux/states/events/hooks';
import Chart from './Chart';
import Skeleton from './Skeleton';

type RoadSpeedVsTravelSpeedProps = {
  chartIndex?: number;
  eventId: string | null;
  isLoading: boolean;
  setChartIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
  speeds: UseEvent['data'];
};

const RoadSpeedVsTravelSpeed: React.FunctionComponent<
  RoadSpeedVsTravelSpeedProps
> = ({ chartIndex, eventId, isLoading, setChartIndex, speeds }) => {
  return isLoading ? (
    <Skeleton />
  ) : (
    <Chart
      chartIndex={chartIndex}
      eventId={eventId}
      setChartIndex={setChartIndex}
      speeds={speeds}
    />
  );
};

export default React.memo(RoadSpeedVsTravelSpeed);
