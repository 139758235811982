import { useCallback } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { Notification as NotificationProps } from '../types';
import { notificationHideAction, notificationShowAction } from '../actions';
import store from 'modules/redux';

export type Notification = Omit<NotificationProps, 'id' | 'visible'>;

const useNotificationAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    notificationHide: useCallback(
      (id: string) => {
        if (
          store
            .getState()
            .notifications.find(
              notification => notification.id === id && notification.visible,
            )
        ) {
          dispatch(notificationHideAction(id));
        }
      },
      [dispatch],
    ),
    notificationHideAll: useCallback(() => {
      if (store.getState().notifications) {
        store
          .getState()
          .notifications.map(notification =>
            dispatch(notificationHideAction(notification.id)),
          );
      }
    }, [dispatch]),
    notificationShow: useCallback(
      (notification: Notification) => {
        return dispatch(notificationShowAction(notification));
      },
      [dispatch],
    ),
  };
};

export default useNotificationAction;
