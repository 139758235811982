import yup from 'modules/validation';

export type Model = {
  reasonForDeclining: string;
};

export const defaultValues: Model = {
  reasonForDeclining: '',
};

export const schema = yup.object().shape({
  reasonForDeclining: yup.string().required(),
});
