import React from 'react';

import { UsePolicyHolderRewards } from 'modules/redux/states/engagement/hooks';
import Item from './Item';
import Pagination from 'modules/pagination';

import styles from './styles.module.scss';

type ComponentProps = {
  data: UsePolicyHolderRewards['data'];
  handlePaging: UsePolicyHolderRewards['handlePaging'];
  isLoading: UsePolicyHolderRewards['isLoading'];
  page: UsePolicyHolderRewards['page'];
  pageSize: UsePolicyHolderRewards['pageSize'];
};

const Component: React.FunctionComponent<ComponentProps> = ({
  data,
  handlePaging,
  isLoading,
  page,
  pageSize,
}) => {
  return (
    <div className={styles['history-rewards']}>
      <ul>
        {data?.data.map((reward, index) => {
          return (
            <Item
              key={`reward-item-${index}`}
              currentBalance={reward.currentBalance}
              cultureResourceKey={reward.cultureResourceKey}
              dateOfReward={reward.dateOfReward}
              description={reward.description}
              logType={reward.logType}
              previousBalance={reward.previousBalance}
              reason={reward.reason}
            />
          );
        })}
      </ul>
      {page && pageSize && data?.message && (
        <Pagination
          handlePaging={handlePaging}
          isLoading={isLoading}
          page={page}
          pageSize={pageSize}
          totalCount={Number(data.message.split('of')[1])}
        />
      )}
    </div>
  );
};

export default React.memo(Component);
