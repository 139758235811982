import React from 'react';

import { WidthPercentage } from 'components/Layout/constants';
import * as Layout from 'components/Layout';
import PageTitle from 'components/PageTitle';
import PolicyHolders from './components/PolicyHolders';
import { UsePolicyHolderUsers } from 'modules/redux/states/engagement/hooks';

type TemplateProps = {
  users: UsePolicyHolderUsers;
};

const Template: React.FunctionComponent<TemplateProps> = ({ ...props }) => {
  return (
    <React.Fragment>
      <PageTitle title="Policy Holders" />
      <Layout.Container>
        <Layout.Column width={WidthPercentage.HUNDRED}>
          <PolicyHolders {...props.users} />
        </Layout.Column>
      </Layout.Container>
    </React.Fragment>
  );
};

export default React.memo(Template);
