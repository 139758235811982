import { PolicyAction } from './constants';
import { PolicyLogoutAction, PolicyResetAction } from './types';

export { policyGetDetailsAction } from './details/actions';
export { policyGetSearchAction } from './search/actions';
export { policyGetScoresAction } from './scores/actions';
export { policyGetTripAction, policyPutTripAction } from './trip/actions';
export { policyGetTripsAction, policyPagingTripsAction } from './trips/actions';

export const policyLogoutAction: PolicyLogoutAction = () => ({
  type: PolicyAction.LOGOUT,
});

export const policyResetAction: PolicyResetAction = () => ({
  type: PolicyAction.RESET,
});
