import React from 'react';
import clsx from 'clsx';

import { CardProps } from './types';
import { FontSize, Padding } from './constants';

import styles from './styles.module.scss';

const Card: React.FunctionComponent<CardProps> = ({
  children,
  className,
  padding = Padding.SMALL,
  fontSize = FontSize.LARGE,
  forwardedRef,
  scrollable,
  renderHeader,
  title,
}) => {
  const classNames = clsx(
    styles.card,
    styles[`padding-${padding}`],
    styles[`font-size-${fontSize}`],
    scrollable && styles.scrollable,
    className,
  );
  return (
    <section className={classNames} ref={forwardedRef}>
      {(renderHeader || title) && (
        <header>
          {title && <h2>{title}</h2>}
          {renderHeader && renderHeader()}
        </header>
      )}
      <div>{children}</div>
    </section>
  );
};

export default React.memo(Card);
