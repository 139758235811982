import type { UsePolicyHolderUsers } from './types';
import usePolicyHolderBrand from './usePolicyHolderBrand';
import usePolicyHolderUsers from './usePolicyHolderUsers';

export type { UsePolicyHolderBrand, UsePolicyHolderUsers } from './types';

const usePolicyHolderSearch = (): {
  users: UsePolicyHolderUsers;
} => {
  const brand = usePolicyHolderBrand();
  const users = usePolicyHolderUsers(brand.data?.brandId);

  return { users };
};

export default usePolicyHolderSearch;
