import React, { useCallback, useState } from 'react';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import {
  notificationDestroyAction,
  notificationHideAction,
} from 'modules/notifications/actions';
import Notification from '../Notification';

import styles from './styles.module.scss';

const Notifications: React.FunctionComponent = () => {
  const notifications = useSelector(state => state.notifications, shallowEqual);
  const [pause, setPause] = useState(false);
  const dispatch: Dispatch = useDispatch();
  const handleMouseEnter = useCallback(() => setPause(true), []);
  const handleMouseLeave = useCallback(() => setPause(false), []);
  return notifications.length > 0 ? (
    <div
      className={styles['notifications']}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {notifications.map((notification, index) => {
        return (
          <Notification
            destroyNotification={() =>
              dispatch(notificationDestroyAction(notification.id))
            }
            hideNotification={() =>
              dispatch(notificationHideAction(notification.id))
            }
            index={index + 1}
            key={notification.id}
            pause={pause}
            total={notifications.length}
            {...notification}
          />
        );
      })}
    </div>
  ) : null;
};

export default Notifications;
