import usePolicyDetails from './usePolicyDetails';
import usePolicyScores from './usePolicyScores';
import usePolicyTrip from './usePolicyTrip';
import usePolicyTrips from './usePolicyTrips';

import type {
  UsePolicyDetails,
  UsePolicyScores,
  UsePolicyTrip,
  UsePolicyTrips,
} from './types';

export type {
  UsePolicyDetails,
  UsePolicyScores,
  UsePolicyTrip,
  UsePolicyTrips,
} from './types';

const usePolicySummary = (): {
  details: UsePolicyDetails;
  scores: UsePolicyScores;
  trip: UsePolicyTrip | null;
  trips: UsePolicyTrips;
} => {
  const details = usePolicyDetails();

  const scores = usePolicyScores(details.data?.policyDetails.identifier);

  const trips = usePolicyTrips(details.data?.policyDetails.identifier);

  const trip = usePolicyTrip(details.data?.policyDetails.identifier);

  return { details, scores, trip, trips };
};

export default usePolicySummary;
