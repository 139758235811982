import React, { useRef } from 'react';
import clsx from 'clsx';

import { FontSize, Padding } from 'components/Card/constants';
import { ReactComponent as SpinnerSVG } from 'modules/theme/images/icons/spinners/circular-bars.svg';
import Card from 'components/Card';

import chartStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const ref = useRef<HTMLElement>(null);

  const classNames = clsx(chartStyles.charts, styles.skeleton);
  return (
    <Card
      className={classNames}
      fontSize={FontSize.SMALL}
      forwardedRef={ref}
      padding={Padding.SMALL}
      title="Road speed vs travel speed">
      <span data-nosnippet className="visually-hidden">
        Loading road speed vs travel speed chart
      </span>
      <SpinnerSVG />
      <div className={chartStyles['chart-container']}>
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Card>
  );
};

export default React.memo(Skeleton);
