import { api, newAbortController } from 'modules/api';
import { CreateDefaultAction } from './types';
import { DefaultApiResponse } from 'modules/api/types';
import { Dispatch, RootState } from 'modules/redux';
import { isAxiosAbortError, isUnauthorizedError } from 'modules/api/errors';
import { timeout } from '../utils';
import { v4 as uuidv4 } from 'uuid';

export const createDefaultAction =
  <Request, Response>({
    actionStarted,
    actionSuccess,
    actionFailure,
    endpoint,
    isActive,
    isAuthorized,
    isPresent,
    request,
  }: CreateDefaultAction<Request, Response>) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const startTime = new Date().getTime();
    const actionId = uuidv4();
    let state = getState();
    try {
      if (!isAuthorized(state) || isActive(state)) return;

      const controller = newAbortController();

      dispatch(actionStarted({ actionId, controller, request }));

      const response: DefaultApiResponse = await api({
        controller,
        dispatch,
        getState,
        ...endpoint(request),
      });

      await timeout(startTime, 1000);

      state = getState();

      if (isAuthorized(state) && isActive(state) && isPresent(actionId, state))
        dispatch(actionSuccess({ actionId, data: response.data, request }));
    } catch (error) {
      await timeout(startTime, 1000);

      state = getState();

      if (
        !isUnauthorizedError(error) &&
        !isAxiosAbortError(error) &&
        isAuthorized(state) &&
        isActive(state) &&
        isPresent(actionId, state)
      )
        dispatch(
          actionFailure({
            actionId,
            error:
              error instanceof Error ? error : new Error('Unexpected Error.'),
            request,
          }),
        );
    }
  };
