import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { isAdmin } from 'modules/admin';
import { ReactComponent as ErrorSVG } from 'modules/theme/images/icons/inputs/error.svg';
import Button from 'components/Button';

import styles from './styles.module.scss';

type PolicyHoldersErrorProps = {
  handleError: () => void;
  isError: Error | false;
};

const ErrorMessage: React.FunctionComponent<PolicyHoldersErrorProps> = ({
  handleError,
  isError,
}) => {
  return (
    <div className={styles['policy-holders-error']}>
      <ErrorSVG />
      <h2>{isAdmin ? 'Error' : 'Something went wrong'}</h2>
      {isAdmin && !!isError && isError.message && <p>{isError.message}</p>}
      <Button
        color={ButtonColor.BLUE}
        label="Retry"
        onClick={handleError}
        style={ButtonStyle.PRIMARY}
      />
    </div>
  );
};

export default React.memo(ErrorMessage);
