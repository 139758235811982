import { useCallback } from 'react';

import { policyGetTripAction } from 'modules/redux/states/policy/actions';
import { UseHandleError } from './types';

const useHandleError: UseHandleError = ({
  dispatch,
  policyId,
  subjectId,
  tripId,
}) =>
  useCallback(() => {
    if (policyId && subjectId && tripId)
      dispatch(policyGetTripAction({ policyId, subjectId, tripId }));
  }, [dispatch, policyId, subjectId, tripId]);

export default useHandleError;
