import React, { useCallback } from 'react';
import clsx from 'clsx';

import { InputPrimitiveProps } from '../types';
import { InputType } from '../constants';

import styles from '../styles.module.scss';

const InputPrimitive: React.FunctionComponent<InputPrimitiveProps> = ({
  autoFocus,
  children,
  disabled,
  id,
  inputRef,
  invalid,
  name,
  onBlur,
  onFocus,
  readOnly,
  size,
  tabIndex,
  type = InputType.TEXT,
  value,
  ...props
}) => {
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      !disabled && !!onBlur && onBlur(event);
    },
    [disabled, onBlur],
  );
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      disabled && event.currentTarget.blur();
      !disabled && !!onFocus && onFocus(event);
    },
    [disabled, onFocus],
  );
  const getTabIndex = useCallback(() => {
    if (disabled) return -1;
    return tabIndex ? tabIndex : 0;
  }, [disabled, tabIndex]);

  const className = clsx(
    styles.input,
    styles[`${size}`],
    styles[`${type}`],
    disabled && styles.disabled,
    readOnly && styles['read-only'],
    !disabled && invalid && styles.invalid,
  );

  return (
    <div className={className}>
      <input
        autoFocus={!disabled && autoFocus}
        id={id || name}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        readOnly={readOnly}
        ref={inputRef}
        tabIndex={getTabIndex()}
        type={type}
        value={value || ''}
        {...props}
      />
      {children}
    </div>
  );
};

export default React.memo(InputPrimitive);
