import React from 'react';

import { usePolicySearch } from 'modules/redux/states/policy/hooks';

import Template from './Template';

const Route: React.FunctionComponent = () => {
  const { handleSearch, isError, isLoading } = usePolicySearch();

  return (
    <Template
      handleSearch={handleSearch}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default Route;
