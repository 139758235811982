import React from 'react';
import clsx from 'clsx';

import { ReactComponent as LinkFillIcon } from 'modules/theme/images/icons/history/link-fill.svg';
import { ReactComponent as LinkIcon } from 'modules/theme/images/icons/history/link.svg';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

type EmailTemplateProps = {
  active: boolean;
  body: string | null;
  children: React.ReactNode;
  handleEmailTemplate: (id: number) => void;
  id: number;
  isSending: boolean;
};

const EmailTemplate: React.FunctionComponent<EmailTemplateProps> = ({
  active,
  body,
  children,
  handleEmailTemplate,
  id,
  isSending,
}) => {
  const classNames = clsx(
    styles['history-messages-item'],
    active && isSending && styles.fetching,
  );

  return (
    <li className={classNames}>
      <Button
        ariaLabel="Open Email"
        disabled={isSending && !body}
        onClick={() => handleEmailTemplate(id)}>
        {children}
        {active && isSending ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <LinkIcon />
            <LinkFillIcon />
          </React.Fragment>
        )}
      </Button>
    </li>
  );
};

export default React.memo(EmailTemplate);
