import React from 'react';

import { UsePolicyScores } from 'modules/redux/states/policy/hooks/usePolicySummary';
import Chart from './Chart';
import ErrorMessage from './ErrorMessage';
import Skeleton from './Skeleton';

type ScoreOverTimeProps = {
  handleError: UsePolicyScores['handleError'];
  handleUpdate: UsePolicyScores['handleUpdate'];
  isError: UsePolicyScores['isError'];
  isLoading: UsePolicyScores['isLoading'];
  scores: UsePolicyScores['data'];
};

const ScoreOverTime: React.FunctionComponent<ScoreOverTimeProps> = ({
  handleError,
  handleUpdate,
  isError,
  isLoading,
  scores,
}) => {
  return isError ? (
    <ErrorMessage handleError={handleError} isError={isError} />
  ) : isLoading ? (
    <Skeleton />
  ) : (
    <Chart handleUpdate={handleUpdate} scores={scores} />
  );
};

export default React.memo(ScoreOverTime);
