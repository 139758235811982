import React, { useMemo } from 'react';

import Card from 'components/Card';
import ErrorMessage from './ErrorMessage';
import Filter from './Filter';
import { formatDateRange } from 'modules/utils';
import NoResults from './NoResults';
import Skeleton from './Skeleton';
import TripsList from './TripsList';
import {
  UsePolicyTrip,
  UsePolicyTrips,
} from 'modules/redux/states/policy/hooks/usePolicySummary';

type TripsProps = {
  data: UsePolicyTrips['data'];
  endDate: UsePolicyTrips['endDate'];
  handleClick: UsePolicyTrips['handleClick'];
  handleError: UsePolicyTrips['handleError'];
  handlePaging: UsePolicyTrips['handlePaging'];
  handleSearch: UsePolicyTrips['handleSearch'];
  isError: UsePolicyTrips['isError'];
  isLoading: UsePolicyTrips['isLoading'];
  isSendingError: UsePolicyTrips['isSendingError'];
  policyId?: string;
  startDate: UsePolicyTrips['startDate'];
  tripId: UsePolicyTrip['tripId'];
};

const Trips: React.FunctionComponent<TripsProps> = ({
  data,
  endDate,
  handleClick,
  handleError,
  handlePaging,
  handleSearch,
  isError,
  isLoading,
  isSendingError,
  policyId,
  startDate,
  tripId,
}) => {
  const dateRange = useMemo(
    () =>
      formatDateRange({
        endDate: data?.results ? data.results[0].endDateTime : '',
        startDate: data?.results ? data.results.slice(-1)[0].startDateTime : '',
      }),
    [data],
  );
  return isError ? (
    <ErrorMessage handleError={handleError} isError={isError} />
  ) : (
    <Card
      renderHeader={() => (
        <Filter
          dateRange={dateRange}
          handleSearch={handleSearch}
          isLoading={isLoading}
        />
      )}
      scrollable={true}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <React.Fragment>
          {data?.results && data.results.length > 0 ? (
            <TripsList
              data={data}
              handleClick={handleClick}
              handleError={handleError}
              handlePaging={handlePaging}
              isError={isSendingError}
              policyId={policyId}
              tripId={tripId}
            />
          ) : (
            <NoResults />
          )}
        </React.Fragment>
      )}
    </Card>
  );
};

export default React.memo(Trips);
