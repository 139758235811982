import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { convertToTxt } from './utils';
import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { engagementGetReportAction } from 'modules/redux/states/engagement/report/actions';
import { ModalConfirmTemplate, useModalConfirm } from 'modules/modals/hooks';
import { ModalErrorText } from 'modules/modals/components/ModalErrorMessage';
import { UsePolicyHolderReport } from '../types';
import { ROUTE } from 'modules/router/constants';

const usePolicyHolderReport = (): UsePolicyHolderReport => {
  const { brandId, endUserId } = useParams() as {
    brandId: string;
    endUserId: string;
  };
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isError, isLoading } = useSelector(
    state => state.engagement.report,
    shallowEqual,
  );
  const { showModalConfirm } = useModalConfirm();

  const handleDownload = useCallback(
    () => data?.data && convertToTxt(data.data),
    [data],
  );

  const handleError = useCallback(() => {
    if (showModalConfirm) {
      showModalConfirm({
        onCancel: () => navigate(ROUTE.ENGAGEMENT_SEARCH, { replace: true }),
        onConfirm: () =>
          dispatch(
            engagementGetReportAction({ brandId, externalid: endUserId }),
          ),
        renderContent: () => (
          <ModalErrorText error={isError ? isError : undefined} />
        ),
        template: ModalConfirmTemplate.POLICY_HOLDER_REPORT_ERROR,
      });
    }
  }, [brandId, dispatch, endUserId, isError, navigate, showModalConfirm]);

  useEffect(() => {
    if (!data)
      dispatch(engagementGetReportAction({ brandId, externalid: endUserId }));
  }, [brandId, data, dispatch, endUserId]);

  useEffect(() => {
    if (isError) handleError();
  }, [handleError, isError]);

  return {
    data,
    handleError,
    handleDownload,
    isError,
    isLoading: !!isLoading || !data?.data,
  };
};

export default usePolicyHolderReport;
