import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import { AppAction } from './constants';
import { AppActionType } from './types';
import { getRoute } from 'modules/router/utils';
import { RootState } from 'modules/redux';
import { RouterActionType } from 'modules/router/types';
import { setupPlatform } from './helpers';

const createDocumentsMiddleware: () => Middleware<RootState> =
  () => () => next => async (action: AppActionType | RouterActionType) => {
    switch (action.type) {
      case AppAction.INIT:
        setupPlatform(action.platform);
        break;

      case LOCATION_CHANGE: {
        const route = getRoute(action.payload.location.pathname);
        document.title = `IMS${route && ` | ${route.title}`}`;
        break;
      }
    }

    return next(action);
  };

export default createDocumentsMiddleware;
