import { useCallback, useEffect, useRef, useState } from 'react';

import { getTemplate } from '../templates/confirm';
import { ModalConfirmTemplate, ModalType } from '../constants';
import { ModalConfirmProps } from '../types';
import useModalAction from './useModalAction';

export type ShowModalConfirmProps = Omit<
  ModalConfirmProps,
  'destroyModal' | 'hideModal' | 'id' | 'open'
> & { template?: ModalConfirmTemplate };

export type UseModalConfirm = () => {
  hideModalConfirm: () => void;
  showModalConfirm?: (props: ShowModalConfirmProps) => void;
};

const useModalConfirm: UseModalConfirm = () => {
  const { modalShow, modalHide } = useModalAction();
  const [modal, setModal] = useState<string | null>();
  const modalRef = useRef<() => void>();

  useEffect(() => {
    modalRef.current = () => {
      modal && modalHide(modal);
      setModal(null);
    };
  }, [modal, modalHide]);

  const hideModal = useCallback(
    () => modalRef.current && modalRef.current(),
    [],
  );

  const showModal = useCallback(
    ({ onCancel, onConfirm, template, ...props }: ShowModalConfirmProps) => {
      if (!modal) {
        setModal(
          modalShow({
            onCancel: () => {
              onCancel && onCancel();
              hideModal();
            },
            onConfirm: () => {
              onConfirm && onConfirm();
              hideModal();
            },
            type: ModalType.CONFIRM,
            ...getTemplate(template),
            ...props,
          }),
        );
      }
    },
    [hideModal, modal, modalShow],
  );

  useEffect(() => {
    return hideModal;
  }, [hideModal]);

  return {
    hideModalConfirm: hideModal,
    showModalConfirm: !modal ? showModal : undefined,
  };
};

export default useModalConfirm;
