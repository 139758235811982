import { useCallback } from 'react';

import { authLogoutAction } from '../actions';
import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';

export type UseAuthAccount = {
  email?: string;
  handleLogout: () => void;
  name?: string;
};

const useAuthAccount = (): UseAuthAccount => {
  const user = useSelector(state => state.auth.user, shallowEqual);
  const dispatch: Dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(authLogoutAction());
  }, [dispatch]);

  return { email: user?.email, name: user?.name, handleLogout };
};

export default useAuthAccount;
