import React from 'react';
import {
  Control,
  DeepMap,
  DeepPartial,
  FieldValues,
  UnpackNestedValue,
  useForm,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form';

import { AnyObjectSchema, resolver } from 'modules/validation';
import logger from 'modules/logger';

const onError = (errors: unknown) =>
  logger.debug('Form Validation Errors', errors);

type FormProps<T extends FieldValues> = {
  children?: (props: {
    control: Control<T>;
    dirtyFields?: DeepMap<DeepPartial<T>, boolean>;
    isValid: boolean;
    isFormSubmitting: boolean;
    reset: UseFormReset<T>;
    setValue?: UseFormSetValue<T>;
    values: UnpackNestedValue<T>;
  }) => React.ReactNode;
  className?: string;
  defaultValues: UnpackNestedValue<DeepPartial<T>>;
  formRef?: React.MutableRefObject<HTMLFormElement | null>;
  id?: string;
  onSubmit: (
    data: UnpackNestedValue<T>,
    reset: UseFormReset<T>,
    event?: React.BaseSyntheticEvent,
  ) => unknown | Promise<unknown>;
  schema?: AnyObjectSchema;
};

function Form<T extends FieldValues>({
  children,
  className,
  defaultValues,
  formRef,
  id,
  onSubmit,
  schema,
}: FormProps<T>) {
  const {
    control,
    formState: { isSubmitting, dirtyFields, isValid },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<T>({
    criteriaMode: 'firstError',
    defaultValues: defaultValues,
    mode: 'onTouched',
    resolver: schema ? resolver(schema) : undefined,
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const values = watch();
  return (
    <form
      className={className}
      id={id}
      onSubmit={handleSubmit(
        async (data, event) => await onSubmit(data, reset, event),
        onError,
      )}
      ref={formRef}
      noValidate={true}>
      {children &&
        children({
          control,
          isFormSubmitting: isSubmitting,
          isValid,
          dirtyFields,
          reset,
          setValue,
          values,
        })}
    </form>
  );
}

export default React.memo(Form) as typeof Form;
