import { useCallback } from 'react';

import { engagementGetRewardsAction } from 'modules/redux/states/engagement/rewards/actions';
import { Paging } from 'modules/pagination';
import { UseHandleError, UseHandleGet, UseHandlePaging } from './types';

export const useHandleError: UseHandleError = ({
  dispatch,
  endUserId,
  page,
  pageSize,
}) =>
  useCallback(
    () =>
      dispatch(
        engagementGetRewardsAction({
          endUserId,
          page,
          pageSize,
        }),
      ),
    [dispatch, endUserId, page, pageSize],
  );

export const useHandlePaging: UseHandlePaging = ({
  dispatch,
  endUserId,
  page,
  pageSize,
}) =>
  useCallback(
    (type: Paging) => {
      dispatch(
        engagementGetRewardsAction({
          endUserId,
          page: page ? (type === Paging.NEXT ? page + 1 : page - 1) : undefined,
          pageSize,
        }),
      );
    },
    [dispatch, endUserId, page, pageSize],
  );

export const useHandleGet: UseHandleGet = ({ dispatch, endUserId }) =>
  useCallback(
    () =>
      dispatch(
        engagementGetRewardsAction({
          endUserId,
          page: 1,
          pageSize: 10,
        }),
      ),
    [dispatch, endUserId],
  );
