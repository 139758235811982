import retry from 'async-retry';
import axios from 'axios';

import { isAbortError, isUnauthorizedError } from './errors';
import { newAuthError } from 'modules/auth/providers/auth0';
import { authLogoutAction } from 'modules/auth/actions';
import { Api, DefaultApiResponse } from './types';
import { getAxiosConfig } from './utils';
import { isAdmin } from 'modules/admin';
import logger from 'modules/logger';

export { API } from './constants';
export { apiAbort, newAbortController } from './utils';

const RETRIES = isAdmin ? 2 : 5;

export const api: Api = async ({ dispatch, getState, ...config }) => {
  const response = await retry(
    async bail => {
      try {
        return await axios(
          await getAxiosConfig({ dispatch, getState, ...config }),
        );
      } catch (error) {
        if (isUnauthorizedError(error) && getState().auth.isAuthenticated) {
          dispatch(authLogoutAction());
          return bail(newAuthError());
        }
        if (isAbortError(error)) return bail(error as Error);
        logger.warn('API RETRY');
        throw error;
      }
    },
    {
      retries: RETRIES,
    },
  );
  return response as DefaultApiResponse;
};
