import { Config } from '../types';
import { shallowEqual, useSelector } from 'modules/redux';

export type UseConfig = {
  data: Config['data'];
  isError: Config['isError'];
  isLoading: boolean;
};

const useConfig = (): UseConfig => {
  const { data, isError } = useSelector(state => state.config, shallowEqual);
  return { data, isError, isLoading: !data };
};

export default useConfig;
