import yup from 'modules/validation';

export type Model = {
  debugSelect: string;
  debugSelectEmpty: string;
  debugSelectDisabled: string;
  debugSelectDisabledEmpty: string;
  debugSelectReadOnly: string;
  debugSelectEmptyReadOnly: string;
};

export const defaultValues: Model = {
  debugSelect: 'option-1',
  debugSelectEmpty: '',
  debugSelectDisabled: 'option-1',
  debugSelectDisabledEmpty: '',
  debugSelectReadOnly: 'option-1',
  debugSelectEmptyReadOnly: '',
};

export const schema = yup.object().shape({
  debugSelect: yup.string().required(),
  debugSelectEmpty: yup.string().required(),
  debugSelectDisabled: yup.string(),
  debugSelectDisabledEmpty: yup.string(),
  debugSelectReadOnly: yup.string(),
  debugSelectEmptyReadOnly: yup.string(),
});
