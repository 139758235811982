import { ModalConfirmProps } from '../../types';
import { ModalConfirmTemplate } from '../../constants';

import modalDebug from './modalDebug';
import modalEventsError from './modalEventsError';
import modalPolicyDetailsError from './modalPolicyDetailsError';
import modalPolicyHolderAdditionalDetailsSendingError from './modalPolicyHolderAdditionalDetailsSendingError';
import modalPolicyHolderPostPointsError from './modalPolicyHolderPostPointsError';
import modalPolicyHolderReportError from './modalPolicyHolderReportError';

export type ModalConfirmTemplateProps = Omit<
  ModalConfirmProps,
  'destroyModal' | 'hideModal' | 'id' | 'open'
>;

export const getTemplate = (
  template?: ModalConfirmTemplate,
): ModalConfirmTemplateProps | undefined => {
  switch (template) {
    case ModalConfirmTemplate.DEBUG:
      return modalDebug;
    case ModalConfirmTemplate.EVENTS_ERROR:
      return modalEventsError;
    case ModalConfirmTemplate.POLICY_DETAILS_ERROR:
      return modalPolicyDetailsError;
    case ModalConfirmTemplate.POLICY_HOLDER_REPORT_ERROR:
      return modalPolicyHolderReportError;
    case ModalConfirmTemplate.POLICY_HOLDER_ADDITIONAL_DETAILS_SENDING_ERROR:
      return modalPolicyHolderAdditionalDetailsSendingError;
    case ModalConfirmTemplate.POLICY_HOLDER_POST_POINTS_SENDING_ERROR:
      return modalPolicyHolderPostPointsError;
    default:
      return undefined;
  }
};
