import React from 'react';
import clsx from 'clsx';

import { ReactComponent as ChatFillIcon } from 'modules/theme/images/icons/history/chat-fill.svg';
import { ReactComponent as ChatIcon } from 'modules/theme/images/icons/history/chat.svg';
import { RewardCultureResourceType } from 'modules/redux/states/engagement/rewards/constants';
import { useToggle } from './hooks';
import Button from 'components/Button';
import Icon from '../Icon';

import styles from './styles.module.scss';

type Props = {
  currentBalance: number;
  cultureResourceKey: RewardCultureResourceType;
  dateOfReward: string;
  description: string;
  logType: number;
  previousBalance: number;
  reason?: string;
};

const Item: React.FunctionComponent<Props> = ({
  currentBalance,
  cultureResourceKey,
  dateOfReward,
  description,
  logType,
  previousBalance,
  reason,
}) => {
  const {
    animating,
    date,
    difference,
    handleClick,
    handleTransitionEnd,
    reasonRef,
    toggle,
  } = useToggle(currentBalance, dateOfReward, previousBalance);

  const classNames = clsx(
    styles['history-rewards-item'],
    toggle && styles['show-reason'],
    animating && styles.animating,
    cultureResourceKey === RewardCultureResourceType.EXPIRED_POINTS &&
      styles.expired,
    logType === 4 && styles.added,
    logType === 6 && styles.removed,
  );

  return (
    <li className={classNames}>
      <div>
        <div>
          <Icon type={cultureResourceKey} />
        </div>
        <p>
          <strong>{date}</strong>
          <span>{description}</span>
        </p>
        {!!reason && (
          <Button ariaLabel="Toggle Reason" onClick={handleClick}>
            <ChatIcon />
            <ChatFillIcon />
          </Button>
        )}
        <span>
          <span>
            {cultureResourceKey === RewardCultureResourceType.EXPIRED_POINTS ? (
              'Expired'
            ) : (
              <React.Fragment>
                {logType === 6 ? (
                  <React.Fragment>{currentBalance} PTS</React.Fragment>
                ) : (
                  <React.Fragment>{previousBalance} PTS</React.Fragment>
                )}
              </React.Fragment>
            )}
          </span>
          <span>
            {cultureResourceKey === RewardCultureResourceType.EXPIRED_POINTS ? (
              <React.Fragment>{currentBalance} pts</React.Fragment>
            ) : (
              <React.Fragment>
                {logType === 6 ? (
                  <React.Fragment>{difference} pts</React.Fragment>
                ) : (
                  <React.Fragment>+{currentBalance} pts</React.Fragment>
                )}
              </React.Fragment>
            )}
          </span>
        </span>
      </div>
      {(toggle || animating) && (
        <div
          className={styles.reason}
          onTransitionEnd={handleTransitionEnd}
          ref={reasonRef}>
          <p>{reason}</p>
        </div>
      )}
    </li>
  );
};

export default React.memo(Item);
