import { useCallback } from 'react';

import { engagementGetUsersAction } from 'modules/redux/states/engagement/users/actions';
import { Paging } from 'modules/pagination';
import {
  UseHandleGet,
  UseHandleError,
  UseHandlePaging,
  UseHandleSearch,
} from './types';

export const useHandleGet: UseHandleGet = ({ brandId, dispatch }) =>
  useCallback(
    () =>
      brandId &&
      dispatch(
        engagementGetUsersAction({
          brandId,
          page: 1,
          pageSize: 20,
        }),
      ),
    [brandId, dispatch],
  );

export const useHandleError: UseHandleError = ({
  brandId,
  dispatch,
  page,
  pageSize,
  searchString,
}) =>
  useCallback(
    () =>
      brandId &&
      dispatch(
        engagementGetUsersAction({ brandId, page, pageSize, searchString }),
      ),
    [brandId, dispatch, page, pageSize, searchString],
  );

export const useHandlePaging: UseHandlePaging = ({
  brandId,
  dispatch,
  page,
  pageSize,
  searchString,
}) =>
  useCallback(
    (type: Paging) => {
      if (brandId) {
        dispatch(
          engagementGetUsersAction({
            brandId,
            page: page
              ? type === Paging.NEXT
                ? page + 1
                : page - 1
              : undefined,
            pageSize,
            searchString,
          }),
        );
      }
    },
    [brandId, dispatch, page, pageSize, searchString],
  );

export const useHandleSearch: UseHandleSearch = ({ brandId, dispatch }) =>
  useCallback(
    searchString => {
      if (brandId) {
        dispatch(
          engagementGetUsersAction({
            brandId,
            page: 1,
            pageSize: 20,
            searchString,
          }),
        );
      }
    },
    [brandId, dispatch],
  );
