export enum ModalAction {
  DESTROY = 'MODAL_DESTROY',
  INSERT = 'MODAL_INSERT',
  HIDE = 'MODAL_HIDE',
}

export enum ModalCustomTemplate {
  ADD_REMOVE_POINTS = 'ADD_REMOVE_POINTS',
  APPROVE_EVENT = 'APPROVE_EVENT',
  DECLINE_EVENT = 'DECLINE_EVENT',
  INVALIDATE_TRIP = 'INVALIDATE_TRIP',
  REPORTS = 'REPORTS',
}

export enum ModalConfirmTemplate {
  DEBUG = 'DEBUG',
  EVENTS_ERROR = 'EVENTS_ERROR',
  POLICY_DETAILS_ERROR = 'POLICY_DETAILS_ERROR',
  POLICY_HOLDER_REPORT_ERROR = 'POLICY_HOLDER_REPORT_ERROR',
  POLICY_HOLDER_ADDITIONAL_DETAILS_SENDING_ERROR = 'POLICY_HOLDER_ADDITIONAL_DETAILS_SENDING_ERROR',
  POLICY_HOLDER_POST_POINTS_SENDING_ERROR = 'POLICY_HOLDER_POST_POINTS_SENDING_ERROR',
}

export enum ModalError {
  TEXT_WITH_ADMIN_EMAIL = 'Please try again. If the problem persists contact the site admin: ',
  TEXT = 'Please try again.',
  TITLE = 'Something went wrong',
}

export enum ModalInfoTemplate {
  ADD_POINTS_ERROR = 'ADD_POINTS_ERROR',
  DEBUG = 'DEBUG',
  DEBUG_SUCCESSFUL_SUBMISSION = 'DEBUG_SUCCESSFUL_SUBMISSION',
  EVENTS_ERROR = 'EVENTS_ERROR',
  REMOVE_POINTS_ERROR = 'REMOVE_POINTS_ERROR',
}

export enum ModalType {
  CUSTOM = 'custom',
  CONFIRM = 'confirm',
  INFO = 'info',
  FORM = 'form',
  SESSION_TIMEOUT = 'session-timeout',
}

export enum ModalAnimation {
  SLIDE_UP = 'slide-up',
  ZOOM_IN = 'zoom-in',
}

export enum ModalAnimationOut {
  SLIDE_DOWN = 'slide-down',
  ZOOM_OUT = 'zoom-out',
}
