import produce from 'immer';

import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import { PointType } from 'modules/modals/templates/custom/modalAddRemovePoints/Template/constants';

function pointsPostAddReducer(state: Engagement, action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.POST_ADD_POINTS_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.points.actionId = null;
        draftState.points.isSending = false;
        draftState.points.isSendingError = action.error;

        draftState.points.type = PointType.ADD;
        draftState.points.reason = action.request.reason;
        draftState.points.points = action.request.points;
      });

    case EngagementAction.POST_ADD_POINTS_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.points.actionId = action.actionId;
        draftState.points.isError = false;
        draftState.points.isSending = action.controller || true;
        draftState.points.isSendingError = false;
      });

    case EngagementAction.POST_ADD_POINTS_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.points.actionId = null;
        draftState.points.isSending = false;
        draftState.points.isSendingError = false;

        draftState.points.data = null;
        draftState.rewards.data = null;
      });

    default:
      return state;
  }
}

export default pointsPostAddReducer;
