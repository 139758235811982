import React from 'react';

import { UsePolicyHolderMessages } from 'modules/redux/states/engagement/hooks';
import Item from './Item';
import Pagination from 'modules/pagination';

import styles from './styles.module.scss';

type ComponentProps = {
  data: UsePolicyHolderMessages['data'];
  handleEmailTemplate: UsePolicyHolderMessages['handleEmailTemplate'];
  handlePaging: UsePolicyHolderMessages['handlePaging'];
  id: UsePolicyHolderMessages['id'];
  isLoading: UsePolicyHolderMessages['isLoading'];
  isSending: UsePolicyHolderMessages['isSending'];
  page: UsePolicyHolderMessages['page'];
  pageSize: UsePolicyHolderMessages['pageSize'];
};

const Component: React.FunctionComponent<ComponentProps> = ({
  data,
  handleEmailTemplate,
  handlePaging,
  id,
  isLoading,
  isSending,
  page,
  pageSize,
}) => {
  return (
    <div className={styles['history-messages']}>
      <ul>
        {data?.data.map((message, index) => {
          return (
            <Item
              activeId={id}
              body={message.messageBody}
              date={message.messageDate}
              handleEmailTemplate={handleEmailTemplate}
              id={message.messageId}
              isSending={isSending}
              key={`message-item-${index}`}
              medium={message.messageMedium}
              subject={message.messageSubject}
            />
          );
        })}
      </ul>
      {page && pageSize && data?.message && (
        <Pagination
          handlePaging={handlePaging}
          isLoading={isLoading}
          page={page}
          pageSize={pageSize}
          totalCount={Number(data.message.split('of')[1])}
        />
      )}
    </div>
  );
};

export default React.memo(Component);
