import React, { useCallback } from 'react';
import clsx from 'clsx';

import { ReactComponent as ChevronSVG } from 'modules/theme/images/icons/chevrons/chevron.svg';
import { SelectProps } from './types';

import styles from './styles.module.scss';

const Select: React.FunctionComponent<SelectProps> = ({
  autoFocus,
  children,
  disabled,
  id,
  inputRef,
  invalid,
  name,
  onBlur,
  onFocus,
  options,
  placeholder,
  readOnly,
  size,
  tabIndex,
  value,
  ...props
}) => {
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) =>
      !disabled && !readOnly && !!onBlur && onBlur(event),
    [disabled, onBlur, readOnly],
  );
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) => {
      disabled && event.currentTarget.blur();
      !disabled && !readOnly && !!onFocus && onFocus(event);
    },
    [disabled, onFocus, readOnly],
  );
  const getTabIndex = useCallback(() => {
    if (disabled || readOnly) return -1;
    return tabIndex ? tabIndex : 0;
  }, [disabled, readOnly, tabIndex]);

  const className = clsx(
    styles.select,
    styles[`${size}`],
    disabled && styles.disabled,
    readOnly && styles['read-only'],
    value === '' && styles.placeholder,
    !disabled && invalid && styles.invalid,
  );

  return (
    <div className={className}>
      <select
        autoFocus={!disabled && autoFocus}
        disabled={disabled}
        id={id || name}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={inputRef}
        tabIndex={getTabIndex()}
        value={value || ''}
        {...props}>
        {!!placeholder && (
          <option disabled={readOnly} value="">
            {placeholder}
          </option>
        )}
        {options?.map((option, key) => (
          <option
            disabled={readOnly || option.value === ''}
            key={key}
            value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ChevronSVG />
      {children}
    </div>
  );
};

export default React.memo(Select);
