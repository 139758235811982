import React, { useCallback } from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import {
  NotificationSeverity,
  NotificationTemplate,
  useNotification,
} from 'modules/notifications/hooks';
import { Padding } from 'components/Card/constants';
import Button from 'components/Button';
import Card from 'components/Card';

import styles from '../styles.module.scss';

const Notifications: React.FunctionComponent = () => {
  const { hideAllNotifications, showNotification } = useNotification();

  const handleShowNotification1 = useCallback(() => {
    if (showNotification)
      showNotification({
        template: NotificationTemplate.POINTS_ADDED,
      });
  }, [showNotification]);
  const handleShowNotification2 = useCallback(() => {
    if (showNotification)
      showNotification({
        message: 'Error notification',
        severity: NotificationSeverity.ERROR,
      });
  }, [showNotification]);
  const handleShowNotification3 = useCallback(() => {
    if (showNotification)
      showNotification({
        message: 'Warning notification',
        severity: NotificationSeverity.INFO,
      });
  }, [showNotification]);
  const handleShowNotification4 = useCallback(() => {
    if (showNotification)
      showNotification({
        message: 'Information notification',
        severity: NotificationSeverity.WARNING,
      });
  }, [showNotification]);
  const handleShowNotification5 = useCallback(() => {
    if (showNotification)
      showNotification({
        message: 'Default notification',
      });
  }, [showNotification]);

  const handleHideNotification3 = useCallback(() => {
    hideAllNotifications();
  }, [hideAllNotifications]);

  return (
    <Card
      className={styles.buttons}
      padding={Padding.LARGE}
      title="Notifications">
      <div>
        <Button
          color={ButtonColor.BLUE}
          label="Success Notification - Add Points"
          onClick={handleShowNotification1}
          style={ButtonStyle.PRIMARY}
        />
        <Button
          color={ButtonColor.BLUE}
          label="Error Notification"
          onClick={handleShowNotification2}
          style={ButtonStyle.PRIMARY}
        />
        <Button
          color={ButtonColor.BLUE}
          label="Info Notification"
          onClick={handleShowNotification3}
          style={ButtonStyle.PRIMARY}
        />
        <Button
          color={ButtonColor.BLUE}
          label="Warning Notification"
          onClick={handleShowNotification4}
          style={ButtonStyle.PRIMARY}
        />
        <Button
          color={ButtonColor.BLUE}
          label="Default Notification"
          onClick={handleShowNotification5}
          style={ButtonStyle.PRIMARY}
        />
        <Button
          color={ButtonColor.RED}
          label="Hide All Notifications"
          onClick={handleHideNotification3}
          style={ButtonStyle.PRIMARY}
        />
      </div>
    </Card>
  );
};

export default React.memo(Notifications);
