import produce from 'immer';

import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from 'modules/redux/states/engagement/constants';

function pointsGetReducer(state: Engagement, action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.GET_POINTS_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.points.actionId = null;
        draftState.points.isError = action.error;
        draftState.points.isLoading = false;

        draftState.points.data = null;
      });

    case EngagementAction.GET_POINTS_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.points.actionId = action.actionId;
        draftState.points.isError = false;
        draftState.points.isLoading = action.controller || true;

        draftState.points.data = null;
      });

    case EngagementAction.GET_POINTS_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.points.actionId = null;
        draftState.points.isError = false;
        draftState.points.isLoading = false;

        draftState.points.data = action.data;
      });

    default:
      return state;
  }
}

export default pointsGetReducer;
