import React from 'react';

import { ReactComponent as NoResultsIcon } from 'modules/theme/images/icons/search/no-results.svg';

import styles from './styles.module.scss';

const NoResults: React.FunctionComponent = () => {
  return (
    <div className={styles['no-results']}>
      <NoResultsIcon />
      <h2>No results found</h2>
      <p>Please try another search term</p>
    </div>
  );
};

export default React.memo(NoResults);
