import { ModalInfoTemplateProps } from './';
import * as Hyperlink from 'components/Hyperlink';

const template: ModalInfoTemplateProps = {
  ariaLabel: 'Info',
  renderHeader: () => <h2>Info Title</h2>,
  renderContent: () => (
    <p>
      Lorem ipsum <Hyperlink.ExternalLink href="#">test</Hyperlink.ExternalLink>
    </p>
  ),
};

export default template;
