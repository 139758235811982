export enum EventsAction {
  GET_STARTED = 'EVENTS_GET_STARTED',
  GET_SUCCESS = 'EVENTS_GET_SUCCESS',
  GET_FAILURE = 'EVENTS_GET_FAILURE',

  PUT_STARTED = 'EVENTS_PUT_STARTED',
  PUT_SUCCESS = 'EVENTS_PUT_SUCCESS',
  PUT_FAILURE = 'EVENTS_PUT_FAILURE',

  LOGOUT = 'EVENTS_LOGOUT',

  RESET = 'EVENTS_RESET',
}
