import React, { useCallback, useMemo } from 'react';
import { UseFormReset } from 'react-hook-form';

import Button from 'components/Button';
import {
  ButtonColor,
  ButtonStyle,
  ButtonType,
} from 'components/Button/constants';
import Card from 'components/Card';
import Form from 'components/Form';
import { Model, defaultValues, schema } from './model';
import { ModalAnimation, ModalCustomTemplate } from 'modules/modals/constants';
import { QuestionSelect } from 'components/Questions';
import { useConfigSelector } from 'modules/redux/states/config/hooks';
import { useModalCustom } from 'modules/modals/hooks';

import styles from './styles.module.scss';

const GenerateReport: React.FunctionComponent = () => {
  const { showModalCustom } = useModalCustom();

  const {
    tripsApplication: {
      reports: { reportTypes },
    },
  } = useConfigSelector();

  const options = useMemo(
    () =>
      reportTypes.map(report => ({
        value: report.key,
        label: report.label,
      })),
    [reportTypes],
  );

  const handleSubmit = useCallback(
    async (values: Model, reset: UseFormReset<Model>) => {
      if (showModalCustom && values.report)
        showModalCustom({
          ariaLabel: reportTypes.find(item => item.key === values.report)
            ?.label,
          animation: ModalAnimation.SLIDE_UP,
          onClose: reset,
          template: ModalCustomTemplate.REPORTS,
          payload: {
            report: values.report,
          },
        });
    },
    [reportTypes, showModalCustom],
  );

  return (
    <Card className={styles['generate-report']} title="Generate Report">
      <Form<Model>
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        schema={schema}>
        {({ control, isFormSubmitting }) => (
          <QuestionSelect
            control={control}
            label="Choose a report"
            name="report"
            options={options}
            placeholder="-- Select a report --">
            <Button
              color={ButtonColor.BLUE}
              disabled={!showModalCustom}
              label="Generate"
              loading={isFormSubmitting}
              style={ButtonStyle.PRIMARY}
              type={ButtonType.SUBMIT}
            />
          </QuestionSelect>
        )}
      </Form>
    </Card>
  );
};

export default React.memo(GenerateReport);
