import { useCallback } from 'react';

import { engagementGetPointsAction } from '../../../points/actions/engagementGetPointsAction';
import { UseHandleError, UseHandleGet } from './types';

export const useHandleError: UseHandleError = ({ dispatch, endUserId }) =>
  useCallback(
    () => dispatch(engagementGetPointsAction({ endUserId })),
    [dispatch, endUserId],
  );

export const useHandleGet: UseHandleGet = ({ dispatch, endUserId }) =>
  useCallback(
    () => dispatch(engagementGetPointsAction({ endUserId })),
    [dispatch, endUserId],
  );
