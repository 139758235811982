import { useParams } from 'react-router-dom';

import { shallowEqual, useSelector } from 'modules/redux';
import { useConfigSelector } from 'modules/redux/states/config/hooks';
import { UsePolicyTripSelector } from './types';

const usePolicyTripSelector: UsePolicyTripSelector = ({
  policyId,
  showModalCustom,
}) => {
  const { subjectId, tripId } = useParams<{
    subjectId: string | undefined;
    tripId: string | undefined;
  }>();

  const trip = useSelector(
    state => state.policy.trip.find(trip => trip.tripId === tripId),
    shallowEqual,
  );

  const {
    tripsApplication: {
      invalidation: { enabled },
    },
  } = useConfigSelector();

  return policyId && trip?.data
    ? {
        data: trip.data,
        enabled,
        isError: trip.isError,
        isInvalid: !trip.data?.state.isValid,
        isLoading: !!trip.isLoading,
        isSending: !showModalCustom || !!trip.isSending,
        subjectId,
        tripId,
      }
    : {
        data: null,
        enabled,
        isError: trip?.isError || false,
        isInvalid: false,
        isLoading: true,
        isSending: false,
        subjectId,
        tripId,
      };
};

export default usePolicyTripSelector;
