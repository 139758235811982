import { API } from 'modules/api';
import { createDefaultAction } from 'modules/redux/factories';
import { PolicyAction } from '../../../constants';
import {
  PolicyPutTripAction,
  PolicyPutTripStartedAction,
  PolicyPutTripSuccessAction,
  PolicyPutTripFailureAction,
} from './types';

export type { PolicyPutTripActionApiRequest } from './types';

export const policyPutTripAction: PolicyPutTripAction = request =>
  createDefaultAction({
    actionStarted: policyPutTripStartedAction,
    actionSuccess: policyPutTripSuccessAction,
    actionFailure: policyPutTripFailureAction,
    endpoint: API.PUT_POLICY_TRIP,
    isActive: state =>
      !!state.policy.trip.find(trip => trip.tripId === request.tripId)
        ?.isSending,
    isAuthorized: state =>
      state.auth.isAuthenticated &&
      !!state.config.data?.tripsApplication.invalidation.enabled,
    isPresent: (actionId, state) =>
      !!state.policy.trip.find(trip => actionId === trip.actionId) &&
      request.policyId === state.policy.details.data?.policyDetails.identifier,
    request: request,
  });

const policyPutTripStartedAction: PolicyPutTripStartedAction = payload => ({
  type: PolicyAction.PUT_TRIP_STARTED,
  ...payload,
});

const policyPutTripSuccessAction: PolicyPutTripSuccessAction = payload => ({
  type: PolicyAction.PUT_TRIP_SUCCESS,
  ...payload,
});

const policyPutTripFailureAction: PolicyPutTripFailureAction = payload => ({
  type: PolicyAction.PUT_TRIP_FAILURE,
  ...payload,
});
