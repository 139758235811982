/* eslint-disable no-prototype-builtins */
import axios, { CancelTokenSource } from 'axios';
import { browserVersion, isMobileSafari, isSafari } from 'react-device-detect';
import { getAuth0Token, newAuthError } from 'modules/auth/providers/auth0';
import { NewAbortController, GetAxiosConfig, ApiAbort } from './types';

const detectAbortController =
  (isSafari || isMobileSafari) && Number(browserVersion) < 13
    ? false
    : Request.prototype.hasOwnProperty('signal');

export const getAxiosConfig: GetAxiosConfig = async ({
  controller,
  dispatch,
  getState,
  url,
  ...config
}) => {
  const token = await getAuth0Token();
  if (!token) throw newAuthError();
  return {
    ...config,
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
    signal: (controller as AbortController)?.signal,
    url,
  };
};

export const newAbortController: NewAbortController = () => {
  try {
    if (!detectAbortController) throw new Error('AbortController Unsupported.');
    return new AbortController();
  } catch {
    try {
      return axios.CancelToken.source();
    } catch {
      return;
    }
  }
};

export const apiAbort: ApiAbort = controller => {
  if (controller instanceof AbortController) {
    controller.abort();
  } else if (
    typeof controller === 'object' &&
    typeof controller.cancel === 'function'
  ) {
    controller.cancel();
  }
};
