import React, { useCallback } from 'react';
import clsx from 'clsx';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { ModalAnimation } from '../../constants';
import { ModalConfirmProps } from '../../types';
import Button from 'components/Button';
import ModalOverlay from '../ModalOverlay';

import templateStyles from '../Modals/styles.module.scss';

type ModalProps = Omit<
  ModalConfirmProps,
  | 'animation'
  | 'destroyModal'
  | 'disableEscapeKey'
  | 'hideModal'
  | 'id'
  | 'open'
> & { active?: boolean };

const Modal = React.memo<ModalProps>(
  ({
    active,
    ariaLabel,
    cancelLabel,
    confirmLabel,
    onCancel,
    onConfirm,
    renderContent,
    renderHeader,
  }) => {
    const classNames = clsx(templateStyles['modal-template']);
    return (
      <div aria-label={ariaLabel} className={classNames} role="dialog">
        {!!renderHeader && <header>{renderHeader()}</header>}
        {!!renderContent && <div>{renderContent()}</div>}
        <footer>
          <Button
            disabled={!active}
            color={ButtonColor.BLUE}
            label={cancelLabel}
            onClick={onCancel}
            style={ButtonStyle.SECONDARY}
          />
          <Button
            disabled={!active}
            color={ButtonColor.BLUE}
            label={confirmLabel}
            onClick={onConfirm}
            style={ButtonStyle.PRIMARY}
          />
        </footer>
      </div>
    );
  },
);

const ModalConfirm: React.FunctionComponent<ModalConfirmProps> = ({
  animation = ModalAnimation.ZOOM_IN,
  ariaLabel,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  destroyModal,
  disableEscapeKey = false,
  hideModal,
  id,
  onCancel,
  onConfirm,
  open,
  renderContent,
  renderHeader,
}) => {
  const handleCancel = useCallback(() => {
    onCancel ? open && onCancel() : open && hideModal();
  }, [hideModal, onCancel, open]);
  const handleConfirm = useCallback(() => {
    onConfirm ? open && onConfirm() : open && hideModal();
  }, [hideModal, onConfirm, open]);
  return (
    <ModalOverlay
      animation={animation}
      destroyModal={destroyModal}
      disableEscapeKey={disableEscapeKey}
      id={id}
      onClose={handleCancel}
      open={open}>
      <Modal
        ariaLabel={ariaLabel}
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        renderContent={renderContent}
        renderHeader={renderHeader}
      />
    </ModalOverlay>
  );
};

export default React.memo(ModalConfirm);
