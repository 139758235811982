import React from 'react';

import {
  UsePolicyDetails,
  UsePolicyScores,
  UsePolicyTrip,
  UsePolicyTrips,
} from 'modules/redux/states/policy/hooks/usePolicySummary';
import * as Layout from 'components/Layout';
import PageTitle from 'components/PageTitle';
import PolicyNumber from './components/PolicyNumber';
import ScoreOverTime from './components/ScoreOverTime';
import Trip from './components/Trip';
import Trips from './components/Trips';

import styles from './styles.module.scss';

type TemplateProps = {
  details: UsePolicyDetails;
  scores: UsePolicyScores;
  trip: UsePolicyTrip | null;
  trips: UsePolicyTrips;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  details,
  scores,
  trip,
  trips,
}) => {
  return (
    <React.Fragment>
      <PageTitle title="Policy Summary">
        <PolicyNumber
          policyId={details.data?.policyDetails.identifier}
          isLoading={details.isLoading}
        />
      </PageTitle>
      <Layout.Container>
        <Layout.Column className={styles['policy-container']}>
          <Trips
            data={trips.data}
            endDate={trips.endDate}
            handleClick={trips.handleClick}
            handleError={trips.handleError}
            handlePaging={trips.handlePaging}
            handleSearch={trips.handleSearch}
            isError={trips.isError}
            isLoading={trips.isLoading}
            isSendingError={trips.isSendingError}
            policyId={details.data?.policyDetails.identifier}
            startDate={trips.startDate}
            tripId={trip?.tripId}
          />
        </Layout.Column>
        <Layout.Column scrollable={true}>
          {trip ? (
            <Trip
              data={trip.data}
              handleError={trip.handleError}
              handleInvalidateTrip={trip.handleInvalidateTrip}
              isError={trip.isError}
              isLoading={trip.isLoading}
              isInvalid={trip.isInvalid}
              isSending={trip.isSending}
            />
          ) : (
            <ScoreOverTime
              handleError={scores.handleError}
              handleUpdate={scores.handleUpdate}
              isError={scores.isError}
              isLoading={scores.isLoading}
              scores={scores.data}
            />
          )}
        </Layout.Column>
      </Layout.Container>
    </React.Fragment>
  );
};

export default React.memo(Template);
