import { EngagementReportData } from 'modules/redux/states/engagement/report/types';

export const convertToTxt = (report: EngagementReportData) => {
  /* Create download link */
  downloadTxt({
    content: JSON.stringify(report, null, 2),
    filename: `${report.endUserFirstName}_${report.endUserLastName}`,
  });
};

const downloadTxt = ({
  content,
  filename,
}: {
  content: string;
  filename: string;
}) => {
  const link = document.createElement('a');
  const file = new Blob([content], {
    type: 'text/plain',
  });
  link.href = URL.createObjectURL(file);
  link.download = `${filename}.txt`;
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
