import { useCallback } from 'react';

import {
  engagementGetAdditionalDetailsAction,
  EngagementGetAdditionalDetailsActionApiRequest,
} from 'modules/redux/states/engagement/additionalDetails/actions';
import { UseHandleError } from './types';

const useHandleError: UseHandleError = ({
  dispatch,
  page,
  pageSize,
  payload,
}) =>
  useCallback(
    () =>
      !!payload &&
      dispatch(
        engagementGetAdditionalDetailsAction({
          ...payload,
          page,
          pageSize,
        } as EngagementGetAdditionalDetailsActionApiRequest),
      ),
    [dispatch, page, pageSize, payload],
  );

export default useHandleError;
