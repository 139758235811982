import { Feature, GeoJsonProperties, Geometry, Position } from 'geojson';
import { PolicyEventDetails } from 'modules/redux/states/policy/trip/types';

export const decodePolyline = (
  encodedPolyline: string,
  showGeometricLine = true,
  policyEvents: PolicyEventDetails[] = [],
) => {
  const precision = 5;

  /**
   * Decodes to a [latitude, longitude] coordinates array.
   *
   * This is adapted from the implementation in Project-OSRM.
   *
   * @param {String} str
   * @param {Number} precision
   * @returns {Array}
   *
   * @see https://github.com/Project-OSRM/osrm-frontend/blob/master/WebContent/routing/OSRM.RoutingGeometry.js
   */
  const decode = (str: string) => {
    const coordinates = [],
      factor = Math.pow(10, precision || 5);
    let index = 0,
      lat = 0,
      lng = 0,
      shift = 0,
      result = 0,
      byte = null,
      latitude_change,
      longitude_change;

    // Coordinates have variable length when encoded, so just keep
    // track of whether we've hit the end of the string. In each
    // loop iteration, a single coordinate is decoded.
    while (index < str.length) {
      // Reset shift, result, and byte
      byte = null;
      shift = 0;
      result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

      shift = result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

      lat += latitude_change;
      lng += longitude_change;

      coordinates.push([lat / factor, lng / factor]);
    }

    return coordinates;
  };

  const flipped = (coords: Position[]) => {
    const flipped = [];
    for (let i = 0; i < coords.length; i++) {
      flipped.push(coords[parseInt(i.toString())].slice().reverse());
    }
    return flipped;
  };

  /**
   * Decodes to a GeoJSON LineString geometry.
   *
   * @param {String} str
   * @returns {Object}
   */
  const toGeoJSON = (str: string) => {
    const coords = decode(str);
    return {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: flipped(coords),
      },
    } as Feature<Geometry, GeoJsonProperties>;
  };

  const toGoogleCoords = (str: string) => {
    const coords = decode(str);
    const googleCoords = [];
    for (const element of coords) {
      googleCoords.push({
        lat: element[0],
        lng: element[1],
      });
    }
    return googleCoords;
  };

  const eventsToGeoJSON = (events: PolicyEventDetails[]) => {
    const coords = [];
    for (const event of events) {
      coords.push([event.lat, event.lon]);
    }
    return {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: flipped(coords),
      },
    } as Feature<Geometry, GeoJsonProperties>;
  };

  const eventsToGoogleCoords = (events: PolicyEventDetails[]) => {
    const googleCoords = [];
    for (const event of events) {
      googleCoords.push({
        lat: event.lat,
        lng: event.lon,
      });
    }
    return googleCoords;
  };

  const googleMapCoords = showGeometricLine
    ? toGoogleCoords(encodedPolyline)
    : eventsToGoogleCoords(policyEvents);
  const mapBoxCoords = showGeometricLine
    ? toGeoJSON(encodedPolyline)
    : eventsToGeoJSON(policyEvents);

  return { googleMapCoords, mapBoxCoords };
};
