import { useCallback, useEffect } from 'react';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';

import { buildEngagementPath } from 'modules/router/utils';
import {
  useHandleError,
  useHandleGet,
  useHandlePaging,
  useHandleSearch,
} from './hooks';
import { useNavigate } from 'react-router-dom';
import { UsePolicyHolderUsers } from '../types';

const usePolicyHolderUsers = (brandId?: string): UsePolicyHolderUsers => {
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();

  const { data, isError, isLoading, page, pageSize, searchString } =
    useSelector(state => state.engagement.users, shallowEqual);

  const handleClick = useCallback(
    (endUserId: string) => {
      return !!brandId && navigate(buildEngagementPath(brandId, endUserId));
    },
    [brandId, navigate],
  );

  const handleGet = useHandleGet({ brandId, dispatch });

  const handleError = useHandleError({
    brandId,
    dispatch,
    page,
    pageSize,
    searchString,
  });

  const handlePaging = useHandlePaging({
    brandId,
    dispatch,
    page,
    pageSize,
    searchString,
  });

  const handleSearch = useHandleSearch({
    brandId,
    dispatch,
    searchString,
  });

  useEffect(() => {
    if (!data && handleGet) handleGet();
  }, [data, handleGet]);

  return {
    data,
    handleClick,
    handleError,
    handlePaging,
    handleSearch,
    isError,
    isLoading: !!isLoading || !data,
    page,
    pageSize,
    searchString,
  };
};

export default usePolicyHolderUsers;
