import { RouteType } from './types';

export enum ROUTE {
  DEBUG = '/debug',
  LOGIN = '/',
  ENGAGEMENT_SEARCH = '/policy-holders',
  ENGAGEMENT_SUMMARY = '/policy-holders/:brandId/:endUserId',
  EVENTS = '/events',
  EVENTS_ID = '/events/:eventId',
  EVENTS_TIMEOUT = '/events/timeout',
  POLICY_SEARCH = '/policies',
  POLICY_SUMMARY = '/policies/:policyId',
  POLICY_SUMMARY_SUBJECT = '/policies/:policyId/:subjectId',
  POLICY_SUMMARY_TRIP = '/policies/:policyId/:subjectId/:tripId',
  REPORTS = '/reports',
}

export const routes: RouteType[] = [
  {
    path: ROUTE.DEBUG,
    title: 'Debug',
  },
  {
    path: ROUTE.ENGAGEMENT_SEARCH,
    title: 'Policy Holders',
  },
  {
    path: ROUTE.ENGAGEMENT_SUMMARY,
    title: 'Policy Holder Details',
  },
  {
    path: ROUTE.EVENTS,
    title: 'Events',
  },
  {
    path: ROUTE.EVENTS_ID,
    title: 'Events',
  },
  {
    path: ROUTE.LOGIN,
    title: 'Login',
  },
  {
    path: ROUTE.POLICY_SEARCH,
    title: 'Policy Search',
  },
  {
    path: ROUTE.POLICY_SUMMARY,
    title: 'Policy Summary',
  },
  {
    path: ROUTE.POLICY_SUMMARY_TRIP,
    title: 'Trip Summary',
  },
  {
    path: ROUTE.REPORTS,
    title: 'Reports',
  },
];
