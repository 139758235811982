import React from 'react';

import { WidthPercentage } from 'components/Layout/constants';
import { UsePolicyTrip } from 'modules/redux/states/policy/hooks/usePolicySummary';
import * as Layout from 'components/Layout';
import ErrorMessage from './ErrorMessage';
import InvalidateTrip from './InvalidateTrip';
import PolicyEventsSummary from './PolicyEventsSummary';
import TripMap from './TripMap';

type TripProps = {
  data: UsePolicyTrip['data'];
  handleError: UsePolicyTrip['handleError'];
  handleInvalidateTrip: UsePolicyTrip['handleInvalidateTrip'];
  isError: UsePolicyTrip['isError'];
  isLoading: UsePolicyTrip['isLoading'];
  isInvalid: UsePolicyTrip['isInvalid'];
  isSending: UsePolicyTrip['isSending'];
};

const Trip: React.FunctionComponent<TripProps> = ({
  data,
  handleError,
  handleInvalidateTrip,
  isError,
  isLoading,
  isInvalid,
  isSending,
}) => {
  return isError ? (
    <ErrorMessage handleError={handleError} isError={isError} />
  ) : (
    <React.Fragment>
      <TripMap
        events={data?.events}
        geometry={data?.geometry}
        isLoading={isLoading}
      />
      <Layout.Row>
        <Layout.Column width={WidthPercentage.FIFTY}>
          <PolicyEventsSummary events={data?.events} isLoading={isLoading} />
        </Layout.Column>
        <Layout.Column width={WidthPercentage.FIFTY}>
          <InvalidateTrip
            isLoading={isLoading}
            isInvalid={isInvalid}
            isSending={isSending}
            onInvalidateTrip={handleInvalidateTrip}
          />
        </Layout.Column>
      </Layout.Row>
    </React.Fragment>
  );
};
export default React.memo(Trip);
