import React, { useMemo } from 'react';

import Confirm from '../../components/Confirm';
import DateRange from '../../components/DateRange';
import Download from '../../components/Download';
import Failure from '../../components/Failure';
import PolicySearch from '../../components/PolicySearch';
import useModalReports from './hooks';
import { ModalReportStep } from './constants';

type TemplateProps = {
  active: boolean;
  onClose: () => void;
  payload?: Record<string, unknown>;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  active,
  onClose,
  payload,
}) => {
  const {
    handleConfirm,
    handleDateRange,
    handleError,
    handlePolicySearch,
    isError,
    isLoading,
    link,
    reportType,
    step,
  } = useModalReports(payload?.report as string);

  const render = useMemo(
    () => (step: ModalReportStep) => {
      switch (step) {
        case ModalReportStep.ERROR:
          return (
            <Failure
              active={active}
              isError={isError}
              onCancel={onClose}
              onError={handleError}
            />
          );
        case ModalReportStep.POLICY:
          return (
            <PolicySearch
              active={active}
              isError={isError}
              isLoading={isLoading}
              onCancel={onClose}
              onSubmit={handlePolicySearch}
              title={reportType?.label || 'Policy Search'}
            />
          );
        case ModalReportStep.DATE:
          return (
            <DateRange
              active={active}
              isLoading={isLoading}
              onCancel={onClose}
              onSubmit={handleDateRange}
              title={reportType?.label || 'Date Range'}
            />
          );
        case ModalReportStep.CONFIRM:
          return (
            <Confirm
              active={active}
              cancelable={true}
              isLoading={isLoading}
              label="Generate"
              onCancel={onClose}
              onConfirm={handleConfirm}
              title={reportType?.label || 'Confirm'}
              text={
                isLoading
                  ? 'Generating report. Please wait...'
                  : 'Click below to generate the report.'
              }
            />
          );
        case ModalReportStep.SUCCESS:
          return <Download active={active} link={link} onClose={onClose} />;
        default:
          return null;
      }
    },
    [
      active,
      handleConfirm,
      handleDateRange,
      handleError,
      handlePolicySearch,
      isError,
      isLoading,
      link,
      onClose,
      reportType,
    ],
  );

  return render(step);
};
export default React.memo(Template);
