import React from 'react';
import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import Button from 'components/Button';
import {
  ExternalLinkTarget,
  HyperlinkColor,
  HyperlinkStyle,
} from 'components/Hyperlink/constant';
import * as Hyperlink from 'components/Hyperlink';

type DownloadProps = {
  active: boolean;
  link: string | null;
  onClose: () => void;
};

const Download: React.FunctionComponent<DownloadProps> = ({
  active,
  link,
  onClose,
}) => {
  return (
    <React.Fragment>
      <header>
        <h2>Document Ready</h2>
      </header>
      <div>
        <p>Click the button to download the document.</p>
      </div>
      <footer>
        <Button
          color={ButtonColor.BLUE}
          disabled={!active}
          label="Close"
          onClick={onClose}
          style={ButtonStyle.SECONDARY}
        />
        <Hyperlink.ExternalLink
          color={HyperlinkColor.BLUE}
          disabled={!active}
          label="Download"
          href={link || undefined}
          rel="noopener noreferrer"
          style={HyperlinkStyle.PRIMARY}
          target={ExternalLinkTarget.BLANK}
        />
      </footer>
    </React.Fragment>
  );
};

export default React.memo(Download);
