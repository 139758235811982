/* eslint-disable no-template-curly-in-string */

export enum ValdationMessages {
  DATE = 'Please enter a valid date',

  EMAIL = 'Please enter a valid email address.',

  ONE_OF = 'Please complete this question.',

  NOT_TYPE = 'Please enter a valid ${type}.',

  REQUIRED = 'Please complete this question.',
}

/* eslint-enable no-template-curly-in-string */

export default ValdationMessages;
