import React from 'react';
import clsx from 'clsx';

import { PageTitleProps } from './types';
import Breadcrumb from 'components/Breadcrumbs';

import styles from './styles.module.scss';

const PageTitle: React.FunctionComponent<PageTitleProps> = ({
  children,
  className,
  enableBreadcrumbs = true,
  title,
}) => {
  const classNames = clsx(styles['page-title'], className);
  return (
    <header className={classNames}>
      {enableBreadcrumbs && <Breadcrumb />}
      <div>
        <h1>{title}</h1>
        {children}
      </div>
    </header>
  );
};

export default React.memo(PageTitle);
