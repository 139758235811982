import { Middleware } from 'redux';

import { AuthAction } from 'modules/auth/constants';
import { AuthActionType } from 'modules/auth/types';
import { Dispatch, RootState } from 'modules/redux';
import { drawerHideAction } from './actions';
import { RouterActionType } from 'modules/router/types';

const createDrawersMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case AuthAction.LOGOUT: {
        const { drawers }: RootState = getState();
        drawers.forEach(
          drawer => drawer.open && dispatch(drawerHideAction(drawer.id)),
        );
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default createDrawersMiddleware;
