import React from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import clsx from 'clsx';

import { QuestionTemplateProps } from '../types';
import { ReactComponent as ErrorSVG } from 'modules/theme/images/icons/inputs/error.svg';

import styles from './styles.module.scss';

function Question<T extends FieldValues>({
  children,
  control,
  disabled,
  id,
  label,
  name,
  readOnly,
  size,
}: QuestionTemplateProps<T>) {
  const {
    field: { onChange, value, onBlur, ref },
    fieldState: { error, invalid },
  } = useController({
    control,
    name: name as Path<T>,
  });
  const className = clsx(
    styles.question,
    styles[`${size}`],
    disabled && styles.disabled,
    readOnly && styles['read-only'],
    !disabled && invalid && styles.invalid,
  );
  return (
    <div className={className}>
      {!!label && <label htmlFor={id ? id : name}>{label}</label>}
      {children &&
        children({
          invalid,
          onBlur,
          onChange,
          ref,
          value,
        })}
      {invalid && !!error?.message && (
        <p aria-live="polite">
          <ErrorSVG />
          <span>{error.message}</span>
        </p>
      )}
    </div>
  );
}

export default React.memo(Question) as typeof Question;
