import { API } from 'modules/api';
import { createDefaultAction } from '../../../factories';
import { PolicyAction } from '../constants';
import {
  PolicyGetSearchAction,
  PolicyGetSearchStartedAction,
  PolicyGetSearchSuccessAction,
  PolicyGetSearchFailureAction,
} from './types';

export const policyGetSearchAction: PolicyGetSearchAction = request =>
  createDefaultAction({
    actionStarted: policyGetSearchStartedAction,
    actionSuccess: policyGetSearchSuccessAction,
    actionFailure: policyGetSearchFailureAction,
    endpoint: API.GET_POLICY,
    isActive: state => !!state.policy.details.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) => actionId === state.policy.details.actionId,
    request: request,
  });

const policyGetSearchStartedAction: PolicyGetSearchStartedAction = payload => ({
  type: PolicyAction.GET_SEARCH_STARTED,
  ...payload,
});

const policyGetSearchSuccessAction: PolicyGetSearchSuccessAction = payload => ({
  type: PolicyAction.GET_SEARCH_SUCCESS,
  ...payload,
});

const policyGetSearchFailureAction: PolicyGetSearchFailureAction = payload => ({
  type: PolicyAction.GET_SEARCH_FAILURE,
  ...payload,
});
