import React, { useCallback } from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import {
  ModalConfirmTemplate,
  ModalInfoTemplate,
} from 'modules/modals/constants';
import { Padding } from 'components/Card/constants';
import {
  useModalConfirm,
  useModalCustom,
  useModalInfo,
} from 'modules/modals/hooks';
import Button from 'components/Button';
import Card from 'components/Card';

import styles from '../styles.module.scss';

const Modals: React.FunctionComponent = () => {
  const { showModalConfirm } = useModalConfirm();
  const { showModalCustom } = useModalCustom();
  const { showModalInfo } = useModalInfo();

  const handleShowModalConfirm = useCallback(() => {
    if (showModalConfirm)
      showModalConfirm({
        template: ModalConfirmTemplate.DEBUG,
      });
  }, [showModalConfirm]);

  const handleShowModalCustom = useCallback(() => {
    if (showModalCustom)
      showModalCustom({
        renderContent: ({ active, onClose }) => (
          <React.Fragment>
            <header>
              <h2>Custom Modal</h2>
            </header>
            <footer>
              <Button
                color={ButtonColor.BLUE}
                disabled={!active}
                label="Close"
                onClick={onClose}
                style={ButtonStyle.PRIMARY}
              />
            </footer>
          </React.Fragment>
        ),
      });
  }, [showModalCustom]);

  const handleShowModalInfo = useCallback(() => {
    if (showModalInfo)
      showModalInfo({
        template: ModalInfoTemplate.DEBUG,
      });
  }, [showModalInfo]);

  return (
    <Card className={styles.buttons} padding={Padding.LARGE} title="Modals">
      <div>
        <Button
          color={ButtonColor.BLUE}
          label="Info Modal"
          onClick={handleShowModalInfo}
          style={ButtonStyle.PRIMARY}
        />
        <Button
          color={ButtonColor.RED}
          label="Confirm Modal"
          onClick={handleShowModalConfirm}
          style={ButtonStyle.PRIMARY}
        />
        <Button
          color={ButtonColor.BLUE}
          label="Custom Modal"
          onClick={handleShowModalCustom}
          style={ButtonStyle.PRIMARY}
        />
      </div>
    </Card>
  );
};

export default React.memo(Modals);
