import { API } from 'modules/api';
import { createDefaultAction } from '../../../../factories';
import { EventsAction } from '../../constants';
import {
  EventsPutAction,
  EventsPutFailureAction,
  EventsPutStartedAction,
  EventsPutSuccessAction,
} from './types';
import { getSpeedingEventId } from '../../utils';

export type { EventsPutActionApiRequest, EventsPutActionType } from './types';

export const eventsPutAction: EventsPutAction = request =>
  createDefaultAction({
    actionStarted: eventsPutStartedAction,
    actionSuccess: eventsPutSuccessAction,
    actionFailure: eventsPutFailureAction,
    endpoint: API.PUT_EVENT,
    isActive: state => !!state.events.isSending,
    isAuthorized: state =>
      state.auth.isAuthenticated &&
      !!state.config.data?.eventsApplication.invalidation.enabled,
    isPresent: (actionId, state) =>
      actionId === state.events.actionId &&
      request.eventId === getSpeedingEventId(state.events.data),
    request: request,
  });

const eventsPutStartedAction: EventsPutStartedAction = payload => ({
  type: EventsAction.PUT_STARTED,
  ...payload,
});

const eventsPutSuccessAction: EventsPutSuccessAction = payload => ({
  type: EventsAction.PUT_SUCCESS,
  ...payload,
});

const eventsPutFailureAction: EventsPutFailureAction = payload => ({
  type: EventsAction.PUT_FAILURE,
  ...payload,
});
