import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { isAdmin } from 'modules/admin';
import { ReactComponent as ErrorSVG } from 'modules/theme/images/icons/inputs/error.svg';
import { ReactComponent as WalletIcon } from 'modules/theme/images/icons/card/wallet.svg';
import Button from 'components/Button';
import Card from 'components/Card';

import styles from './styles.module.scss';

type PolicyDetailsErrorProps = {
  handleError: () => void;
  isError: false | Error;
};

const ErrorMessage: React.FunctionComponent<PolicyDetailsErrorProps> = ({
  handleError,
  isError,
}) => {
  return (
    <Card
      className={styles['points-balance-error']}
      renderHeader={() => <WalletIcon />}
      title="Points Balance">
      <ErrorSVG />
      <h2>{isAdmin ? 'Error' : 'Something went wrong'}</h2>
      {isAdmin && !!isError && isError.message && <p>{isError.message}</p>}
      <Button
        color={ButtonColor.BLUE}
        label="Retry"
        onClick={handleError}
        style={ButtonStyle.PRIMARY}
      />
    </Card>
  );
};

export default React.memo(ErrorMessage);
