import { PlatformType } from 'modules/platform';

const htmlClasses = (classes: { add: boolean; name: string }[]): void => {
  const html = document.getElementsByTagName('html')[0];
  html &&
    classes.forEach((item: { add: boolean; name: string }) => {
      item.add
        ? html.classList.add(item.name)
        : html.classList.remove(item.name);
    });
};

export const setupPlatform = (platform: PlatformType) => {
  htmlClasses([
    {
      add: !platform.isMobile,
      name: 'platform-desktop',
    },
    {
      add: platform.isMobile,
      name: 'platform-mobile',
    },
    {
      add: platform.isMobileOnly,
      name: 'platform-phone',
    },
    {
      add: platform.isTablet,
      name: 'platform-tablet',
    },
    {
      add: platform.isAndroid,
      name: 'platform-android',
    },
    {
      add: platform.isIOS,
      name: 'platform-ios',
    },
    {
      add: platform.isMacOs,
      name: 'platform-mac',
    },
    {
      add: platform.isWindows,
      name: 'platform-windows',
    },
    {
      add: platform.isIE,
      name: 'browser-ie',
    },
    {
      add: platform.isFirefox,
      name: 'browser-firefox',
    },
    {
      add: platform.isSafari,
      name: 'browser-safari',
    },
  ]);
};
