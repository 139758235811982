import { matchPath } from 'react-router-dom';
import { push, replace } from 'redux-first-history';

import { Dispatch, RootState } from 'modules/redux';
import { ROUTE } from 'modules/router/constants';
import { RouteType } from 'modules/router/types';
import { routes } from './constants';

export const getRoute = (path: string): RouteType | undefined =>
  routes.find(route => matchRoute(route.path, path));

export const matchRoute = (pathA: ROUTE, pathB: string | undefined): boolean =>
  !!matchPath(pathA || '', pathB || '');

export const middlewareNavigate = (
  dispatch: Dispatch,
  path: string,
  type?: 'push' | 'replace',
) => dispatch(type === 'replace' ? replace(path) : push(path));

export const middlewareMatchRoute = (
  getState: () => RootState,
  path: ROUTE,
): boolean => matchRoute(path, getState().router.location?.pathname);

export const getPreviousRoute = (router: RootState['router']) => {
  return router.previousLocations && router.previousLocations.length > 1
    ? router.previousLocations[1].location?.pathname
    : undefined;
};

export const buildEventsPath = (eventId: string) =>
  ROUTE.EVENTS_ID.replace(':eventId', encodeURIComponent(eventId));

export const buildEngagementPath = (brandId: string, endUserId: string) =>
  ROUTE.ENGAGEMENT_SUMMARY.replace(
    ':brandId',
    encodeURIComponent(brandId),
  ).replace(':endUserId', encodeURIComponent(endUserId));

export const buildPolicyPath = (policyId: string) =>
  ROUTE.POLICY_SUMMARY.replace(':policyId', encodeURIComponent(policyId));

export const buildTripPath = (
  policyId: string,
  subjectId: string,
  tripId: string,
) =>
  ROUTE.POLICY_SUMMARY_TRIP.replace(':policyId', encodeURIComponent(policyId))
    .replace(':subjectId', encodeURIComponent(subjectId))
    .replace(':tripId', encodeURIComponent(tripId));

// export const getExpireDate = (date: string) => {
//   const d = new Date(date);
//   return d
//     .toLocaleString('en-GB', {
//       day: '2-digit',
//       month: '2-digit',
//       year: 'numeric',
//     })
//     .split(',')[0];
// };
