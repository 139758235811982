import React from 'react';

import { PointType } from './constants';
import Points from '../../components/Points';
import Failure from '../../components/Failure';
import useModalPoints from './hooks';

type TemplateProps = {
  active: boolean;
  onClose: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  active,
  onClose,
}) => {
  const {
    data,
    handleAddPoints,
    handleAddPointsError,
    handleClick,
    handleRemovePoints,
    handleRemovePointsError,
    handleTransitionEnd,
    isSending,
    isSendingError,
    pointType,
    ref,
  } = useModalPoints(onClose);

  return isSendingError && pointType === PointType.ADD ? (
    <Failure
      active={active}
      isError={isSendingError}
      onCancel={onClose}
      onError={handleAddPointsError}
    />
  ) : isSendingError && pointType === PointType.REMOVE ? (
    <Failure
      active={active}
      isError={isSendingError}
      onCancel={onClose}
      onError={handleRemovePointsError}
    />
  ) : (
    <Points
      active={active}
      bankedPoints={data?.data.bankedPoints}
      forwardedRef={ref}
      isSending={!!isSending}
      onAddPoints={handleAddPoints}
      onCancel={onClose}
      onClick={handleClick}
      onRemovePoints={handleRemovePoints}
      onTransitionEnd={handleTransitionEnd}
      pointType={pointType}
    />
  );
};
export default React.memo(Template);
