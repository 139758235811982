import { API } from 'modules/api';
import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from '../../../constants';
import {
  EngagementGetAdditionalDetailsAction,
  EngagementGetAdditionalDetailsStartedAction,
  EngagementGetAdditionalDetailsSuccessAction,
  EngagementGetAdditionalDetailsFailureAction,
} from './types';

export type { EngagementGetAdditionalDetailsActionApiRequest } from './types';

export const engagementGetAdditionalDetailsAction: EngagementGetAdditionalDetailsAction =
  request =>
    createDefaultAction({
      actionStarted: engagementGetAdditionalDetailsStartedAction,
      actionSuccess: engagementGetAdditionalDetailsSuccessAction,
      actionFailure: engagementGetAdditionalDetailsFailureAction,
      endpoint: API.GET_ENGAGEMENT_ADDITIONAL_DETAILS,
      isActive: state => !!state.engagement.additionalDetails.isLoading,
      isAuthorized: state => state.auth.isAuthenticated,
      isPresent: (actionId, state) =>
        actionId === state.engagement.additionalDetails.actionId &&
        request.endUserExternalId ===
          state.engagement.details.data?.data.endUserId,
      request: request,
    });

const engagementGetAdditionalDetailsStartedAction: EngagementGetAdditionalDetailsStartedAction =
  payload => ({
    type: EngagementAction.GET_ADDITIONAL_DETAILS_STARTED,
    ...payload,
  });

const engagementGetAdditionalDetailsSuccessAction: EngagementGetAdditionalDetailsSuccessAction =
  payload => ({
    type: EngagementAction.GET_ADDITIONAL_DETAILS_SUCCESS,
    ...payload,
  });

const engagementGetAdditionalDetailsFailureAction: EngagementGetAdditionalDetailsFailureAction =
  payload => ({
    type: EngagementAction.GET_ADDITIONAL_DETAILS_FAILURE,
    ...payload,
  });
