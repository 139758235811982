import React from 'react';
import clsx from 'clsx';

import Button from 'components/Button';
import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import Card from 'components/Card';
import Skeleton from './Skeleton';
import { UsePolicyTrip } from 'modules/redux/states/policy/hooks/usePolicySummary';

import styles from './styles.module.scss';

type InvalidateTripProps = {
  isLoading: UsePolicyTrip['isLoading'];
  isInvalid: boolean;
  isSending: boolean;
  onInvalidateTrip: UsePolicyTrip['handleInvalidateTrip'];
};

const InvalidateTrip: React.FunctionComponent<InvalidateTripProps> = ({
  isLoading,
  isInvalid,
  isSending,
  onInvalidateTrip,
}) => {
  const classNames = clsx(
    styles['invalidate-trip'],
    isInvalid && styles.invalid,
  );
  return onInvalidateTrip ? (
    isLoading ? (
      <Skeleton />
    ) : (
      <Card className={classNames}>
        {!isInvalid ? (
          <Button
            color={ButtonColor.RED}
            disabled={isSending}
            label="Invalidate Journey"
            onClick={onInvalidateTrip}
            style={ButtonStyle.SECONDARY}
          />
        ) : (
          <div>Trip Invalidated</div>
        )}
      </Card>
    )
  ) : null;
};

export default React.memo(InvalidateTrip);
