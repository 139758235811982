import React from 'react';

import { Padding } from 'components/Card/constants';
import { ReactComponent as SpinnerSVG } from 'modules/theme/images/icons/spinners/circular-bars.svg';
import Card from 'components/Card';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  return (
    <Card className={styles.skeleton} padding={Padding.NONE}>
      <span data-nosnippet className="visually-hidden">
        Loading trip map
      </span>
      <SpinnerSVG />
      <div></div>
    </Card>
  );
};

export default React.memo(Skeleton);
