export enum ExternalLinkTarget {
  BLANK = '_blank',
  SELF = '_self',
}

export enum HyperlinkColor {
  RED = 'red',
  BLUE = 'blue',
}

export enum HyperlinkStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
