import { ModalAnimation } from 'modules/modals/constants';
import { ModalCustomTemplateProps } from '..';
import Template from './Template';

const template: ModalCustomTemplateProps = {
  animation: ModalAnimation.SLIDE_UP,
  ariaLabel: 'Approve Event',
  disableEscapeKey: true,
  renderContent: props => <Template {...props} />,
};

export default template;
