import React from 'react';

import { HyperlinkColor, HyperlinkStyle } from 'components/Hyperlink/constant';
import { Padding } from 'components/Card/constants';
import { ROUTE } from 'modules/router/constants';
import * as Hyperlink from 'components/Hyperlink';
import Card from 'components/Card';

import styles from '../styles.module.scss';

const Buttons: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <Card
        className={styles.hyperlinks}
        padding={Padding.LARGE}
        title="Hyperlinks - Internal">
        <div>
          <Hyperlink.InternalLink label="Homepage" route="/" />
          <Hyperlink.InternalLink
            color={HyperlinkColor.BLUE}
            label="Homepage"
            route="/"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.InternalLink
            color={HyperlinkColor.RED}
            label="Homepage"
            route="/"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.InternalLink
            color={HyperlinkColor.BLUE}
            label="Homepage"
            route="/"
            style={HyperlinkStyle.SECONDARY}
          />
          <Hyperlink.InternalLink
            color={HyperlinkColor.RED}
            label="Homepage"
            route="/"
            style={HyperlinkStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.hyperlinks}
        padding={Padding.LARGE}
        title="Hyperlinks - Internal Disabled">
        <div>
          <Hyperlink.InternalLink label="Homepage" route="/" />
          <Hyperlink.InternalLink
            color={HyperlinkColor.BLUE}
            disabled={true}
            label="Homepage"
            route="/"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.InternalLink
            color={HyperlinkColor.RED}
            disabled={true}
            label="Homepage"
            route="/"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.InternalLink
            color={HyperlinkColor.BLUE}
            disabled={true}
            label="Homepage"
            route="/"
            style={HyperlinkStyle.SECONDARY}
          />
          <Hyperlink.InternalLink
            color={HyperlinkColor.RED}
            disabled={true}
            label="Homepage"
            route="/"
            style={HyperlinkStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.hyperlinks}
        padding={Padding.LARGE}
        title="Hyperlinks - Internal Loading">
        <div>
          <Hyperlink.InternalLink label="Homepage" route="/" />
          <Hyperlink.InternalLink
            color={HyperlinkColor.BLUE}
            label="Homepage"
            loading={true}
            route="/"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.InternalLink
            color={HyperlinkColor.RED}
            label="Homepage"
            loading={true}
            route="/"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.InternalLink
            color={HyperlinkColor.BLUE}
            label="Homepage"
            loading={true}
            route="/"
            style={HyperlinkStyle.SECONDARY}
          />
          <Hyperlink.InternalLink
            color={HyperlinkColor.RED}
            label="Homepage"
            loading={true}
            route="/"
            style={HyperlinkStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.buttons}
        padding={Padding.LARGE}
        title="Hyperlinks - External">
        <div>
          <Hyperlink.ExternalLink label="Google" href="https://google.com" />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.BLUE}
            href="https://google.com"
            label="Google"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.RED}
            href="https://google.com"
            label="Google"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.BLUE}
            href="https://google.com"
            label="Google"
            style={HyperlinkStyle.SECONDARY}
          />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.RED}
            href="https://google.com"
            label="Google"
            style={HyperlinkStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.buttons}
        padding={Padding.LARGE}
        title="Hyperlinks - External Disabled">
        <div>
          <Hyperlink.ExternalLink label="Google" href="https://google.com" />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.BLUE}
            disabled={true}
            href="https://google.com"
            label="Google"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.RED}
            disabled={true}
            href="https://google.com"
            label="Google"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.BLUE}
            disabled={true}
            href="https://google.com"
            label="Google"
            style={HyperlinkStyle.SECONDARY}
          />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.RED}
            disabled={true}
            href="https://google.com"
            label="Google"
            style={HyperlinkStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.buttons}
        padding={Padding.LARGE}
        title="Hyperlinks - External Loading">
        <div>
          <Hyperlink.ExternalLink label="Google" href="https://google.com" />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.BLUE}
            href="https://google.com"
            loading={true}
            label="Google"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.RED}
            href="https://google.com"
            loading={true}
            label="Google"
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.BLUE}
            href="https://google.com"
            loading={true}
            label="Google"
            style={HyperlinkStyle.SECONDARY}
          />
          <Hyperlink.ExternalLink
            color={HyperlinkColor.RED}
            href="https://google.com"
            loading={true}
            label="Google"
            style={HyperlinkStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.buttons}
        padding={Padding.LARGE}
        title="Hyperlinks - Navigation (Router NavLink)">
        <div>
          <Hyperlink.NavigationLink label="Debug" route={ROUTE.DEBUG} />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.BLUE}
            label="Debug"
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.RED}
            label="Debug"
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.BLUE}
            label="Debug"
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.SECONDARY}
          />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.RED}
            label="Debug"
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.buttons}
        padding={Padding.LARGE}
        title="Hyperlinks - Navigation (Router NavLink) Disabled">
        <div>
          <Hyperlink.NavigationLink label="Debug" route={ROUTE.DEBUG} />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.BLUE}
            disabled={true}
            label="Debug"
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.RED}
            disabled={true}
            label="Debug"
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.BLUE}
            disabled={true}
            label="Debug"
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.SECONDARY}
          />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.RED}
            disabled={true}
            label="Debug"
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.buttons}
        padding={Padding.LARGE}
        title="Hyperlinks - Navigation (Router NavLink) Loading">
        <div>
          <Hyperlink.NavigationLink label="Debug" route={ROUTE.DEBUG} />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.BLUE}
            label="Debug"
            loading={true}
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.RED}
            label="Debug"
            loading={true}
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.PRIMARY}
          />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.BLUE}
            label="Debug"
            loading={true}
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.SECONDARY}
          />
          <Hyperlink.NavigationLink
            color={HyperlinkColor.RED}
            label="Debug"
            loading={true}
            route={ROUTE.DEBUG}
            style={HyperlinkStyle.SECONDARY}
          />
        </div>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(Buttons);
