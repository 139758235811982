import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const isValidDate = (
  date: string | number | Dayjs | Date | null | undefined,
) => !!date && dayjs(date).isValid();

export const getEndDateString = (date: Date | string): string | undefined =>
  isValidDate(date)
    ? dayjs.utc(dayjs(date).format('YYYY-MM-DD')).endOf('day').toJSON()
    : undefined;

export const getStartDateString = (date: Date | string): string | undefined =>
  isValidDate(date)
    ? dayjs.utc(dayjs(date).format('YYYY-MM-DD')).startOf('day').toJSON()
    : undefined;

export const formatDateRange = ({
  endDate,
  startDate,
}: {
  endDate?: Date | string;
  startDate?: Date | string;
}): string | null =>
  isValidDate(startDate) && isValidDate(endDate)
    ? `${dayjs.utc(startDate).format('DD/MM/YYYY')} and ${dayjs
        .utc(endDate)
        .format('DD/MM/YYYY')}`
    : null;

export const getExpireDate = (date: string) => {
  return isValidDate(date) ? dayjs.utc(date).format('DD-MM-YYYY') : undefined;
};
