export enum PolicyEventType {
  ACCELERATION = 'acceleration',
  BRAKING = 'braking',
  CORNERING = 'cornering',
  DISTRACTED_DRIVING = 'distractedDriving',
  OVERALL = 'overall',
  OVER_SPEED = 'overspeed',
  POSITION_UPDATE = 'positionUpdate',
  SMOOTHNESS = 'smoothness',
  SPEEDING = 'speeding',
  TIME_OF_DAY = 'timeOfDay',
}
