import { AuthAction } from '../constants';
import {
  AuthGetUserStartedAction,
  AuthGetUserSuccessAction,
  AuthGetUserFailureAction,
} from '../types';
import { getAuth0User, isAuthTokenError } from '../providers/auth0';
import { Dispatch, RootState } from 'modules/redux';

export const authGetUserAction = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    try {
      if (!getState().auth.isAuthenticated) return;
      dispatch(authGetUserStartedAction());
      const user = await getAuth0User();
      getState().auth.isAuthenticated &&
        dispatch(authGetUserSuccessAction({ user }));
    } catch (error) {
      !isAuthTokenError(error) &&
        getState().auth.isAuthenticated &&
        dispatch(
          authGetUserFailureAction({
            error:
              error instanceof Error ? error : new Error('Unexpected Error.'),
          }),
        );
    }
  };
};

const authGetUserStartedAction: AuthGetUserStartedAction = () => ({
  type: AuthAction.GET_USER_STARTED,
});

const authGetUserSuccessAction: AuthGetUserSuccessAction = payload => ({
  type: AuthAction.GET_USER_SUCCESS,
  ...payload,
});

const authGetUserFailureAction: AuthGetUserFailureAction = payload => ({
  type: AuthAction.GET_USER_FAILURE,
  ...payload,
});
