import React from 'react';

import { usePolicyHoldersSearch } from 'modules/redux/states/engagement/hooks';
import Template from './Template';

const Route: React.FunctionComponent = () => {
  const { ...props } = usePolicyHoldersSearch();
  return (
    <React.Fragment>
      <Template {...props} />
    </React.Fragment>
  );
};

export default Route;
