import { useCallback } from 'react';

import { policyGetScoresAction } from 'modules/redux/states/policy/actions';
import { shallowEqual, useSelector } from 'modules/redux';
import {
  UseHandleError,
  UseHandleUpdate,
  UsePolicyScoresSelector,
} from './types';

export const useHandleError: UseHandleError = ({ dispatch, policyId }) =>
  useCallback(() => {
    if (policyId) dispatch(policyGetScoresAction({ policyId }));
  }, [dispatch, policyId]);

export const useHandleUpdate: UseHandleUpdate = ({ dispatch, policyId }) =>
  useCallback(
    aggregateType => {
      if (policyId)
        dispatch(policyGetScoresAction({ aggregateType, policyId }));
    },
    [dispatch, policyId],
  );

export const usePolicyScoresSelector: UsePolicyScoresSelector = policyId => {
  const { data, isError, isLoading } = useSelector(
    state => state.policy.scores,
    shallowEqual,
  );
  return {
    data,
    isError,
    isLoading: !data || !!isLoading || !policyId,
  };
};
