import { getPreviousRoute, matchRoute } from 'modules/router/utils';
import { RootState } from 'modules/redux';
import { ROUTE } from 'modules/router/constants';

import { EngagementMessagesData } from './messages/types';

const isPolicySummaryCurrentRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.ENGAGEMENT_SUMMARY, router.location?.pathname);

const isPolicySummaryPreviousRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.ENGAGEMENT_SUMMARY, getPreviousRoute(router));

const isPolicySearchPreviousRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.ENGAGEMENT_SEARCH, getPreviousRoute(router));

export const shouldResetEngagement = (router: RootState['router']) =>
  (isPolicySearchPreviousRoute(router) &&
    !isPolicySummaryCurrentRoute(router)) ||
  isPolicySummaryPreviousRoute(router) ||
  !isPolicySummaryCurrentRoute(router);

export const closePopup = (popup: Window | null) => popup?.close();

export const openPopup = (message?: EngagementMessagesData) => {
  const [height, width, left, top] = [
    500,
    550,
    window.screen.width / 2 - 550 / 2,
    window.screen.height / 2 - 500 / 2,
  ];
  const popup = window.open(
    `${process.env.PUBLIC_URL}/popup.html`,
    'Loading...',
    `width=${width}, height=${height}, top=${top}, left=${left}`,
  );
  popup?.blur();
  window.focus();
  !!message && populatePopup(message, popup, 1000);
  return popup;
};

export const populatePopup = (
  message: EngagementMessagesData,
  popup: Window | null,
  timeout?: number,
) =>
  message.messageBody && popup
    ? setTimeout(() => {
        if (message.messageBody) {
          popup.document.open();
          popup.document.title = message.messageSubject;
          popup.document.write(message.messageBody);
          popup.document.close();
          popup.focus();
        }
      }, timeout || 0)
    : popup?.close();
