import { useCallback } from 'react';

import {
  engagementGetAdditionalDetailsAction,
  EngagementGetAdditionalDetailsActionApiRequest,
} from 'modules/redux/states/engagement/additionalDetails/actions';
import { Paging } from 'modules/pagination';
import { UseHandlePaging } from './types';

const useHandlePaging: UseHandlePaging = ({
  dispatch,
  page,
  pageSize,
  payload,
}) =>
  useCallback(
    (type: Paging) =>
      !!payload &&
      dispatch(
        engagementGetAdditionalDetailsAction({
          ...payload,
          page: page ? (type === Paging.NEXT ? page + 1 : page - 1) : undefined,
          pageSize,
        } as EngagementGetAdditionalDetailsActionApiRequest),
      ),
    [dispatch, page, pageSize, payload],
  );

export default useHandlePaging;
