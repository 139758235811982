import React, { useRef } from 'react';
import clsx from 'clsx';

import { DrawerOverlayProps } from './types';
import { useFocusLock } from 'modules/hooks/useFocusLock';
import { useDrawer } from './hooks';
import { useScreenLock } from 'modules/hooks/useScreenLock';

import styles from './styles.module.scss';

type DrawerOverlayChildrenProps = { active?: boolean };

const DrawerOverlay: React.FunctionComponent<DrawerOverlayProps> = ({
  children,
  destroyDrawer,
  disableEscapeKey,
  id,
  onClose,
  open,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { animating } = useDrawer({
    animationIn: styles['fade-in'],
    animationOut: styles['fade-out'],
    destroyDrawer,
    disableEscapeKey,
    onClose,
    ref,
  });
  useFocusLock({ isLocked: open, rootNode: ref });
  useScreenLock({ trigger: open });

  const classNames = clsx(
    styles['drawer-overlay'],
    styles['slide-in'],
    open && styles.open,
    !open && styles.closing,
    animating && styles.animating,
  );
  return (
    <div className={classNames} id={`drawer-${id}`} ref={ref} tabIndex={0}>
      <div>
        {React.Children.map(children, child => {
          const modalChild = child as React.ReactElement<
            React.PropsWithChildren<DrawerOverlayChildrenProps>
          >;
          return React.isValidElement(child)
            ? React.cloneElement(modalChild, { active: animating || open })
            : child;
        })}
      </div>
    </div>
  );
};

export default React.memo(DrawerOverlay);
