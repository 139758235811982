import { useCallback, useEffect } from 'react';

import { reportsGetStatusAction } from 'modules/redux/states/reports/actions';
import {
  ReportsGetStatusActionApiRequest,
  ReportsGetStatusActionApiResponse,
} from 'modules/redux/states/reports/types';
import { ReportStatus } from 'modules/redux/states/reports/constants';
import { useDispatchWithCallback } from 'modules/redux/hooks';
import { UseHandleGetStatus } from './types';

const useHandleGetStatus: UseHandleGetStatus = ({
  data,
  setData,
  setError,
  setLink,
  setLoading,
}) => {
  const dispatch = useDispatchWithCallback<
    ReportsGetStatusActionApiRequest,
    ReportsGetStatusActionApiResponse['data']
  >();

  const handleFailure = useCallback(
    (error: Error) => {
      setError(error);
      setLoading(false);
    },
    [setError, setLoading],
  );

  const handleSuccess = useCallback(
    (data: ReportsGetStatusActionApiResponse['data']) => {
      if (
        ![ReportStatus.COMPLETE, ReportStatus.PENDING].includes(data.status)
      ) {
        handleFailure(new Error('Report Deleted'));
      } else {
        setData(state => {
          return state
            ? {
                ...state,
                status:
                  data.status === ReportStatus.COMPLETE && data.presignedUrl
                    ? ReportStatus.COMPLETE
                    : ReportStatus.PENDING,
              }
            : null;
        });
        if (data.status === ReportStatus.COMPLETE && data.presignedUrl) {
          setLink(data.presignedUrl);
          setLoading(false);
        }
      }
    },
    [handleFailure, setData, setLink, setLoading],
  );

  const handleGetStatus = useCallback(
    (request: ReportsGetStatusActionApiRequest) =>
      dispatch(reportsGetStatusAction, {
        onFailure: handleFailure,
        onSuccess: handleSuccess,
        request,
      }),
    [dispatch, handleFailure, handleSuccess],
  );

  useEffect(() => {
    if (data?.status && data.status === ReportStatus.PENDING) {
      const timer = setTimeout(() => {
        handleGetStatus({ reportId: data.reportId });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [data, handleGetStatus]);

  return handleGetStatus;
};

export default useHandleGetStatus;
