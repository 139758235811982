export const getJourneyMarkers = (route: google.maps.LatLngLiteral[]) => {
  if (!route) {
    return;
  }

  return {
    start: {
      lat: route[0]?.lat,
      lng: route[0]?.lng,
    },
    end: {
      lat: route[route.length - 1]?.lat,
      lng: route[route.length - 1]?.lng,
    },
  };
};
