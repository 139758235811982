import { ThunkMiddleware } from 'redux-thunk';

import { modalShowAction } from 'modules/modals/actions';
import { ModalType } from 'modules/modals/constants';
import { Dispatch, RootState } from 'modules/redux';
import { AppActionType } from 'modules/app/types';
import { AppAction } from 'modules/app/constants';
import { Modal } from 'modules/modals/types';
import { AuthActionType } from './types';
import { AuthAction } from './constants';
import {
  logoutAuth0Client,
  loginAuth0Client,
  resetAuth0Client,
} from './providers/auth0';
import {
  authConfigAction,
  authGetUserAction,
  authLoginAction,
} from './actions';

const createAuthMiddleware: () => ThunkMiddleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | AuthActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT: {
        nextAction = next(action);
        dispatch(authConfigAction());
        break;
      }

      case AuthAction.CONFIG_FAILURE: {
        nextAction = next(action);
        dispatch(
          modalShowAction({
            closeLabel: 'Retry',
            onClose: resetAuth0Client,
            renderHeader: () => <h2>Authentication Error</h2>,
            renderContent: () => <p>{action.error.message}</p>,
            type: ModalType.INFO,
          } as Modal),
        );
        break;
      }

      case AuthAction.CONFIG_SUCCESS: {
        nextAction = next(action);
        getState().auth.isAuthenticated
          ? dispatch(authGetUserAction())
          : dispatch(authLoginAction());
        break;
      }

      case AuthAction.LOGIN_SUCCESS: {
        nextAction = next(action);
        dispatch(authGetUserAction());
        break;
      }

      case AuthAction.LOGIN: {
        nextAction = next(action);
        loginAuth0Client();
        break;
      }

      case AuthAction.LOGOUT: {
        nextAction = next(action);
        logoutAuth0Client();
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default createAuthMiddleware;
