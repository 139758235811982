import { API } from 'modules/api';
import { createCallbackAction } from 'modules/redux/factories';
import { ReportsAction } from 'modules/redux/states/reports/constants';
import {
  ReportsPostAction,
  ReportsPostFailureAction,
  ReportsPostStartedAction,
  ReportsPostSuccessAction,
} from './types';

export type {
  ReportsPostActionApiRequest,
  ReportsPostActionApiResponse,
  ReportsPostActionType,
} from './types';

export const reportsPostAction: ReportsPostAction = payload =>
  createCallbackAction({
    actionStarted: reportsPostStartedAction,
    actionSuccess: reportsPostSuccessAction,
    actionFailure: reportsPostFailureAction,
    endpoint: API.POST_REPORT,
    isAuthorized: state =>
      state.auth.isAuthenticated &&
      !!state.config.data?.tripsApplication.reports.enabled,
    payload,
  });

const reportsPostStartedAction: ReportsPostStartedAction = payload => ({
  type: ReportsAction.POST_STARTED,
  ...payload,
});

const reportsPostSuccessAction: ReportsPostSuccessAction = payload => ({
  type: ReportsAction.POST_SUCCESS,
  ...payload,
});

const reportsPostFailureAction: ReportsPostFailureAction = payload => ({
  type: ReportsAction.POST_FAILURE,
  ...payload,
});
