import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { ReactComponent as Download } from 'modules/theme/images/icons/buttons/download.svg';
import { UsePolicyHolderReport } from 'modules/redux/states/engagement/hooks';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

type ReportProps = {
  handleDownload: UsePolicyHolderReport['handleDownload'];
  isError: UsePolicyHolderReport['isError'];
  isLoading: UsePolicyHolderReport['isLoading'];
};

const Report: React.FunctionComponent<ReportProps> = ({
  handleDownload,
  isError,
  isLoading,
}) => {
  return (
    <Button
      className={styles.report}
      color={ButtonColor.BLUE}
      disabled={isLoading || !!isError}
      label="Download driver data"
      onClick={handleDownload}
      style={ButtonStyle.PRIMARY}>
      {isLoading || !!isError ? <Spinner /> : <Download />}
    </Button>
  );
};

export default React.memo(Report);
