import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE } from 'modules/router/constants';

const useHandleSessionTimeout = () => {
  const navigate = useNavigate();

  const handleSessionTimeout = useCallback(
    () => navigate(ROUTE.EVENTS_TIMEOUT, { replace: true }),
    [navigate],
  );

  return handleSessionTimeout;
};

export default useHandleSessionTimeout;
