import { useEffect } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { policyGetDetailsAction } from 'modules/redux/states/policy/actions';
import { useHandleError, usePolicyDetailsSelector } from './hooks';
import { UsePolicyDetails } from '../types';

const usePolicyDetails = (): UsePolicyDetails => {
  const dispatch: Dispatch = useDispatch();
  const { data, isError, isLoading, policyId } = usePolicyDetailsSelector();

  useEffect(() => {
    if (!data) dispatch(policyGetDetailsAction({ policyId }));
  }, [data, dispatch, policyId]);

  useHandleError({ dispatch, isError, policyId });

  return {
    data,
    isError,
    isLoading,
  };
};

export default usePolicyDetails;
