import React from 'react';
import clsx from 'clsx';

import { LayoutContainerProps } from './types';

import styles from './styles.module.scss';

const LayoutContainer: React.FunctionComponent<LayoutContainerProps> = ({
  children,
  className,
}) => {
  const classNames = clsx(styles.container, className);
  return <div className={classNames}>{children}</div>;
};

export default React.memo(LayoutContainer);
