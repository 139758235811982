import { API } from 'modules/api';

import { createDefaultAction } from 'modules/redux/factories';
import { PolicyAction } from 'modules/redux/states/policy/constants';
import {
  PolicyGetTripsAction,
  PolicyGetTripsStartedAction,
  PolicyGetTripsSuccessAction,
  PolicyGetTripsFailureAction,
} from './types';

export type { PolicyGetTripsActionApiRequest } from './types';

export const policyGetTripsAction: PolicyGetTripsAction = request =>
  createDefaultAction({
    actionStarted: policyGetTripsStartedAction,
    actionSuccess: policyGetTripsSuccessAction,
    actionFailure: policyGetTripsFailureAction,
    endpoint: API.GET_POLICY_TRIPS,
    isActive: state => !!state.policy.trips.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      actionId === state.policy.trips.actionId &&
      request.policyId === state.policy.details.data?.policyDetails.identifier,
    request: request,
  });

const policyGetTripsStartedAction: PolicyGetTripsStartedAction = payload => ({
  type: PolicyAction.GET_TRIPS_STARTED,
  ...payload,
});

const policyGetTripsSuccessAction: PolicyGetTripsSuccessAction = payload => ({
  type: PolicyAction.GET_TRIPS_SUCCESS,
  ...payload,
});

const policyGetTripsFailureAction: PolicyGetTripsFailureAction = payload => ({
  type: PolicyAction.GET_TRIPS_FAILURE,
  ...payload,
});
