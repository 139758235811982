import React from 'react';

import { NotificationSeverity } from 'modules/notifications/constants';
import { ReactComponent as ErrorSVG } from 'modules/theme/images/icons/notifications/error.svg';
import { ReactComponent as InfoSVG } from 'modules/theme/images/icons/notifications/info.svg';
import { ReactComponent as SuccessSVG } from 'modules/theme/images/icons/notifications/success.svg';
import { ReactComponent as WarningSVG } from 'modules/theme/images/icons/notifications/warning.svg';

type IconProps = {
  type?: NotificationSeverity;
};

const Icon: React.FunctionComponent<IconProps> = ({ type }) => {
  switch (type) {
    case NotificationSeverity.ERROR:
      return <ErrorSVG />;
    case NotificationSeverity.INFO:
      return <InfoSVG />;
    case NotificationSeverity.SUCCESS:
      return <SuccessSVG />;
    case NotificationSeverity.WARNING:
      return <WarningSVG />;
    default:
      return <InfoSVG />;
  }
};

export default React.memo(Icon);
