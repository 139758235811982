import { ModalCustomProps } from '../../types';
import { ModalCustomTemplate } from '../../constants';

import modalAddRemovePoints from './modalAddRemovePoints';
import modalApproveEvent from './modalApproveEvent';
import modalDeclineEvent from './modalDeclineEvent';
import modalInvalidateTrip from './modalInvalidateTrip';
import modalReports from './modalReports';

export type ModalCustomTemplateProps = Omit<
  ModalCustomProps,
  'destroyModal' | 'hideModal' | 'id' | 'onClose' | 'open'
>;

export const getTemplate = (
  template?: ModalCustomTemplate,
): ModalCustomTemplateProps | undefined => {
  switch (template) {
    case ModalCustomTemplate.ADD_REMOVE_POINTS:
      return modalAddRemovePoints;
    case ModalCustomTemplate.APPROVE_EVENT:
      return modalApproveEvent;
    case ModalCustomTemplate.DECLINE_EVENT:
      return modalDeclineEvent;
    case ModalCustomTemplate.INVALIDATE_TRIP:
      return modalInvalidateTrip;
    case ModalCustomTemplate.REPORTS:
      return modalReports;
    default:
      return undefined;
  }
};
