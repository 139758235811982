import { useCallback } from 'react';

import {
  engagementGetAdditionalDetailsAction,
  EngagementGetAdditionalDetailsActionApiRequest,
} from 'modules/redux/states/engagement/additionalDetails/actions';
import { UseHandleGet } from './types';

const useHandleGet: UseHandleGet = ({ dispatch, payload }) =>
  useCallback(
    () =>
      !!payload &&
      dispatch(
        engagementGetAdditionalDetailsAction({
          ...payload,
          page: 1,
          pageSize: 20,
        } as EngagementGetAdditionalDetailsActionApiRequest),
      ),
    [dispatch, payload],
  );

export default useHandleGet;
