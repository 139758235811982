import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { useHandleError, useHandleGet } from './hooks';
import { UsePolicyHolderPoints } from '../types';

const usePolicyHolderPoints = (): UsePolicyHolderPoints => {
  const dispatch: Dispatch = useDispatch();
  const { endUserId } = useParams() as { endUserId: string };

  const { data, isError, isLoading } = useSelector(
    state => state.engagement.points,
    shallowEqual,
  );

  const handleError = useHandleError({ dispatch, endUserId });

  const handleGet = useHandleGet({
    dispatch,
    endUserId,
  });

  useEffect(() => {
    if (!data) handleGet();
  }, [data, handleGet]);

  return {
    data,
    handleError,
    handleGet,
    isError,
    isLoading: !!isLoading,
  };
};

export default usePolicyHolderPoints;
