import { useCallback, useEffect } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { DrawerCustomTemplate, useDrawerCustom } from 'modules/drawers/hooks';
import { engagementGetDetailsAction } from 'modules/redux/states/engagement/details/actions';
import { UsePolicyHolderDetails } from '../types';
import { usePolicyHolderDetailsSelector } from './hooks';

const usePolicyHolderDetails = (): UsePolicyHolderDetails => {
  const { hideDrawerCustom, showDrawerCustom } = useDrawerCustom();
  const dispatch: Dispatch = useDispatch();

  const { data, isError, isLoading, endUserId } =
    usePolicyHolderDetailsSelector();

  const handleError = useCallback(
    () => dispatch(engagementGetDetailsAction({ endUserId })),
    [dispatch, endUserId],
  );

  const handleClick = useCallback(() => {
    if (showDrawerCustom)
      showDrawerCustom({
        template: DrawerCustomTemplate.DETAILS,
        payload: { endUserExternalId: endUserId },
      });
  }, [endUserId, showDrawerCustom]);

  useEffect(() => {
    return hideDrawerCustom;
  }, [hideDrawerCustom, endUserId]);

  useEffect(() => {
    if (!data) dispatch(engagementGetDetailsAction({ endUserId }));
  }, [data, dispatch, endUserId]);

  useEffect(() => {
    if (isError) handleError();
  }, [handleError, isError]);

  return { data, handleClick, handleError, isError, isLoading };
};

export default usePolicyHolderDetails;
