import { useCallback } from 'react';

import { ModalCustomTemplate } from 'modules/modals/constants';
import { UseHandleDeclineEvent } from './types';

const useHandleDeclineEvent: UseHandleDeclineEvent = ({
  approved,
  enabled,
  eventId,
  isSending,
  showModalCustom,
}) => {
  const handleDeclineEvent = useCallback(() => {
    if (approved === null && !isSending && showModalCustom)
      showModalCustom({
        template: ModalCustomTemplate.DECLINE_EVENT,
        payload: { approved: false, eventId },
      });
  }, [approved, eventId, isSending, showModalCustom]);

  return enabled ? handleDeclineEvent : undefined;
};

export default useHandleDeclineEvent;
