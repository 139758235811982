import React from 'react';
import { GeoJsonProperties, Feature, Geometry } from 'geojson';
import { Source, Layer } from 'react-map-gl';

import { useRelativeUnits } from '../hooks';

type PolylineGeojsonProps = {
  forwardedRef: React.RefObject<HTMLElement>;
  polyline: Feature<Geometry, GeoJsonProperties>;
};

const Polyline: React.FunctionComponent<PolylineGeojsonProps> = ({
  polyline,
  forwardedRef,
}) => {
  const { lineWidth } = useRelativeUnits(forwardedRef);
  return (
    <React.Fragment>
      <Source data={polyline} id="route" type="geojson" />
      <Layer
        id="polyline"
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        paint={{
          'line-color': '#0e8cf8',
          'line-width': lineWidth,
        }}
        source="route"
        type="line"
      />
    </React.Fragment>
  );
};

export default React.memo(Polyline);
