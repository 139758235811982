import { API } from 'modules/api';
import { createDefaultAction } from '../../../factories';
import { PolicyAction } from '../constants';
import {
  PolicyGetScoresAction,
  PolicyGetScoresStartedAction,
  PolicyGetScoresSuccessAction,
  PolicyGetScoresFailureAction,
} from './types';

export const policyGetScoresAction: PolicyGetScoresAction = request =>
  createDefaultAction({
    actionStarted: policyGetScoresStartedAction,
    actionSuccess: policyGetScoresSuccessAction,
    actionFailure: policyGetScoresFailureAction,
    endpoint: API.GET_POLICY_SCORES,
    isActive: state => !!state.policy.scores.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      actionId === state.policy.scores.actionId &&
      request.policyId === state.policy.details.data?.policyDetails.identifier,
    request: request,
  });

const policyGetScoresStartedAction: PolicyGetScoresStartedAction = payload => ({
  type: PolicyAction.GET_SCORES_STARTED,
  ...payload,
});

const policyGetScoresSuccessAction: PolicyGetScoresSuccessAction = payload => ({
  type: PolicyAction.GET_SCORES_SUCCESS,
  ...payload,
});

const policyGetScoresFailureAction: PolicyGetScoresFailureAction = payload => ({
  type: PolicyAction.GET_SCORES_FAILURE,
  ...payload,
});
