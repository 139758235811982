import { Engagement, EngagementActionType } from './types';
import { EngagementAction } from './constants';
import additionalDetailsReducer from './additionalDetails/reducer';
import brandReducer from './brand/reducer';
import detailsReducer from './details/reducer';
import messagesReducer from './messages/reducer';
import pointsReducer from './points/reducer';
import reportReducer from './report/reducer';
import rewardsReducer from './rewards/reducer';
import usersReducer from './users/reducer';

const initialState = (): Engagement => {
  return {
    additionalDetails: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
      isSending: false,
      isSendingError: false,
    },
    brand: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
    },
    details: {
      id: '',
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
    },
    messages: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
      isSending: false,
      isSendingError: false,
    },
    points: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
      isSending: false,
      isSendingError: false,
    },
    report: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
    },
    rewards: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
    },
    users: {
      actionId: null,
      data: null,
      isError: false,
      isLoading: false,
    },
  };
};

function reducer(state = initialState(), action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.LOGOUT:
    case EngagementAction.RESET:
      return initialState();

    case EngagementAction.GET_ADDITIONAL_DETAILS_FAILURE:
    case EngagementAction.GET_ADDITIONAL_DETAILS_STARTED:
    case EngagementAction.GET_ADDITIONAL_DETAILS_SUCCESS:
    case EngagementAction.POST_ADDITIONAL_DETAILS_FAILURE:
    case EngagementAction.POST_ADDITIONAL_DETAILS_STARTED:
    case EngagementAction.POST_ADDITIONAL_DETAILS_SUCCESS:
    case EngagementAction.POST_ADDITIONAL_DETAILS_RESET_ERROR:
      return additionalDetailsReducer(state, action);

    case EngagementAction.GET_BRAND_FAILURE:
    case EngagementAction.GET_BRAND_STARTED:
    case EngagementAction.GET_BRAND_SUCCESS:
      return brandReducer(state, action);

    case EngagementAction.GET_DETAILS_FAILURE:
    case EngagementAction.GET_DETAILS_STARTED:
    case EngagementAction.GET_DETAILS_SUCCESS:
      return detailsReducer(state, action);

    case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_FAILURE:
    case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_STARTED:
    case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_SUCCESS:
    case EngagementAction.GET_MESSAGES_FAILURE:
    case EngagementAction.GET_MESSAGES_STARTED:
    case EngagementAction.GET_MESSAGES_SUCCESS:
      return messagesReducer(state, action);

    case EngagementAction.GET_POINTS_FAILURE:
    case EngagementAction.GET_POINTS_STARTED:
    case EngagementAction.GET_POINTS_SUCCESS:
    case EngagementAction.POST_ADD_POINTS_FAILURE:
    case EngagementAction.POST_ADD_POINTS_STARTED:
    case EngagementAction.POST_ADD_POINTS_SUCCESS:
    case EngagementAction.POST_REMOVE_POINTS_FAILURE:
    case EngagementAction.POST_REMOVE_POINTS_STARTED:
    case EngagementAction.POST_REMOVE_POINTS_SUCCESS:
    case EngagementAction.POST_SEND_POINTS_RESET_ERROR:
      return pointsReducer(state, action);

    case EngagementAction.GET_REPORT_FAILURE:
    case EngagementAction.GET_REPORT_STARTED:
    case EngagementAction.GET_REPORT_SUCCESS:
      return reportReducer(state, action);

    case EngagementAction.GET_REWARDS_FAILURE:
    case EngagementAction.GET_REWARDS_STARTED:
    case EngagementAction.GET_REWARDS_SUCCESS:
      return rewardsReducer(state, action);

    case EngagementAction.GET_USERS_FAILURE:
    case EngagementAction.GET_USERS_STARTED:
    case EngagementAction.GET_USERS_SUCCESS:
      return usersReducer(state, action);

    default:
      return state;
  }
}

export default reducer;
