import { useEffect } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { policyGetScoresAction } from 'modules/redux/states/policy/actions';
import { UsePolicyScores } from '../types';
import {
  useHandleError,
  useHandleUpdate,
  usePolicyScoresSelector,
} from './hooks';

const usePolicyScores = (policyId?: string): UsePolicyScores => {
  const { data, isError, isLoading } = usePolicyScoresSelector(policyId);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (policyId) dispatch(policyGetScoresAction({ policyId }));
  }, [dispatch, policyId]);

  const handleUpdate = useHandleUpdate({ dispatch, policyId });

  const handleError = useHandleError({ dispatch, policyId });

  return {
    data,
    handleError,
    handleUpdate,
    isError,
    isLoading: !data || !!isLoading || !policyId,
  };
};

export default usePolicyScores;
