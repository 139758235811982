import React from 'react';

import {
  isAndroid,
  isChrome,
  isIOS,
  isMacOs as isMacOsDefault,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
} from 'react-device-detect';

export type PlatformType = {
  isAndroid: boolean;
  isChrome: boolean;
  isIOS: boolean;
  isMacOs: boolean;
  isMobile: boolean;
  isMobileOnly: boolean;
  isSafari: boolean;
  isIE: boolean;
  isFirefox: boolean;
  isTablet: boolean;
  isWindows: boolean;
  osVersion: string;
};

export const isMacOs = isMacOsDefault && !isIOS && !isTablet;

export default React.createContext({
  isAndroid,
  isChrome,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
} as PlatformType);

export {
  isAndroid,
  isChrome,
  isIOS,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
} from 'react-device-detect';
