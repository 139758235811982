import React, { useCallback } from 'react';

import { eventSort } from './utils';
import { PolicyEventDetails } from 'modules/redux/states/policy/trip/types';
import { ReactComponent as TripSVG } from 'modules/theme/images/icons/navigation/trips.svg';
import EventsSummaryItem from './PolicyEventsSummaryItem';

import styles from './styles.module.scss';

type PolicyEventsSummaryListProps = {
  events: PolicyEventDetails[];
};

const PolicyEventsSummaryList: React.FunctionComponent<
  PolicyEventsSummaryListProps
> = ({ events }) => {
  const eventsList = useCallback(() => eventSort(events), [events]);

  return eventsList().length > 0 ? (
    <ul>
      {eventsList().map((event, index: number) => (
        <EventsSummaryItem
          count={event.count}
          eventType={event.type}
          key={`event-${index}`}
        />
      ))}
    </ul>
  ) : (
    <div className={styles['no-events']}>
      <TripSVG />
      <p>No events have been found for this trip</p>
    </div>
  );
};

export default React.memo(PolicyEventsSummaryList);
