import React from 'react';

import { FontSize, Padding } from 'components/Card/constants';
import {
  Buttons,
  Drawers,
  Hyperlinks,
  QuestionsInputs,
  QuestionsSelects,
  QuestionsTextAreas,
  Modals,
  Notifications,
  ShortText,
  Text,
} from './components';
import { WidthPercentage } from 'components/Layout/constants';
import * as Layout from 'components/Layout';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';

import styles from './styles.module.scss';

const Template: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <PageTitle title="Debug" />
      <Layout.Container className={styles.debug}>
        <Layout.Column className={styles['debug-container']}>
          <Card
            renderHeader={() => (
              <span>Render React component or inline JSX</span>
            )}
            scrollable={true}
            title="Form">
            <QuestionsInputs />
            <QuestionsSelects />
            <QuestionsTextAreas />
            <Text />
          </Card>
        </Layout.Column>
        <Layout.Column scrollable={true}>
          <Notifications />
          <Drawers />
          <Modals />
          <Buttons />
          <Hyperlinks />
          <Card padding={Padding.LARGE} title="Text">
            <ShortText />
          </Card>
          <Layout.Row>
            <Layout.Column width={WidthPercentage.FIFTY}>
              <Card fontSize={FontSize.SMALL} title="Text">
                <ShortText />
              </Card>
            </Layout.Column>
            <Layout.Column width={WidthPercentage.FIFTY}>
              <Card fontSize={FontSize.SMALL} title="Text">
                <ShortText />
              </Card>
            </Layout.Column>
          </Layout.Row>
        </Layout.Column>
      </Layout.Container>
    </React.Fragment>
  );
};

export default React.memo(Template);
