import React from 'react';

import { WidthPercentage } from 'components/Layout/constants';
import { UsePolicySearch } from 'modules/redux/states/policy/hooks';
import * as Layout from 'components/Layout';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import PolicySearchForm from './components/PolicySearchForm';

type TemplateProps = {
  handleSearch: UsePolicySearch['handleSearch'];
  isError: UsePolicySearch['isError'];
  isLoading: UsePolicySearch['isLoading'];
};

const Template: React.FunctionComponent<TemplateProps> = ({
  handleSearch,
  isError,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <PageTitle title="Policy Search" />
      <Layout.Container>
        <Layout.Column width={WidthPercentage.FIFTY}>
          <Card>
            <PolicySearchForm
              handleSearch={handleSearch}
              isError={isError}
              isLoading={isLoading}
            />
          </Card>
        </Layout.Column>
      </Layout.Container>
    </React.Fragment>
  );
};

export default React.memo(Template);
