import React from 'react';

import { WidthPercentage } from 'components/Layout/constants';
import * as Layout from 'components/Layout';
import GenerateReport from './components/GenerateReport';
import PageTitle from 'components/PageTitle';

const Template: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <PageTitle title="Reports" />
      <Layout.Container>
        <Layout.Column width={WidthPercentage.FIFTY}>
          <GenerateReport />
        </Layout.Column>
      </Layout.Container>
    </React.Fragment>
  );
};

export default React.memo(Template);
