import dayjs from 'dayjs';

export const useDates = (endDateTime?: string, startDateTime?: string) => {
  const startDate = dayjs(startDateTime).format('DD/MM/YYYY, HH:mm');
  const endDate = dayjs(endDateTime)
    .format('DD/MM/YYYY, HH:mm')
    .split(',')
    .filter(item => !startDate.split(',').includes(item))
    .join(',')
    .trim();

  return { endDate, startDate };
};
