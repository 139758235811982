import React from 'react';

import styles from './styles.module.scss';

const Component: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className={styles['history-rewards-skeleton']}>
        <ul>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
          <li>
            <div>
              <div></div>
              <p>
                <strong></strong>
                <span></span>
              </p>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
          </li>
        </ul>
        <footer>
          <div>
            <strong></strong>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
        </footer>
      </div>
      <span data-nosnippet className="visually-hidden">
        Loading history rewards
      </span>
    </React.Fragment>
  );
};

export default React.memo(Component);
