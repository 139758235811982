import React from 'react';

import { InputPrimitive } from 'components/Inputs/Input';
import { ReactComponent as Cross } from 'modules/theme/images/icons/drawer/cross.svg';
import { ReactComponent as Tick } from 'modules/theme/images/icons/drawer/tick.svg';
import Button from 'components/Button';

import styles from './styles.module.scss';

type AdditionalDetailFieldInputProps = {
  handleCancelChanges: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSaveChanges: () => Promise<void>;
  keyName: string;
  value: string;
};

const AdditionalDetailFieldInput: React.FunctionComponent<
  AdditionalDetailFieldInputProps
> = ({
  handleCancelChanges,
  handleChange,
  handleKeyDown,
  handleSaveChanges,
  keyName,
  value,
}) => {
  return (
    <div className={styles['additional-detail-field-input']}>
      <InputPrimitive
        name={keyName}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
      />
      <Button
        ariaLabel={`Cancel changes for ${keyName} field`}
        onClick={handleCancelChanges}>
        <Cross />
      </Button>
      <Button
        ariaLabel={`Save changes for ${keyName} field`}
        disabled={value.length === 0}
        onClick={handleSaveChanges}>
        <Tick />
      </Button>
    </div>
  );
};

export default React.memo(AdditionalDetailFieldInput);
