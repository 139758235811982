import React, { useCallback, useEffect } from 'react';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { policyPutTripAction } from 'modules/redux/states/policy/trip/actions';
import { PolicyPutTripActionApiRequest } from 'modules/redux/states/policy/trip/actions';

import Confirm from '../components/Confirm';
import Failure from '../components/Failure';

type TemplateProps = {
  active: boolean;
  onClose: () => void;
  payload?: Record<string, unknown>;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  active,
  onClose,
  payload,
}) => {
  const dispatch: Dispatch = useDispatch();

  const trip = useSelector(
    state => state.policy.trip.find(trip => trip.tripId === payload?.tripId),
    shallowEqual,
  );

  const handleConfirm = useCallback(
    () =>
      !!payload &&
      dispatch(policyPutTripAction(payload as PolicyPutTripActionApiRequest)),
    [dispatch, payload],
  );

  useEffect(() => {
    trip && !trip?.data?.state.isValid && onClose();
  }, [onClose, trip]);

  return trip?.isSendingError ? (
    <Failure
      active={active}
      isError={trip.isSendingError}
      onCancel={onClose}
      onError={handleConfirm}
    />
  ) : (
    <Confirm
      active={active}
      isLoading={!!trip?.isSending}
      onCancel={onClose}
      onConfirm={handleConfirm}
      text="Invalidating a trip is permanent. Are you sure you would like to proceed?"
      title="Are you sure?"
    />
  );
};
export default React.memo(Template);
