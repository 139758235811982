import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import Button from 'components/Button';

import styles from './styles.module.scss';

type TripsListErrorProps = {
  handleError: () => void;
};

const ErrorMessage: React.FunctionComponent<TripsListErrorProps> = ({
  handleError,
}) => {
  return (
    <li className={styles['trips-list-pagination-error']}>
      There was an error loading more trips.
      <Button
        color={ButtonColor.BLUE}
        label="Try again"
        onClick={handleError}
        style={ButtonStyle.PRIMARY}
      />
    </li>
  );
};

export default React.memo(ErrorMessage);
