import { useCallback } from 'react';

import { ModalCustomTemplate } from 'modules/modals/constants';
import { UseHandleInvalidateTrip } from './types';

const useHandleInvalidateTrip: UseHandleInvalidateTrip = ({
  enabled,
  isSending,
  isInvalid,
  policyId,
  tripId,
  showModalCustom,
}) => {
  const handleInvalidateTrip = useCallback(() => {
    if (!isSending && !isInvalid && policyId && showModalCustom)
      showModalCustom({
        template: ModalCustomTemplate.INVALIDATE_TRIP,
        payload: { policyId, tripId },
      });
  }, [isSending, isInvalid, policyId, showModalCustom, tripId]);

  return enabled ? handleInvalidateTrip : undefined;
};

export default useHandleInvalidateTrip;
