import React from 'react';

import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type PolicyNumberProps = {
  isLoading?: boolean;
  policyId?: string;
};

const PolicyNumber: React.FunctionComponent<PolicyNumberProps> = ({
  isLoading,
  policyId,
}) => {
  return !isLoading ? (
    <React.Fragment>
      {policyId && (
        <p className={styles['policy-number']}>
          <span>Policy Number</span>
          <span>{policyId}</span>
        </p>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default React.memo(PolicyNumber);
