import React, { useCallback } from 'react';
import clsx from 'clsx';

import { ButtonProps } from './types';
import { ButtonType } from './constants';
import { ReactComponent as SpinnerSVG } from 'modules/theme/images/icons/spinners/circular-bars.svg';
import { useActiveAnimation, useHandlers } from './hooks';

import styles from './styles.module.scss';

const Button: React.FunctionComponent<ButtonProps> = ({
  ariaControls,
  ariaExpanded,
  ariaLabel,
  children,
  className,
  color,
  disabled,
  form,
  id,
  label,
  loading,
  onClick,
  style,
  tabIndex,
  title,
  type = ButtonType.BUTTON,
}) => {
  const { animation, handleActiveAnimation, ref } = useActiveAnimation(
    disabled,
    loading,
  );
  const { handleClick, handleKeyDown, handleMouseDown, handleSubmit } =
    useHandlers(handleActiveAnimation, disabled, loading, onClick);
  const getTabIndex = useCallback(() => {
    if (disabled) return -1;
    return tabIndex ? tabIndex : 0;
  }, [disabled, tabIndex]);

  const classNames = clsx(
    style && styles.button,
    color && styles[`${color}`],
    style && styles[`${style}`],
    animation && 'active-animation',
    loading && styles.loading,
    !disabled && !loading && styles.useable,
    className,
  );

  return (
    <button
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-label={ariaLabel || label}
      className={classNames}
      disabled={disabled}
      form={form}
      id={id}
      onClick={
        type === ButtonType.BUTTON && onClick ? handleClick : handleSubmit
      }
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      ref={ref}
      tabIndex={getTabIndex()}
      title={title || ariaLabel}
      type={type}>
      {label && <span>{label}</span>}
      {loading && <SpinnerSVG />}
      {children}
    </button>
  );
};

export default React.memo(Button);
