import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { Padding } from 'components/Card/constants';
import Button from 'components/Button';
import Card from 'components/Card';

import styles from '../styles.module.scss';

const Buttons: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <Card className={styles.buttons} padding={Padding.LARGE} title="Buttons">
        <div>
          <Button
            color={ButtonColor.BLUE}
            label="Search"
            style={ButtonStyle.PRIMARY}
          />
          <Button
            color={ButtonColor.RED}
            label="Search"
            style={ButtonStyle.PRIMARY}
          />
          <Button
            color={ButtonColor.BLUE}
            label="Search"
            style={ButtonStyle.SECONDARY}
          />
          <Button
            color={ButtonColor.RED}
            label="Search"
            style={ButtonStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.buttons}
        padding={Padding.LARGE}
        title="Buttons - Disabled">
        <div>
          <Button
            color={ButtonColor.BLUE}
            disabled={true}
            label="Search"
            style={ButtonStyle.PRIMARY}
          />
          <Button
            color={ButtonColor.RED}
            disabled={true}
            label="Search"
            style={ButtonStyle.PRIMARY}
          />
          <Button
            color={ButtonColor.BLUE}
            disabled={true}
            label="Search"
            style={ButtonStyle.SECONDARY}
          />
          <Button
            color={ButtonColor.RED}
            disabled={true}
            label="Search"
            style={ButtonStyle.SECONDARY}
          />
        </div>
      </Card>
      <Card
        className={styles.buttons}
        padding={Padding.LARGE}
        title="Buttons - Loading">
        <div>
          <Button
            color={ButtonColor.BLUE}
            label="Search"
            loading={true}
            style={ButtonStyle.PRIMARY}
          />
          <Button
            color={ButtonColor.RED}
            label="Search"
            loading={true}
            style={ButtonStyle.PRIMARY}
          />
          <Button
            color={ButtonColor.BLUE}
            label="Search"
            loading={true}
            style={ButtonStyle.SECONDARY}
          />
          <Button
            color={ButtonColor.RED}
            label="Search"
            loading={true}
            style={ButtonStyle.SECONDARY}
          />
        </div>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(Buttons);
