import React from 'react';
import clsx from 'clsx';

import { LayoutColumnProps } from './types';

import styles from './styles.module.scss';

const LayoutColumn: React.FunctionComponent<LayoutColumnProps> = ({
  children,
  className,
  scrollable,
  width,
}) => {
  const classNames = clsx(
    styles.column,
    styles[`${width}`],
    scrollable && styles.scrollable,
    className,
  );
  return <div className={classNames}>{children}</div>;
};

export default React.memo(LayoutColumn);
