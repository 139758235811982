import React from 'react';
import clsx from 'clsx';

import { ReactComponent as ClosedFillIcon } from 'modules/theme/images/icons/history/manually-added-points.svg';
import { ReactComponent as ClosedIcon } from 'modules/theme/images/icons/history/toggle.svg';
import { ReactComponent as OpenFillIcon } from 'modules/theme/images/icons/history/manually-subtracted-points.svg';
import { ReactComponent as OpenIcon } from 'modules/theme/images/icons/history/open.svg';
import { useToggle } from './hooks';
import Button from 'components/Button';

import styles from './styles.module.scss';

type ToggleProps = {
  children: (props: {
    descRef: React.MutableRefObject<HTMLElement | null>;
    handleTransitionEnd: (e: React.TransitionEvent<HTMLElement>) => void;
  }) => React.ReactNode;
  label: string;
};

const Toggle: React.FunctionComponent<ToggleProps> = ({ children, label }) => {
  const { descRef, disabled, handleClick, handleTransitionEnd, toggle } =
    useToggle();

  const classNames = clsx(
    styles['history-messages-item'],
    toggle && styles['show-description'],
  );
  return (
    <li className={classNames}>
      <Button ariaLabel={label} disabled={disabled} onClick={handleClick}>
        {children({ descRef, handleTransitionEnd })}
        {toggle ? (
          <React.Fragment>
            <OpenIcon />
            <OpenFillIcon />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ClosedIcon />
            <ClosedFillIcon />
          </React.Fragment>
        )}
      </Button>
    </li>
  );
};

export default React.memo(Toggle);
