import React from 'react';

import { FontSize } from 'components/Card/constants';
import { PolicyEventDetails } from 'modules/redux/states/policy/trip/types';
import { UsePolicyTrip } from 'modules/redux/states/policy/hooks/usePolicySummary';
import Card from 'components/Card';
import PolicyEventsSummaryList from './PolicyEventsSummaryList';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type PolicyEventsSummaryProps = {
  events?: PolicyEventDetails[];
  isLoading: UsePolicyTrip['isLoading'];
};

const PolicyEventsSummary: React.FunctionComponent<
  PolicyEventsSummaryProps
> = ({ events, isLoading }) => {
  return !isLoading && events ? (
    <Card
      className={styles['events-summary']}
      fontSize={FontSize.SMALL}
      title="Events summary">
      <PolicyEventsSummaryList events={events} />
    </Card>
  ) : (
    <Skeleton />
  );
};

export default React.memo(PolicyEventsSummary);
