import React from 'react';

import Card from 'components/Card';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  return (
    <Card className={styles.skeleton}>
      <span data-nosnippet className="visually-hidden">
        Loading invalidate trip
      </span>
      <div>
        <span></span>
      </div>
    </Card>
  );
};

export default React.memo(Skeleton);
