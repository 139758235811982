import { GetUseModalReportsStep } from './types';
import { ModalReportStep } from '../constants';

export const getUseModalReportsStep: GetUseModalReportsStep = ({
  endDate,
  isError,
  link,
  policy,
  reportType,
  startDate,
}) => {
  if (!!reportType.requiresPolicyId && !policy) return ModalReportStep.POLICY;

  if (!!reportType.requiresDate && (!endDate || !startDate))
    return ModalReportStep.DATE;

  if (!isError && !link) return ModalReportStep.CONFIRM;

  if (!isError) return ModalReportStep.SUCCESS;

  return ModalReportStep.ERROR;
};
