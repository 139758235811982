import yup from 'modules/validation';

export type Model = {
  points: number;
  reason: string;
};

export const defaultValues: Model = {
  points: 0,
  reason: '',
};

export const schema = yup.object().shape({
  points: yup.number().min(1).required(),
  reason: yup.string().required(),
});
