import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { defaultValues, Model as RemovePointsModel, schema } from './model';
import { PointType } from '../../../modalAddRemovePoints/Template/constants';
import { QuestionNumber, QuestionTextArea } from 'components/Questions';
import Form from 'components/Form';

type RemoveProps = {
  active: boolean;
  bankedPoints: number;
  handleRemovePoints: (values: RemovePointsModel) => void;
  isSending: boolean;
  pointType: PointType;
};

const Remove: React.FunctionComponent<RemoveProps> = ({
  active,
  bankedPoints,
  handleRemovePoints,
  isSending,
  pointType,
}) => {
  const defaultValue = useMemo(() => {
    return {
      bankedPoints: bankedPoints + 1,
      points: defaultValues.points,
      reason: defaultValues.reason,
    };
  }, [bankedPoints]);

  return (
    <React.Fragment>
      <Form<RemovePointsModel>
        defaultValues={defaultValue}
        id="points-form"
        onSubmit={handleRemovePoints}
        schema={schema}>
        {({ control, setValue }) => (
          <React.Fragment>
            <QuestionNumber
              control={control}
              disabled={!active || isSending}
              label="Enter number of points (Maximum of 5,000 points)"
              max={5000}
              min={0}
              name="points"
              readOnly={!active || isSending}
              setValue={setValue}
            />
            {pointType === PointType.REMOVE && (
              <Controller
                control={control}
                name="bankedPoints"
                render={({ field }) => <input {...field} type="hidden" />}
              />
            )}
            <QuestionTextArea
              control={control}
              disabled={!active || isSending}
              label="Reason for points adjustment"
              name="reason"
              placeholder={`Enter a reason for ${pointType} points`}
              readOnly={!active || isSending}
            />
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
};

export default React.memo(Remove);
