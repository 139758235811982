import { ModalInfoProps } from '../../types';
import { ModalInfoTemplate } from '../../constants';

import modalAddPointsError from './modalAddPointsError';
import modalDebug from './modalDebug';
import modalDebugSuccessfulSubmission from './modalDebugSuccessfulSubmission';
import modalEventsError from './modalEventsError';
import modalRemovePointsError from './modalRemovePointsError';

export type ModalInfoTemplateProps = Omit<
  ModalInfoProps,
  'destroyModal' | 'hideModal' | 'id' | 'open'
>;

export const getTemplate = (
  template?: ModalInfoTemplate,
): ModalInfoTemplateProps | undefined => {
  switch (template) {
    case ModalInfoTemplate.ADD_POINTS_ERROR:
      return modalAddPointsError;
    case ModalInfoTemplate.DEBUG:
      return modalDebug;
    case ModalInfoTemplate.DEBUG_SUCCESSFUL_SUBMISSION:
      return modalDebugSuccessfulSubmission;
    case ModalInfoTemplate.EVENTS_ERROR:
      return modalEventsError;
    case ModalInfoTemplate.REMOVE_POINTS_ERROR:
      return modalRemovePointsError;
    default:
      return undefined;
  }
};
