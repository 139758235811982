import React from 'react';

import { ReactComponent as AutoBankedPoints } from 'modules/theme/images/icons/history/auto-banked-points.svg';
import { ReactComponent as Expired } from 'modules/theme/images/icons/history/expired.svg';
import { ReactComponent as GiftCard } from 'modules/theme/images/icons/history/gift-card.svg';
import { ReactComponent as ManuallyAddedPoints } from 'modules/theme/images/icons/history/manually-added-points.svg';
import { ReactComponent as ManuallyRemovedPoints } from 'modules/theme/images/icons/history/manually-subtracted-points.svg';
import { RewardCultureResourceType } from 'modules/redux/states/engagement/rewards/constants';

type Props = {
  type: RewardCultureResourceType;
};

const Icon: React.FunctionComponent<Props> = ({ type }) => {
  switch (type) {
    case RewardCultureResourceType.AUTO_BANKED_POINTS:
    case RewardCultureResourceType.BANKED_POINTS:
      return <AutoBankedPoints />;
    case RewardCultureResourceType.EXPIRED_POINTS:
      return <Expired />;
    case RewardCultureResourceType.CLAIMED_REWARD:
      return <GiftCard />;
    case RewardCultureResourceType.SUBMIT_POINTS:
      return <ManuallyAddedPoints />;
    case RewardCultureResourceType.REMOVED_POINTS:
      return <ManuallyRemovedPoints />;
    default:
      return null;
  }
};

export default React.memo(Icon);
