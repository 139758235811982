import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import PlatformContext from 'modules/platform';
import { UsePolicyTrips } from 'modules/redux/states/policy/hooks/usePolicySummary';

type UseTripsList = (props: {
  handlePaging: UsePolicyTrips['handlePaging'];
  policyId?: string;
}) => {
  inView: boolean;
  ref: (node?: Element | null | undefined) => void;
};

const useTripsList: UseTripsList = ({ handlePaging, policyId }) => {
  const { isAndroid } = useContext(PlatformContext);
  const [loadMore, setLoadMore] = useState(false);

  const { ref, inView } = useInView({
    threshold: isAndroid ? 0.95 : 1,
    onChange: inView => inView && setLoadMore(true),
  });

  useEffect(() => {
    if (handlePaging && inView && loadMore) {
      handlePaging();
      setLoadMore(false);
    }
  }, [handlePaging, inView, loadMore]);

  return {
    inView,
    ref,
  };
};

export default useTripsList;
