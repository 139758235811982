import { API } from 'modules/api';
import { createDefaultAction } from 'modules/redux/factories';
import { PolicyAction } from '../../../constants';
import {
  PolicyGetTripAction,
  PolicyGetTripStartedAction,
  PolicyGetTripSuccessAction,
  PolicyGetTripFailureAction,
} from './types';

export type { PolicyGetTripActionApiRequest } from './types';

export const policyGetTripAction: PolicyGetTripAction = request =>
  createDefaultAction({
    actionStarted: policyGetTripStartedAction,
    actionSuccess: policyGetTripSuccessAction,
    actionFailure: policyGetTripFailureAction,
    endpoint: API.GET_POLICY_TRIP,
    isActive: state =>
      !!state.policy.trip.find(trip => trip.tripId === request.tripId)
        ?.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      !!state.policy.trip.find(trip => actionId === trip.actionId) &&
      request.policyId === state.policy.details.data?.policyDetails.identifier,
    request: request,
  });

const policyGetTripStartedAction: PolicyGetTripStartedAction = payload => ({
  type: PolicyAction.GET_TRIP_STARTED,
  ...payload,
});

const policyGetTripSuccessAction: PolicyGetTripSuccessAction = payload => ({
  type: PolicyAction.GET_TRIP_SUCCESS,
  ...payload,
});

const policyGetTripFailureAction: PolicyGetTripFailureAction = payload => ({
  type: PolicyAction.GET_TRIP_FAILURE,
  ...payload,
});
