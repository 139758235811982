import React from 'react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Navigate, Route, Routes } from 'react-router-dom';

import { history, shallowEqual, useSelector } from 'modules/redux';
import { isAdmin } from 'modules/admin';
import { ROUTE } from './constants';
import RouteApp from 'routes/app';
import RouteDebug from 'routes/debug';
import RouteEngagementSearch from 'routes/engagement/search';
import RouteEngagementSummary from 'routes/engagement/summary';
import RouteEvents from 'routes/events/events';
import RouteEventsTimeout from 'routes/events/timeout';
import RouteEvent from 'routes/events/event';
import RouteLogin from 'routes/login';
import RoutePolicySearch from 'routes/policy/search';
import RoutePolicySummary from 'routes/policy/summary';
import RouteReports from 'routes/policy/reports';
import { useConfigSelector } from 'modules/redux/states/config/hooks';

const Component: React.FunctionComponent = () => {
  const { isAuthenticated } = useSelector(state => state.auth, shallowEqual);
  const { eventsApplication, tripsApplication } = useConfigSelector();
  return (
    <Router history={history}>
      <RouteApp>
        {!isAuthenticated ? (
          <Routes>
            <Route index element={<RouteLogin />} />

            <Route path="*" element={<Navigate replace to={ROUTE.LOGIN} />} />
          </Routes>
        ) : (
          <Routes>
            <Route
              index
              element={
                tripsApplication.enabled ? (
                  <Navigate replace to={ROUTE.POLICY_SEARCH} />
                ) : eventsApplication.enabled ? (
                  <Navigate replace to={ROUTE.EVENTS} />
                ) : (
                  <React.Fragment />
                )
              }
            />

            {eventsApplication.enabled && (
              <React.Fragment>
                <Route path={ROUTE.EVENTS} element={<RouteEvents />} />

                <Route
                  path={ROUTE.EVENTS_TIMEOUT}
                  element={<RouteEventsTimeout />}
                />

                <Route path={ROUTE.EVENTS_ID} element={<RouteEvent />} />
              </React.Fragment>
            )}

            {tripsApplication.enabled && (
              <React.Fragment>
                <Route
                  path={ROUTE.POLICY_SEARCH}
                  element={<RoutePolicySearch />}
                />

                <Route
                  path={ROUTE.POLICY_SUMMARY}
                  element={<RoutePolicySummary />}
                />

                <Route
                  path={ROUTE.POLICY_SUMMARY_SUBJECT}
                  element={<Navigate replace to={ROUTE.POLICY_SEARCH} />}
                />

                <Route
                  path={ROUTE.POLICY_SUMMARY_TRIP}
                  element={<RoutePolicySummary />}
                />

                {tripsApplication.reports.enabled && (
                  <Route path={ROUTE.REPORTS} element={<RouteReports />} />
                )}
              </React.Fragment>
            )}

            <Route
              path={ROUTE.ENGAGEMENT_SEARCH}
              element={<RouteEngagementSearch />}
            />

            <Route
              path={ROUTE.ENGAGEMENT_SUMMARY}
              element={<RouteEngagementSummary />}
            />

            {isAdmin && <Route path={ROUTE.DEBUG} element={<RouteDebug />} />}

            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        )}
      </RouteApp>
    </Router>
  );
};

export default Component;
