import { useState } from 'react';

const useChartHover = () => {
  const [chartIndex, setChartIndex] = useState<number | undefined>();
  return {
    chartIndex,
    setChartIndex,
  };
};

export default useChartHover;
