import { useCallback, useEffect, useRef, useState } from 'react';

export const useActiveAnimation = (disabled?: boolean, loading?: boolean) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [animation, setAnimation] = useState(false);

  const getAnimationDuration = (element: HTMLButtonElement) => {
    return (
      window
        .getComputedStyle(element, null)
        .getPropertyValue('animation-duration') !== '0s' ||
      window
        .getComputedStyle(element, ':before')
        .getPropertyValue('animation-duration') !== '0s' ||
      window
        .getComputedStyle(element, ':after')
        .getPropertyValue('animation-duration') !== '0s'
    );
  };

  const handleActiveAnimation = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLButtonElement>,
    ) => {
      if (
        ref &&
        ref.current &&
        event.currentTarget === ref.current &&
        getAnimationDuration(ref.current)
      ) {
        setAnimation(true);
      }
    },
    [ref],
  );

  const handleAnimationEnd = useCallback((event: AnimationEvent) => {
    if (event.currentTarget === ref.current) {
      setAnimation(false);
    }
  }, []);

  useEffect(() => {
    if (disabled || loading) {
      setAnimation(false);
    }
  }, [disabled, loading]);

  useEffect(() => {
    const element = ref.current;
    element?.addEventListener('animationend', handleAnimationEnd);
    return () =>
      element?.removeEventListener('animationend', handleAnimationEnd);
  }, [animation, handleAnimationEnd]);

  return {
    animation,
    handleActiveAnimation,
    ref,
  } as const;
};
