import { API } from 'modules/api';

import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import {
  EngagementGetRewardsAction,
  EngagementGetRewardsFailureAction,
  EngagementGetRewardsStartedAction,
  EngagementGetRewardsSuccessAction,
} from './types';

export const engagementGetRewardsAction: EngagementGetRewardsAction = request =>
  createDefaultAction({
    actionStarted: engagementGetRewardsStartedAction,
    actionSuccess: engagementGetRewardsSuccessAction,
    actionFailure: engagementGetRewardsFailureAction,
    endpoint: API.GET_ENGAGEMENT_REWARDS,
    isActive: state => !!state.engagement.rewards.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      actionId === state.engagement.rewards.actionId,
    request: request,
  });

const engagementGetRewardsStartedAction: EngagementGetRewardsStartedAction =
  payload => ({
    type: EngagementAction.GET_REWARDS_STARTED,
    ...payload,
  });

const engagementGetRewardsSuccessAction: EngagementGetRewardsSuccessAction =
  payload => ({
    type: EngagementAction.GET_REWARDS_SUCCESS,
    ...payload,
  });

const engagementGetRewardsFailureAction: EngagementGetRewardsFailureAction =
  payload => ({
    type: EngagementAction.GET_REWARDS_FAILURE,
    ...payload,
  });
