import React from 'react';

import styles from './styles.module.scss';

const NoResults: React.FunctionComponent = () => {
  return (
    <div className={styles['no-results']}>
      <h2>No messages found</h2>
    </div>
  );
};

export default React.memo(NoResults);
