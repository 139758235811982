import { API } from 'modules/api';
import { createCallbackAction } from 'modules/redux/factories';
import { ReportsAction } from 'modules/redux/states/reports/constants';
import {
  ReportsGetPolicyAction,
  ReportsGetPolicyFailureAction,
  ReportsGetPolicyStartedAction,
  ReportsGetPolicySuccessAction,
} from './types';

export type {
  ReportsGetPolicyActionApiRequest,
  ReportsGetPolicyActionApiResponse,
  ReportsGetPolicyActionType,
} from './types';

export const reportsGetPolicyAction: ReportsGetPolicyAction = payload =>
  createCallbackAction({
    actionStarted: reportsGetPolicyStartedAction,
    actionSuccess: reportsGetPolicySuccessAction,
    actionFailure: reportsGetPolicyFailureAction,
    endpoint: API.GET_REPORT_POLICY,
    isAuthorized: state =>
      state.auth.isAuthenticated &&
      !!state.config.data?.tripsApplication.reports.enabled,
    payload,
  });

const reportsGetPolicyStartedAction: ReportsGetPolicyStartedAction =
  payload => ({
    type: ReportsAction.GET_POLICY_STARTED,
    ...payload,
  });

const reportsGetPolicySuccessAction: ReportsGetPolicySuccessAction =
  payload => ({
    type: ReportsAction.GET_POLICY_SUCCESS,
    ...payload,
  });

const reportsGetPolicyFailureAction: ReportsGetPolicyFailureAction =
  payload => ({
    type: ReportsAction.GET_POLICY_FAILURE,
    ...payload,
  });
