import React from 'react';

import { PointType } from '../../modalAddRemovePoints/Template/constants';
import Button from 'components/Button';

import styles from './styles.module.scss';

type PointsFormButtonsProps = {
  bankedPoints: number;
  handleClick: (pointType: PointType) => void;
  isSending: boolean;
  pointType: PointType;
};

const Buttons: React.FunctionComponent<PointsFormButtonsProps> = ({
  bankedPoints,
  handleClick,
  isSending,
  pointType,
}) => {
  return (
    <div className={styles.buttons}>
      <Button
        className={pointType === PointType.ADD ? styles.selected : undefined}
        disabled={isSending}
        label="Add points"
        onClick={() => handleClick(PointType.ADD)}
      />
      <Button
        className={pointType === PointType.REMOVE ? styles.selected : undefined}
        disabled={isSending || bankedPoints === 0}
        label="Remove points"
        onClick={() => handleClick(PointType.REMOVE)}
      />
    </div>
  );
};

export default React.memo(Buttons);
