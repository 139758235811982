import { useCallback } from 'react';

import { engagementPostRemovePointsAction } from 'modules/redux/states/engagement/points/actions/engagementPostRemovePointsAction';
import { Model as RemovePointsModel } from '../../../components/Points/Remove/model';
import { UseHandleRemove } from './types';

const useHandleRemove: UseHandleRemove = ({
  dispatch,
  endUserId,
  onClose,
  points,
  reason,
}) => {
  const handleRemovePointsError = useCallback(() => {
    endUserId &&
      points &&
      reason &&
      dispatch(
        engagementPostRemovePointsAction({
          endUserId,
          points,
          reason,
          removalType: 0,
        }),
      );
  }, [dispatch, endUserId, points, reason]);

  const handleRemovePoints = useCallback(
    (values: RemovePointsModel) => {
      if (endUserId) {
        dispatch(
          engagementPostRemovePointsAction({
            endUserId,
            points: values.points,
            reason: values.reason,
            removalType: 0,
          }),
        ).then(() => onClose());
      }
    },
    [dispatch, endUserId, onClose],
  );

  return { handleRemovePoints, handleRemovePointsError };
};

export default useHandleRemove;
