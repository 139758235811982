import axios from 'axios';

import { isAuthTokenError } from 'modules/auth/providers/auth0';
import { ApiAbortCodes } from './constants';

export const isApiAbortError = (error: unknown): boolean =>
  axios.isAxiosError(error) &&
  !!error.response &&
  Object.values(ApiAbortCodes).includes(error.response.status);

export const isAxiosAbortError = (error: unknown): boolean =>
  axios.isCancel(error) ||
  (error instanceof Error &&
    (error.message === 'canceled' || error.name === 'AbortError'));

export const isUnauthorizedError = (error: unknown): boolean =>
  (axios.isAxiosError(error) &&
    error.response?.status === ApiAbortCodes.UNAUTHORIZED &&
    !!error.config.url?.includes(process.env.REACT_APP_API_ROOT as string)) ||
  isAuthTokenError(error);

export const isAbortError = (error: unknown): boolean =>
  isApiAbortError(error) || isAuthTokenError(error) || isAxiosAbortError(error);
