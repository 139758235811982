import { NotificationProps } from '../types';
import { NotificationTemplate } from '../constants';

import notificationPointsAdded from './notificationPointsAdded';
import notificationPointsRemoved from './notificationPointsRemoved';

export type NotificationTemplateProps = Omit<
  NotificationProps,
  'destroyNotification' | 'hideNotification' | 'id' | 'visible'
>;

export const getTemplate = (
  template?: NotificationTemplate,
): NotificationTemplateProps | undefined => {
  switch (template) {
    case NotificationTemplate.POINTS_ADDED:
      return notificationPointsAdded;
    case NotificationTemplate.POINTS_REMOVED:
      return notificationPointsRemoved;
    default:
      return undefined;
  }
};
