import { useCallback } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormSetValue,
} from 'react-hook-form';

export function useButtons<T extends FieldValues>({
  max,
  min,
  name,
  setValue,
  value,
}: {
  max: number;
  min: number;
  name: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  setValue?: UseFormSetValue<T>;
  value: string;
}) {
  const handleDecrement = useCallback(() => {
    if (setValue && parseInt(value) > min) {
      setValue(
        name as Path<T>,
        (parseInt(value) - 1) as UnpackNestedValue<PathValue<T, Path<T>>>,
        { shouldValidate: true },
      );
    }
  }, [min, name, setValue, value]);

  const handleIncrement = useCallback(() => {
    if (setValue && parseInt(value) < max) {
      setValue(
        name as Path<T>,
        (parseInt(value) + 1) as UnpackNestedValue<PathValue<T, Path<T>>>,
        { shouldValidate: true },
      );
    }
  }, [max, name, setValue, value]);

  return {
    handleDecrement,
    handleIncrement,
  };
}
