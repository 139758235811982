import { useMemo, useState } from 'react';

import { getUseModalReportsStep } from './utils';
import { ReportsPostActionApiRequest } from 'modules/redux/states/reports/types';
import useHandleConfirm from './useHandleConfirm';
import useHandleDateRange from './useHandleDateRange';
import useHandlePolicySearch from './useHandlePolicySearch';
import { useReportType } from 'modules/redux/states/reports/hooks';
import { UseModalReports } from './types';

const useModalReports: UseModalReports = report => {
  const [isError, setError] = useState<Error | false>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const reportType = useReportType(report);

  const { handlePolicySearch, policy } = useHandlePolicySearch({
    isLoading,
    reportType,
    setError,
    setLoading,
  });

  const { endDate, handleDateRange, startDate } = useHandleDateRange({
    setLoading,
  });

  const request: ReportsPostActionApiRequest = useMemo(
    () => ({
      endDate,
      reportName: reportType.key,
      startDate,
      subjectId: policy?.subjectId,
      tenantId: policy?.tenantId,
    }),
    [endDate, policy, reportType.key, startDate],
  );

  const { handleConfirm, handleError, link } = useHandleConfirm({
    isLoading,
    reportType,
    request,
    setError,
    setLoading,
  });

  const step = useMemo(
    () =>
      getUseModalReportsStep({
        endDate,
        isError,
        link,
        policy,
        reportType,
        startDate,
      }),
    [endDate, isError, link, policy, reportType, startDate],
  );

  return {
    handleConfirm,
    handleDateRange,
    handleError,
    handlePolicySearch,
    isError,
    isLoading,
    link,
    reportType,
    step,
  };
};

export default useModalReports;
