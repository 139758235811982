import { useCallback, useEffect, useRef, useState } from 'react';

import { PointType } from '../constants';

const usePointsToggle = () => {
  const [pointType, setPointType] = useState<PointType>(PointType.ADD);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback(
    (pointType: PointType) => setPointType(pointType),
    [setPointType],
  );

  const handleTransitionEnd = useCallback(
    (event: React.TransitionEvent<HTMLDivElement>) => {
      if (ref.current && event.propertyName === 'height') {
        if (ref.current.style.height === '0px') {
          ref.current.style.visibility = 'hidden';
        }
      }
    },
    [],
  );

  useEffect(() => {
    if (ref.current) {
      if (pointType === PointType.ADD) {
        ref.current.style.visibility = '';
        ref.current.style.height = `${ref.current.scrollHeight}px`;
        ref.current.style.marginBottom = '20px';
        ref.current.style.opacity = '1';
      } else {
        ref.current.style.height = '0px';
        ref.current.style.marginBottom = '0px';
        ref.current.style.opacity = '0';
      }
    }
  }, [pointType]);

  return {
    handleClick,
    handleTransitionEnd,
    pointType,
    ref,
  };
};

export default usePointsToggle;
