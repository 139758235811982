import React, { useMemo } from 'react';

import { UsePolicyHolderUsers } from 'modules/redux/states/engagement/hooks';

import styles from './styles.module.scss';

type ResultsCountProps = {
  data: UsePolicyHolderUsers['data'];
  isLoading: boolean;
  page?: number;
  pageSize?: number;
  searchString?: string;
};

const ResultsCount: React.FunctionComponent<ResultsCountProps> = ({
  data,
  isLoading,
  page,
  pageSize,
  searchString,
}) => {
  const visibleCount = useMemo(() => {
    if (data && pageSize && page) {
      const totalCount = data.data.count;
      const remainder = totalCount % pageSize;
      const isFullPage = page <= Math.floor(totalCount / pageSize);
      if (isFullPage) {
        return pageSize;
      } else {
        return remainder;
      }
    }
  }, [data, pageSize, page]);

  return searchString ? (
    <React.Fragment>
      {isLoading ? (
        <p aria-hidden="true" className={styles.skeleton}></p>
      ) : data && data.data.count ? (
        <p className={styles['results-count']}>
          Showing <strong>{visibleCount}</strong>{' '}
          {data.data.count === 1 ? 'result' : 'results'} for{' '}
          <strong>"{searchString}"</strong>
        </p>
      ) : null}
    </React.Fragment>
  ) : null;
};

export default React.memo(ResultsCount);
