import produce from 'immer';
import { Engagement, EngagementActionType } from '../../../types';
import { EngagementAction } from '../../../constants';

function messagesGetReducer(state: Engagement, action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.GET_MESSAGES_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.messages.actionId = null;
        draftState.messages.isError = action.error;
        draftState.messages.isLoading = false;

        draftState.messages.data = null;
      });

    case EngagementAction.GET_MESSAGES_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.messages.actionId = action.actionId;
        draftState.messages.isError = false;
        draftState.messages.isLoading = action.controller || true;

        draftState.messages.data = null;
        draftState.messages.page = action.request.page;
        draftState.messages.pageSize = action.request.pageSize;
      });

    case EngagementAction.GET_MESSAGES_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.messages.actionId = null;
        draftState.messages.isError = false;
        draftState.messages.isLoading = false;

        draftState.messages.data = action.data;
      });

    default:
      return state;
  }
}

export default messagesGetReducer;
