import { api } from 'modules/api';
import { CreateCallbackAction } from './types';
import { DefaultApiResponse } from 'modules/api/types';
import { Dispatch, RootState } from 'modules/redux';
import { isAxiosAbortError, isUnauthorizedError } from 'modules/api/errors';
import { timeout } from '../utils';
import { v4 as uuidv4 } from 'uuid';

export const createCallbackAction =
  <Request, Response>({
    actionStarted,
    actionSuccess,
    actionFailure,
    endpoint,
    isAuthorized,
    payload: {
      controller,
      isPresent,
      onCanceled,
      onFailure,
      onStarted,
      onSuccess,
      request,
    },
  }: CreateCallbackAction<Request, Response>) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const startTime = new Date().getTime();
    const actionId = uuidv4();
    let state = getState();
    try {
      if (!isAuthorized(state)) return;

      dispatch(actionStarted({ actionId, request }));
      !!onStarted && onStarted();

      const response: DefaultApiResponse = await api({
        controller,
        dispatch,
        getState,
        ...endpoint(request),
      });

      await timeout(startTime, 1000);

      state = getState();

      if (isAuthorized(state) && isPresent()) {
        dispatch(actionSuccess({ actionId, data: response.data, request }));
        onSuccess(response.data);
      }
    } catch (error) {
      await timeout(startTime, 1000);

      state = getState();

      if (!isUnauthorizedError(error) && isAuthorized(state) && isPresent()) {
        if (isAxiosAbortError(error)) {
          !!onCanceled && onCanceled();
        } else {
          dispatch(
            actionFailure({
              actionId,
              error:
                error instanceof Error ? error : new Error('Unexpected Error.'),
              request,
            }),
          );
          onFailure(
            error instanceof Error ? error : new Error('Unexpected Error.'),
          );
        }
      }
    }
  };
