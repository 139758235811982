import { useCallback } from 'react';

import { engagementPostAdditionalDetailsResetErrorAction } from 'modules/redux/states/engagement/additionalDetails/actions';
import { ModalConfirmTemplate } from 'modules/modals/constants';
import { ModalErrorText } from 'modules/modals/components/ModalErrorMessage';
import { UseHandleSendingError } from './types';

const useHandleSendingError: UseHandleSendingError = ({
  dataKeyName,
  dispatch,
  handleUpdateField,
  isSendingError,
  showModalConfirm,
  value,
}) =>
  useCallback(() => {
    if (showModalConfirm) {
      showModalConfirm({
        onCancel: () =>
          dispatch(engagementPostAdditionalDetailsResetErrorAction()),
        onConfirm: () => handleUpdateField(dataKeyName, value),
        renderContent: () => (
          <ModalErrorText error={isSendingError ? isSendingError : undefined} />
        ),
        template:
          ModalConfirmTemplate.POLICY_HOLDER_ADDITIONAL_DETAILS_SENDING_ERROR,
      });
    }
  }, [
    dataKeyName,
    dispatch,
    handleUpdateField,
    isSendingError,
    showModalConfirm,
    value,
  ]);

export default useHandleSendingError;
