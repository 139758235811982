import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getSpeedingEventId } from '../../../utils';
import { shallowEqual, useSelector } from 'modules/redux';
import { useConfigSelector } from 'modules/redux/states/config/hooks';
import { UseEventSelector } from './types';

const useEventSelector: UseEventSelector = showModalCustom => {
  const { eventId } = useParams() as { eventId: string };

  const { data, isError, isLoading, isSending } = useSelector(
    state => state.events,
    shallowEqual,
  );

  const {
    eventsApplication: {
      invalidation: { enabled },
    },
  } = useConfigSelector();

  const isEvent = useMemo(
    () => getSpeedingEventId(data) === eventId,
    [data, eventId],
  );

  return data && isEvent
    ? {
        approved: typeof data.approved === 'boolean' ? data.approved : null,
        data,
        enabled,
        eventId,
        isError,
        isLoading: !!isLoading,
        isSending: !showModalCustom || !!isSending,
      }
    : {
        approved: null,
        data: null,
        enabled,
        eventId,
        isError,
        isLoading: true,
        isSending: false,
      };
};

export default useEventSelector;
