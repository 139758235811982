import React from 'react';

import { useConfigSelector } from 'modules/redux/states/config/hooks';

import styles from './styles.module.scss';

const Legend: React.FunctionComponent = () => {
  const {
    tripsApplication: {
      scores: { types },
    },
  } = useConfigSelector();
  return (
    <ul className={styles.legend}>
      {types.map((i, index) => {
        return (
          <li key={`${i.label}-${index}`}>
            <span style={{ backgroundColor: `${i.colour}` }}></span>
            <span>{i.label}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default React.memo(Legend);
