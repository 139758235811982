import React from 'react';
import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import Button from 'components/Button';

type SuccessProps = {
  active: boolean;
  label?: string;
  onSuccess: () => void;
  text: string;
  title: string;
};

const Success: React.FunctionComponent<SuccessProps> = ({
  active,
  label = 'Continue',
  onSuccess,
  text,
  title,
}) => {
  return (
    <React.Fragment>
      <header>
        <h2>{title}</h2>
      </header>
      <div>
        <p>{text}</p>
      </div>
      <footer>
        <Button
          color={ButtonColor.BLUE}
          disabled={!active}
          label={label}
          onClick={onSuccess}
          style={ButtonStyle.PRIMARY}
        />
      </footer>
    </React.Fragment>
  );
};

export default React.memo(Success);
