import { produce } from 'immer';

import { Config, ConfigActionType } from './types';
import { ConfigAction } from './constants';
import { ConfigGetActionApiResponse } from './types';
import { localStorageGetObject } from 'modules/storage';

const initialState = (data?: unknown): Config => {
  return {
    actionId: null,
    data: data ? (data as ConfigGetActionApiResponse['data']) : null,
    isError: false,
    isLoading: false,
  };
};

function configReducer(
  state = initialState(localStorageGetObject('CONFIG')),
  action: ConfigActionType,
) {
  switch (action.type) {
    case ConfigAction.GET_FAILURE:
      return produce(state, (draftState: Config) => {
        draftState.actionId = null;
        draftState.isError = action.error;
        draftState.isLoading = false;

        if (draftState.data) {
          draftState.data.eventsApplication.invalidation.enabled = false;
          draftState.data.tripsApplication.invalidation.enabled = false;
        }
      });

    case ConfigAction.GET_STARTED:
      return produce(state, (draftState: Config) => {
        draftState.actionId = action.actionId;
        draftState.isError = false;
        draftState.isLoading = action.controller || true;
      });

    case ConfigAction.GET_SUCCESS:
      return produce(state, (draftState: Config) => {
        draftState.actionId = null;
        draftState.isError = false;
        draftState.isLoading = false;

        draftState.data = action.data;
      });

    case ConfigAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default configReducer;
