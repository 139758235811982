import React from 'react';

import { WidthPercentage } from 'components/Layout/constants';
import { UseEvent } from 'modules/redux/states/events/hooks';
import * as Layout from 'components/Layout';
import ApproveEvent from './components/ApproveEvent';
import DeclineEvent from './components/DeclineEvent';
import Map from './components/GoogleMap';
import PageTitle from 'components/PageTitle';
import RoadSpeedVsTravelSpeed from './components/RoadSpeedVsTravelSpeed';
import EventId from './components/EventId';

type TemplateProps = {
  approved?: boolean | null;
  chartIndex?: number;
  data?: UseEvent['data'];
  eventId?: UseEvent['eventId'];
  handleApproveEvent?: UseEvent['handleApproveEvent'];
  handleDeclineEvent?: UseEvent['handleDeclineEvent'];
  isLoading?: UseEvent['isLoading'];
  isSending?: UseEvent['isSending'];
  setChartIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  approved = null,
  chartIndex,
  data = null,
  eventId = null,
  handleApproveEvent,
  handleDeclineEvent,
  isLoading = true,
  isSending = false,
  setChartIndex,
}) => {
  return (
    <React.Fragment>
      <PageTitle enableBreadcrumbs={false} title="Events">
        <EventId eventId={eventId} isLoading={isLoading} />
      </PageTitle>
      <Layout.Container>
        <Layout.Column width={WidthPercentage.HUNDRED} scrollable={true}>
          <Map chartIndex={chartIndex} data={data} isLoading={isLoading} />
          <Layout.Row>
            <Layout.Column width={WidthPercentage.TWENTY_FIVE}>
              <DeclineEvent
                approved={approved}
                isLoading={isLoading}
                isSending={isSending}
                onDeclineEvent={handleDeclineEvent}
              />
            </Layout.Column>
            <Layout.Column width={WidthPercentage.FIFTY}>
              <RoadSpeedVsTravelSpeed
                chartIndex={chartIndex}
                eventId={eventId}
                isLoading={isLoading}
                setChartIndex={setChartIndex}
                speeds={data}
              />
            </Layout.Column>
            <Layout.Column width={WidthPercentage.TWENTY_FIVE}>
              <ApproveEvent
                approved={approved}
                isLoading={isLoading}
                isSending={isSending}
                onApproveEvent={handleApproveEvent}
              />
            </Layout.Column>
          </Layout.Row>
        </Layout.Column>
      </Layout.Container>
    </React.Fragment>
  );
};

export default React.memo(Template);
