import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import {
  eventsPutAction,
  EventsPutActionApiRequest,
} from 'modules/redux/states/events/actions';
import { ROUTE } from 'modules/router/constants';
import { useConfigSelector } from 'modules/redux/states/config/hooks';

import Confirm from '../../components/Confirm';
import Failure from '../../components/Failure';
import Form, { Model } from './Form';
import Success from '../../components/Success';

type TemplateProps = {
  active: boolean;
  onClose: () => void;
  payload?: Record<string, unknown>;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  active,
  onClose,
  payload,
}) => {
  const [reason, setReason] = useState<string>();
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isSending, isSendingError } = useSelector(
    state => state.events,
    shallowEqual,
  );
  const {
    eventsApplication: {
      invalidation: { reasons },
    },
  } = useConfigSelector();

  const handleConfirm = useCallback(
    (reason?: string) =>
      !!payload &&
      dispatch(
        eventsPutAction({
          ...payload,
          reason,
        } as EventsPutActionApiRequest),
      ),
    [dispatch, payload],
  );

  const handleError = useCallback(
    () => handleConfirm(reason),
    [handleConfirm, reason],
  );

  const handleSubmit = useCallback(
    (values: Model) => {
      setReason(values.reasonForDeclining);
      handleConfirm(values.reasonForDeclining);
    },
    [handleConfirm],
  );

  const handleSuccess = useCallback(
    () => navigate(ROUTE.EVENTS, { replace: true }),
    [navigate],
  );

  return isSendingError ? (
    <Failure
      active={active}
      isError={isSendingError}
      onCancel={onClose}
      onError={handleError}
    />
  ) : typeof data?.approved !== 'boolean' ? (
    Array.isArray(reasons) && reasons.length > 0 ? (
      <Form
        active={active}
        isLoading={!!isSending}
        onCancel={onClose}
        onSubmit={handleSubmit}
        reasons={reasons}
      />
    ) : (
      <Confirm
        active={active}
        isLoading={!!isSending}
        onCancel={onClose}
        onConfirm={handleConfirm}
        text="Click below to decline event."
        title="Are you sure?"
      />
    )
  ) : (
    <Success
      active={active}
      label="Next Event"
      onSuccess={handleSuccess}
      text="The event was declined, click below to receive another event."
      title="Event declined"
    />
  );
};
export default React.memo(Template);
