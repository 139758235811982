import React from 'react';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div
        aria-hidden={true}
        className={styles['drawer-template-additional-details-skeleton']}>
        <header>
          <div>Name</div>
          <div>Value</div>
        </header>
        <div>
          <ul>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span>
                <span></span>
              </span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span>
                <span></span>
              </span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span>
                <span></span>
              </span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span>
                <span></span>
              </span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span>
                <span></span>
              </span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
            <li>
              <div></div>
              <div></div>
              <span></span>
            </li>
          </ul>
        </div>
        <footer>
          <div>
            <strong></strong>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
        </footer>
      </div>
      <span data-nosnippet className="visually-hidden">
        Loading additional details
      </span>
    </React.Fragment>
  );
};

export default React.memo(Skeleton);
