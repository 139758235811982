import yup from 'modules/validation';

export type Model = {
  policyId: string;
};

export const defaultValues: Model = {
  policyId: '',
};

export const schema = yup.object().shape({
  policyId: yup
    .string()
    .required('Please enter a policy number or Strata reference'),
});
