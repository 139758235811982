import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import {
  ModalErrorText,
  ModalErrorTitle,
} from 'modules/modals/components/ModalErrorMessage';
import Button from 'components/Button';

type FailureProps = {
  active: boolean;
  isError: Error | false;
  onCancel: () => void;
  onError?: () => void;
};

const Failure: React.FunctionComponent<FailureProps> = ({
  active,
  isError,
  onCancel,
  onError,
}) => {
  return (
    <React.Fragment>
      <header>
        <ModalErrorTitle />
      </header>
      <div>
        <ModalErrorText error={isError ? isError : undefined} />
      </div>
      <footer>
        <Button
          color={ButtonColor.BLUE}
          disabled={!active}
          label="Cancel"
          onClick={onCancel}
          style={ButtonStyle.SECONDARY}
        />
        {onError && (
          <Button
            color={ButtonColor.BLUE}
            disabled={!active}
            label="Retry"
            onClick={onError}
            style={ButtonStyle.PRIMARY}
          />
        )}
      </footer>
    </React.Fragment>
  );
};

export default React.memo(Failure);
