import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  StoreEnhancer,
} from 'redux';
import thunk from 'redux-thunk';
import { createReduxHistoryContext } from 'redux-first-history';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createSelectorHook } from 'react-redux';
import { createBrowserHistory } from 'history';
import { isChrome } from 'react-device-detect';
import { unstable_batchedUpdates } from 'react-dom';

import { isAdmin } from 'modules/admin';
import middleware from './middleware';
import reducers from './reducers';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    batch: unstable_batchedUpdates,
    history: createBrowserHistory(),
    reduxTravelling: isAdmin && isChrome,
    savePreviousLocations: 1,
  });

const rootReducer = combineReducers({
  router: routerReducer,
  ...reducers,
});

const composeEnhancers =
  isAdmin && isChrome ? composeWithDevTools({}) : compose;
const storeEnhancers: StoreEnhancer[] = [];

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk, routerMiddleware, ...middleware()),
    ...storeEnhancers,
  ),
);

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector = createSelectorHook<RootState>();

export default store;
