import { useCallback, useMemo, useState } from 'react';

import { getUseModalConfirmStep } from './utils';
import { ModalConfirmStep } from './constants';
import { Data, UseHandleConfirm } from './types';
import useHandleGetStatus from './useHandleGetStatus';
import useHandleRequest from './useHandleRequest';

const useHandleConfirm: UseHandleConfirm = ({
  isLoading,
  reportType,
  request,
  setError,
  setLoading,
}) => {
  const [link, setLink] = useState<string | null>(null);
  const [data, setData] = useState<Data>(null);
  const reportId = data?.reportId;
  const status = data?.status;

  const step = useMemo(
    () => getUseModalConfirmStep({ link, status }),
    [link, status],
  );

  const handleRequest = useHandleRequest({
    reportType,
    request,
    setData,
    setError,
    setLoading,
  });

  const handleGetStatus = useHandleGetStatus({
    data,
    setData,
    setError,
    setLink,
    setLoading,
  });

  const handleConfirm = useCallback(() => {
    if (!isLoading) {
      setLoading(true);
      switch (step) {
        case ModalConfirmStep.STATUS:
          !!reportId && handleGetStatus({ reportId });
          break;
        default:
          handleRequest(request);
      }
    }
  }, [
    handleGetStatus,
    handleRequest,
    isLoading,
    reportId,
    request,
    setLoading,
    step,
  ]);

  const handleError = useCallback(() => {
    setError(false);
    handleConfirm();
  }, [handleConfirm, setError]);

  return {
    handleConfirm,
    handleError,
    link,
  };
};

export default useHandleConfirm;
