import React, { useMemo } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { FieldError, FieldValues, Path, useController } from 'react-hook-form';

import { isAdmin } from 'modules/admin';
import {
  InputAutocomplete,
  InputType,
} from 'components/Inputs/Input/constants';
import { InputPrimitive } from 'components/Inputs/Input';
import { QuestionPolicySearchProps } from './types';
import { QuestionSize } from '../../constants';
import { ReactComponent as ErrorSVG } from 'modules/theme/images/icons/inputs/error.svg';
import { ReactComponent as SearchSVG } from 'modules/theme/images/icons/inputs/search.svg';
import { StatusCodes } from 'http-status-codes';

import questionStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const getErrorMessage = ({
  error,
  isError,
}: {
  error: FieldError | undefined;
  isError: false | Error;
}) =>
  error?.message
    ? error.message
    : isError
    ? axios.isAxiosError(isError) &&
      isError.response?.status === StatusCodes.NOT_FOUND
      ? 'We could not find a record for your entry. Please enter a valid policy ID or Strata reference.'
      : isAdmin && isError instanceof Error
      ? `Error: ${isError.message}`
      : 'Something went wrong.'
    : undefined;

function QuestionPolicySearch<T extends FieldValues>({
  disabled,
  children,
  control,
  id,
  isError,
  label,
  name,
  readOnly,
  ...props
}: QuestionPolicySearchProps<T>) {
  const {
    field: { onChange, value, onBlur, ref },
    fieldState: { error, invalid },
  } = useController({
    control,
    name: name as Path<T>,
  });

  const errorMessage = useMemo(
    () => getErrorMessage({ error, isError }),
    [error, isError],
  );

  const className = clsx(
    styles['question-policy-search'],
    questionStyles.question,
    questionStyles.medium,
    disabled && questionStyles.disabled,
    readOnly && questionStyles['read-only'],
    !disabled && (invalid || !!isError) && questionStyles.invalid,
  );

  return (
    <div className={className}>
      {!!label && <label htmlFor={id ? id : name}>{label}</label>}
      <InputPrimitive
        autoComplete={InputAutocomplete.OFF}
        disabled={disabled}
        id={id}
        inputRef={ref}
        invalid={invalid || !!isError}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        size={QuestionSize.MEDIUM}
        type={InputType.TEXT}
        value={value as string}
        {...props}>
        <SearchSVG />
        {children}
      </InputPrimitive>
      {(invalid || !!isError) && !!errorMessage && (
        <p aria-live="polite">
          <ErrorSVG />
          <span>{errorMessage}</span>
        </p>
      )}
    </div>
  );
}

export default React.memo(QuestionPolicySearch) as typeof QuestionPolicySearch;
