import { API } from 'modules/api';
import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from '../../../constants';
import {
  EngagementPostAddPointsAction,
  EngagementPostAddPointsStartedAction,
  EngagementPostAddPointsSuccessAction,
  EngagementPostAddPointsFailureAction,
} from './types';

export const engagementPostAddPointsAction: EngagementPostAddPointsAction =
  request =>
    createDefaultAction({
      actionStarted: engagementPostAddPointsStartedAction,
      actionSuccess: engagementPostAddPointsSuccessAction,
      actionFailure: engagementPostAddPointsFailureAction,
      endpoint: API.POST_ENGAGEMENT_ADD_POINTS,
      isActive: state => !!state.engagement.points.isSending,
      isAuthorized: state => state.auth.isAuthenticated,
      isPresent: (actionId, state) =>
        actionId === state.engagement.points.actionId,
      request: request,
    });

const engagementPostAddPointsStartedAction: EngagementPostAddPointsStartedAction =
  payload => ({
    type: EngagementAction.POST_ADD_POINTS_STARTED,
    ...payload,
  });

const engagementPostAddPointsSuccessAction: EngagementPostAddPointsSuccessAction =
  payload => ({
    type: EngagementAction.POST_ADD_POINTS_SUCCESS,
    ...payload,
  });

const engagementPostAddPointsFailureAction: EngagementPostAddPointsFailureAction =
  payload => ({
    type: EngagementAction.POST_ADD_POINTS_FAILURE,
    ...payload,
  });
