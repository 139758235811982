import { AuthAction } from '../constants';
import {
  AuthConfigStartedAction,
  AuthConfigSuccessAction,
  AuthConfigFailureAction,
  AuthLoginSuccessAction,
} from '../types';
import { createAuth0Client } from '../providers/auth0';
import { Dispatch } from 'modules/redux';

export const authConfigAction = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(authConfigStartedAction());
      const result = await createAuth0Client();
      dispatch(
        result.isCallback && result.isAuthenticated
          ? authLoginSuccessAction({ isAuthenticated: result.isAuthenticated })
          : authConfigSuccessAction({
              isAuthenticated: result.isAuthenticated,
            }),
      );
    } catch (error) {
      dispatch(
        authConfigFailureAction({
          error:
            error instanceof Error ? error : new Error('Unexpected Error.'),
        }),
      );
    }
  };
};

const authConfigStartedAction: AuthConfigStartedAction = () => ({
  type: AuthAction.CONFIG_STARTED,
});

const authConfigSuccessAction: AuthConfigSuccessAction = payload => ({
  type: AuthAction.CONFIG_SUCCESS,
  ...payload,
});

const authConfigFailureAction: AuthConfigFailureAction = payload => ({
  type: AuthAction.CONFIG_FAILURE,
  ...payload,
});

const authLoginSuccessAction: AuthLoginSuccessAction = payload => ({
  type: AuthAction.LOGIN_SUCCESS,
  ...payload,
});
