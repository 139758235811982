import { Policy, PolicyActionType } from '../types';
import { PolicyAction } from '../constants';
import tripGetReducer from './actions/policyGetTripAction/reducer';
import tripPutReducer from './actions/policyPutTripAction/reducer';

function tripReducer(state: Policy, action: PolicyActionType) {
  switch (action.type) {
    case PolicyAction.GET_TRIP_FAILURE:
    case PolicyAction.GET_TRIP_STARTED:
    case PolicyAction.GET_TRIP_SUCCESS:
      return tripGetReducer(state, action);

    case PolicyAction.PUT_TRIP_FAILURE:
    case PolicyAction.PUT_TRIP_STARTED:
    case PolicyAction.PUT_TRIP_SUCCESS:
      return tripPutReducer(state, action);

    default:
      return state;
  }
}

export default tripReducer;
