import { useCallback } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { ModalConfirmProps, ModalCustomProps, ModalInfoProps } from '../types';
import { modalHideAction, modalShowAction } from '../actions';
import { ModalType } from '../constants';
import store from 'modules/redux';

export type Modal =
  | ({ type: ModalType.CUSTOM } & Omit<
      ModalCustomProps,
      'destroyModal' | 'hideModal' | 'id' | 'open'
    >)
  | ({ type: ModalType.CONFIRM } & Omit<
      ModalConfirmProps,
      'destroyModal' | 'hideModal' | 'id' | 'open'
    >)
  | ({ type: ModalType.INFO } & Omit<
      ModalInfoProps,
      'destroyModal' | 'hideModal' | 'id' | 'open'
    >);

const useModalAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    modalHide: useCallback(
      (id: string) => {
        store.getState().modals.find(modal => modal.id === id && modal.open) &&
          dispatch(modalHideAction(id));
      },
      [dispatch],
    ),
    modalShow: useCallback(
      (modal: Modal) => {
        return dispatch(modalShowAction(modal));
      },
      [dispatch],
    ),
  };
};

export default useModalAction;
