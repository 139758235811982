import produce from 'immer';
import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from '../constants';

function rewardsReducer(state: Engagement, action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.GET_REWARDS_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.rewards.actionId = null;
        draftState.rewards.isError = action.error;
        draftState.rewards.isLoading = false;

        draftState.rewards.data = null;
      });

    case EngagementAction.GET_REWARDS_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.rewards.actionId = action.actionId;
        draftState.rewards.isError = false;
        draftState.rewards.isLoading = action.controller || true;

        draftState.rewards.data = null;
        draftState.rewards.page = action.request.page;
        draftState.rewards.pageSize = action.request.pageSize;
      });

    case EngagementAction.GET_REWARDS_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.rewards.actionId = null;
        draftState.rewards.isError = false;
        draftState.rewards.isLoading = false;

        draftState.rewards.data = action.data;
      });

    default:
      return state;
  }
}

export default rewardsReducer;
