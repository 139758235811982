import React from 'react';

import { usePolicySummary } from 'modules/redux/states/policy/hooks';
import Template from './Template';

const Route: React.FunctionComponent = () => {
  const { details, scores, trip, trips } = usePolicySummary();
  return (
    <Template details={details} scores={scores} trip={trip} trips={trips} />
  );
};

export default Route;
