import { useCallback, useEffect, useRef, useState } from 'react';

export const useRelativeScrollbar = () => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const scrollableRef = useRef<HTMLUListElement | null>(null);

  const updateScrollbar = useCallback(() => {
    if (rootRef.current && scrollableRef.current) {
      if (rootRef.current.clientWidth > scrollableRef.current.clientWidth) {
        return setRelativeScrollbar(true);
      } else {
        return setRelativeScrollbar(false);
      }
    }
  }, []);

  const [isRelativeScrollbar, setRelativeScrollbar] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver(() => updateScrollbar());
    rootRef.current &&
      observer.observe(rootRef.current, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, [updateScrollbar]);

  useEffect(() => {
    updateScrollbar();
    window.addEventListener('resize', updateScrollbar);
    window.addEventListener('orientationchange', updateScrollbar);
    return () => {
      window.removeEventListener('resize', updateScrollbar);
      window.removeEventListener('orientationchange', updateScrollbar);
    };
  }, [updateScrollbar]);

  return {
    isRelativeScrollbar,
    rootRef,
    scrollableRef,
  };
};
