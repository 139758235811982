import React, { useMemo } from 'react';

import { defaultValues, Model as AddPointsModel, schema } from './model';
import { QuestionNumber, QuestionTextArea } from 'components/Questions';
import { PointType } from '../../../modalAddRemovePoints/Template/constants';
import Form from 'components/Form';

type AddProps = {
  active: boolean;
  handleAddPoints: (values: AddPointsModel) => void;
  isSending: boolean;
  pointType: PointType;
};

const Add: React.FunctionComponent<AddProps> = ({
  active,
  handleAddPoints,
  isSending,
  pointType,
}) => {
  const defaultValue = useMemo(() => {
    return {
      points: defaultValues.points,
      reason: defaultValues.reason,
    };
  }, []);

  return (
    <Form<AddPointsModel>
      defaultValues={defaultValue}
      id="points-form"
      onSubmit={handleAddPoints}
      schema={schema}>
      {({ control, setValue }) => (
        <React.Fragment>
          <QuestionNumber
            control={control}
            disabled={!active || isSending}
            label="Enter number of points (Maximum of 5,000 points)"
            max={5000}
            min={0}
            name="points"
            readOnly={!active || isSending}
            setValue={setValue}
          />
          <QuestionTextArea
            control={control}
            disabled={!active || isSending}
            label="Reason for points adjustment"
            name="reason"
            placeholder={`Enter a reason for ${pointType} points`}
            readOnly={!active || isSending}
          />
        </React.Fragment>
      )}
    </Form>
  );
};

export default React.memo(Add);
