import { Middleware } from 'redux';

import { AuthAction } from 'modules/auth/constants';
import { AuthActionType } from 'modules/auth/types';
import { Dispatch, RootState } from 'modules/redux';
import { notificationHideAction } from './actions';
import { RouterActionType } from 'modules/router/types';

const createNotificationsMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case AuthAction.LOGOUT: {
        const { notifications }: RootState = getState();
        notifications.forEach(
          notification =>
            notification.visible &&
            dispatch(notificationHideAction(notification.id)),
        );
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default createNotificationsMiddleware;
