import { useCallback, useState } from 'react';

import { Model as DateRangeModel } from '../../../components/DateRange';
import { getEndDateString, getStartDateString } from 'modules/utils';
import { UseHandleDateRange } from './types';

const useHandleDateRange: UseHandleDateRange = ({ setLoading }) => {
  const [{ endDate, startDate }, setDate] = useState<{
    endDate: string | undefined;
    startDate: string | undefined;
  }>({ startDate: undefined, endDate: undefined });

  const handleDateRange = useCallback(
    (values: DateRangeModel) => {
      setLoading(true);
      setDate({
        endDate: getEndDateString(values.endDate),
        startDate: getStartDateString(values.startDate),
      });
    },
    [setLoading],
  );

  return {
    endDate,
    handleDateRange,
    startDate,
  };
};

export default useHandleDateRange;
