import { useCallback } from 'react';

import { engagementPostAddPointsAction } from 'modules/redux/states/engagement/points/actions/engagementPostAddPointsAction';
import { Model as AddPointsModel } from '../../../components/Points/Add/model';
import { UseHandleAdd } from './types';

const useHandleAdd: UseHandleAdd = ({
  dispatch,
  endUserId,
  onClose,
  points,
  reason,
}) => {
  const handleAddPointsError = useCallback(() => {
    endUserId &&
      points &&
      reason &&
      dispatch(engagementPostAddPointsAction({ endUserId, points, reason }));
  }, [dispatch, endUserId, points, reason]);

  const handleAddPoints = useCallback(
    (values: AddPointsModel) => {
      if (endUserId) {
        dispatch(
          engagementPostAddPointsAction({
            endUserId,
            points: values.points,
            reason: values.reason,
          }),
        ).then(() => onClose());
      }
    },
    [dispatch, endUserId, onClose],
  );

  return { handleAddPoints, handleAddPointsError };
};

export default useHandleAdd;
