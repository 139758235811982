import React, { useCallback } from 'react';
import clsx from 'clsx';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { ModalAnimation } from '../../constants';
import { ModalInfoProps } from '../../types';
import Button from 'components/Button';
import ModalOverlay from '../ModalOverlay';

import templateStyles from '../Modals/styles.module.scss';

type ModalProps = Omit<
  ModalInfoProps,
  | 'animation'
  | 'destroyModal'
  | 'disableEscapeKey'
  | 'hideModal'
  | 'id'
  | 'open'
> & { active?: boolean };

const Modal = React.memo<ModalProps>(
  ({ active, ariaLabel, closeLabel, onClose, renderContent, renderHeader }) => {
    const classNames = clsx(templateStyles['modal-template']);
    return (
      <div aria-label={ariaLabel} className={classNames} role="dialog">
        {!!renderHeader && <header>{renderHeader()}</header>}
        {!!renderContent && <div>{renderContent()}</div>}
        <footer>
          <Button
            disabled={!active}
            color={ButtonColor.BLUE}
            label={closeLabel}
            onClick={onClose}
            style={ButtonStyle.PRIMARY}
          />
        </footer>
      </div>
    );
  },
);

const ModalInfo: React.FunctionComponent<ModalInfoProps> = ({
  animation = ModalAnimation.ZOOM_IN,
  ariaLabel,
  closeLabel = 'Close',
  destroyModal,
  disableEscapeKey = false,
  hideModal,
  id,
  onClose,
  open,
  renderContent,
  renderHeader,
}) => {
  const handleClose = useCallback(() => {
    onClose ? open && onClose() : open && hideModal();
  }, [hideModal, onClose, open]);
  return (
    <ModalOverlay
      animation={animation}
      destroyModal={destroyModal}
      disableEscapeKey={disableEscapeKey}
      id={id}
      onClose={handleClose}
      open={open}>
      <Modal
        ariaLabel={ariaLabel}
        closeLabel={closeLabel}
        onClose={handleClose}
        renderContent={renderContent}
        renderHeader={renderHeader}
      />
    </ModalOverlay>
  );
};

export default React.memo(ModalInfo);
