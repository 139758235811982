import React from 'react';
import clsx from 'clsx';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { isAdmin } from 'modules/admin';
import { ReactComponent as ErrorSVG } from 'modules/theme/images/icons/inputs/error.svg';
import { UsePolicyScores } from 'modules/redux/states/policy/hooks/usePolicySummary';
import Button from 'components/Button';
import Card from 'components/Card';

import styles from './styles.module.scss';

type ScoreOverTimeErrorProps = {
  handleError: UsePolicyScores['handleError'];
  isError: UsePolicyScores['isError'];
};

const ErrorMessage: React.FunctionComponent<ScoreOverTimeErrorProps> = ({
  handleError,
  isError,
}) => {
  const classNames = clsx(styles['score-over-time-error']);
  return (
    <Card className={classNames} title="Score over time">
      <ErrorSVG />
      <h2>{isAdmin ? 'Error' : 'Something went wrong'}</h2>
      {isAdmin && !!isError && isError.message && <p>{isError.message}</p>}
      <Button
        color={ButtonColor.BLUE}
        label="Retry"
        onClick={handleError}
        style={ButtonStyle.PRIMARY}
      />
    </Card>
  );
};

export default React.memo(ErrorMessage);
