import React, { useCallback, useContext, useState } from 'react';
import clsx from 'clsx';

import { InputDateProps } from '../types';
import PlatformContext from 'modules/platform';

import styles from '../styles.module.scss';

const showPicker = (
  event:
    | React.MouseEvent<HTMLInputElement>
    | React.KeyboardEvent<HTMLInputElement>
    | React.FocusEvent<HTMLInputElement>,
  isMobile: boolean,
) => {
  try {
    !isMobile &&
      (
        event.currentTarget as HTMLInputElement & { showPicker: () => void }
      ).showPicker();
  } catch {
    return;
  }
};

const InputDate: React.FunctionComponent<InputDateProps> = ({
  autoFocus,
  children,
  disabled,
  id,
  inputRef,
  invalid,
  name,
  onBlur,
  onFocus,
  readOnly,
  size,
  tabIndex,
  value,
  ...props
}) => {
  const { isMobile } = useContext(PlatformContext);
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      !disabled && !!onBlur && onBlur(event);
      setIsFocused(false);
    },
    [disabled, onBlur],
  );
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) =>
      !readOnly && !disabled && showPicker(event, isMobile),
    [disabled, isMobile, readOnly],
  );
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        (readOnly || disabled) &&
        (event.key === 'Enter' || event.key === 'Space' || event.key === ' ')
      ) {
        event?.preventDefault();
      }
    },
    [disabled, readOnly],
  );
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      disabled && event.currentTarget.blur();
      !disabled && !!onFocus && onFocus(event);
      setIsFocused(true);
    },
    [disabled, onFocus],
  );
  const getTabIndex = useCallback(() => {
    if (disabled) return -1;
    return tabIndex ? tabIndex : 0;
  }, [disabled, tabIndex]);

  const className = clsx(
    styles.input,
    styles[`${size}`],
    styles.date,
    disabled && styles.disabled,
    readOnly && styles['read-only'],
    !value && styles['no-value'],
    isFocused && styles['focused'],
    !disabled && invalid && styles.invalid,
  );

  return (
    <div className={className}>
      <input
        autoFocus={!disabled && autoFocus}
        id={id || name}
        name={name}
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        placeholder="DD/MM/YYYY"
        readOnly={readOnly}
        ref={inputRef}
        tabIndex={getTabIndex()}
        type="date"
        value={value || ''}
        {...props}
      />
      {children}
    </div>
  );
};

export default React.memo(InputDate);
