import React from 'react';
import clsx from 'clsx';

import { FontSize } from 'components/Card/constants';
import Card from 'components/Card';

import cardStyles from 'components/Card/styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(
    styles.skeleton,
    cardStyles.card,
    cardStyles['padding-small'],
  );
  return (
    <React.Fragment>
      <Card
        className={classNames}
        fontSize={FontSize.SMALL}
        title="Events summary">
        <span data-nosnippet className="visually-hidden">
          Loading events summary
        </span>
        <div aria-hidden="true">
          <ul>
            <li>
              <span>
                <span></span>
              </span>
              <div>
                <p></p>
                <span></span>
              </div>
            </li>
            <li>
              <span>
                <span></span>
              </span>
              <div>
                <p></p>
                <span></span>
              </div>
            </li>
            <li>
              <span>
                <span></span>
              </span>
              <div>
                <p></p>
                <span></span>
              </div>
            </li>
            <li>
              <span>
                <span></span>
              </span>
              <div>
                <p></p>
                <span></span>
              </div>
            </li>
            <li>
              <span>
                <span></span>
              </span>
              <div>
                <p></p>
                <span></span>
              </div>
            </li>
          </ul>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(Skeleton);
