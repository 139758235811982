import React from 'react';

import { ReactComponent as SpinnerSVG } from 'modules/theme/images/icons/spinners/circular-bars.svg';
import { useSecondaryNav } from 'components/Header/hooks';
import Drawers from 'modules/drawers/components/Drawers';
import Header from 'components/Header';
import Modals from 'modules/modals/components/Modals';
import Notifications from 'modules/notifications/components/Notifications';

import styles from './styles.module.scss';

type TemplateProps = {
  children: React.ReactNode;
  isAuthenticated: boolean;
  isLoading: boolean;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  children,
  isAuthenticated,
  isLoading,
}) => {
  const { handleClick, handleClose, mainRef, open, secondaryNavRef } =
    useSecondaryNav();
  return (
    <React.Fragment>
      <Header
        handleClick={handleClick}
        handleClose={handleClose}
        isAuthenticated={isAuthenticated}
        isLoading={isLoading}
        open={open}
        secondaryNavRef={secondaryNavRef}
      />
      <main className={styles.main} ref={mainRef}>
        {!isLoading ? (
          children
        ) : (
          <SpinnerSVG className={styles['config-loading']} />
        )}
      </main>
      <Drawers />
      <Modals />
      <Notifications />
    </React.Fragment>
  );
};

export default React.memo(Template);
