import { useCallback } from 'react';

import { parseValueOnChange, parseValueOnKeyDown } from '../utils';

export function useHandlers({
  disabled,
  max,
  onBlur,
  onChange,
  onFocus,
  tabIndex,
  value,
}: {
  disabled?: boolean;
  max: number;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  tabIndex?: number;
  value: string;
}) {
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      !disabled && !!onBlur && onBlur(event);
    },
    [disabled, onBlur],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      parseValueOnChange(event, max, onChange);
    },
    [max, onChange],
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      disabled && event.currentTarget.blur();
      !disabled && !!onFocus && onFocus(event);
    },
    [disabled, onFocus],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      parseValueOnKeyDown(event, max, value);
    },
    [max, value],
  );

  const getTabIndex = useCallback(() => {
    if (disabled) return -1;
    return tabIndex ? tabIndex : 0;
  }, [disabled, tabIndex]);

  return {
    getTabIndex,
    handleBlur,
    handleChange,
    handleFocus,
    handleKeyDown,
  };
}
