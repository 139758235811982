import React from 'react';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { modalDestroyAction, modalHideAction } from '../../actions';
import {
  ModalConfirmProps,
  ModalCustomProps,
  ModalInfoProps,
} from '../../types';
import { ModalType } from '../../constants';
import { RenderModalProps } from './types';
import ModalCustom from '../ModalCustom';
import ModalConfirm from '../ModalConfirm';
import ModalInfo from '../ModalInfo';

import styles from './styles.module.scss';

const renderModal = ({ type, ...props }: RenderModalProps) => {
  switch (type) {
    case ModalType.CUSTOM:
      return <ModalCustom key={props.id} {...(props as ModalCustomProps)} />;
    case ModalType.CONFIRM:
      return <ModalConfirm key={props.id} {...(props as ModalConfirmProps)} />;
    case ModalType.INFO:
      return <ModalInfo key={props.id} {...(props as ModalInfoProps)} />;
    default:
      return null;
  }
};

const Modals: React.FunctionComponent = () => {
  const modals = useSelector(state => state.modals, shallowEqual);
  const dispatch: Dispatch = useDispatch();
  return modals.length > 0 ? (
    <div className={styles['modals']}>
      {modals.map(modal =>
        renderModal({
          destroyModal: () => dispatch(modalDestroyAction(modal.id)),
          hideModal: () => dispatch(modalHideAction(modal.id)),
          ...modal,
        }),
      )}
    </div>
  ) : null;
};

export default Modals;
