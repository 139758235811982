import React from 'react';

import { useEvent } from 'modules/redux/states/events/hooks';
import Session from 'modules/session';
import Template from '../events/Template';
import useChartHover from '../events/hook';

const Route: React.FunctionComponent = () => {
  const { chartIndex, setChartIndex } = useChartHover();
  const {
    approved,
    data,
    eventId,
    handleApproveEvent,
    handleDeclineEvent,
    handleSessionTimeout,
    isLoading,
    isSending,
  } = useEvent();
  return (
    <Session
      timeout={process.env.REACT_APP_EVENT_SESSION}
      handleSessionTimeout={handleSessionTimeout}>
      <Template
        approved={approved}
        chartIndex={chartIndex}
        data={data}
        eventId={eventId}
        handleApproveEvent={handleApproveEvent}
        handleDeclineEvent={handleDeclineEvent}
        isLoading={isLoading}
        isSending={isSending}
        setChartIndex={setChartIndex}
      />
    </Session>
  );
};

export default Route;
