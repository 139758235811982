import { EngagementAction } from '../constants';
import { Engagement, EngagementActionType } from '../types';
import messagesEmailTemplateGetReducer from './actions/engagementGetMessagesEmailTemplateAction/reducer';
import messagesGetReducer from './actions/engagementGetMessagesAction/reducer';

function messagesReducer(state: Engagement, action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_FAILURE:
    case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_STARTED:
    case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_SUCCESS:
      return messagesEmailTemplateGetReducer(state, action);

    case EngagementAction.GET_MESSAGES_FAILURE:
    case EngagementAction.GET_MESSAGES_STARTED:
    case EngagementAction.GET_MESSAGES_SUCCESS:
      return messagesGetReducer(state, action);

    default:
      return state;
  }
}

export default messagesReducer;
