export enum ButtonColor {
  RED = 'red',
  BLUE = 'blue',
}

export enum ButtonStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}
