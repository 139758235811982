import { Engagement, EngagementActionType } from '../types';
import { EngagementAction } from '../constants';
import additionalDetailsGetReducer from './actions/engagementGetAdditionalDetailsAction/reducer';
import additionalDetailsPostReducer from './actions/engagementPostAdditionalDetailsAction/reducer';

function additionalDetailsReducer(
  state: Engagement,
  action: EngagementActionType,
) {
  switch (action.type) {
    case EngagementAction.GET_ADDITIONAL_DETAILS_FAILURE:
    case EngagementAction.GET_ADDITIONAL_DETAILS_STARTED:
    case EngagementAction.GET_ADDITIONAL_DETAILS_SUCCESS:
      return additionalDetailsGetReducer(state, action);

    case EngagementAction.POST_ADDITIONAL_DETAILS_FAILURE:
    case EngagementAction.POST_ADDITIONAL_DETAILS_STARTED:
    case EngagementAction.POST_ADDITIONAL_DETAILS_SUCCESS:
    case EngagementAction.POST_ADDITIONAL_DETAILS_RESET_ERROR:
      return additionalDetailsPostReducer(state, action);

    default:
      return state;
  }
}

export default additionalDetailsReducer;
