import React from 'react';

import { isAdmin } from 'modules/admin';
import axios, { AxiosError } from 'axios';

import { ModalError } from 'modules/modals/constants';
import * as Hyperlink from 'components/Hyperlink';

type ModalErrorTextProps = { error?: Error };

export const ModalErrorText: React.FunctionComponent<ModalErrorTextProps> = ({
  error,
}) => {
  switch (true) {
    case isAdmin:
      return error instanceof Error ? (
        <React.Fragment>
          <p>{error.message}</p>
          {axios.isAxiosError(error) && !!error.response?.data?.message && (
            <p>{error.response?.data?.message}</p>
          )}
        </React.Fragment>
      ) : (
        <p>{ModalError.TEXT}</p>
      );

    case axios.isAxiosError(error) && !!error.response?.data?.message:
      return <p>{(error as AxiosError)?.response?.data.message}</p>;

    default:
      return (
        <p>
          {process.env.REACT_APP_ADMIN_EMAIL ? (
            <React.Fragment>
              {ModalError.TEXT_WITH_ADMIN_EMAIL}
              <Hyperlink.ExternalLink
                href={`mailto:${process.env.REACT_APP_ADMIN_EMAIL}`}>
                {process.env.REACT_APP_ADMIN_EMAIL}
              </Hyperlink.ExternalLink>
              .
            </React.Fragment>
          ) : (
            <React.Fragment>{ModalError.TEXT}</React.Fragment>
          )}
        </p>
      );
  }
};

export const ModalErrorTitle: React.FunctionComponent = () => {
  return <h2>{isAdmin ? 'Error' : ModalError.TITLE}</h2>;
};
