import React from 'react';

import styles from './styles.module.scss';

const Component: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className={styles['history-messages-skeleton']}>
        <ul>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
          <li>
            <div>
              <span></span>
            </div>
            <p>
              <strong></strong>
              <span>
                <span></span>
              </span>
            </p>
            <span>
              <span></span>
              <span></span>
            </span>
            <span></span>
          </li>
        </ul>
        <footer>
          <div>
            <strong></strong>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
        </footer>
      </div>
      <span data-nosnippet className="visually-hidden">
        Loading history messages
      </span>
    </React.Fragment>
  );
};

export default React.memo(Component);
