import React from 'react';

import Button from 'components/Button';
import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { UseEvent } from 'modules/redux/states/events/hooks';

import styles from './styles.module.scss';

type ApproveEventProps = {
  approved: UseEvent['approved'];
  isLoading: UseEvent['isLoading'];
  isSending: UseEvent['isSending'];
  onApproveEvent: UseEvent['handleApproveEvent'];
};

const ApproveEvent: React.FunctionComponent<ApproveEventProps> = ({
  approved,
  isLoading,
  isSending,
  onApproveEvent,
}) => {
  return isLoading || onApproveEvent ? (
    <Button
      className={styles['approve-event-button']}
      color={ButtonColor.BLUE}
      disabled={isLoading || isSending || typeof approved === 'boolean'}
      label={
        isLoading || approved === false
          ? ''
          : approved === true
          ? 'Approved'
          : 'Approve'
      }
      onClick={onApproveEvent}
      style={ButtonStyle.PRIMARY}
    />
  ) : null;
};

export default React.memo(ApproveEvent);
