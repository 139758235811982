import { useParams } from 'react-router-dom';

import { shallowEqual, useSelector } from 'modules/redux';
import { UsePolicyHolderDetailsSelector } from './types';

const usePolicyHolderDetailsSelector: UsePolicyHolderDetailsSelector = () => {
  const { endUserId } = useParams() as { endUserId: string };

  const { data, isError, isLoading } = useSelector(
    state => state.engagement.details,
    shallowEqual,
  );

  return data && data.data.endUserId === endUserId
    ? { data, isError, isLoading: !!isLoading, endUserId }
    : { data: null, isError, isLoading: true, endUserId };
};

export default usePolicyHolderDetailsSelector;
