import authReducer from '../auth/reducer';
import configReducer from './states/config/reducer';
import drawersReducer from '../drawers/reducer';
import engagementReducer from './states/engagement/reducer';
import eventsReducer from './states/events/reducer';
import modalsReducer from '../modals/reducer';
import notificationsReducer from '../notifications/reducer';
import policyReducer from './states/policy/reducer';

const reducers = {
  auth: authReducer,
  config: configReducer,
  drawers: drawersReducer,
  engagement: engagementReducer,
  events: eventsReducer,
  modals: modalsReducer,
  notifications: notificationsReducer,
  policy: policyReducer,
};

export default reducers;
