import React from 'react';
import clsx from 'clsx';

import { ReactComponent as SpinnerSVG } from 'modules/theme/images/icons/spinners/circular-bars.svg';
import Card from 'components/Card';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(styles['google-map'], styles.loading);
  return (
    <Card className={classNames}>
      <span data-nosnippet className="visually-hidden">
        Loading Google Maps
      </span>
      <div></div>
      <SpinnerSVG />
    </Card>
  );
};

export default React.memo(Skeleton);
