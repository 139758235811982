import yup from 'modules/validation';

export type Model = {
  startDate: Date | string;
  endDate: Date | string;
};

export const defaultValues: Model = {
  startDate: '',
  endDate: '',
};

export const schema = yup.object().shape({
  startDate: yup.date(),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), "End date can't be before Start date"),
});
