import React, { ReactNode, useMemo, useRef } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';

import { getXAxisTick } from './helper';
import { FontSize, Padding } from 'components/Card/constants';
import { useConfigSelector } from 'modules/redux/states/config/hooks';
import { UsePolicyScores } from 'modules/redux/states/policy/hooks/usePolicySummary';
import { useRelativeUnits } from './hooks';
import Buttons from './Buttons';
import Card from 'components/Card';
import Legend from './Legend';

import styles from './styles.module.scss';

type ScoreOverTimeChartProps = {
  scores?: UsePolicyScores['data'];
  handleUpdate?: UsePolicyScores['handleUpdate'];
};

const Chart: React.FunctionComponent<ScoreOverTimeChartProps> = ({
  handleUpdate,
  scores,
}) => {
  const ref = useRef<HTMLElement>(null);

  const {
    tripsApplication: {
      scores: { types },
    },
  } = useConfigSelector();

  const {
    largeLineWidth,
    largeTooltipCircle,
    lineWidth,
    tooltipCircle,
    xAxisHeight,
    xAxisPadding,
    yAxisWidth,
  } = useRelativeUnits(ref);

  const startDate = useMemo(
    () => scores && scores.results[0]?.scoreDate,
    [scores],
  );
  const endDate = useMemo(
    () => scores && scores.results[scores.results.length - 1]?.scoreDate,
    [scores],
  );
  const specificDateRange = useMemo(
    () => scores && `${startDate} to ${endDate}`,
    [endDate, startDate, scores],
  );
  const aggregateType = useMemo(
    () => scores && scores.results[0]?.aggregateType,
    [scores],
  );

  return (
    <Card
      className={styles.charts}
      fontSize={FontSize.LARGE}
      forwardedRef={ref}
      padding={Padding.LARGE}
      renderHeader={() => (
        <Buttons aggregateType={aggregateType} handleUpdate={handleUpdate} />
      )}
      title="Score over time">
      {scores && scores.results.length > 0 && (
        <figure role="group">
          <div
            role="img"
            aria-label={`Line chart showing the ${aggregateType} policy summary scores.`}>
            <div aria-hidden={true}>
              <Legend />
              <div className={styles['chart-container']}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={scores.results}
                    layout="horizontal"
                    margin={{ top: 8, right: 5, bottom: 5, left: 5 }}>
                    <CartesianGrid horizontal={true} vertical={false} />
                    <XAxis
                      allowDuplicatedCategory={false}
                      dataKey="scoreDate"
                      height={xAxisHeight}
                      padding={{ left: xAxisPadding, right: xAxisPadding }}
                      tickFormatter={(value: string, index: number) =>
                        getXAxisTick(
                          index,
                          value,
                          aggregateType,
                          startDate,
                          endDate,
                        )
                      }
                      tickLine={false}
                    />
                    <YAxis
                      axisLine={false}
                      domain={[0, 100]}
                      tickCount={5}
                      tickLine={false}
                      width={yAxisWidth}
                    />
                    <Tooltip
                      itemStyle={{ textTransform: 'capitalize' }}
                      labelFormatter={(label: string) =>
                        dayjs(label).format('D/M/YY')
                      }
                    />
                    {types
                      .filter(i => i.keyline)
                      .map(i => {
                        return (
                          <Line
                            activeDot={{ r: largeTooltipCircle }}
                            key={`line-${i.key}`}
                            dataKey={`score.${i.key}`}
                            dot={false}
                            name={i.label}
                            stroke={i.colour}
                            strokeWidth={largeLineWidth}
                            type="linear"
                          />
                        );
                      })}
                    {types
                      .filter(i => !i.keyline)
                      .map(i => {
                        return (
                          <Line
                            activeDot={{ r: tooltipCircle }}
                            key={`line-${i.key}`}
                            dataKey={`score.${i.key}`}
                            dot={false}
                            name={i.label}
                            stroke={i.colour}
                            strokeWidth={lineWidth}
                            type="linear"
                          />
                        );
                      })}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <figcaption className="visually-hidden">
            <h2>Overview</h2>
            <p>
              The chart shows the policy summary scores for {specificDateRange}.
            </p>
            <h2>Values</h2>
            <table className="visually-hidden">
              <caption>Policy Summary Scores for {specificDateRange}</caption>
              <thead>
                <tr>
                  <th>Date</th>
                  {scores.results.map((score, index) => {
                    return (
                      <th key={`${score.scoreDate}-${index}`}>
                        {score.scoreDate}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {types.map((type, index) => {
                  return (
                    <tr key={`${type.key}-${index}`}>
                      <td>{type.label}</td>
                      {scores.results.map((score, idx) => {
                        return (
                          <td key={`${score.scoreDate}-${type.key}-${idx}`}>
                            {
                              (score.score as Record<string, ReactNode>)[
                                `${type.key}`
                              ]
                            }
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </figcaption>
        </figure>
      )}
    </Card>
  );
};

export default React.memo(Chart);
