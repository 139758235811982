import React from 'react';

import { ReactComponent as TripSVG } from 'modules/theme/images/icons/navigation/trips.svg';

import styles from './styles.module.scss';

const NoResults: React.FunctionComponent = () => {
  return (
    <div className={styles['trips-summary-no-trips']}>
      <TripSVG />
      <p>No trips have been found for this time period</p>
    </div>
  );
};

export default React.memo(NoResults);
