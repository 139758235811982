import React from 'react';

import { UsePolicyHolderUsers } from 'modules/redux/states/engagement/hooks';
import ErrorMessage from './ErrorMessage';
import NoResults from './NoResults';
import PolicyHoldersForm from './PolicyHoldersForm';
import PolicyHoldersList from './PolicyHoldersList';
import ResultsCount from './ResultsCount';
import Skeleton from './Skeleton';

type PolicyHoldersProp = {
  data: UsePolicyHolderUsers['data'];
  handleClick: UsePolicyHolderUsers['handleClick'];
  handleError: UsePolicyHolderUsers['handleError'];
  handlePaging: UsePolicyHolderUsers['handlePaging'];
  handleSearch: UsePolicyHolderUsers['handleSearch'];
  isError: UsePolicyHolderUsers['isError'];
  isLoading: UsePolicyHolderUsers['isLoading'];
  page: UsePolicyHolderUsers['page'];
  pageSize: UsePolicyHolderUsers['pageSize'];
  searchString: UsePolicyHolderUsers['searchString'];
};

const PolicyHolders: React.FunctionComponent<PolicyHoldersProp> = ({
  data,
  handleClick,
  handleError,
  handlePaging,
  handleSearch,
  isError,
  isLoading,
  page,
  pageSize,
  searchString,
}) => {
  return isError ? (
    <ErrorMessage handleError={handleError} isError={isError} />
  ) : (
    <React.Fragment>
      <PolicyHoldersForm handleSearch={handleSearch} isLoading={isLoading} />
      <ResultsCount
        data={data}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        searchString={searchString}
      />
      {isLoading ? (
        <Skeleton />
      ) : (
        <React.Fragment>
          {data && data.data && data.data.endUsers ? (
            <PolicyHoldersList
              endUsers={data.data.endUsers}
              handleClick={handleClick}
              handlePaging={handlePaging}
              isLoading={isLoading}
              page={page}
              pageSize={pageSize}
              totalCount={data.data.count}
            />
          ) : (
            <NoResults />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default React.memo(PolicyHolders);
