import { API } from 'modules/api';

import { createDefaultAction } from 'modules/redux/factories';
import { PolicyAction } from 'modules/redux/states/policy/constants';
import {
  PolicyPagingTripsAction,
  PolicyPagingTripsStartedAction,
  PolicyPagingTripsSuccessAction,
  PolicyPagingTripsFailureAction,
} from './types';

export type { PolicyPagingTripsActionApiRequest } from './types';

export const policyPagingTripsAction: PolicyPagingTripsAction = request =>
  createDefaultAction({
    actionStarted: policyPagingTripsStartedAction,
    actionSuccess: policyPagingTripsSuccessAction,
    actionFailure: policyPagingTripsFailureAction,
    endpoint: API.GET_POLICY_TRIPS,
    isActive: state =>
      !state.policy.trips.isLoading && !!state.policy.trips.isSending,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      actionId === state.policy.trips.actionId &&
      request.policyId === state.policy.details.data?.policyDetails.identifier,
    request: request,
  });

const policyPagingTripsStartedAction: PolicyPagingTripsStartedAction =
  payload => ({
    type: PolicyAction.PAGING_TRIPS_STARTED,
    ...payload,
  });

const policyPagingTripsSuccessAction: PolicyPagingTripsSuccessAction =
  payload => ({
    type: PolicyAction.PAGING_TRIPS_SUCCESS,
    ...payload,
  });

const policyPagingTripsFailureAction: PolicyPagingTripsFailureAction =
  payload => ({
    type: PolicyAction.PAGING_TRIPS_FAILURE,
    ...payload,
  });
