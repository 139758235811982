import React from 'react';
import clsx from 'clsx';
import { FieldValues, Path, useController } from 'react-hook-form';

import {
  InputAutocomplete,
  InputType,
} from 'components/Inputs/Input/constants';
import { InputPrimitive } from 'components/Inputs/Input';
import { QuestionPolicyHoldersSearchProps } from './types';
import { QuestionSize } from '../../constants';

import questionStyles from '../styles.module.scss';
import styles from './styles.module.scss';

function QuestionPolicyHoldersSearch<T extends FieldValues>({
  disabled,
  children,
  control,
  id,
  label,
  name,
  readOnly,
  ...props
}: QuestionPolicyHoldersSearchProps<T>) {
  const {
    field: { onChange, value, onBlur, ref },
  } = useController({
    control,
    name: name as Path<T>,
  });

  const className = clsx(
    styles['question-policy-holders-search'],
    questionStyles.question,
    questionStyles.medium,
    disabled && questionStyles.disabled,
    readOnly && questionStyles['read-only'],
    value && styles['value'],
  );

  return (
    <div className={className}>
      {!!label && <label htmlFor={id ? id : name}>{label}</label>}
      <InputPrimitive
        autoComplete={InputAutocomplete.OFF}
        disabled={disabled}
        id={id}
        inputRef={ref}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        size={QuestionSize.MEDIUM}
        type={InputType.TEXT}
        value={value as string}
        {...props}>
        {children}
      </InputPrimitive>
    </div>
  );
}

export default React.memo(
  QuestionPolicyHoldersSearch,
) as typeof QuestionPolicyHoldersSearch;
