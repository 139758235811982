import { DrawerCustomTemplate } from '../constants';
import { DrawerCustomProps } from '../types';
import drawerDetails from './drawerDetails';

export type DrawerCustomTemplateProps = Omit<
  DrawerCustomProps,
  'destroyDrawer' | 'hideDrawer' | 'id' | 'onClose' | 'open'
>;

export const getTemplate = (
  template?: DrawerCustomTemplate,
): DrawerCustomTemplateProps | undefined => {
  switch (template) {
    case DrawerCustomTemplate.DETAILS:
      return drawerDetails;
    default:
      return undefined;
  }
};
