import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { ReactComponent as DriverIcon } from 'modules/theme/images/icons/card/driver.svg';
import { UsePolicyHolderDetails } from 'modules/redux/states/engagement/hooks';
import Button from 'components/Button';
import Card from 'components/Card';

import styles from './styles.module.scss';

type Props = {
  data: UsePolicyHolderDetails['data'];
  handleClick: () => void;
};

const Component: React.FunctionComponent<Props> = ({ data, handleClick }) => {
  return (
    <Card
      className={styles['policy-details']}
      renderHeader={() => <DriverIcon />}
      title="Policy Details">
      <ul>
        <li>
          <strong>Name</strong>
          <span>{`${data?.data?.firstName} ${data?.data?.lastName}`}</span>
        </li>
        <li>
          <strong>Policy number</strong>
          <span>{data?.data?.reference}</span>
        </li>
        <li>
          <strong>Email address</strong>
          <span>{data?.data?.emailAddress}</span>
        </li>
        <li>
          <strong>External ID</strong>
          <span>{data?.data?.endUserId}</span>
        </li>
      </ul>
      <Button
        color={ButtonColor.BLUE}
        disabled={!handleClick}
        label="View additional details"
        onClick={handleClick}
        style={ButtonStyle.SECONDARY}
      />
    </Card>
  );
};

export default React.memo(Component);
