import { Middleware } from 'redux';

import { AuthAction } from 'modules/auth/constants';
import { AuthActionType } from 'modules/auth/types';
import { dispatchAbort, Dispatch, RootState } from 'modules/redux';
import { ConfigAction } from './constants';
import { ConfigActionType } from './types';
import { configGetAction, configLogoutAction } from './actions';
import { localStorageSetObject, localStorageRemoveItem } from 'modules/storage';
import { modalShowAction } from 'modules/modals/actions';
import { ModalType } from 'modules/modals/constants';
import { Modal } from 'modules/modals/types';
import { resetAuth0Client } from 'modules/auth/providers/auth0';

const MAX_RETRIES = 5;
let retryCounter = 0;

const createConfigMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | ConfigActionType) => {
    let nextAction;

    switch (action.type) {
      case AuthAction.CONFIG_SUCCESS:
      case AuthAction.LOGIN_SUCCESS:
        nextAction = next(action);
        dispatch(configGetAction());
        break;

      case ConfigAction.GET_FAILURE:
        nextAction = next(action);
        retryCounter++;
        if (retryCounter <= MAX_RETRIES) {
          setTimeout(() => dispatch(configGetAction()), 5000);
        } else {
          dispatch(
            modalShowAction({
              closeLabel: 'Retry',
              onClose: resetAuth0Client,
              renderHeader: () => <h2>Forbidden Error</h2>,
              renderContent: () => <p>{action.error.message}</p>,
              type: ModalType.INFO,
            } as Modal),
          );
        }
        break;

      case ConfigAction.GET_SUCCESS:
        localStorageSetObject('CONFIG', action.data);
        break;

      case AuthAction.LOGOUT:
        localStorageRemoveItem('CONFIG');
        dispatchAbort(getState().config);
        nextAction = next(action);
        dispatch(configLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default createConfigMiddleware;
