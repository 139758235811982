import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import { apiAbort } from 'modules/api';
import { AuthAction } from 'modules/auth/constants';
import { AuthActionType } from 'modules/auth/types';
import { buildPolicyPath, middlewareNavigate } from 'modules/router/utils';
import { dispatchAbort, Dispatch, RootState } from 'modules/redux';
import { PolicyAction } from './constants';
import { PolicyActionType } from './types';
import { policyLogoutAction, policyResetAction } from './actions';
import { RouterActionType } from 'modules/router/types';
import { shouldResetPolicy } from './utils';

const createPolicyMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | PolicyActionType | RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case LOCATION_CHANGE: {
        nextAction = next(action);
        shouldResetPolicy(getState().router) && dispatch(policyResetAction());
        break;
      }

      case PolicyAction.GET_SEARCH_SUCCESS: {
        nextAction = next(action);
        middlewareNavigate(
          dispatch,
          buildPolicyPath(action.data.policyDetails.identifier),
        );
        break;
      }

      case PolicyAction.GET_TRIPS_STARTED: {
        const { auth, policy } = getState();
        if (auth.isAuthenticated) apiAbort(policy.trips.isSending);
        break;
      }

      case PolicyAction.RESET: {
        const { auth, policy } = getState();
        if (auth.isAuthenticated) {
          dispatchAbort([policy.trips]);
        } else return;
        break;
      }

      case AuthAction.LOGOUT: {
        const policy = getState().policy;
        dispatchAbort([
          policy.details,
          policy.scores,
          ...policy.trip,
          policy.trips,
        ]);
        apiAbort(policy.trips.isSending);
        nextAction = next(action);
        dispatch(policyLogoutAction());
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default createPolicyMiddleware;
