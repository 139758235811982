export enum ConfigAction {
  GET_STARTED = 'CONFIG_GET_STARTED',
  GET_SUCCESS = 'CONFIG_GET_SUCCESS',
  GET_FAILURE = 'CONFIG_GET_FAILURE',

  LOGOUT = 'CONFIG_LOGOUT',
}

export enum ConfigUnitsDistance {
  KM = 'km',
  MILES = 'miles',
}
