import produce from 'immer';
import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from '../constants';

function usersReducer(state: Engagement, action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.GET_USERS_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.users.actionId = null;
        draftState.users.isError = action.error;
        draftState.users.isLoading = false;

        draftState.users.data = null;
      });

    case EngagementAction.GET_USERS_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.users.actionId = action.actionId;
        draftState.users.isError = false;
        draftState.users.isLoading = action.controller || true;

        draftState.users.data = null;
        draftState.users.searchString = action.request.searchString;
        draftState.users.page = action.request.page;
        draftState.users.pageSize = action.request.pageSize;
      });

    case EngagementAction.GET_USERS_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.users.actionId = null;
        draftState.users.isError = false;
        draftState.users.isLoading = false;

        draftState.users.data = action.data;
      });

    default:
      return state;
  }
}

export default usersReducer;
