import yup from 'modules/validation';

export type Model = {
  debugTextArea: string;
  debugTextAreaEmpty: string;
  debugTextAreaDisabled: string;
  debugTextAreaDisabledEmpty: string;
  debugTextAreaReadOnly: string;
  debugTextAreaEmptyReadOnly: string;
};

export const defaultValues: Model = {
  debugTextArea: 'Prefilled value',
  debugTextAreaEmpty: '',
  debugTextAreaDisabled: 'Prefilled value',
  debugTextAreaDisabledEmpty: '',
  debugTextAreaReadOnly: 'Prefilled value',
  debugTextAreaEmptyReadOnly: '',
};

export const schema = yup.object().shape({
  debugTextArea: yup.string().required(),
  debugTextAreaEmpty: yup.string().required(),
  debugTextAreaDisabled: yup.string(),
  debugTextAreaDisabledEmpty: yup.string(),
  debugTextAreaReadOnly: yup.string(),
  debugTextAreaEmptyReadOnly: yup.string(),
});
