import usePolicyHolderReport from './usePolicyHolderReport';

import usePolicyHolderMessages from './usePolicyHolderMessages';
import type {
  UsePolicyHolderDetails,
  UsePolicyHolderMessages,
  UsePolicyHolderPoints,
  UsePolicyHolderReport,
  UsePolicyHolderRewards,
} from './types';
import usePolicyHolderDetails from './usePolicyHolderDetails';
import usePolicyHolderPoints from './usePolicyHolderPoints';
import usePolicyHolderRewards from './usePolicyHolderRewards';

export type {
  UsePolicyHolderDetails,
  UsePolicyHolderMessages,
  UsePolicyHolderPoints,
  UsePolicyHolderReport,
  UsePolicyHolderRewards,
} from './types';

const usePolicyHolderSummary = (): {
  details: UsePolicyHolderDetails;
  messages: UsePolicyHolderMessages;
  points: UsePolicyHolderPoints;
  report: UsePolicyHolderReport;
  rewards: UsePolicyHolderRewards;
} => {
  const details = usePolicyHolderDetails();
  const messages = usePolicyHolderMessages();
  const points = usePolicyHolderPoints();
  const report = usePolicyHolderReport();
  const rewards = usePolicyHolderRewards();

  return { details, messages, points, report, rewards };
};

export default usePolicyHolderSummary;
