import { produce } from 'immer';

import { Policy, PolicyActionType } from 'modules/redux/states/policy/types';
import { PolicyAction } from 'modules/redux/states/policy/constants';

function tripsPagingReducer(state: Policy, action: PolicyActionType) {
  switch (action.type) {
    case PolicyAction.PAGING_TRIPS_FAILURE:
      return produce(state, (draftState: Policy) => {
        draftState.trips.actionId = null;

        draftState.trips.isSending = false;
        draftState.trips.isSendingError = action.error;
      });

    case PolicyAction.PAGING_TRIPS_STARTED:
      return produce(state, (draftState: Policy) => {
        draftState.trips.actionId = action.actionId;

        draftState.trips.isSending = action.controller || true;
        draftState.trips.isSendingError = false;
      });

    case PolicyAction.PAGING_TRIPS_SUCCESS:
      return produce(state, (draftState: Policy) => {
        draftState.trips.actionId = null;
        draftState.trips.isSending = false;
        draftState.trips.isSendingError = false;

        if (draftState.trips.data) {
          draftState.trips.data.paging = action.data.paging;

          draftState.trips.data.results = [
            ...draftState.trips.data.results,
            ...action.data.results,
          ].filter(
            (trip, index, array) =>
              index === array.findIndex(item => item.tripId === trip.tripId),
          );
        }
      });

    default:
      return state;
  }
}

export default tripsPagingReducer;
