import { useCallback } from 'react';

export const useHandlers = (
  handleActiveAnimation: (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.KeyboardEvent<HTMLAnchorElement>,
  ) => void,
  disabled?: boolean,
  loading?: boolean,
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void,
) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (!disabled && !loading && onClick) onClick(event);
      if (disabled || loading) event.preventDefault();
    },
    [disabled, loading, onClick],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      handleActiveAnimation(event);
    },
    [handleActiveAnimation],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (event.key === 'Enter') {
        handleActiveAnimation(event);
      }
    },
    [handleActiveAnimation],
  );

  return {
    handleClick,
    handleKeyDown,
    handleMouseDown,
  };
};
