import React from 'react';
import clsx from 'clsx';

import { ReactComponent as ErrorSVG } from 'modules/theme/images/icons/inputs/error.svg';
import Card from 'components/Card';

import styles from './styles.module.scss';

type GoogleMapErrorProps = {
  loadError: Error;
};

const ErrorMessage: React.FunctionComponent<GoogleMapErrorProps> = ({
  loadError,
}) => {
  const classNames = clsx(styles['google-map'], styles.error);
  return (
    <Card className={classNames}>
      <div>
        <ErrorSVG />
        <h2>{loadError?.name}</h2>
        <p>{loadError?.message}</p>
      </div>
    </Card>
  );
};

export default React.memo(ErrorMessage);
