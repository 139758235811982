export enum EngagementAction {
  GET_BRAND_STARTED = 'ENGAGEMENT_GET_BRAND_STARTED',
  GET_BRAND_SUCCESS = 'ENGAGEMENT_GET_BRAND_SUCCESS',
  GET_BRAND_FAILURE = 'ENGAGEMENT_GET_BRAND_FAILURE',

  GET_USERS_STARTED = 'ENGAGEMENT_GET_USERS_STARTED',
  GET_USERS_SUCCESS = 'ENGAGEMENT_GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'ENGAGEMENT_GET_USERS_FAILURE',

  PAGING_USERS_STARTED = 'ENGAGEMENT_PAGING_USERS_STARTED',
  PAGING_USERS_SUCCESS = 'ENGAGEMENT_PAGING_USERS_SUCCESS',
  PAGING_USERS_FAILURE = 'ENGAGEMENT_PAGING_USERS_FAILURE',

  GET_REPORT_STARTED = 'ENGAGEMENT_GET_REPORT_STARTED',
  GET_REPORT_SUCCESS = 'ENGAGEMENT_GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE = 'ENGAGEMENT_GET_REPORT_FAILURE',

  GET_DETAILS_STARTED = 'ENGAGEMENT_GET_DETAILS_STARTED',
  GET_DETAILS_SUCCESS = 'ENGAGEMENT_GET_DETAILS_SUCCESS',
  GET_DETAILS_FAILURE = 'ENGAGEMENT_GET_DETAILS_FAILURE',

  GET_ADDITIONAL_DETAILS_STARTED = 'ENGAGEMENT_GET_ADDITIONAL_DETAILS_STARTED',
  GET_ADDITIONAL_DETAILS_SUCCESS = 'ENGAGEMENT_GET_ADDITIONAL_DETAILS_SUCCESS',
  GET_ADDITIONAL_DETAILS_FAILURE = 'ENGAGEMENT_GET_ADDITIONAL_DETAILS_FAILURE',

  POST_ADDITIONAL_DETAILS_STARTED = 'ENGAGEMENT_POST_ADDITIONAL_DETAILS_STARTED',
  POST_ADDITIONAL_DETAILS_SUCCESS = 'ENGAGEMENT_POST_ADDITIONAL_DETAILS_SUCCESS',
  POST_ADDITIONAL_DETAILS_FAILURE = 'ENGAGEMENT_POST_ADDITIONAL_DETAILS_FAILURE',
  POST_ADDITIONAL_DETAILS_RESET_ERROR = 'ENGAGEMENT_POST_ADDITIONAL_DETAILS_RESET_ERROR',

  GET_POINTS_STARTED = 'ENGAGEMENT_GET_POINTS_STARTED',
  GET_POINTS_SUCCESS = 'ENGAGEMENT_GET_POINTS_SUCCESS',
  GET_POINTS_FAILURE = 'ENGAGEMENT_GET_POINTS_FAILURE',

  GET_REWARDS_STARTED = 'ENGAGEMENT_GET_REWARDS_STARTED',
  GET_REWARDS_SUCCESS = 'ENGAGEMENT_GET_REWARDS_SUCCESS',
  GET_REWARDS_FAILURE = 'ENGAGEMENT_GET_REWARDS_FAILURE',

  GET_MESSAGES_STARTED = 'ENGAGEMENT_GET_MESSAGES_STARTED',
  GET_MESSAGES_SUCCESS = 'ENGAGEMENT_GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAILURE = 'ENGAGEMENT_GET_MESSAGES_FAILURE',

  GET_MESSAGES_EMAIL_TEMPLATE_STARTED = 'ENGAGEMENT_GET_MESSAGES_EMAIL_TEMPLATE_STARTED',
  GET_MESSAGES_EMAIL_TEMPLATE_SUCCESS = 'ENGAGEMENT_GET_MESSAGES_EMAIL_TEMPLATE_SUCCESS',
  GET_MESSAGES_EMAIL_TEMPLATE_FAILURE = 'ENGAGEMENT_GET_MESSAGES_EMAIL_TEMPLATE_FAILURE',

  POST_ADD_POINTS_STARTED = 'ENGAGEMENT_POST_ADD_POINTS_STARTED',
  POST_ADD_POINTS_SUCCESS = 'ENGAGEMENT_POST_ADD_POINTS_SUCCESS',
  POST_ADD_POINTS_FAILURE = 'ENGAGEMENT_POST_ADD_POINTS_FAILURE',

  POST_REMOVE_POINTS_STARTED = 'ENGAGEMENT_POST_REMOVE_POINTS_STARTED',
  POST_REMOVE_POINTS_SUCCESS = 'ENGAGEMENT_POST_REMOVE_POINTS_SUCCESS',
  POST_REMOVE_POINTS_FAILURE = 'ENGAGEMENT_POST_REMOVE_POINTS_FAILURE',
  POST_SEND_POINTS_RESET_ERROR = 'ENGAGEMENT_POST_SEND_POINTS_RESET_ERROR',

  OPEN_MESSAGES_EMAIL_TEMPLATE = 'ENGAGEMENT_OPEN_MESSAGES_EMAIL_TEMPLATE',

  LOGOUT = 'ENGAGEMENT_LOGOUT',

  RESET = 'ENGAGEMENT_RESET',
}
