import { API } from 'modules/api';

import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import {
  EngagementGetReportAction,
  EngagementGetReportFailureAction,
  EngagementGetReportStartedAction,
  EngagementGetReportSuccessAction,
} from './types';

export type { EngagementGetReportActionApiRequest } from './types';

export const engagementGetReportAction: EngagementGetReportAction = request =>
  createDefaultAction({
    actionStarted: engagementGetReportStartedAction,
    actionSuccess: engagementGetReportSuccessAction,
    actionFailure: engagementGetReportFailureAction,
    endpoint: API.GET_ENGAGEMENT_REPORT,
    isActive: state => !!state.engagement.report.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      actionId === state.engagement.report.actionId,
    request: request,
  });

const engagementGetReportStartedAction: EngagementGetReportStartedAction =
  payload => ({
    type: EngagementAction.GET_REPORT_STARTED,
    ...payload,
  });

const engagementGetReportSuccessAction: EngagementGetReportSuccessAction =
  payload => ({
    type: EngagementAction.GET_REPORT_SUCCESS,
    ...payload,
  });

const engagementGetReportFailureAction: EngagementGetReportFailureAction =
  payload => ({
    type: EngagementAction.GET_REPORT_FAILURE,
    ...payload,
  });
