import React, { useMemo } from 'react';

import Button from 'components/Button';
import {
  ButtonColor,
  ButtonStyle,
  ButtonType,
} from 'components/Button/constants';
import { ConfigEventsApplication } from 'modules/redux/states/config/types';
import { defaultValues, Model, schema } from './model';
import Form from 'components/Form';
import { QuestionSelect } from 'components/Questions';

import styles from './styles.module.scss';

export type { Model } from './model';

type DeclineEventFormProps = {
  active: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: Model) => void;
  reasons: ConfigEventsApplication['invalidation']['reasons'];
};

const DeclineForm: React.FunctionComponent<DeclineEventFormProps> = ({
  active,
  isLoading,
  onCancel,
  onSubmit,
  reasons,
}) => {
  const options = useMemo(
    () =>
      reasons.map(reason => ({
        value: reason.key,
        label: reason.label,
      })),
    [reasons],
  );
  return (
    <React.Fragment>
      <header>
        <h2>Please select your reason for declining</h2>
      </header>
      <Form<Model>
        className={styles['declined-modal-form']}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        schema={schema}>
        {({ control, isFormSubmitting }) => (
          <React.Fragment>
            <div>
              <QuestionSelect
                control={control}
                disabled={!active || isLoading}
                name="reasonForDeclining"
                options={options}
                placeholder="-- Select a reason --"
                readOnly={!active || isLoading}
              />
            </div>
            <footer>
              <Button
                color={ButtonColor.BLUE}
                disabled={!active || isFormSubmitting || isLoading}
                label="Cancel"
                onClick={onCancel}
                style={ButtonStyle.SECONDARY}
              />
              <Button
                color={ButtonColor.BLUE}
                disabled={!active}
                label="Confirm"
                loading={isFormSubmitting || isLoading}
                style={ButtonStyle.PRIMARY}
                type={ButtonType.SUBMIT}
              />
            </footer>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
};

export default React.memo(DeclineForm);
