import { useCallback } from 'react';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { policyGetSearchAction } from 'modules/redux/states/policy/actions';

export type UsePolicySearch = {
  handleSearch: (policyId: string) => void;
  isError: false | Error;
  isLoading: boolean;
};

const usePolicySearch = (): UsePolicySearch => {
  const dispatch: Dispatch = useDispatch();

  const { isError, isLoading } = useSelector(
    state => state.policy.details,
    shallowEqual,
  );

  const handleSearch = useCallback(
    (policyId: string) => dispatch(policyGetSearchAction({ policyId })),
    [dispatch],
  );

  return { handleSearch, isError, isLoading: !!isLoading };
};

export default usePolicySearch;
