import React, { useCallback, useState } from 'react';

import Button from 'components/Button';
import Form from './Form';
import { getEndDateString, getStartDateString } from 'modules/utils';
import { Model } from './Form/model';
import { ReactComponent as SpinnerSVG } from 'modules/theme/images/icons/spinners/circular-bars.svg';
import { UsePolicyTrips } from 'modules/redux/states/policy/hooks/usePolicySummary';

import styles from './styles.module.scss';

type FilterProps = {
  dateRange: string | null;
  handleSearch: UsePolicyTrips['handleSearch'];
  isLoading: boolean;
};

const Filter: React.FunctionComponent<FilterProps> = ({
  dateRange,
  handleSearch,
  isLoading,
}) => {
  const [toggleFilter, setToggleFilter] = useState(false);

  const handleClearFilter = useCallback(() => {
    handleSearch({});
    setToggleFilter(false);
  }, [handleSearch]);

  const handleOpenFilter = useCallback(() => setToggleFilter(true), []);

  const handleSubmit = useCallback(
    (data: Model) =>
      handleSearch({
        endDate: getEndDateString(data.endDate),
        startDate: getStartDateString(data.startDate),
      }),
    [handleSearch],
  );

  return !toggleFilter ? (
    <Button
      className={styles['toggle-form-button']}
      disabled={isLoading}
      label="Filter by date"
      onClick={handleOpenFilter}>
      {isLoading && <SpinnerSVG />}
    </Button>
  ) : (
    <Form
      dateRange={dateRange}
      isLoading={isLoading}
      onClearFilter={handleClearFilter}
      onSubmit={handleSubmit}
    />
  );
};

export default React.memo(Filter);
