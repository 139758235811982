import React from 'react';
import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import Button from 'components/Button';

type ConfirmProps = {
  active: boolean;
  cancelable?: boolean;
  isLoading: boolean;
  label?: string;
  onCancel: () => void;
  onConfirm: () => void;
  text?: string;
  title: string;
};

const Confirm: React.FunctionComponent<ConfirmProps> = ({
  active,
  cancelable,
  isLoading,
  label = 'Confirm',
  onCancel,
  onConfirm,
  text,
  title,
}) => {
  return (
    <React.Fragment>
      <header>
        <h2>{title}</h2>
      </header>
      {!!text && (
        <div>
          <p>{text}</p>
        </div>
      )}
      <footer>
        <Button
          color={ButtonColor.BLUE}
          disabled={!active || (!cancelable && isLoading)}
          label="Cancel"
          onClick={onCancel}
          style={ButtonStyle.SECONDARY}
        />
        <Button
          color={ButtonColor.BLUE}
          disabled={!active}
          label={label}
          loading={isLoading}
          onClick={onConfirm}
          style={ButtonStyle.PRIMARY}
        />
      </footer>
    </React.Fragment>
  );
};

export default React.memo(Confirm);
