import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import { appActionInit } from 'modules/app/actions';
import ErrorFallback from './ErrorFallback';
import Router from 'modules/router';
import store from 'modules/redux';
import PlatformContext, {
  isAndroid,
  isChrome,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
  PlatformType,
} from 'modules/platform';

const platform: PlatformType = {
  isAndroid,
  isChrome,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
};

store.dispatch(appActionInit(platform));

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        <PlatformContext.Provider value={platform}>
          <Router />
        </PlatformContext.Provider>
      </Provider>
    </ErrorBoundary>
  );
}

export default React.memo(App);
