import { produce } from 'immer';

import { Events, EventsActionType } from '../../types';
import { EventsAction } from '../../constants';

function eventsGetReducer(state: Events, action: EventsActionType) {
  switch (action.type) {
    case EventsAction.GET_FAILURE:
      return produce(state, (draftState: Events) => {
        draftState.actionId = null;
        draftState.isError = action.error;
        draftState.isLoading = false;

        draftState.data = null;
      });

    case EventsAction.GET_STARTED:
      return produce(state, (draftState: Events) => {
        draftState.actionId = action.actionId;
        draftState.isError = false;
        draftState.isLoading = action.controller || true;

        draftState.data = null;
      });

    case EventsAction.GET_SUCCESS:
      return produce(state, (draftState: Events) => {
        draftState.actionId = null;
        draftState.isError = false;
        draftState.isLoading = false;

        draftState.data = action.data;
      });

    default:
      return state;
  }
}

export default eventsGetReducer;
