import React from 'react';
import { Polyline } from '@react-google-maps/api';

type GooglePolylineProps = {
  polyline: google.maps.LatLngLiteral[];
};

const GooglePolyline: React.FunctionComponent<GooglePolylineProps> = ({
  polyline,
}) => {
  return (
    <Polyline
      options={{
        strokeColor: '#358FFC',
        strokeOpacity: 1,
        strokeWeight: 5,
      }}
      path={polyline}
      visible={true}
    />
  );
};

export default React.memo(GooglePolyline);
