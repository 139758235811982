import React from 'react';

import Template from './Template';
import { useEvents } from 'modules/redux/states/events/hooks';

const Route: React.FunctionComponent = () => {
  useEvents();
  return <Template />;
};

export default Route;
