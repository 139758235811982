import React from 'react';
import clsx from 'clsx';

import { ExternalLinkProps } from './types';
import { ReactComponent as SpinnerSVG } from 'modules/theme/images/icons/spinners/circular-bars.svg';
import { useActiveAnimation, useHandlers } from './hooks';

import styles from './styles.module.scss';

const ExternalLink: React.FunctionComponent<ExternalLinkProps> = ({
  ariaLabel,
  children,
  className,
  color,
  disabled,
  href,
  label,
  loading,
  rel,
  style,
  target,
  title,
}) => {
  const { animation, handleActiveAnimation, ref } = useActiveAnimation();
  const { handleClick, handleMouseDown, handleKeyDown } = useHandlers(
    handleActiveAnimation,
    disabled,
    loading,
  );

  const classNames = clsx(
    style && styles.hyperlink,
    color && styles[`${color}`],
    disabled && styles['disabled'],
    loading && styles['loading'],
    style && styles[`${style}`],
    animation && 'active-animation',
    !disabled && !loading && styles.useable,
    className,
  );

  return (
    <a
      aria-label={ariaLabel}
      className={classNames}
      href={href}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      ref={ref}
      rel={rel}
      tabIndex={disabled || loading ? -1 : 0}
      target={target}
      title={title || ariaLabel}>
      {label && <span>{label}</span>}
      {loading && <SpinnerSVG />}
      {children}
    </a>
  );
};

export default React.memo(ExternalLink);
