import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { InternalLinkProps } from './types';
import { ReactComponent as SpinnerSVG } from 'modules/theme/images/icons/spinners/circular-bars.svg';
import { useActiveAnimation, useHandlers } from './hooks';

import styles from './styles.module.scss';

const InternalLink: React.FunctionComponent<InternalLinkProps> = ({
  ariaLabel,
  children,
  className,
  color,
  disabled,
  label,
  loading,
  route,
  style,
  title,
}) => {
  const { animation, handleActiveAnimation, ref } = useActiveAnimation();
  const { handleClick, handleMouseDown, handleKeyDown } = useHandlers(
    handleActiveAnimation,
    disabled,
    loading,
  );

  const classNames = clsx(
    style && styles.hyperlink,
    color && styles[`${color}`],
    disabled && styles['disabled'],
    loading && styles['loading'],
    style && styles[`${style}`],
    animation && 'active-animation',
    !disabled && !loading && styles.useable,
    className,
  );
  return (
    <Link
      aria-label={ariaLabel}
      className={classNames}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      ref={ref}
      tabIndex={disabled || loading ? -1 : 0}
      title={title || ariaLabel}
      to={route}>
      {label && <span>{label}</span>}
      {loading && <SpinnerSVG />}
      {children}
    </Link>
  );
};

export default React.memo(InternalLink);
