import { produce } from 'immer';

import { Policy, PolicyActionType } from '../types';
import { PolicyAction } from '../constants';

function searchReducer(state: Policy, action: PolicyActionType) {
  switch (action.type) {
    case PolicyAction.GET_SEARCH_FAILURE:
      return produce(state, (draftState: Policy) => {
        draftState.details.actionId = null;
        draftState.details.isError = action.error;
        draftState.details.isLoading = false;

        draftState.details.data = null;
      });

    case PolicyAction.GET_SEARCH_STARTED:
      return produce(state, (draftState: Policy) => {
        draftState.details.actionId = action.actionId;
        draftState.details.isError = false;
        draftState.details.isLoading = action.controller || true;

        draftState.details.data = null;
      });

    case PolicyAction.GET_SEARCH_SUCCESS:
      return produce(state, (draftState: Policy) => {
        draftState.details.actionId = null;
        draftState.details.isError = false;
        draftState.details.isLoading = false;

        draftState.details.data = action.data;
      });

    default:
      return state;
  }
}

export default searchReducer;
