import { CancelTokenSource } from 'axios';
import sleep from 'sleep-promise';

import { apiAbort } from 'modules/api';

type DispatchAbort = (
  state:
    | {
        isLoading: AbortController | CancelTokenSource | boolean;
      }
    | {
        isLoading: AbortController | CancelTokenSource | boolean;
      }[],
) => void;

export const dispatchAbort: DispatchAbort = states =>
  (Array.isArray(states) ? states : [states]).forEach(state =>
    apiAbort(state.isLoading),
  );

export const timeout = async (startTime: number, endTime: number) =>
  new Date().getTime() - startTime < endTime &&
  (await sleep(endTime - (new Date().getTime() - startTime)));
