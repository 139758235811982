import { NotificationAction } from './constants';
import { Notification, NotificationActionType } from './types';

const initialState: Notification[] = [];

function notificationsReducer(
  state = initialState,
  action: NotificationActionType,
) {
  switch (action.type) {
    case NotificationAction.DESTROY:
      return state.filter(notification => notification.id !== action.id);
    case NotificationAction.HIDE:
      return state.map(notification => {
        if (notification.id !== action.id) {
          return notification;
        }
        return {
          ...notification,
          visible: false,
        };
      });
    case NotificationAction.INSERT:
      return [...state, { ...action.notification }];
    default:
      return state;
  }
}

export default notificationsReducer;
