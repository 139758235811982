import React from 'react';

import {
  ButtonColor,
  ButtonStyle,
  ButtonType,
} from 'components/Button/constants';
import { defaultValues, Model, schema } from './model';
import { QuestionSize } from 'components/Questions/constants';
import { QuestionDate } from 'components/Questions';
import { ReactComponent as SpinnerSVG } from 'modules/theme/images/icons/spinners/circular-bars.svg';
import { UsePolicyTrips } from 'modules/redux/states/policy/hooks';
import Button from 'components/Button';
import Form from 'components/Form';

import styles from './styles.module.scss';
import tripListStyles from '../styles.module.scss';

type FilterByDateFormProps = {
  dateRange: string | null;
  isLoading: UsePolicyTrips['isLoading'];
  onClearFilter: () => void;
  onSubmit: (values: Model) => void;
};

function FilterByDateForm({
  dateRange,
  isLoading,
  onClearFilter,
  onSubmit,
}: FilterByDateFormProps) {
  return (
    <Form<Model>
      className={styles['filter-by-date-form']}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      schema={schema}>
      {({ control, isFormSubmitting }) => {
        return (
          <React.Fragment>
            <Button
              className={tripListStyles['toggle-form-button']}
              disabled={isLoading}
              label="Clear all filters"
              onClick={() => onClearFilter()}>
              {isLoading && <SpinnerSVG />}
            </Button>
            <div>
              <QuestionDate
                control={control}
                label="From"
                name="startDate"
                readOnly={isLoading}
                size={QuestionSize.SMALL}
              />
              <QuestionDate
                control={control}
                label="To"
                name="endDate"
                readOnly={isLoading}
                size={QuestionSize.SMALL}
              />
              <Button
                color={ButtonColor.BLUE}
                label="Go"
                loading={isFormSubmitting || isLoading}
                style={ButtonStyle.PRIMARY}
                type={ButtonType.SUBMIT}
              />
            </div>
            {dateRange && <p>Showing trips between {dateRange}</p>}
          </React.Fragment>
        );
      }}
    </Form>
  );
}
export default React.memo(FilterByDateForm) as typeof FilterByDateForm;
