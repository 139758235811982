import React, {
  useRef,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { Map as Mapbox, LngLatBoundsLike, MapRef, Marker } from 'react-map-gl';
import bbox from '@turf/bbox';

import { decodePolyline } from 'modules/polyline/decode';
import { getJourneyMarkers } from '../helpers';
import { JOURNEY_POSITION } from '../constants';
import { Padding } from 'components/Card/constants';
import { PolicyEventDetails } from 'modules/redux/states/policy/trip/types';
import { PolicyEventType } from 'modules/redux/states/policy/trip/constants';
import { useConfigSelector } from 'modules/redux/states/config/hooks';
import Card from 'components/Card';
import EventIcon from './MapEventIcons';
import JourneyMarker from './JourneyMarker';
import Polyline from './Polyline';

import styles from './styles.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

type TripMapProps = {
  events: PolicyEventDetails[];
  geometry: string;
};

const Map: React.FunctionComponent<TripMapProps> = ({ events, geometry }) => {
  const ref = useRef<HTMLElement>(null);
  const mapRef = useRef<MapRef | null>(null);
  const [canCentreMap, setCanCentreMap] = useState(true);
  const {
    tripsApplication: { showGeometricLine },
  } = useConfigSelector();
  const { mapBoxCoords } = useMemo(
    () => decodePolyline(geometry, showGeometricLine, events),
    [geometry, showGeometricLine, events],
  );
  const journey = useMemo(
    () => getJourneyMarkers(mapBoxCoords),
    [mapBoxCoords],
  );
  const journeyEnd = useMemo(() => events.slice(-1), [events]);

  const bounds = useMemo(() => bbox(mapBoxCoords), [mapBoxCoords]);

  const positionMarkers = useMemo(
    () =>
      events
        ?.filter(e => e.eventType === PolicyEventType.POSITION_UPDATE)
        .map((position, index) => (
          <Marker
            key={`${position.lat}-${index}`}
            latitude={position.lat}
            longitude={position.lon}>
            <div aria-hidden={true} className={styles['position-icon']}>
              <div>
                <EventIcon eventType={position.eventType} />
                <span>{position.sequenceTerm}</span>
              </div>
            </div>
          </Marker>
        )),
    [events],
  );
  const eventMarkers = useMemo(
    () =>
      events
        ?.filter(e => e.eventType !== PolicyEventType.POSITION_UPDATE)
        .map((event, index) => (
          <Marker
            key={`${event.lat}-${index}`}
            latitude={event.lat}
            longitude={event.lon}>
            <div aria-hidden={true} className={styles['map-icon']}>
              <EventIcon eventType={event.eventType} />
            </div>
          </Marker>
        )),
    [events],
  );

  const onMapRender = useCallback(() => {
    if (canCentreMap) {
      mapRef.current?.fitBounds(bounds as LngLatBoundsLike, {
        padding: 50,
        duration: 3000,
      });
      setCanCentreMap(false);
    }
  }, [bounds, canCentreMap]);

  useEffect(() => {
    mapRef.current &&
      mapRef.current.fitBounds(bounds as LngLatBoundsLike, {
        padding: 50,
        duration: 3000,
      });
  }, [bounds]);

  return (
    <Card className={styles.map} forwardedRef={ref} padding={Padding.NONE}>
      <div aria-hidden={true}>
        <Mapbox
          initialViewState={{
            bounds: bounds as LngLatBoundsLike,
            fitBoundsOptions: {
              padding: 50,
            },
          }}
          onRender={onMapRender}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken={process.env.REACT_APP_MAPBOX}
          ref={mapRef}
          reuseMaps>
          {journey && journeyEnd && (
            <React.Fragment>
              {journey.start.lon && journey.start.lat && (
                <JourneyMarker
                  lon={journey.start.lon}
                  lat={journey.start.lat}
                  position={JOURNEY_POSITION.start}
                />
              )}
              {journeyEnd[0].lon && journeyEnd[0].lat && (
                <JourneyMarker
                  lon={journeyEnd[0].lon}
                  lat={journeyEnd[0].lat}
                  position={JOURNEY_POSITION.end}
                />
              )}
            </React.Fragment>
          )}
          {eventMarkers}
          {!showGeometricLine && positionMarkers}
          {!!showGeometricLine && (
            <Polyline forwardedRef={ref} polyline={mapBoxCoords} />
          )}
        </Mapbox>
      </div>
      <section aria-label="Map" className="visually-hidden">
        <h2>Event map for current trip.</h2>
        <ul>
          {journey && (
            <li>{`Journey start. latitude: ${journey.start.lat} and longitude: ${journey.start.lon}`}</li>
          )}
          {events?.map((event, index) => (
            <li
              key={`accessibility-${event.eventType}-${index}`}>{`${event.eventType} event occurred at latitude: ${event.lat} and longitude: ${event.lon}`}</li>
          ))}
          {journey && (
            <li>{`Journey end. latitude: ${journey.end.lat} and longitude: ${journey.end.lon}`}</li>
          )}
        </ul>
      </section>
    </Card>
  );
};

export default React.memo(Map);
