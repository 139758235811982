import React, { useCallback, useState } from 'react';

import { HistoryType } from './constants';
import { ReactComponent as ClockIcon } from 'modules/theme/images/icons/card/clock.svg';
import {
  UsePolicyHolderMessages,
  UsePolicyHolderRewards,
} from 'modules/redux/states/engagement/hooks';
import Buttons from './Buttons';
import Card from 'components/Card';
import Messages from './Messages';
import Rewards from './Rewards';

import styles from './styles.module.scss';

const historyList = (
  historyType: HistoryType,
  messages: UsePolicyHolderMessages,
  rewards: UsePolicyHolderRewards,
) => {
  switch (historyType) {
    case HistoryType.MESSAGES:
      return <Messages {...messages} />;
    case HistoryType.REWARDS:
      return <Rewards {...rewards} />;
    default:
      return null;
  }
};

type HistoryProps = {
  messages: UsePolicyHolderMessages;
  rewards: UsePolicyHolderRewards;
};

const History: React.FunctionComponent<HistoryProps> = ({
  messages,
  rewards,
}) => {
  const [historyType, setHistoryType] = useState<HistoryType>(
    HistoryType.REWARDS,
  );
  const handleClick = useCallback(
    (historyType: HistoryType) => setHistoryType(historyType),
    [setHistoryType],
  );

  return (
    <Card
      className={styles.history}
      renderHeader={() => (
        <React.Fragment>
          <ClockIcon />
          <Buttons
            handleClick={handleClick}
            historyType={historyType}
            isLoading={messages.isLoading}
          />
        </React.Fragment>
      )}
      title="History">
      {historyList(historyType, messages, rewards)}
    </Card>
  );
};

export default React.memo(History);
