import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import notificationPointsAdded from 'modules/notifications/templates/notificationPointsAdded';
import notificationPointsRemoved from 'modules/notifications/templates/notificationPointsRemoved';
import { AuthAction } from 'modules/auth/constants';
import { AuthActionType } from 'modules/auth/types';
import { dispatchAbort, Dispatch, RootState } from 'modules/redux';
import { EngagementAction } from './constants';
import { EngagementActionType } from './types';
import { engagementLogoutAction, engagementResetAction } from './actions';
import { engagementGetMessagesEmailTemplateAction } from './messages/actions';
import { notificationShowAction } from 'modules/notifications/actions';
import { RouterActionType } from 'modules/router/types';
import {
  shouldResetEngagement,
  populatePopup,
  closePopup,
  openPopup,
} from './utils';

const createEngagementMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | EngagementActionType | RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case LOCATION_CHANGE: {
        nextAction = next(action);
        shouldResetEngagement(getState().router) &&
          dispatch(engagementResetAction());
        break;
      }

      case EngagementAction.OPEN_MESSAGES_EMAIL_TEMPLATE: {
        nextAction = next(action);
        const endUserId = getState().engagement.details.data?.data.endUserId;
        const message = getState().engagement.messages.data?.data.find(
          message => message.messageId === action.id,
        );
        message?.messageBody
          ? openPopup(message)
          : !!message &&
            endUserId &&
            dispatch(
              engagementGetMessagesEmailTemplateAction({
                endUserId,
                id: message.messageId,
                popup: openPopup(),
              }),
            );
        break;
      }

      case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_SUCCESS: {
        nextAction = next(action);
        const message = getState().engagement.messages.data?.data.find(
          message => message.messageId === action.request.id,
        );
        !!message && populatePopup(message, action.request.popup);
        break;
      }

      case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_FAILURE: {
        closePopup(action.request.popup);
        break;
      }

      case EngagementAction.POST_ADD_POINTS_SUCCESS: {
        dispatch(notificationShowAction(notificationPointsAdded));
        break;
      }

      case EngagementAction.POST_REMOVE_POINTS_SUCCESS: {
        dispatch(notificationShowAction(notificationPointsRemoved));
        break;
      }

      case EngagementAction.RESET: {
        const { auth, engagement } = getState();
        if (auth.isAuthenticated) {
          dispatchAbort([
            engagement.additionalDetails,
            engagement.brand,
            engagement.details,
            engagement.messages,
            engagement.points,
            engagement.report,
            engagement.rewards,
            engagement.users,
          ]);
        } else return;
        break;
      }

      case AuthAction.LOGOUT: {
        const engagement = getState().engagement;
        dispatchAbort([
          engagement.additionalDetails,
          engagement.brand,
          engagement.details,
          engagement.messages,
          engagement.points,
          engagement.report,
          engagement.rewards,
          engagement.users,
        ]);
        nextAction = next(action);
        dispatch(engagementLogoutAction());
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default createEngagementMiddleware;
