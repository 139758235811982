import React from 'react';

import Chevron from './Chevron';
import Button from 'components/Button';
import { UsePolicyHolderUsers } from 'modules/redux/states/engagement/hooks';

type PolicyHoldersItemProps = {
  email: string;
  firstName: string;
  handleClick: UsePolicyHolderUsers['handleClick'];
  isLoading: boolean;
  lastName: string;
  active: string;
  policyNumber: string;
  rewardId: string;
};

const PolicyHoldersItem: React.FunctionComponent<PolicyHoldersItemProps> = ({
  email,
  firstName,
  handleClick,
  isLoading,
  policyNumber,
  lastName,
  active,
  rewardId,
}) => {
  return (
    <Button
      ariaLabel={`${firstName} ${lastName}`}
      disabled={isLoading}
      onClick={() => handleClick(rewardId)}>
      <span>{firstName}</span>
      <span>{lastName}</span>
      <span>{email}</span>
      <span>{active}</span>
      <span>{policyNumber}</span>
      <span>
        {rewardId}
        <Chevron />
      </span>
    </Button>
  );
};

export default React.memo(PolicyHoldersItem);
