import { useMemo } from 'react';

import { ConfigTripsApplication } from 'modules/redux/states/config/types';
import { useConfigSelector } from 'modules/redux/states/config/hooks';

export type UseReportType = (
  key: string,
) => ConfigTripsApplication['reports']['reportTypes'][0];

const useReportType: UseReportType = (key: string) => {
  const {
    tripsApplication: {
      reports: { reportTypes },
    },
  } = useConfigSelector();

  const reportType = useMemo(
    () => reportTypes.find(item => item.key === key),
    [key, reportTypes],
  );

  return reportType as ReturnType<UseReportType>;
};

export default useReportType;
