import React from 'react';
import { Marker } from '@react-google-maps/api';

type JourneyMarkerProps = {
  position: google.maps.LatLngLiteral;
  start?: boolean;
};

const JourneyMarker: React.FunctionComponent<JourneyMarkerProps> = ({
  position,
  start,
}) => {
  return (
    <React.Fragment>
      <Marker
        icon={{
          anchor: new google.maps.Point(105, 108),
          fillColor: '#358FFC',
          fillOpacity: 1,
          path: 'M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0',
          scale: 0.13,
          strokeWeight: 0,
        }}
        position={position}
        zIndex={0}
      />
      {start && (
        <Marker
          icon={{
            anchor: new google.maps.Point(110, 110),
            fillColor: 'white',
            fillOpacity: 1,
            path: 'M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0',
            scale: 0.065,
            strokeWeight: 0,
          }}
          position={position}
          zIndex={1}
        />
      )}
    </React.Fragment>
  );
};
export default React.memo(JourneyMarker);
