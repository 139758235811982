export enum PolicyAction {
  GET_DETAILS_STARTED = 'POLICY_GET_DETAILS_STARTED',
  GET_DETAILS_SUCCESS = 'POLICY_GET_DETAILS_SUCCESS',
  GET_DETAILS_FAILURE = 'POLICY_GET_DETAILS_FAILURE',

  GET_SCORES_STARTED = 'POLICY_GET_SCORES_STARTED',
  GET_SCORES_SUCCESS = 'POLICY_GET_SCORES_SUCCESS',
  GET_SCORES_FAILURE = 'POLICY_GET_SCORES_FAILURE',

  GET_SEARCH_STARTED = 'POLICY_GET_SEARCH_STARTED',
  GET_SEARCH_SUCCESS = 'POLICY_GET_SEARCH_SUCCESS',
  GET_SEARCH_FAILURE = 'POLICY_GET_SEARCH_FAILURE',

  GET_TRIP_STARTED = 'POLICY_GET_TRIP_STARTED',
  GET_TRIP_SUCCESS = 'POLICY_GET_TRIP_SUCCESS',
  GET_TRIP_FAILURE = 'POLICY_GET_TRIP_FAILURE',

  PUT_TRIP_STARTED = 'POLICY_PUT_TRIP_STARTED',
  PUT_TRIP_SUCCESS = 'POLICY_PUT_TRIP_SUCCESS',
  PUT_TRIP_FAILURE = 'POLICY_PUT_TRIP_FAILURE',

  GET_TRIPS_STARTED = 'POLICY_GET_TRIPS_STARTED',
  GET_TRIPS_SUCCESS = 'POLICY_GET_TRIPS_SUCCESS',
  GET_TRIPS_FAILURE = 'POLICY_GET_TRIPS_FAILURE',

  PAGING_TRIPS_STARTED = 'POLICY_PAGING_TRIPS_STARTED',
  PAGING_TRIPS_SUCCESS = 'POLICY_PAGING_TRIPS_SUCCESS',
  PAGING_TRIPS_FAILURE = 'POLICY_PAGING_TRIPS_FAILURE',

  LOGOUT = 'POLICY_LOGOUT',

  RESET = 'POLICY_RESET',
}
