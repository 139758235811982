import dayjs from 'dayjs';

import { AggregateType } from 'modules/redux/states/policy/scores/constants';

export const getXAxisTick = (
  index: number,
  value: string,
  aggregateType?: AggregateType | null,
  startDate?: string | null,
  endDate?: string | null,
) => {
  const year = dayjs(endDate).diff(dayjs(startDate), 'day') > 365;
  const monthly =
    year || index === 0
      ? dayjs(value).format('MMM YY')
      : dayjs(value).format('MMM');
  const daily =
    year || index === 0
      ? dayjs(value).format('D/M/YY')
      : dayjs(value).format('D/M');

  switch (aggregateType) {
    case AggregateType.MONTHLY:
      return monthly;
    case AggregateType.YTD:
    case AggregateType.WEEKLY:
    case AggregateType.DAILY:
      return daily;
    default:
      return value;
  }
};
