import React, { useEffect, useState } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { ReactComponent as Cross } from 'modules/theme/images/icons/drawer/cross.svg';
import {
  useHandleGet,
  useHandleError,
  usePolicyHolderAdditionalDetailsSelector,
  useHandlePaging,
  useHandleUpdateField,
} from './hooks';
import { useModalConfirm } from 'modules/modals/hooks';
import AdditionalDetails from './components/AdditionalDetails';
import Button from 'components/Button';
import ErrorMessage from './components/ErrorMessage';
import NoResults from './components/NoResults';
import Skeleton from './components/Skeleton';
import useHandleSendingError from './hooks/useHandleSendingError';

type TemplateProps = {
  active: boolean;
  onClose: () => void;
  payload?: Record<string, unknown>;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  active,
  onClose,
  payload,
}) => {
  const [activeAdditionalDetail, setActiveAdditionalDetail] = useState<
    string | null
  >(null);
  const dispatch: Dispatch = useDispatch();
  const { showModalConfirm } = useModalConfirm();

  const {
    data,
    dataKeyName,
    isError,
    isLoading,
    isSending,
    isSendingError,
    page,
    pageSize,
    value,
  } = usePolicyHolderAdditionalDetailsSelector();

  const handleError = useHandleError({
    dispatch,
    page,
    pageSize,
    payload,
  });
  const handleGet = useHandleGet({
    dispatch,
    payload,
  });
  const handlePaging = useHandlePaging({
    dispatch,
    page,
    pageSize,
    payload,
  });
  const handleUpdateField = useHandleUpdateField({
    dispatch,
    payload,
  });
  const handleSendError = useHandleSendingError({
    dataKeyName,
    dispatch,
    handleUpdateField,
    isSendingError,
    showModalConfirm,
    value,
  });

  useEffect(() => {
    !data && handleGet();
  }, [data, handleGet]);

  useEffect(() => {
    isSendingError && handleSendError();
  }, [handleSendError, isSendingError]);

  return (
    <React.Fragment>
      <header>
        <h2>Additional Details</h2>
        <Button
          ariaLabel="Close Additional Details Drawer"
          disabled={
            !active || isLoading || isSending || !!activeAdditionalDetail
          }
          onClick={onClose}>
          <Cross />
        </Button>
      </header>
      {isError ? (
        <ErrorMessage handleError={handleError} isError={isError} />
      ) : isLoading ? (
        <Skeleton />
      ) : !data || !data?.data.userData.length ? (
        <NoResults />
      ) : (
        <AdditionalDetails
          activeAdditionalDetail={activeAdditionalDetail}
          additionalDetails={data.data.userData}
          dataKeyName={dataKeyName}
          handlePaging={handlePaging}
          handleUpdateField={handleUpdateField}
          isLoading={isLoading}
          isSending={isSending}
          isSendingError={isSendingError}
          page={page}
          pagingMessage={data?.message}
          pageSize={pageSize}
          setActiveAdditionalDetail={setActiveAdditionalDetail}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(Template);
