import { ModalAnimation, ModalAnimationOut } from './constants';

export const getAnimationOut = (animation: ModalAnimation) => {
  switch (animation) {
    case ModalAnimation.SLIDE_UP:
      return ModalAnimationOut.SLIDE_DOWN;
    case ModalAnimation.ZOOM_IN:
      return ModalAnimationOut.ZOOM_OUT;
    default:
      return null;
  }
};
