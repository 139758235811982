import React from 'react';
import clsx from 'clsx';

import { FieldsetProps } from './types';
import { FontSize } from 'components/Card/constants';

import styles from './styles.module.scss';

const Fieldset: React.FunctionComponent<FieldsetProps> = ({
  className,
  children,
  fontSize = FontSize.SMALL,
  title,
}) => {
  const classNames = clsx(styles.fieldset, styles[`${fontSize}`], className);

  return (
    <fieldset className={classNames}>
      {title && (
        <legend>
          <h2>{title}</h2>
        </legend>
      )}
      {children}
    </fieldset>
  );
};

export default React.memo(Fieldset);
