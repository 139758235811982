import { API } from 'modules/api';

import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import {
  EngagementGetBrandAction,
  EngagementGetBrandFailureAction,
  EngagementGetBrandStartedAction,
  EngagementGetBrandSuccessAction,
} from './types';

export type { EngagementGetBrandActionApiRequest } from './types';

export const engagementGetBrandAction: EngagementGetBrandAction = () =>
  createDefaultAction({
    actionStarted: engagementGetBrandStartedAction,
    actionSuccess: engagementGetBrandSuccessAction,
    actionFailure: engagementGetBrandFailureAction,
    endpoint: API.GET_ENGAGEMENT_BRAND,
    isActive: state => !!state.engagement.brand.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      actionId === state.engagement.brand.actionId,
    request: undefined,
  });

const engagementGetBrandStartedAction: EngagementGetBrandStartedAction =
  payload => ({
    type: EngagementAction.GET_BRAND_STARTED,
    ...payload,
  });

const engagementGetBrandSuccessAction: EngagementGetBrandSuccessAction =
  payload => ({
    type: EngagementAction.GET_BRAND_SUCCESS,
    ...payload,
  });

const engagementGetBrandFailureAction: EngagementGetBrandFailureAction =
  payload => ({
    type: EngagementAction.GET_BRAND_FAILURE,
    ...payload,
  });
