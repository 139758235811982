import React, { useMemo } from 'react';
import clsx from 'clsx';

import Button from 'components/Button';
import { ConfigUnitsDistance } from 'modules/redux/states/config/constants';
import { ReactComponent as DistanceSVG } from 'modules/theme/images/icons/events/distance.svg';
import { ReactComponent as DurationSVG } from 'modules/theme/images/icons/events/clock.svg';
import { TripsResultsType } from 'modules/redux/states/policy/trips/types';
import { useDates } from './hooks';
import useConfigSelector from 'modules/redux/states/config/hooks/useConfigSelector';
import { UsePolicyTrips } from 'modules/redux/states/policy/hooks/usePolicySummary';

import styles from './styles.module.scss';

type TripsListItemProps = TripsResultsType & {
  handleClick: UsePolicyTrips['handleClick'];
  selected: boolean;
};

const TripsListItem: React.FunctionComponent<TripsListItemProps> = ({
  distance,
  duration,
  endDateTime,
  handleClick,
  selected,
  scores,
  startDateTime,
  state,
  subjectId,
  tripId,
}) => {
  const { endDate, startDate } = useDates(endDateTime, startDateTime);
  const { units } = useConfigSelector();

  const classNames = clsx(
    styles['trips-summary-item'],
    !state.isValid && styles.invalid,
    selected && styles.selected,
  );

  const distanceFormatted = useMemo(() => {
    const converted = Math.round((distance / 1.609) * 100) / 100;
    return units.distance === ConfigUnitsDistance.KM
      ? `${distance} km`
      : `${converted} ${converted === 1 ? 'mile' : 'miles'}`;
  }, [distance, units.distance]);

  const durationFormatted = useMemo(() => {
    const minutes = Math.floor(duration / 60);
    return minutes > 0
      ? `${minutes} ${minutes === 1 ? 'min' : 'mins'}`
      : `${duration} ${duration === 1 ? 'sec' : 'seconds'}`;
  }, [duration]);

  return (
    <li className={classNames}>
      <Button onClick={() => handleClick({ subjectId, tripId })}>
        <span>
          <time dateTime={startDateTime}>{startDate}</time> -{' '}
          <time dateTime={endDateTime}>{endDate}</time>
        </span>
        <span>
          <span>
            <DurationSVG /> {durationFormatted}
          </span>
          <span>
            <DistanceSVG /> {distanceFormatted}
          </span>
          {!state.isValid && (
            <span>
              <span>Invalidated</span>
            </span>
          )}
        </span>
        {scores && typeof scores.overall === 'number' && (
          <span>{scores.overall}</span>
        )}
      </Button>
    </li>
  );
};

export default React.memo(TripsListItem);
