import { useCallback, useEffect, useRef, useState } from 'react';

import { getTemplate } from '../templates';
import { Notification } from '../types';
import { NotificationTemplate } from '../constants';
import useNotificationAction from './useNotificationAction';

export type ShowNotificationProps = Omit<
  Notification,
  'destroyNotification' | 'hideNotification' | 'id' | 'visible'
> & {
  template?: NotificationTemplate;
};

export type UseNotification = () => {
  hideAllNotifications: () => void;
  showNotification?: (props: ShowNotificationProps) => void;
};

const useNotification: UseNotification = () => {
  const { notificationHideAll, notificationShow } = useNotificationAction();
  const [notifications, setNotifications] = useState<string | null>();
  const notificationRef = useRef<() => void>();

  useEffect(() => {
    notificationRef.current = () => {
      notifications && notificationHideAll();
    };
  }, [notifications, notificationHideAll]);

  const hideAllNotifications = useCallback(() => {
    notificationRef.current && notificationRef.current();
  }, []);

  const showNotification = useCallback(
    ({ template, ...props }: ShowNotificationProps) => {
      setNotifications(
        notificationShow({
          ...getTemplate(template),
          ...props,
        }),
      );
    },
    [notificationShow],
  );

  useEffect(() => {
    return hideAllNotifications;
  }, [hideAllNotifications]);

  return {
    hideAllNotifications,
    showNotification,
  };
};

export default useNotification;
