import React from 'react';
import { Marker } from '@react-google-maps/api';
import clsx from 'clsx';

type GoogleMarkerProps = {
  isHover?: boolean;
  isActive?: boolean;
  label: number;
  position: google.maps.LatLngLiteral;
  zIndex: number;
};

const GoogleMarker: React.FunctionComponent<GoogleMarkerProps> = ({
  isHover,
  isActive,
  label,
  position,
  zIndex,
}) => {
  const className = clsx(
    isActive && 'google-marker-active',
    !isActive && 'google-marker',
    isHover && 'google-marker-hover',
  );
  return (
    <Marker
      icon={{
        anchor: new google.maps.Point(49, 137),
        fillOpacity: 0,
        labelOrigin: new google.maps.Point(51, 63),
        path: 'M100,52.4913C100,81.4795,50,125,50,125S0,81.4795,0,52.4913S22.3932,0,50,0S100,23.5033,100,52.4913z',
        scale: 0.24,
        strokeWeight: 0,
      }}
      label={{
        className: className,
        color: '#ffffff',
        fontFamily: 'Lato',
        fontWeight: 'normal',
        text: isActive ? '0' : label.toString(),
      }}
      position={position}
      zIndex={zIndex}
    />
  );
};
export default React.memo(GoogleMarker);
