import { useCallback, useEffect, useRef, useState } from 'react';

export const useSecondaryNav = () => {
  const secondaryNavRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    handleMenuAnimationStart();
    if (!open) {
      document.documentElement.classList.add('menu-open');
      setOpen(true);
    } else {
      document.documentElement.classList.remove('menu-open');
      setOpen(false);
    }
  }, [open]);

  const handleClose = useCallback(() => {
    if (open) {
      handleMenuAnimationStart();
      document.documentElement.classList.remove('menu-open');
      setOpen(false);
    }
  }, [open]);

  const handleMenuAnimationStart = () => {
    document.documentElement.classList.add('animating-menu');
  };
  const handleMenuAnimationEnd = () => {
    document.documentElement.classList.remove('animating-menu');
  };

  useEffect(() => {
    const element = secondaryNavRef.current;
    element?.addEventListener('transitionend', handleMenuAnimationEnd);
    return () => {
      element?.removeEventListener('transitionend', handleMenuAnimationEnd);
    };
  }, [open]);

  return { handleClick, handleClose, mainRef, open, secondaryNavRef };
};
