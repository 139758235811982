import { ModalConfirmTemplateProps } from '.';
import { ModalErrorTitle } from 'modules/modals/components/ModalErrorMessage';

const template: ModalConfirmTemplateProps = {
  ariaLabel: 'Policy Holder Additional Details Sending Error',
  cancelLabel: 'Cancel',
  confirmLabel: 'Retry',
  renderHeader: () => <ModalErrorTitle />,
};

export default template;
