import { useCallback, useEffect, useRef, useState } from 'react';

import { apiAbort, newAbortController } from 'modules/api/utils';
import { Dispatch, RootState, useDispatch } from 'modules/redux';
import { CallbackActionPayload } from './factories/createCallbackAction/types';

type DispatchWithCallback<Request, Response> = (
  action: (
    payload: CallbackActionPayload<Request, Response>,
  ) => (dispatch: Dispatch, getState: () => RootState) => Promise<void>,
  payload: Omit<
    CallbackActionPayload<Request, Response>,
    'controller' | 'isPresent'
  >,
) => Promise<void>;

export const useDispatchWithCallback = <Request, Response>() => {
  const [controller] = useState(newAbortController());
  const dispatch: Dispatch = useDispatch();
  const mounted = useRef(true);

  const isPresent = useCallback(() => mounted.current, []);

  const customDispatch: DispatchWithCallback<Request, Response> = useCallback(
    async (action, payload) =>
      dispatch(action({ controller, isPresent, ...payload })),
    [controller, dispatch, isPresent],
  );

  useEffect(() => {
    return () => {
      mounted.current = false;
      apiAbort(controller);
    };
  }, [controller]);

  return customDispatch;
};
