import { useEffect } from 'react';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { policyGetTripsAction } from 'modules/redux/states/policy/actions';
import {
  useHandleClick,
  useHandleError,
  useHandlePaging,
  useHandleSearch,
} from './hooks';
import { UsePolicyTrips } from '../types';

const usePolicyTrips = (policyId?: string): UsePolicyTrips => {
  const dispatch: Dispatch = useDispatch();

  const {
    data,
    isError,
    isLoading,
    isSending,
    isSendingError,
    startDate,
    endDate,
  } = useSelector(state => state.policy.trips, shallowEqual);

  const pageAfter = data?.paging.cursors.after;

  useEffect(() => {
    if (policyId) dispatch(policyGetTripsAction({ policyId }));
  }, [dispatch, policyId]);

  const handleClick = useHandleClick({ policyId });

  const handleSearch = useHandleSearch({ dispatch, policyId });

  const handlePaging = useHandlePaging({
    dispatch,
    endDate,
    pageAfter,
    policyId,
    startDate,
  });

  const handleError = useHandleError({
    dispatch,
    endDate,
    isSendingError,
    pageAfter,
    policyId,
    startDate,
  });

  return {
    data,
    endDate,
    handleClick,
    handleError,
    handlePaging: pageAfter ? handlePaging : undefined,
    handleSearch,
    isError,
    isLoading: !data || !!isLoading || !policyId,
    isSending: !!isSending,
    isSendingError,
    startDate,
  };
};

export default usePolicyTrips;
