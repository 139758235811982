import React, { useRef } from 'react';
import clsx from 'clsx';

import { ReactComponent as Edit } from 'modules/theme/images/icons/drawer/edit.svg';
import { useInput } from '../AdditionalDetailFieldInput/hooks';
import AdditionalDetailFieldInput from '../AdditionalDetailFieldInput';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

type AdditionalDetailFieldProps = {
  active: boolean;
  disabled?: boolean;
  handleUpdateField: (dataKeyName: string, value: string) => void;
  isSending: boolean;
  isSendingError: Error | false;
  keyName: string;
  setActiveAdditionalDetail: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  value: string;
};

const AdditionalDetailField: React.FunctionComponent<
  AdditionalDetailFieldProps
> = ({
  active,
  disabled,
  handleUpdateField,
  isSending,
  isSendingError,
  keyName,
  setActiveAdditionalDetail,
  value: fieldValue,
}) => {
  const entryRef = useRef<HTMLLIElement | null>(null);
  const classNames = clsx(
    styles['additional-detail-field'],
    isSending && styles.sending,
  );

  const {
    handleCancelChanges,
    handleChange,
    handleKeyDown,
    handleSaveChanges,
    value,
  } = useInput(
    active,
    entryRef,
    fieldValue,
    handleUpdateField,
    isSending,
    isSendingError,
    keyName,
    setActiveAdditionalDetail,
  );
  return (
    <li className={classNames} ref={entryRef}>
      <div>{keyName}</div>
      {isSending && active ? (
        <div className={styles.saving}>
          <Spinner />
          <p>Saving...</p>
        </div>
      ) : active ? (
        <AdditionalDetailFieldInput
          handleCancelChanges={handleCancelChanges}
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          handleSaveChanges={handleSaveChanges}
          keyName={keyName}
          value={value}
        />
      ) : (
        <React.Fragment>
          <div>{fieldValue}</div>
          <Button
            ariaLabel={!disabled ? `Edit ${keyName} field` : undefined}
            disabled={disabled}
            onClick={() => setActiveAdditionalDetail(keyName)}>
            <Edit />
          </Button>
        </React.Fragment>
      )}
    </li>
  );
};

export default React.memo(AdditionalDetailField);
