import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { BreadcrumbType } from './types';
import { routes, ROUTE } from 'modules/router/constants';
import { RouteType } from 'modules/router/types';
import * as Hyperlink from 'components/Hyperlink';

import styles from './styles.module.scss';

const Breadcrumb: React.FunctionComponent = () => {
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: [ROUTE.LOGIN, ROUTE.POLICY_SUMMARY_SUBJECT],
  });
  const domain = window.location.protocol + '//' + window.location.host;
  return breadcrumbs.length > 1 ? (
    <nav aria-label="Breadcrumb" className={styles.breadcrumb}>
      <ol itemScope={true} itemType="https://schema.org/BreadcrumbList">
        {breadcrumbs.map((breadcrumb: BreadcrumbType, key: number) => (
          <li
            itemProp="itemListElement"
            itemScope={true}
            itemType="https://schema.org/ListItem"
            key={breadcrumb.key}>
            {key + 1 === breadcrumbs.length ? (
              <React.Fragment>
                <span itemProp="name">
                  {(breadcrumb.match.route as RouteType)?.title}
                </span>
                <meta content={`${key + 1}`} itemProp="position" />
              </React.Fragment>
            ) : (
              <Hyperlink.NavigationLink
                itemID={domain + breadcrumb.match.pathname}
                itemProp="item"
                itemScope={true}
                itemType="https://schema.org/WebPage"
                route={breadcrumb.match.pathname}>
                <span itemProp="name">
                  {(breadcrumb.match.route as RouteType)?.title}
                </span>
                <meta content={`${key + 1}`} itemProp="position" />
              </Hyperlink.NavigationLink>
            )}
          </li>
        ))}
      </ol>
    </nav>
  ) : null;
};

export default React.memo(Breadcrumb);
