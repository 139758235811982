import { useCallback } from 'react';

import {
  engagementPostAdditionalDetailsAction,
  EngagementPostAdditionalDetailsActionApiRequest,
} from 'modules/redux/states/engagement/additionalDetails/actions';
import { UseHandleUpdateField } from './types';

const useHandleUpdateField: UseHandleUpdateField = ({ dispatch, payload }) =>
  useCallback(
    (dataKeyName, value) => {
      !!payload &&
        dispatch(
          engagementPostAdditionalDetailsAction({
            ...payload,
            value,
            dataKeyName,
          } as EngagementPostAdditionalDetailsActionApiRequest),
        );
    },
    [dispatch, payload],
  );

export default useHandleUpdateField;
