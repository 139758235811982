import { Engagement, EngagementActionType } from '../types';
import { EngagementAction } from '../constants';
import pointsGetReducer from './actions/engagementGetPointsAction/reducer';
import pointsPostAddReducer from './actions/engagementPostAddPointsAction/reducer';
import pointsPostRemoveReducer from './actions/engagementPostRemovePointsAction/reducer';

function pointsReducer(state: Engagement, action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.GET_POINTS_FAILURE:
    case EngagementAction.GET_POINTS_STARTED:
    case EngagementAction.GET_POINTS_SUCCESS:
      return pointsGetReducer(state, action);

    case EngagementAction.POST_ADD_POINTS_FAILURE:
    case EngagementAction.POST_ADD_POINTS_STARTED:
    case EngagementAction.POST_ADD_POINTS_SUCCESS:
      return pointsPostAddReducer(state, action);

    case EngagementAction.POST_REMOVE_POINTS_FAILURE:
    case EngagementAction.POST_REMOVE_POINTS_STARTED:
    case EngagementAction.POST_REMOVE_POINTS_SUCCESS:
    case EngagementAction.POST_SEND_POINTS_RESET_ERROR:
      return pointsPostRemoveReducer(state, action);

    default:
      return state;
  }
}

export default pointsReducer;
