export const parseValueOnChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  max: number,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
) => {
  const reg = new RegExp('^[0-9]+$');
  if (
    event.target.value.match(reg) ||
    event.target.value === '' ||
    event.target.value.length > max.toString().length
  ) {
    event.target.value = isNaN(parseInt(event.target.value))
      ? event.target.value
      : parseInt(event.target.value).toString();
    onChange(event);
  } else {
    event.preventDefault();
  }
};

export const parseValueOnKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  max: number,
  value: string,
) => {
  if (
    !(event.key >= '0' && event.key <= '9') &&
    event.key !== 'Enter' &&
    event.key !== 'Backspace' &&
    event.key !== 'Tab'
  ) {
    event.preventDefault();
  }
  if (
    value.length > max.toString().length - 1 &&
    event.key !== 'Enter' &&
    event.key !== 'Backspace'
  )
    event.preventDefault();
};
