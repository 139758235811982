import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import {
  eventsPutAction,
  EventsPutActionApiRequest,
} from 'modules/redux/states/events/actions';
import { ROUTE } from 'modules/router/constants';

import Confirm from '../components/Confirm';
import Failure from '../components/Failure';
import Success from '../components/Success';

type TemplateProps = {
  active: boolean;
  onClose: () => void;
  payload?: Record<string, unknown>;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  active,
  onClose,
  payload,
}) => {
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isSending, isSendingError } = useSelector(
    state => state.events,
    shallowEqual,
  );

  const handleConfirm = useCallback(
    () =>
      !!payload &&
      dispatch(
        eventsPutAction({
          ...payload,
          reason: '',
        } as EventsPutActionApiRequest),
      ),
    [dispatch, payload],
  );

  const handleSuccess = useCallback(() => {
    navigate(ROUTE.EVENTS, { replace: true });
  }, [navigate]);

  return isSendingError ? (
    <Failure
      active={active}
      isError={isSendingError}
      onCancel={onClose}
      onError={handleConfirm}
    />
  ) : typeof data?.approved !== 'boolean' ? (
    <Confirm
      active={active}
      isLoading={!!isSending}
      onCancel={onClose}
      onConfirm={handleConfirm}
      text="Click below to approve the event."
      title="Are you sure?"
    />
  ) : (
    <Success
      active={active}
      label="Next Event"
      onSuccess={handleSuccess}
      text="The event was approved, click below to receive another event."
      title="Event approved"
    />
  );
};
export default React.memo(Template);
