import { shallowEqual, useSelector } from 'modules/redux';
import { UsePolicyHolderAdditionalDetailsSelector } from './types';

const usePolicyHolderAdditionalDetailsSelector: UsePolicyHolderAdditionalDetailsSelector =
  () => {
    const {
      data,
      dataKeyName,
      isError,
      isLoading,
      isSending,
      isSendingError,
      page,
      pageSize,
      value,
    } = useSelector(state => state.engagement.additionalDetails, shallowEqual);

    return data
      ? {
          data,
          dataKeyName,
          isError,
          isLoading: !!isLoading,
          isSending: !!isSending,
          isSendingError,
          page,
          pageSize,
          value,
        }
      : {
          data: null,
          dataKeyName: undefined,
          isError,
          isLoading: true,
          isSending: !!isSending,
          isSendingError,
          page: 1,
          pageSize: 20,
          value: undefined,
        };
  };

export default usePolicyHolderAdditionalDetailsSelector;
