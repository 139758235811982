import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from '../Notifications/styles.module.scss';

const DEFAULT_DELAY = 5000;

export const useNotification = (
  destroyNotification: () => void,
  hideNotification: () => void,
  visible: boolean,
  index?: number,
  pause?: boolean,
  total?: number,
) => {
  const [delay, setDelay] = useState(
    index ? index * DEFAULT_DELAY : DEFAULT_DELAY,
  );
  const [hide, setHide] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const classNames = clsx(visible && styles.visible, !visible && styles.hiding);

  useEffect(() => {
    if (index && total) {
      if (total > 3 && index === 1) {
        setDelay(0);
      } else {
        setDelay(index * DEFAULT_DELAY);
      }
      if (pause && index) {
        setDelay(index * DEFAULT_DELAY);
      }
    }
  }, [index, pause, total]);

  useEffect(() => {
    if (hide && !pause) {
      hideNotification();
      setHide(false);
    }
  }, [hide, hideNotification, pause]);

  useEffect(() => {
    if (!pause) {
      const timer = setTimeout(() => {
        setHide(true);
      }, delay);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [delay, pause]);

  const handleAnimationEnd = useCallback(
    (event: AnimationEvent) => {
      if (
        event.currentTarget === ref.current &&
        event.animationName === styles['slide-out']
      ) {
        destroyNotification();
      }
    },
    [destroyNotification, ref],
  );

  useEffect(() => {
    const element = ref.current;
    element?.addEventListener('animationend', handleAnimationEnd);
    return () => {
      element?.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [handleAnimationEnd, ref]);

  return {
    classNames,
    ref,
    delay,
  };
};
