import { UsePolicyHolderRewards } from 'modules/redux/states/engagement/hooks';
import React from 'react';

import Component from './Component';
import ErrorMessage from './ErrorMessage';
import NoResults from './NoResults';
import Skeleton from './Skeleton';

type RewardsProps = {
  data: UsePolicyHolderRewards['data'];
  handleError: UsePolicyHolderRewards['handleError'];
  handlePaging: UsePolicyHolderRewards['handlePaging'];
  isError: UsePolicyHolderRewards['isError'];
  isLoading: UsePolicyHolderRewards['isLoading'];
  page: UsePolicyHolderRewards['page'];
  pageSize: UsePolicyHolderRewards['pageSize'];
};

const Rewards: React.FunctionComponent<RewardsProps> = ({
  data,
  handleError,
  handlePaging,
  isError,
  isLoading,
  page,
  pageSize,
}) => {
  return isError ? (
    <ErrorMessage handleError={handleError} isError={isError} />
  ) : isLoading ? (
    <Skeleton />
  ) : !data ? (
    <NoResults />
  ) : (
    <Component
      data={data}
      handlePaging={handlePaging}
      isLoading={isLoading}
      page={page}
      pageSize={pageSize}
    />
  );
};

export default React.memo(Rewards);
