import produce from 'immer';

import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import { PointType } from 'modules/modals/templates/custom/modalAddRemovePoints/Template/constants';

function pointsPostRemoveReducer(
  state: Engagement,
  action: EngagementActionType,
) {
  switch (action.type) {
    case EngagementAction.POST_REMOVE_POINTS_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.points.actionId = null;
        draftState.points.isSending = false;
        draftState.points.isSendingError = action.error;

        draftState.points.type = PointType.REMOVE;
        draftState.points.reason = action.request.reason;
        draftState.points.points = action.request.points;
      });

    case EngagementAction.POST_REMOVE_POINTS_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.points.actionId = action.actionId;
        draftState.points.isSending = action.controller || true;
        draftState.points.isSendingError = false;
      });

    case EngagementAction.POST_REMOVE_POINTS_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.points.actionId = null;
        draftState.points.isSending = false;
        draftState.points.isSendingError = false;

        draftState.points.data = null;
        draftState.rewards.data = null;
      });

    case EngagementAction.POST_SEND_POINTS_RESET_ERROR:
      return produce(state, (draftState: Engagement) => {
        draftState.points.isSendingError = false;
      });

    default:
      return state;
  }
}

export default pointsPostRemoveReducer;
