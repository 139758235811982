import { API } from 'modules/api';
import { ConfigAction } from './constants';
import {
  ConfigGetAction,
  ConfigGetFailureAction,
  ConfigGetStartedAction,
  ConfigGetSuccessAction,
  ConfigLogoutAction,
} from './types';
import { createDefaultAction } from '../../factories';

export const configGetAction: ConfigGetAction = () =>
  createDefaultAction({
    actionStarted: configGetStartedAction,
    actionSuccess: configGetSuccessAction,
    actionFailure: configGetFailureAction,
    endpoint: API.GET_CONFIG,
    isActive: state => !!state.config.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) => actionId === state.config.actionId,
    request: undefined,
  });

const configGetStartedAction: ConfigGetStartedAction = payload => ({
  type: ConfigAction.GET_STARTED,
  ...payload,
});

const configGetSuccessAction: ConfigGetSuccessAction = payload => ({
  type: ConfigAction.GET_SUCCESS,
  ...payload,
});

const configGetFailureAction: ConfigGetFailureAction = payload => ({
  type: ConfigAction.GET_FAILURE,
  ...payload,
});

export const configLogoutAction: ConfigLogoutAction = () => ({
  type: ConfigAction.LOGOUT,
});
