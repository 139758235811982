import { EventsAction } from '../constants';
import { EventsLogoutAction, EventsResetAction } from '../types';

export {
  eventsGetAction,
  type EventsGetActionApiRequest,
} from './eventsGetAction';

export {
  eventsPutAction,
  type EventsPutActionApiRequest,
} from './eventsPutAction';

export const eventsResetAction: EventsResetAction = () => ({
  type: EventsAction.RESET,
});

export const eventsLogoutAction: EventsLogoutAction = () => ({
  type: EventsAction.LOGOUT,
});
