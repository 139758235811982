import React, { useCallback } from 'react';

import { ModalCustomTemplate, useModalCustom } from 'modules/modals/hooks';
import { UsePolicyHolderPoints } from 'modules/redux/states/engagement/hooks';
import Component from './Component';
import ErrorMessage from './ErrorMessage';
import Skeleton from './Skeleton';

type PointsBalanceProps = {
  data: UsePolicyHolderPoints['data'];
  handleError: UsePolicyHolderPoints['handleError'];
  isError: UsePolicyHolderPoints['isError'];
  isLoading: UsePolicyHolderPoints['isLoading'];
};

const PointsBalance: React.FunctionComponent<PointsBalanceProps> = ({
  data,
  handleError,
  isError,
  isLoading,
}) => {
  const { showModalCustom } = useModalCustom();

  const handleClick = useCallback(() => {
    if (showModalCustom)
      showModalCustom({
        template: ModalCustomTemplate.ADD_REMOVE_POINTS,
      });
  }, [showModalCustom]);

  return isError ? (
    <ErrorMessage handleError={handleError} isError={isError} />
  ) : isLoading ? (
    <Skeleton />
  ) : (
    <Component data={data} handleClick={handleClick} />
  );
};

export default React.memo(PointsBalance);
