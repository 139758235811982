import produce from 'immer';
import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from 'modules/redux/states/engagement/constants';

function messagesEmailTemplateGetReducer(
  state: Engagement,
  action: EngagementActionType,
) {
  switch (action.type) {
    case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.messages.actionId = null;
        draftState.messages.isSendingError = action.error;
        draftState.messages.isSending = false;
      });

    case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.messages.actionId = action.actionId;
        draftState.messages.isSendingError = false;
        draftState.messages.isSending = action.controller || true;

        draftState.messages.id = action.request.id;
      });

    case EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.messages.actionId = null;
        draftState.messages.isSendingError = false;
        draftState.messages.isSending = false;

        const message = draftState.messages.data?.data.find(
          message => message.messageId === action.request.id,
        );
        if (message) message.messageBody = action.data.data.messageBody;
      });

    default:
      return state;
  }
}

export default messagesEmailTemplateGetReducer;
