import React from 'react';

import { UsePolicyHolderDetails } from 'modules/redux/states/engagement/hooks';
import Component from './Component';
import ErrorMessage from './ErrorMessage';
import Skeleton from './Skeleton';

type PolicyDetailsProps = {
  data: UsePolicyHolderDetails['data'];
  handleClick: UsePolicyHolderDetails['handleClick'];
  handleError: UsePolicyHolderDetails['handleError'];
  isError: UsePolicyHolderDetails['isError'];
  isLoading: UsePolicyHolderDetails['isLoading'];
};

const PolicyDetails: React.FunctionComponent<PolicyDetailsProps> = ({
  data,
  handleClick,
  handleError,
  isError,
  isLoading,
}) => {
  return isError ? (
    <ErrorMessage handleError={handleError} isError={isError} />
  ) : isLoading ? (
    <Skeleton />
  ) : (
    <Component data={data} handleClick={handleClick} />
  );
};

export default React.memo(PolicyDetails);
