import React from 'react';

import styles from './styles.module.scss';

const Legend: React.FunctionComponent = () => {
  return (
    <ul className={styles.legend}>
      <li>
        <span></span>
        <span>Road Speed</span>
      </li>
      <li>
        <span></span>
        <span>Travel Speed</span>
      </li>
    </ul>
  );
};

export default React.memo(Legend);
