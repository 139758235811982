import produce from 'immer';

import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from '../../../constants';

function additionalDetailsGetReducer(
  state: Engagement,
  action: EngagementActionType,
) {
  switch (action.type) {
    case EngagementAction.GET_ADDITIONAL_DETAILS_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.additionalDetails.actionId = null;
        draftState.additionalDetails.isError = action.error;
        draftState.additionalDetails.isLoading = false;

        draftState.additionalDetails.data = null;
      });

    case EngagementAction.GET_ADDITIONAL_DETAILS_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.additionalDetails.actionId = action.actionId;
        draftState.additionalDetails.isError = false;
        draftState.additionalDetails.isLoading = action.controller || true;

        draftState.additionalDetails.data = null;
        draftState.additionalDetails.page = action.request.page;
        draftState.additionalDetails.pageSize = action.request.pageSize;
      });

    case EngagementAction.GET_ADDITIONAL_DETAILS_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.additionalDetails.actionId = null;
        draftState.additionalDetails.isError = false;
        draftState.additionalDetails.isLoading = false;

        draftState.additionalDetails.data = action.data;
      });

    default:
      return state;
  }
}

export default additionalDetailsGetReducer;
