export enum ReportsAction {
  GET_LINK_STARTED = 'REPORTS_GET_LINK_STARTED',
  GET_LINK_SUCCESS = 'REPORTS_GET_LINK_SUCCESS',
  GET_LINK_FAILURE = 'REPORTS_GET_LINK_FAILURE',

  GET_POLICY_STARTED = 'REPORTS_GET_POLICY_STARTED',
  GET_POLICY_SUCCESS = 'REPORTS_GET_POLICY_SUCCESS',
  GET_POLICY_FAILURE = 'REPORTS_GET_POLICY_FAILURE',

  GET_STATUS_STARTED = 'REPORTS_GET_STATUS_STARTED',
  GET_STATUS_SUCCESS = 'REPORTS_GET_STATUS_SUCCESS',
  GET_STATUS_FAILURE = 'REPORTS_GET_STATUS_FAILURE',

  POST_STARTED = 'REPORTS_POST_STARTED',
  POST_SUCCESS = 'REPORTS_POST_SUCCESS',
  POST_FAILURE = 'REPORTS_POST_FAILURE',

  LOGOUT = 'REPORTS_LOGOUT',

  RESET = 'REPORTS_RESET',
}

export enum ReportStatus {
  COMPLETE = 'Complete',
  PENDING = 'Pending',
}
