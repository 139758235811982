import React from 'react';
import { FieldValues } from 'react-hook-form';

import { InputPrimitive } from 'components/Inputs/Input';
import { InputType } from 'components/Inputs/Input/constants';
import { QuestionSize } from '../constants';
import { QuestionTextProps } from '../types';
import QuestionTemplate from '.';

function QuestionText<T extends FieldValues>({
  disabled,
  children,
  control,
  id,
  label,
  name,
  readOnly,
  size = QuestionSize.MEDIUM,
  type = InputType.TEXT,
  ...props
}: QuestionTextProps<T>) {
  return (
    <QuestionTemplate<T>
      control={control}
      disabled={disabled}
      id={id}
      label={label}
      name={name}
      readOnly={readOnly}
      size={size}>
      {({ invalid, onBlur, onChange, ref, value }) => (
        <InputPrimitive
          disabled={disabled}
          id={id}
          inputRef={ref}
          invalid={invalid}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          readOnly={readOnly}
          size={size}
          type={type}
          value={value as string}
          {...props}>
          {children}
        </InputPrimitive>
      )}
    </QuestionTemplate>
  );
}

export default React.memo(QuestionText) as typeof QuestionText;
