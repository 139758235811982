import { API } from 'modules/api';
import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from '../../../constants';
import {
  EngagementPostRemovePointsAction,
  EngagementPostRemovePointsStartedAction,
  EngagementPostRemovePointsSuccessAction,
  EngagementPostRemovePointsFailureAction,
  EngagementPostSendPointsResetErrorAction,
} from './types';

export const engagementPostRemovePointsAction: EngagementPostRemovePointsAction =
  request =>
    createDefaultAction({
      actionStarted: engagementPostRemovePointsStartedAction,
      actionSuccess: engagementPostRemovePointsSuccessAction,
      actionFailure: engagementPostRemovePointsFailureAction,
      endpoint: API.POST_ENGAGEMENT_REMOVE_POINTS,
      isActive: state => !!state.engagement.points.isSending,
      isAuthorized: state => state.auth.isAuthenticated,
      isPresent: (actionId, state) =>
        actionId === state.engagement.points.actionId,
      request: request,
    });

const engagementPostRemovePointsStartedAction: EngagementPostRemovePointsStartedAction =
  payload => ({
    type: EngagementAction.POST_REMOVE_POINTS_STARTED,
    ...payload,
  });

const engagementPostRemovePointsSuccessAction: EngagementPostRemovePointsSuccessAction =
  payload => ({
    type: EngagementAction.POST_REMOVE_POINTS_SUCCESS,
    ...payload,
  });

const engagementPostRemovePointsFailureAction: EngagementPostRemovePointsFailureAction =
  payload => ({
    type: EngagementAction.POST_REMOVE_POINTS_FAILURE,
    ...payload,
  });

export const engagementPostSendPointsResetErrorAction: EngagementPostSendPointsResetErrorAction =
  () => ({
    type: EngagementAction.POST_SEND_POINTS_RESET_ERROR,
  });
