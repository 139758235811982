import { v4 as uuidv4 } from 'uuid';

import { Dispatch } from 'modules/redux';
import {
  Notification,
  NotificationDestroyAction,
  NotificationHideAction,
  NotificationInsertAction,
} from './types';
import { NotificationAction } from './constants';

export function notificationShowAction(
  notification: Omit<Notification, 'id' | 'visible'>,
): (dispatch: Dispatch) => string {
  return (dispatch: Dispatch) => {
    (notification as Notification).id = uuidv4();
    (notification as Notification).visible = true;
    dispatch(notificationInsertAction(notification as Notification));
    return (notification as Notification).id;
  };
}

export const notificationDestroyAction: NotificationDestroyAction = (
  id: string,
) => ({
  type: NotificationAction.DESTROY,
  id,
});

export const notificationHideAction: NotificationHideAction = (id: string) => ({
  type: NotificationAction.HIDE,
  id,
});

export const notificationInsertAction: NotificationInsertAction = (
  notification: Notification,
) => ({
  type: NotificationAction.INSERT,
  notification,
});
