import React from 'react';

import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import { ReactComponent as TimeSVG } from 'modules/theme/images/icons/events/clock.svg';
import { WidthPercentage } from 'components/Layout/constants';
import * as Layout from 'components/Layout';
import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';

import styles from './styles.module.scss';

type TemplateProps = {
  handleGetEvent: () => void;
  handleLogout: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  handleGetEvent,
  handleLogout,
}) => {
  return (
    <React.Fragment>
      <PageTitle enableBreadcrumbs={false} title="Events" />
      <Layout.Container>
        <Layout.Column width={WidthPercentage.HUNDRED} scrollable={true}>
          <Card className={styles['events-timeout']}>
            <TimeSVG />
            <h2>Your event has expired due to inactivity</h2>
            <div>
              <Button
                color={ButtonColor.RED}
                label="Log Out"
                onClick={handleLogout}
                style={ButtonStyle.PRIMARY}
              />
              <Button
                color={ButtonColor.BLUE}
                label="Get Event"
                onClick={handleGetEvent}
                style={ButtonStyle.PRIMARY}
              />
            </div>
          </Card>
        </Layout.Column>
      </Layout.Container>
    </React.Fragment>
  );
};

export default Template;
