import { produce } from 'immer';

import { Policy, PolicyActionType } from '../types';
import { PolicyAction } from '../constants';
import { sortDates } from './utils';

function scoresReducer(state: Policy, action: PolicyActionType) {
  switch (action.type) {
    case PolicyAction.GET_SCORES_FAILURE:
      return produce(state, (draftState: Policy) => {
        draftState.scores.actionId = null;
        draftState.scores.isError = action.error;
        draftState.scores.isLoading = false;

        draftState.scores.data = null;
      });

    case PolicyAction.GET_SCORES_STARTED: {
      return produce(state, (draftState: Policy) => {
        draftState.scores.actionId = action.actionId;
        draftState.scores.isError = false;
        draftState.scores.isLoading = action.controller || true;

        draftState.scores.data = null;
      });
    }

    case PolicyAction.GET_SCORES_SUCCESS:
      return produce(state, (draftState: Policy) => {
        draftState.scores.actionId = null;
        draftState.scores.isError = false;
        draftState.scores.isLoading = false;

        draftState.scores.data = sortDates(action.data);
      });

    default:
      return state;
  }
}

export default scoresReducer;
