import React from 'react';

import * as Hyperlink from 'components/Hyperlink';
import Button from 'components/Button';

type NavItemProps = {
  icon: () => React.ReactNode;
  label: string;
  onClick?: () => void;
  route?: string;
};

const NavItem: React.FunctionComponent<NavItemProps> = ({
  icon,
  label,
  onClick,
  route,
}) => {
  return route ? (
    <li>
      <Hyperlink.NavigationLink route={route}>
        {icon()}
        <span>{label}</span>
      </Hyperlink.NavigationLink>
    </li>
  ) : onClick ? (
    <li>
      <Button onClick={onClick}>
        {icon()}
        <span>{label}</span>
      </Button>
    </li>
  ) : null;
};

export default React.memo(NavItem);
