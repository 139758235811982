import { useEffect } from 'react';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { eventsGetAction } from '../../actions';
import { useHandleError } from './hooks';

const useEvents = () => {
  const dispatch: Dispatch = useDispatch();
  const { isError } = useSelector(state => state.events, shallowEqual);

  useEffect(() => {
    dispatch(eventsGetAction({}));
  }, [dispatch]);

  useHandleError(isError);
};

export default useEvents;
