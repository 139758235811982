import { useCallback, useEffect, useState } from 'react';

export const useInput = (
  active: boolean,
  entryRef: React.MutableRefObject<HTMLLIElement | null>,
  fieldValue: string,
  handleUpdateField: (dataKeyName: string, value: string) => void,
  isSending: boolean,
  isSendingError: Error | false,
  keyName: string,
  setActiveAdditionalDetail: React.Dispatch<
    React.SetStateAction<string | null>
  >,
) => {
  const [value, setValue] = useState(fieldValue);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(event.target.value),
    [],
  );

  const handleSaveChanges = useCallback(async () => {
    if (entryRef.current) {
      entryRef.current.setAttribute('tabIndex', '0');
      entryRef.current.focus();
    }
    handleUpdateField(keyName, value);
    setActiveAdditionalDetail(null);
    setValue(fieldValue);
  }, [
    entryRef,
    handleUpdateField,
    fieldValue,
    keyName,
    setActiveAdditionalDetail,
    setValue,
    value,
  ]);

  const handleCancelChanges = useCallback(() => {
    setValue(fieldValue);
    setActiveAdditionalDetail(null);
  }, [fieldValue, setActiveAdditionalDetail]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (value.length !== 0 && e.key === 'Enter') {
        handleSaveChanges();
      }
      if (e.key === 'Escape') handleCancelChanges();
    },
    [handleCancelChanges, handleSaveChanges, value],
  );

  useEffect(() => {
    if (!isSending || !!isSendingError) {
      if (entryRef.current) {
        entryRef.current.removeAttribute('tabIndex');
      }
    }
  }, [entryRef, isSending, isSendingError]);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  return {
    handleCancelChanges,
    handleChange,
    handleKeyDown,
    handleSaveChanges,
    value,
  };
};
