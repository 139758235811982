import { DrawerAction } from './constants';
import { Drawer, DrawerActionType } from './types';

const initialState: Drawer[] = [];

function drawersReducer(state = initialState, action: DrawerActionType) {
  switch (action.type) {
    case DrawerAction.DESTROY:
      return state.filter(drawer => drawer.id !== action.id);
    case DrawerAction.HIDE:
      return state.map(drawer => {
        if (drawer.id !== action.id) {
          return drawer;
        }
        return {
          ...drawer,
          open: false,
        };
      });
    case DrawerAction.INSERT:
      return [...state, { ...action.drawer }];
    default:
      return state;
  }
}

export default drawersReducer;
