import React from 'react';

import { AggregateType } from 'modules/redux/states/policy/scores/constants';
import { UsePolicyScores } from 'modules/redux/states/policy/hooks/usePolicySummary';
import Button from 'components/Button';
import { useConfigSelector } from 'modules/redux/states/config/hooks';

import styles from './styles.module.scss';

type ScoreOverTimeButtonsProps = {
  aggregateType?: AggregateType | null;
  handleUpdate?: UsePolicyScores['handleUpdate'];
  isLoading?: boolean;
};

const Buttons: React.FunctionComponent<ScoreOverTimeButtonsProps> = ({
  aggregateType,
  handleUpdate,
  isLoading,
}) => {
  const {
    tripsApplication: {
      scores: { aggregateTypes },
    },
  } = useConfigSelector();
  return (
    <div>
      {!!aggregateTypes.monthly && (
        <Button
          className={
            aggregateType === AggregateType.MONTHLY
              ? styles.selected
              : undefined
          }
          disabled={isLoading}
          label="Monthly"
          onClick={() =>
            handleUpdate ? handleUpdate(AggregateType.MONTHLY) : undefined
          }
        />
      )}
      {!!aggregateTypes.weekly && (
        <Button
          className={
            aggregateType === AggregateType.WEEKLY ? styles.selected : undefined
          }
          disabled={isLoading}
          label="Weekly"
          onClick={() =>
            handleUpdate ? handleUpdate(AggregateType.WEEKLY) : undefined
          }
        />
      )}
      {!!aggregateTypes.daily && (
        <Button
          className={
            aggregateType === AggregateType.DAILY ? styles.selected : undefined
          }
          disabled={isLoading}
          label="Daily"
          onClick={() =>
            handleUpdate ? handleUpdate(AggregateType.DAILY) : undefined
          }
        />
      )}
      {!!aggregateTypes.ytd && (
        <Button
          className={
            aggregateType === AggregateType.YTD ? styles.selected : undefined
          }
          disabled={isLoading}
          label="Year to date"
          onClick={() =>
            handleUpdate ? handleUpdate(AggregateType.YTD) : undefined
          }
        />
      )}
    </div>
  );
};

export default React.memo(Buttons);
