import React from 'react';

import { PolicyEventType } from 'modules/redux/states/policy/trip/constants';
import { ReactComponent as AccelerationSVG } from 'modules/theme/images/icons/map/acceleration-events-point.svg';
import { ReactComponent as BrakingSVG } from 'modules/theme/images/icons/map/braking-events-point.svg';
import { ReactComponent as CorneringSVG } from 'modules/theme/images/icons/map/cornering-events-point.svg';
import { ReactComponent as DistractedDrivingSVG } from 'modules/theme/images/icons/map/distracted-driving-events-point.svg';
import { ReactComponent as PositionMarkerSVG } from 'modules/theme/images/icons/map/position-map-marker.svg';
import { ReactComponent as OverallSVG } from 'modules/theme/images/icons/map/overall.svg';
import { ReactComponent as OverSpeedSVG } from 'modules/theme/images/icons/map/over-speed-events-point.svg';
import { ReactComponent as SpedingSVG } from 'modules/theme/images/icons/map/speeding-events-point.svg';
import { ReactComponent as SmoothnessSVG } from 'modules/theme/images/icons/map/smoothness-events-point.svg';
import { ReactComponent as TimeOfDaySVG } from 'modules/theme/images/icons/map/time-of-day-events-point.svg';

type EventIconProps = {
  eventType: PolicyEventType;
};

const EventIcon: React.FunctionComponent<EventIconProps> = ({ eventType }) => {
  switch (eventType) {
    case PolicyEventType.ACCELERATION:
      return <AccelerationSVG />;
    case PolicyEventType.BRAKING:
      return <BrakingSVG />;
    case PolicyEventType.CORNERING:
      return <CorneringSVG />;
    case PolicyEventType.DISTRACTED_DRIVING:
      return <DistractedDrivingSVG />;
    case PolicyEventType.POSITION_UPDATE:
      return <PositionMarkerSVG />;
    case PolicyEventType.OVERALL:
      return <OverallSVG />;
    case PolicyEventType.OVER_SPEED:
      return <OverSpeedSVG />;
    case PolicyEventType.SMOOTHNESS:
      return <SmoothnessSVG />;
    case PolicyEventType.SPEEDING:
      return <SpedingSVG />;
    case PolicyEventType.TIME_OF_DAY:
      return <TimeOfDaySVG />;
    default:
      return null;
  }
};

export default React.memo(EventIcon);
