import { AuthLoginAction, AuthLogoutAction, AuthTokenAction } from '../types';
import { AuthAction } from '../constants';

export { authConfigAction } from './authConfigAction';
export { authGetUserAction } from './authGetUserAction';

export const authLoginAction: AuthLoginAction = () => ({
  type: AuthAction.LOGIN,
});

export const authLogoutAction: AuthLogoutAction = () => ({
  type: AuthAction.LOGOUT,
});

export const authTokenAction: AuthTokenAction = payload => ({
  type: AuthAction.TOKEN,
  ...payload,
});
