import { useCallback, useEffect, useState } from 'react';

import { authLoginAction, authLogoutAction } from '../actions';
import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { Auth } from '../types';

export type UseAuth = {
  handleLogin: () => void;
  handleLogout: () => void;
  isAuthenticated: Auth['isAuthenticated'];
  isError: Auth['isError'];
  isLoading: boolean;
  isReady: Auth['isReady'];
};

const useAuth = (): UseAuth => {
  const { isAuthenticated, isError, isReady } = useSelector(
    state => state.auth,
    shallowEqual,
  );
  const [isLoading, setLoading] = useState(false);
  const dispatch: Dispatch = useDispatch();

  const handleLogin = useCallback(() => {
    if (!isLoading) {
      dispatch(authLoginAction());
      setLoading(true);
    }
  }, [dispatch, isLoading]);

  const handleLogout = useCallback(() => {
    dispatch(authLogoutAction());
  }, [dispatch]);

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isLoading]);

  return {
    handleLogin: handleLogin,
    handleLogout: handleLogout,
    isAuthenticated,
    isError,
    isLoading,
    isReady,
  };
};

export default useAuth;
