import { useEffect } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { policyGetTripAction } from 'modules/redux/states/policy/actions';
import {
  useHandleError,
  useHandleInvalidateTrip,
  usePolicyTripSelector,
} from './hooks';
import { useModalCustom } from 'modules/modals/hooks';
import { UsePolicyTrip } from '../types';

const usePolicyTrip = (policyId?: string): UsePolicyTrip | null => {
  const { hideModalCustom, showModalCustom } = useModalCustom();
  const dispatch: Dispatch = useDispatch();

  const {
    data,
    enabled,
    isError,
    isInvalid,
    isLoading,
    isSending,
    subjectId,
    tripId,
  } = usePolicyTripSelector({
    policyId,
    showModalCustom,
  });

  useEffect(() => {
    if (!data && policyId && subjectId && tripId)
      dispatch(policyGetTripAction({ policyId, subjectId, tripId }));
  }, [data, dispatch, policyId, subjectId, tripId]);

  const handleError = useHandleError({ dispatch, policyId, subjectId, tripId });

  const handleInvalidateTrip = useHandleInvalidateTrip({
    enabled,
    isInvalid,
    isSending,
    policyId,
    showModalCustom,
    tripId,
  });

  useEffect(() => {
    return hideModalCustom;
  }, [hideModalCustom, tripId]);

  return tripId
    ? {
        data,
        handleError,
        handleInvalidateTrip,
        isError,
        isInvalid,
        isLoading,
        isSending,
        tripId,
      }
    : null;
};

export default usePolicyTrip;
