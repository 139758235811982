export enum AuthAction {
  CONFIG_STARTED = 'AUTH_CONFIG_STARTED',
  CONFIG_SUCCESS = 'AUTH_CONFIG_SUCCESS',
  CONFIG_FAILURE = 'AUTH_CONFIG_FAILURE',
  GET_USER_STARTED = 'AUTH_GET_USER_STARTED',
  GET_USER_SUCCESS = 'AUTH_GET_USER_SUCCESS',
  GET_USER_FAILURE = 'AUTH_GET_USER_FAILURE',
  LOGIN = 'AUTH_LOGIN',
  LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  LOGOUT = 'AUTH_LOGOUT',
  TOKEN = 'AUTH_TOKEN',
}
