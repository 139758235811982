import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { MessageMediumType } from 'modules/redux/states/engagement/messages/constants';
import Icon from '../../Icon';

type Props = {
  date: string;
  descRef?: React.MutableRefObject<HTMLElement | null>;
  description: string;
  handleTransitionEnd?: (e: React.TransitionEvent<HTMLElement>) => void;
  messageMedium: MessageMediumType;
};

const ItemContent: React.FunctionComponent<Props> = ({
  date: value,
  descRef,
  description,
  handleTransitionEnd,
  messageMedium,
}) => {
  const time = useMemo(() => {
    return dayjs(value).format('h:mm a');
  }, [value]);
  const date = useMemo(() => {
    return dayjs(value).format('DD MMM YY');
  }, [value]);
  return (
    <React.Fragment>
      <div>
        <Icon type={messageMedium} />
      </div>
      <p>
        <strong>{messageMedium}</strong>
        <span onTransitionEnd={handleTransitionEnd} ref={descRef}>
          <span>{description}</span>
        </span>
      </p>
      <span>
        <span>{date}</span>
        <span>{time}</span>
      </span>
    </React.Fragment>
  );
};

export default React.memo(ItemContent);
