import * as yup from 'yup';

import { ValdationMessages } from './messages';

yup.setLocale({
  mixed: {
    notType: ValdationMessages.NOT_TYPE,
    oneOf: ValdationMessages.ONE_OF,
    required: ValdationMessages.REQUIRED,
  },
  string: {
    email: ValdationMessages.EMAIL,
  },
});

export { yupResolver as resolver } from '@hookform/resolvers/yup';
export { type AnyObjectSchema } from 'yup';
export default yup;
