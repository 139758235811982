import { Policy, PolicyActionType } from '../types';
import { PolicyAction } from '../constants';
import tripsGetReducer from './actions/policyGetTripsAction/reducer';
import tripsPagingReducer from './actions/policyPagingTripsAction/reducer';

function tripsReducer(state: Policy, action: PolicyActionType) {
  switch (action.type) {
    case PolicyAction.GET_TRIPS_FAILURE:
    case PolicyAction.GET_TRIPS_STARTED:
    case PolicyAction.GET_TRIPS_SUCCESS:
      return tripsGetReducer(state, action);

    case PolicyAction.PAGING_TRIPS_FAILURE:
    case PolicyAction.PAGING_TRIPS_STARTED:
    case PolicyAction.PAGING_TRIPS_SUCCESS:
      return tripsPagingReducer(state, action);

    default:
      return state;
  }
}

export default tripsReducer;
