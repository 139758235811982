import yup from 'modules/validation';

export type Model = {
  policyHoldersSearch: string;
};

export const defaultValues: Model = {
  policyHoldersSearch: '',
};

export const schema = yup.object().shape({
  policyHoldersSearch: yup.string(),
});
