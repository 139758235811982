import produce from 'immer';

import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from '../constants';

function reportGetReducer(state: Engagement, action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.GET_REPORT_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.report.actionId = null;
        draftState.report.isError = action.error;
        draftState.report.isLoading = false;
        draftState.report.data = null;
      });

    case EngagementAction.GET_REPORT_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.report.actionId = action.actionId;
        draftState.report.isError = false;
        draftState.report.isLoading = action.controller || true;
        draftState.report.data = null;
        draftState.report.brandId = action.request.brandId;
        draftState.report.externalid = action.request.externalid;
      });

    case EngagementAction.GET_REPORT_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.report.actionId = null;
        draftState.report.isError = false;
        draftState.report.isLoading = false;
        draftState.report.data = action.data;
        draftState.report.brandId = action.request.brandId;
        draftState.report.externalid = action.request.externalid;
      });

    default:
      return state;
  }
}

export default reportGetReducer;
