import React from 'react';

import Button from 'components/Button';
import { ButtonColor, ButtonStyle } from 'components/Button/constants';
import * as Hyperlink from 'components/Hyperlink';
import { isAdmin } from 'modules/admin';
import { ReactComponent as ChevronSVG } from 'modules/theme/images/icons/chevrons/chevron.svg';
import { ReactComponent as HomeSVG } from 'modules/theme/images/icons/navigation/home.svg';
import { ReactComponent as LogoSVG } from 'modules/theme/images/logos/logo-small-white.svg';
import { ReactComponent as LogOutSVG } from 'modules/theme/images/icons/navigation/log-out.svg';

import styles from './styles.module.scss';

const ErrorFallback = ({ error }: { error: unknown }) => {
  return (
    <React.Fragment>
      <div className={styles['error-fallback']}>
        <div role="alert">
          <header>
            <h2>Error</h2>
          </header>
          <div>
            {isAdmin ? (
              <pre>
                {error instanceof Error ? error.message : 'Unexpected Error.'}
              </pre>
            ) : (
              <p>
                We are experiencing some technical issues. Please try again or
                contact us at{' '}
                <Hyperlink.ExternalLink
                  href={`mailto:${process.env.REACT_APP_ADMIN_EMAIL}`}>
                  {process.env.REACT_APP_ADMIN_EMAIL}
                </Hyperlink.ExternalLink>{' '}
                for more support.
              </p>
            )}
          </div>
          <footer>
            <Button
              color={ButtonColor.BLUE}
              label="Try again"
              onClick={() => window.location.reload()}
              style={ButtonStyle.PRIMARY}
            />
          </footer>
        </div>
      </div>
      <div className={styles['error-fallback-nav']}>
        <LogoSVG />
        <span className={styles['outer-button']}>
          <ChevronSVG />
        </span>
        <div>
          <span></span>
          <HomeSVG />
          <LogOutSVG />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ErrorFallback;
