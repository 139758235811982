import React from 'react';

import { GlobalNavigation, SecondaryNavigation } from './components';
import { ReactComponent as ChevronSVG } from 'modules/theme/images/icons/chevrons/chevron.svg';
import Button from 'components/Button';

import styles from './styles.module.scss';

type HeaderProps = {
  handleClick: () => void;
  handleClose: () => void;
  isAuthenticated: boolean;
  isLoading: boolean;
  open: boolean;
  secondaryNavRef: React.RefObject<HTMLDivElement>;
};

const Header: React.FunctionComponent<HeaderProps> = ({
  handleClick,
  handleClose,
  isAuthenticated,
  isLoading,
  open,
  secondaryNavRef,
}) => {
  return (
    <header className={styles.header}>
      {!isLoading && (
        <React.Fragment>
          <GlobalNavigation
            isAuthenticated={isAuthenticated}
            handleClose={handleClose}
          />
          {isAuthenticated && (
            <React.Fragment>
              <Button
                ariaControls="secondary-menu"
                ariaExpanded={open}
                ariaLabel="Secondary Menu"
                onClick={handleClick}>
                <ChevronSVG />
              </Button>
              <SecondaryNavigation
                handleClose={handleClose}
                id="secondary-menu"
                secondaryNavRef={secondaryNavRef}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </header>
  );
};

export default React.memo(Header);
