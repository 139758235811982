import React from 'react';

import { ReactComponent as WalletIcon } from 'modules/theme/images/icons/card/wallet.svg';
import Card from 'components/Card';

import styles from './styles.module.scss';

const Component: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <Card
        ariaHidden={true}
        className={styles['points-balance-skeleton']}
        renderHeader={() => <WalletIcon />}
        title="Points Balance">
        <ul>
          <li>
            <strong></strong>
            <p>
              <strong></strong>
            </p>
          </li>
          <li>
            <strong></strong>
            <p>
              <strong></strong>
            </p>
            <span></span>
          </li>
        </ul>
        <span />
      </Card>
      <span data-nosnippet className="visually-hidden">
        Loading points balance
      </span>
    </React.Fragment>
  );
};

export default React.memo(Component);
