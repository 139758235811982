import { useCallback, useEffect, useState } from 'react';

export const useModal = ({
  animationIn,
  animationOut,
  destroyModal,
  disableEscapeKey,
  onClose,
  ref,
}: {
  animationIn: string;
  animationOut: string;
  destroyModal: () => void;
  disableEscapeKey?: boolean;
  onClose: () => void;
  ref: React.RefObject<HTMLDivElement>;
}) => {
  const [animating, setAnimating] = useState(true);

  const handleAnimationStart = useCallback(
    (event: AnimationEvent) => {
      if (
        event.currentTarget === ref.current &&
        (event.animationName === animationIn ||
          event.animationName === animationOut)
      ) {
        setAnimating(true);
      }
    },
    [animationIn, animationOut, ref],
  );

  const handleAnimationEnd = useCallback(
    (event: AnimationEvent) => {
      if (
        event.currentTarget === ref.current &&
        (event.animationName === animationIn ||
          event.animationName === animationOut)
      ) {
        setAnimating(false);
      }
      if (
        event.currentTarget === ref.current &&
        event.animationName === animationOut
      ) {
        destroyModal();
      }
    },
    [animationIn, animationOut, destroyModal, ref],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && !disableEscapeKey && !animating) {
        onClose && onClose();
      }
    },
    [animating, disableEscapeKey, onClose],
  );

  useEffect(() => {
    const element = ref.current;
    element?.addEventListener('animationstart', handleAnimationStart);
    return () => {
      element?.removeEventListener('animationstart', handleAnimationStart);
    };
  }, [handleAnimationStart, ref]);

  useEffect(() => {
    const element = ref.current;
    element?.addEventListener('animationend', handleAnimationEnd);
    return () => {
      element?.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [handleAnimationEnd, ref]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return { animating };
};
