import React from 'react';
import { Marker } from 'react-map-gl';
import clsx from 'clsx';

import { JOURNEY_POSITION } from '../constants';

import styles from './styles.module.scss';

type JourneyMarkerProps = {
  lon: number;
  lat: number;
  position: string;
};

const JourneyMarker: React.FunctionComponent<JourneyMarkerProps> = ({
  lat,
  lon,
  position,
}) => {
  const className = clsx(
    styles['journey-icon'],
    position === JOURNEY_POSITION.start && styles.start,
  );
  return (
    <Marker
      aria-label={`Journey ${JOURNEY_POSITION}. latitude: ${lat} and longitude: ${lon}`}
      latitude={lat}
      longitude={lon}>
      <div aria-hidden={true} className={styles['map-icon']}>
        <span className={className}></span>
      </div>
    </Marker>
  );
};

export default React.memo(JourneyMarker);
