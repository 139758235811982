import { getPreviousRoute, matchRoute } from 'modules/router/utils';
import { RootState } from 'modules/redux';
import { ROUTE } from 'modules/router/constants';
import { UseEvent } from 'modules/redux/states/events/hooks';

export const getSpeedingEventId = (data: UseEvent['data']) =>
  data?.travelSpeeds.find(item => !!item.speedingEventId)?.speedingEventId ||
  null;

const isEventPreviousRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.EVENTS_ID, getPreviousRoute(router));

const isEventCurrentRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.EVENTS_ID, router.location?.pathname);

const isEventsPreviousRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.EVENTS, getPreviousRoute(router));

export const shouldResetEvents = (router: RootState['router']) =>
  isEventPreviousRoute(router) ||
  (isEventsPreviousRoute(router) && !isEventCurrentRoute(router));
