import React from 'react';

import { UsePolicyHolderMessages } from 'modules/redux/states/engagement/hooks';
import Component from './Component';
import ErrorMessage from './ErrorMessage';
import NoResults from './NoResults';
import Skeleton from './Skeleton';

type MessagesProps = {
  data: UsePolicyHolderMessages['data'];
  handleEmailTemplate: UsePolicyHolderMessages['handleEmailTemplate'];
  handleError: UsePolicyHolderMessages['handleError'];
  handlePaging: UsePolicyHolderMessages['handlePaging'];
  id: UsePolicyHolderMessages['id'];
  isError: UsePolicyHolderMessages['isError'];
  isLoading: UsePolicyHolderMessages['isLoading'];
  isSending: UsePolicyHolderMessages['isSending'];
  page: UsePolicyHolderMessages['page'];
  pageSize: UsePolicyHolderMessages['pageSize'];
};

const Messages: React.FunctionComponent<MessagesProps> = ({
  data,
  handleEmailTemplate,
  handleError,
  handlePaging,
  id,
  isError,
  isLoading,
  isSending,
  page,
  pageSize,
}) => {
  return isError ? (
    <ErrorMessage handleError={handleError} isError={isError} />
  ) : isLoading ? (
    <Skeleton />
  ) : !data ? (
    <NoResults />
  ) : (
    <Component
      data={data}
      handleEmailTemplate={handleEmailTemplate}
      handlePaging={handlePaging}
      id={id}
      isLoading={isLoading}
      isSending={isSending}
      page={page}
      pageSize={pageSize}
    />
  );
};

export default React.memo(Messages);
