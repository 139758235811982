import { produce } from 'immer';

import { Policy, PolicyActionType } from 'modules/redux/states/policy/types';
import { PolicyAction } from 'modules/redux/states/policy/constants';

function tripsGetReducer(state: Policy, action: PolicyActionType) {
  switch (action.type) {
    case PolicyAction.GET_TRIPS_FAILURE:
      return produce(state, (draftState: Policy) => {
        draftState.trips.actionId = null;
        draftState.trips.isError = action.error;
        draftState.trips.isLoading = false;
        draftState.trips.data = null;
      });

    case PolicyAction.GET_TRIPS_STARTED:
      return produce(state, (draftState: Policy) => {
        draftState.trips.actionId = action.actionId;
        draftState.trips.isError = false;
        draftState.trips.isLoading = action.controller || true;
        draftState.trips.data = null;
        draftState.trips.startDate = action.request.startDate;
        draftState.trips.endDate = action.request.endDate;
      });

    case PolicyAction.GET_TRIPS_SUCCESS:
      return produce(state, (draftState: Policy) => {
        draftState.trips.actionId = null;
        draftState.trips.isError = false;
        draftState.trips.isLoading = false;
        draftState.trips.data = action.data;
        draftState.trips.startDate = action.request.startDate;
        draftState.trips.endDate = action.request.endDate;
      });

    default:
      return state;
  }
}

export default tripsGetReducer;
