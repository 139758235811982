import { getEventText } from './helpers';
import { PolicyEventDetails } from 'modules/redux/states/policy/trip/types';
import { PolicyEventType } from 'modules/redux/states/policy/trip/constants';

export const eventSort = (events: PolicyEventDetails[]) =>
  events
    .map(i => i.eventType)
    .filter(i => getEventText(i))
    .reduce<{ type: PolicyEventType; count: number }[]>(
      (previousValue, currentValue, index, array) => {
        !previousValue.find(item => item.type === currentValue) &&
          previousValue.push({
            type: currentValue,
            count: array.filter(type => type === currentValue).length,
          });
        return previousValue;
      },
      [],
    );
