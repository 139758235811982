import React from 'react';

import Session from 'modules/session';
import { useAuth } from 'modules/auth/hooks';
import { useConfig } from 'modules/redux/states/config/hooks';

import Template from './Template';

function Route({ children }: { children: React.ReactNode }) {
  const { isLoading: isConfigLoading } = useConfig();
  const {
    isAuthenticated,
    isError: isAuthError,
    isReady: isAuthReady,
    handleLogout,
  } = useAuth();
  return (
    <Session
      timeout={process.env.REACT_APP_APP_SESSION}
      handleSessionTimeout={handleLogout}>
      <Template
        isAuthenticated={isAuthenticated}
        isLoading={
          !!isAuthError || !isAuthReady || (isAuthenticated && isConfigLoading)
        }>
        {children}
      </Template>
    </Session>
  );
}

export default React.memo(Route);
