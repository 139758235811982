import {
  RedirectLoginOptions,
  GenericError,
  Auth0Client,
} from '@auth0/auth0-spa-js';

import { authOptions } from './constants';

const redirect = authOptions.redirect_uri || window.location.origin;
let client: Auth0Client | null = null;

export const createAuth0Client = async () => {
  const isCallback = hasAuthClientParams();
  let isAuthenticated = false;
  try {
    client = new Auth0Client(authOptions);
    isCallback ? await handleAuthClientParams() : await client.checkSession();
    isAuthenticated = await client.isAuthenticated();
  } catch (error) {
    handleAuthClientError(error);
  }
  return { isCallback, isAuthenticated };
};

export const getAuth0Token = async () =>
  await (client as Auth0Client).getTokenSilently();

export const getAuth0User = async () => {
  const user = await (client as Auth0Client).getUser();
  if (!user) throw new Error('User Undefined.');
  return user;
};

const getLoginOptions = (): RedirectLoginOptions | undefined => {
  const params = new URLSearchParams(window.location.search);
  const options: RedirectLoginOptions = {};
  if (params.get('organization') && params.get('invitation')) {
    options.organization = params.get('organization') || undefined;
    options.invitation = params.get('invitation') || undefined;
  }
  return Object.keys(options).length ? options : undefined;
};

const handleAuthClientError = (error: unknown) => {
  if (isAuthTokenError(error)) return;
  if (error instanceof Error)
    error.message = `${error.message}${!error.message.endsWith('.') && '.'}`;
  throw error;
};

const handleAuthClientParams = async () => {
  try {
    await client?.handleRedirectCallback();
  } finally {
    window.history.replaceState({}, document.title, redirect);
  }
};

const hasAuthClientParams = (query = window.location.search): boolean =>
  (query.includes('code=') && query.includes('state=')) ||
  query.includes('error=');

export const isAuthTokenError = (error: unknown) =>
  error instanceof GenericError && error.error === 'login_required';

export const loginAuth0Client = () => {
  try {
    (client as Auth0Client).loginWithRedirect(getLoginOptions());
  } catch {
    window.location.href = redirect;
  }
};

export const logoutAuth0Client = () => {
  try {
    (client as Auth0Client).logout({
      returnTo: redirect,
    });
  } catch {
    window.location.href = redirect;
  }
};

export const newAuthError = () =>
  new GenericError('login_required', 'Login required.') as Error;

export const resetAuth0Client = () => {
  try {
    const client = new Auth0Client(authOptions);
    client.logout({
      returnTo: redirect,
    });
  } catch {
    window.location.href = redirect;
  }
};
