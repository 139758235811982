import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { buildTripPath } from 'modules/router/utils';
import {
  policyGetTripsAction,
  policyPagingTripsAction,
} from 'modules/redux/states/policy/trips/actions';
import {
  UseHandleClick,
  UseHandleError,
  UseHandlePaging,
  UseHandleSearch,
} from './types';

export const useHandleError: UseHandleError = ({
  dispatch,
  endDate,
  isSendingError,
  pageAfter,
  policyId,
  startDate,
}) =>
  useCallback(() => {
    if (policyId)
      dispatch(
        isSendingError
          ? policyPagingTripsAction({
              endDate,
              pageAfter,
              policyId,
              startDate,
            })
          : policyGetTripsAction({
              endDate,
              policyId,
              startDate,
            }),
      );
  }, [dispatch, endDate, isSendingError, pageAfter, policyId, startDate]);

export const useHandleSearch: UseHandleSearch = ({ dispatch, policyId }) =>
  useCallback(
    ({ endDate, startDate }) => {
      if (policyId)
        dispatch(policyGetTripsAction({ endDate, policyId, startDate }));
    },
    [dispatch, policyId],
  );

export const useHandlePaging: UseHandlePaging = ({
  dispatch,
  endDate,
  pageAfter,
  policyId,
  startDate,
}) =>
  useCallback(() => {
    if (policyId)
      dispatch(
        policyPagingTripsAction({ endDate, pageAfter, policyId, startDate }),
      );
  }, [dispatch, endDate, pageAfter, policyId, startDate]);

export const useHandleClick: UseHandleClick = ({ policyId }) => {
  const navigate = useNavigate();
  return useCallback(
    ({ subjectId, tripId }) => {
      if (policyId && subjectId && tripId)
        navigate(buildTripPath(policyId, subjectId, tripId));
    },
    [navigate, policyId],
  );
};
