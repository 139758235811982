export enum NotificationAction {
  DESTROY = 'NOTIFICATION_DESTROY',
  INSERT = 'NOTIFICATION_INSERT',
  HIDE = 'NOTIFICATION_HIDE',
}

export enum NotificationTemplate {
  POINTS_ADDED = 'POINTS_ADDED',
  POINTS_REMOVED = 'POINTS_REMOVED',
}

export enum NotificationSeverity {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}
