import { useCallback, useState } from 'react';

import { Model as PolicySearchModel } from '../../../components/PolicySearch';
import { reportsGetPolicyAction } from 'modules/redux/states/reports/actions';
import { useDispatchWithCallback } from 'modules/redux/hooks';
import { Policy, UseHandlePolicySearch } from './types';
import {
  ReportsGetPolicyActionApiRequest,
  ReportsGetPolicyActionApiResponse,
} from 'modules/redux/states/reports/types';

const useHandlePolicySearch: UseHandlePolicySearch = ({
  isLoading,
  reportType,
  setError,
  setLoading,
}) => {
  const [policy, setPolicy] = useState<Policy>(undefined);

  const dispatch = useDispatchWithCallback<
    ReportsGetPolicyActionApiRequest,
    ReportsGetPolicyActionApiResponse['data']
  >();

  const handleFailure = useCallback(
    (error: Error) => {
      setError(error);
      setLoading(false);
    },
    [setError, setLoading],
  );

  const handleSuccess = useCallback(
    (data: ReportsGetPolicyActionApiResponse['data']) => {
      setPolicy({
        policyId: data.policyDetails.identifier,
        subjectId: data.subjectId,
        tenantId: data.tenantId,
      });
      !!reportType.requiresDate && setLoading(false);
    },
    [reportType, setLoading],
  );

  const handlePolicySearch = useCallback(
    (values: PolicySearchModel) => {
      if (!isLoading) {
        setError(false);
        setLoading(true);
        dispatch(reportsGetPolicyAction, {
          onFailure: handleFailure,
          onSuccess: handleSuccess,
          request: { policyId: values.policyId },
        });
      }
    },
    [dispatch, handleFailure, handleSuccess, isLoading, setError, setLoading],
  );

  return {
    handlePolicySearch,
    policy,
  };
};

export default useHandlePolicySearch;
