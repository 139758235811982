import { API } from 'modules/api';
import { createCallbackAction } from 'modules/redux/factories';
import { ReportsAction } from 'modules/redux/states/reports/constants';
import {
  ReportsGetStatusAction,
  ReportsGetStatusFailureAction,
  ReportsGetStatusStartedAction,
  ReportsGetStatusSuccessAction,
} from './types';

export type {
  ReportsGetStatusActionApiRequest,
  ReportsGetStatusActionApiResponse,
  ReportsGetStatusActionType,
} from './types';

export const reportsGetStatusAction: ReportsGetStatusAction = payload =>
  createCallbackAction({
    actionStarted: reportsGetStatusStartedAction,
    actionSuccess: reportsGetStatusSuccessAction,
    actionFailure: reportsGetStatusFailureAction,
    endpoint: API.GET_REPORT_STATUS,
    isAuthorized: state =>
      state.auth.isAuthenticated &&
      !!state.config.data?.tripsApplication.reports.enabled,
    payload,
  });

const reportsGetStatusStartedAction: ReportsGetStatusStartedAction =
  payload => ({
    type: ReportsAction.GET_STATUS_STARTED,
    ...payload,
  });

const reportsGetStatusSuccessAction: ReportsGetStatusSuccessAction =
  payload => ({
    type: ReportsAction.GET_STATUS_SUCCESS,
    ...payload,
  });

const reportsGetStatusFailureAction: ReportsGetStatusFailureAction =
  payload => ({
    type: ReportsAction.GET_STATUS_FAILURE,
    ...payload,
  });
