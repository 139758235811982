import { useCallback, useEffect } from 'react';

import { reportsPostAction } from 'modules/redux/states/reports/actions';
import { ReportStatus } from 'modules/redux/states/reports/constants';
import {
  ReportsPostActionApiRequest,
  ReportsPostActionApiResponse,
} from 'modules/redux/states/reports/types';
import { useDispatchWithCallback } from 'modules/redux/hooks';
import { UseHandleRequest } from './types';

const useHandleRequest: UseHandleRequest = ({
  reportType,
  request,
  setData,
  setError,
  setLoading,
}) => {
  const dispatch = useDispatchWithCallback<
    ReportsPostActionApiRequest,
    ReportsPostActionApiResponse['data']
  >();
  const { requiresDate, requiresPolicyId } = reportType;

  const handleFailure = useCallback(
    (error: Error) => {
      setError(error);
      setLoading(false);
    },
    [setError, setLoading],
  );

  const handleSuccess = useCallback(
    (data: ReportsPostActionApiResponse['data']) =>
      setData({ reportId: data.reportId, status: ReportStatus.PENDING }),
    [setData],
  );

  const handleRequest = useCallback(
    (request: ReportsPostActionApiRequest) =>
      dispatch(reportsPostAction, {
        onFailure: handleFailure,
        onSuccess: handleSuccess,
        request,
      }),
    [dispatch, handleFailure, handleSuccess],
  );

  useEffect(() => {
    if (
      (requiresDate || requiresPolicyId) &&
      (!requiresDate || request.startDate) &&
      (!requiresPolicyId || (request.subjectId && request.tenantId))
    )
      handleRequest(request);
  }, [handleRequest, request, requiresDate, requiresPolicyId]);

  return handleRequest;
};

export default useHandleRequest;
