import { Feature, GeoJsonProperties, Geometry, LineString } from 'geojson';

export const getJourneyMarkers = (
  mapBoxCoords: Feature<Geometry, GeoJsonProperties>,
) => {
  const coords = (mapBoxCoords.geometry as LineString).coordinates;

  return {
    start: {
      lat: coords[0][1],
      lon: coords[0][0],
    },
    end: {
      lat: coords[coords.length - 1][1],
      lon: coords[coords.length - 1][0],
    },
  };
};
