import React from 'react';
import { FieldValues } from 'react-hook-form';
import clsx from 'clsx';

import { InputNumberProps } from '../types';
import { ReactComponent as DecreaseIcon } from 'modules/theme/images/icons/points/decrease.svg';
import { ReactComponent as IncreaseIcon } from 'modules/theme/images/icons/points/increase.svg';
import { useButtons, useHandlers } from './hooks';
import Button from 'components/Button';

import styles from './styles.module.scss';

function InputNumber<T extends FieldValues>({
  autoFocus,
  children,
  disabled,
  id,
  inputRef,
  invalid,
  max,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  readOnly,
  setValue,
  size,
  tabIndex,
  value,
  ...props
}: InputNumberProps<T>) {
  const { getTabIndex, handleBlur, handleChange, handleFocus, handleKeyDown } =
    useHandlers({
      disabled,
      max,
      onBlur,
      onChange,
      onFocus,
      tabIndex,
      value,
    });

  const { handleDecrement, handleIncrement } = useButtons({
    max,
    min,
    name,
    setValue,
    value,
  });

  const className = clsx(
    styles['input-number'],
    styles[`${size}`],
    disabled && styles.disabled,
    readOnly && styles['read-only'],
    !disabled && invalid && styles.invalid,
  );

  return (
    <div className={className}>
      <Button
        ariaLabel="Decrease points"
        disabled={disabled || parseInt(value) <= min}
        onClick={handleDecrement}>
        <DecreaseIcon />
      </Button>
      <input
        autoFocus={!disabled && autoFocus}
        id={id || name}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        readOnly={readOnly}
        ref={inputRef}
        tabIndex={getTabIndex()}
        type="number"
        value={value}
        {...props}
      />
      <Button
        ariaLabel="Increase points"
        disabled={disabled || parseInt(value) >= max}
        onClick={handleIncrement}>
        <IncreaseIcon />
      </Button>
      {children}
    </div>
  );
}

export default React.memo(InputNumber) as typeof InputNumber;
