import React, { useCallback } from 'react';
import clsx from 'clsx';

import { QuestionSize } from 'components/Questions/constants';
import { TextAreaProps } from './types';

import styles from './styles.module.scss';

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  autoFocus,
  children,
  disabled,
  id,
  inputRef,
  invalid,
  name,
  onBlur,
  onFocus,
  readOnly,
  rows,
  size,
  tabIndex,
  value,
  ...props
}) => {
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) =>
      !disabled && !!onBlur && onBlur(event),
    [disabled, onBlur],
  );
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      disabled && event.currentTarget.blur();
      !disabled && !!onFocus && onFocus(event);
    },
    [disabled, onFocus],
  );
  const getTabIndex = useCallback(() => {
    if (disabled) return -1;
    return tabIndex ? tabIndex : 0;
  }, [disabled, tabIndex]);
  const getRows = useCallback(() => {
    switch (size) {
      case QuestionSize.SMALL:
        return 4;
      case QuestionSize.MEDIUM:
        return 5;
      default:
        return 5;
    }
  }, [size]);

  const className = clsx(
    styles.textarea,
    styles[`${size}`],
    disabled && styles.disabled,
    readOnly && styles['read-only'],
    !disabled && invalid && styles.invalid,
  );

  return (
    <div className={className}>
      <textarea
        autoFocus={!disabled && autoFocus}
        id={id || name}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        readOnly={readOnly}
        ref={inputRef}
        rows={rows ? rows : getRows()}
        tabIndex={getTabIndex()}
        value={value || ''}
        {...props}
      />
      {children}
    </div>
  );
};

export default React.memo(TextArea);
