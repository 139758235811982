import React from 'react';
import clsx from 'clsx';

import { LayoutRowProps } from './types';

import styles from './styles.module.scss';

const LayoutRow: React.FunctionComponent<LayoutRowProps> = ({
  children,
  className,
}) => {
  const classNames = clsx(styles.row, className);
  return <div className={classNames}>{children}</div>;
};

export default React.memo(LayoutRow);
