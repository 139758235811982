import { v4 as uuidv4 } from 'uuid';

import { Dispatch } from 'modules/redux';
import {
  Modal,
  ModalDestroyAction,
  ModalHideAction,
  ModalInsertAction,
} from './types';
import { ModalAction } from './constants';

export function modalShowAction(
  modal: Omit<Modal, 'id' | 'open'>,
): (dispatch: Dispatch) => string {
  return (dispatch: Dispatch) => {
    (modal as Modal).id = uuidv4();
    (modal as Modal).open = true;
    dispatch(modalInsertAction(modal as Modal));
    return (modal as Modal).id;
  };
}

export const modalDestroyAction: ModalDestroyAction = (id: string) => ({
  type: ModalAction.DESTROY,
  id,
});

export const modalHideAction: ModalHideAction = (id: string) => ({
  type: ModalAction.HIDE,
  id,
});

export const modalInsertAction: ModalInsertAction = (modal: Modal) => ({
  type: ModalAction.INSERT,
  modal,
});
