import React, { useMemo } from 'react';

import { ReactComponent as ChevronSvg } from 'modules/theme/images/icons/chevrons/chevron-thin.svg';
import { Paging } from 'modules/pagination';
import Button from 'components/Button';

export { Paging } from './types';

type PaginationProps = {
  handlePaging: (type: Paging) => void;
  isLoading: boolean;
  page: number;
  pageSize: number;
  totalCount: number;
};

const Pagination: React.FunctionComponent<PaginationProps> = ({
  handlePaging,
  isLoading,
  page,
  pageSize,
  totalCount,
}) => {
  const totalPages = useMemo(
    () => totalCount / pageSize,
    [pageSize, totalCount],
  );
  const minVisibleRecords = useMemo(
    () => page * pageSize - pageSize + 1,
    [page, pageSize],
  );
  const maxVisibleRecords = useMemo(
    () => (page * pageSize < totalCount ? page * pageSize : totalCount),
    [page, pageSize, totalCount],
  );
  return (
    <footer>
      <div>
        <strong>
          {minVisibleRecords}
          <React.Fragment>
            {maxVisibleRecords !== 1 && ` - ${maxVisibleRecords}`}
          </React.Fragment>
        </strong>{' '}
        of {totalCount}
      </div>
      <Button
        disabled={isLoading || !totalCount || page === 1}
        onClick={() => handlePaging(Paging.PREVIOUS)}>
        <span className="visually-hidden">Previous page</span>
        <ChevronSvg />
      </Button>
      <Button
        disabled={isLoading || !totalCount || page >= totalPages}
        onClick={() => handlePaging(Paging.NEXT)}>
        <span className="visually-hidden">Next page</span>
        <ChevronSvg />
      </Button>
    </footer>
  );
};

export default React.memo(Pagination);
