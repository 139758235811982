import { Auth0ClientOptions } from '@auth0/auth0-spa-js';

export const authOptions: Auth0ClientOptions = {
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
  domain: process.env.REACT_APP_AUTH_DOMAIN as string,
  redirect_uri: process.env.REACT_APP_AUTH_REDIRECT || window.location.origin,
  cacheLocation: 'localstorage',
  authorizeTimeoutInSeconds: 10,
  useRefreshTokens: true,
  audience: process.env.REACT_APP_AUTH_AUDIENCE || '',
};
