import eventsGetReducer from './actions/eventsGetAction/reducer';
import eventsPutReducer from './actions/eventsPutAction/reducer';
import { Events, EventsActionType } from './types';
import { EventsAction } from './constants';

const initialState = (): Events => {
  return {
    actionId: null,
    data: null,
    isError: false,
    isLoading: false,
    isSending: false,
    isSendingError: false,
  };
};

function eventsReducer(state = initialState(), action: EventsActionType) {
  switch (action.type) {
    case EventsAction.GET_FAILURE:
    case EventsAction.GET_STARTED:
    case EventsAction.GET_SUCCESS:
      return eventsGetReducer(state, action);

    case EventsAction.PUT_FAILURE:
    case EventsAction.PUT_STARTED:
    case EventsAction.PUT_SUCCESS:
      return eventsPutReducer(state, action);

    case EventsAction.LOGOUT:
    case EventsAction.RESET:
      return initialState();

    default:
      return state;
  }
}

export default eventsReducer;
