import produce from 'immer';
import {
  Engagement,
  EngagementActionType,
} from 'modules/redux/states/engagement/types';
import { EngagementAction } from '../constants';

function brandReducer(state: Engagement, action: EngagementActionType) {
  switch (action.type) {
    case EngagementAction.GET_BRAND_FAILURE:
      return produce(state, (draftState: Engagement) => {
        draftState.brand.actionId = null;
        draftState.brand.isError = action.error;
        draftState.brand.isLoading = false;
        draftState.brand.data = null;
      });

    case EngagementAction.GET_BRAND_STARTED:
      return produce(state, (draftState: Engagement) => {
        draftState.brand.actionId = action.actionId;
        draftState.brand.isError = false;
        draftState.brand.isLoading = action.controller || true;
        draftState.brand.data = null;
      });

    case EngagementAction.GET_BRAND_SUCCESS:
      return produce(state, (draftState: Engagement) => {
        draftState.brand.actionId = null;
        draftState.brand.isError = false;
        draftState.brand.isLoading = false;
        draftState.brand.data = action.data;
      });

    default:
      return state;
  }
}

export default brandReducer;
