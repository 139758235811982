import React from 'react';

import itemStyles from '../TripsList/TripsListItem/styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <span data-nosnippet className="visually-hidden">
        Loading trips list
      </span>
      <div className={styles.skeleton} aria-hidden="true">
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
        <div className={itemStyles['trips-summary-item']}>
          <div>
            <div className={styles['date-time']}>
              <span></span>
            </div>
            <div className={styles.duration}>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={styles.overall}></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Skeleton);
