import React from 'react';

import { usePolicyHolderSummary } from 'modules/redux/states/engagement/hooks';
import Template from './Template';

const Route: React.FunctionComponent = () => {
  const { details, messages, points, report, rewards } =
    usePolicyHolderSummary();

  return (
    <Template
      details={details}
      messages={messages}
      points={points}
      report={report}
      rewards={rewards}
    />
  );
};

export default Route;
