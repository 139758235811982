import React, { useCallback, useRef } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { FontSize, Padding } from 'components/Card/constants';
import { UseEvent } from 'modules/redux/states/events/hooks';
import { useRelativeUnits } from './hooks';
import Card from 'components/Card';
import Legend from './Legend';

import styles from './styles.module.scss';

type RoadSpeedVsTravelSpeedChartProps = {
  chartIndex?: number;
  eventId: string | null;
  setChartIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
  speeds: UseEvent['data'];
};

const Chart: React.FunctionComponent<RoadSpeedVsTravelSpeedChartProps> = ({
  chartIndex,
  eventId,
  setChartIndex,
  speeds,
}) => {
  const ref = useRef<HTMLElement>(null);
  const { lineWidth, tooltipCircle, xAxisHeight, yAxisWidth } =
    useRelativeUnits(ref);

  const handleMouseMove = useCallback(
    (index?: number) => {
      if (typeof index === 'number' && index !== chartIndex && setChartIndex) {
        setChartIndex(index);
      }
    },
    [chartIndex, setChartIndex],
  );
  return (
    <Card
      className={styles.charts}
      fontSize={FontSize.SMALL}
      padding={Padding.SMALL}
      forwardedRef={ref}
      title="Road speed vs travel speed">
      <figure role="group">
        <div
          role="img"
          aria-label={`Line chart showing${
            eventId ? ` event ${eventId}` : ''
          } road speed vs travel speed.`}>
          <div aria-hidden={true}>
            <Legend />
            <div className={styles['chart-container']}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={speeds?.travelSpeeds}
                  layout="horizontal"
                  onMouseMove={state =>
                    handleMouseMove(state.activeTooltipIndex)
                  }
                  onMouseLeave={() => setChartIndex && setChartIndex(undefined)}
                  margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
                  <CartesianGrid horizontal={true} vertical={false} />
                  <XAxis dataKey=" " height={xAxisHeight} tickLine={false} />
                  <YAxis
                    axisLine={false}
                    domain={[0, 100]}
                    tickCount={5}
                    tickLine={false}
                    width={yAxisWidth}
                  />
                  <Tooltip />
                  <Line
                    activeDot={{ r: tooltipCircle }}
                    dataKey={data => data.overRoadSpeed}
                    dot={false}
                    name="Road Speed"
                    stroke="#e62a29"
                    strokeWidth={lineWidth}
                    type="stepAfter"
                  />
                  <Line
                    activeDot={{ r: tooltipCircle }}
                    dataKey="speed"
                    dot={false}
                    name="Travel Speed"
                    stroke="#0e8cf8"
                    strokeWidth={lineWidth}
                    type="monotoneX"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <figcaption className="visually-hidden">
          <h2>Overview</h2>
          <p>{`The chart shows the${
            eventId ? ` event ${eventId}` : ''
          } road speed vs travel speed.`}</p>
          <h2>Values</h2>
          <table>
            <caption>Road speed vs travel speed for event {eventId}</caption>
            <thead>
              <tr>
                <th>Road Speed</th>
                <th>Travel Speed</th>
              </tr>
            </thead>
            <tbody>
              {speeds?.travelSpeeds.map((i, index) => {
                return (
                  <tr key={`speed-${index}`}>
                    <td>{i.overRoadSpeed}</td>
                    <td>{i.speed}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </figcaption>
      </figure>
    </Card>
  );
};

export default React.memo(Chart);
