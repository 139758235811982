import { NotificationTemplateProps } from '.';
import { NotificationSeverity } from '../constants';

const template: NotificationTemplateProps = {
  ariaLabel: 'Points successfully added',
  message: 'Points successfully added',
  severity: NotificationSeverity.SUCCESS,
};

export default template;
