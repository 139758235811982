import { API } from 'modules/api';
import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import {
  EngagementGetMessagesEmailTemplateAction,
  EngagementGetMessagesEmailTemplateStartedAction,
  EngagementGetMessagesEmailTemplateSuccessAction,
  EngagementGetMessagesEmailTemplateFailureAction,
} from './types';

export type { EngagementGetMessagesEmailTemplateActionApiRequest } from './types';

export const engagementGetMessagesEmailTemplateAction: EngagementGetMessagesEmailTemplateAction =
  request =>
    createDefaultAction({
      actionStarted: engagementGetMessagesEmailTemplateStartedAction,
      actionSuccess: engagementGetMessagesEmailTemplateSuccessAction,
      actionFailure: engagementGetMessagesEmailTemplateFailureAction,
      endpoint: API.GET_ENGAGEMENT_MESSAGES_EMAIL_TEMPLATE,
      isActive: state =>
        !state.engagement.messages.isLoading &&
        !!state.engagement.messages.isSending,
      isAuthorized: state => state.auth.isAuthenticated,
      isPresent: (actionId, state) =>
        actionId === state.engagement.messages.actionId,
      request: request,
    });

const engagementGetMessagesEmailTemplateStartedAction: EngagementGetMessagesEmailTemplateStartedAction =
  payload => ({
    type: EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_STARTED,
    ...payload,
  });

const engagementGetMessagesEmailTemplateSuccessAction: EngagementGetMessagesEmailTemplateSuccessAction =
  payload => ({
    type: EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_SUCCESS,
    ...payload,
  });

const engagementGetMessagesEmailTemplateFailureAction: EngagementGetMessagesEmailTemplateFailureAction =
  payload => ({
    type: EngagementAction.GET_MESSAGES_EMAIL_TEMPLATE_FAILURE,
    ...payload,
  });
