import { useParams } from 'react-router-dom';

import { shallowEqual, useSelector } from 'modules/redux';
import { UsePolicyDetailsSelector } from './types';

const usePolicyDetailsSelector: UsePolicyDetailsSelector = () => {
  const { policyId } = useParams() as { policyId: string };

  const { data, isError, isLoading } = useSelector(
    state => state.policy.details,
    shallowEqual,
  );

  return data && data.policyDetails.identifier === policyId
    ? { data, isError, isLoading: !!isLoading, policyId }
    : { data: null, isError, isLoading: true, policyId };
};

export default usePolicyDetailsSelector;
