import { API } from 'modules/api';
import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import {
  EngagementGetMessagesAction,
  EngagementGetMessagesStartedAction,
  EngagementGetMessagesSuccessAction,
  EngagementGetMessagesFailureAction,
} from './types';

export type { EngagementGetMessagesActionApiRequest } from './types';

export const engagementGetMessagesAction: EngagementGetMessagesAction =
  request =>
    createDefaultAction({
      actionStarted: engagementGetMessagesStartedAction,
      actionSuccess: engagementGetMessagesSuccessAction,
      actionFailure: engagementGetMessagesFailureAction,
      endpoint: API.GET_ENGAGEMENT_MESSAGES,
      isActive: state => !!state.engagement.messages.isLoading,
      isAuthorized: state => state.auth.isAuthenticated,
      isPresent: (actionId, state) =>
        actionId === state.engagement.messages.actionId,
      request: request,
    });

const engagementGetMessagesStartedAction: EngagementGetMessagesStartedAction =
  payload => ({
    type: EngagementAction.GET_MESSAGES_STARTED,
    ...payload,
  });

const engagementGetMessagesSuccessAction: EngagementGetMessagesSuccessAction =
  payload => ({
    type: EngagementAction.GET_MESSAGES_SUCCESS,
    ...payload,
  });

const engagementGetMessagesFailureAction: EngagementGetMessagesFailureAction =
  payload => ({
    type: EngagementAction.GET_MESSAGES_FAILURE,
    ...payload,
  });
