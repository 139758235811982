import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { useHandleError, useHandlePaging, useHandleGet } from './hooks';
import { UsePolicyHolderRewards } from '../types';

const usePolicyHolderMessages = (): UsePolicyHolderRewards => {
  const dispatch: Dispatch = useDispatch();
  const { endUserId } = useParams() as { endUserId: string };

  const { data, isError, isLoading, page, pageSize } = useSelector(
    state => state.engagement.rewards,
    shallowEqual,
  );

  const handleError = useHandleError({ dispatch, endUserId, page, pageSize });

  const handlePaging = useHandlePaging({
    dispatch,
    endUserId,
    page,
    pageSize,
  });

  const handleGet = useHandleGet({
    dispatch,
    endUserId,
  });

  useEffect(() => {
    if (!data && handleGet) handleGet();
  }, [data, handleGet]);

  return {
    data,
    handleError,
    handlePaging,
    isError,
    isLoading: !!isLoading,
    page,
    pageSize,
  };
};

export default usePolicyHolderMessages;
