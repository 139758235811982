import { GetUseModalConfirmStep } from './types';
import { ModalConfirmStep } from './constants';
import { ReportStatus } from 'modules/redux/states/reports/constants';

export const getUseModalConfirmStep: GetUseModalConfirmStep = ({
  link,
  status,
}) => {
  if (status === ReportStatus.COMPLETE || link) return ModalConfirmStep.LINK;

  if (status === ReportStatus.PENDING) return ModalConfirmStep.STATUS;

  return ModalConfirmStep.REQUEST;
};
