import React from 'react';

import { ReactComponent as DriverIcon } from 'modules/theme/images/icons/card/driver.svg';
import Card from 'components/Card';

import styles from './styles.module.scss';

const Component: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <span data-nosnippet className="visually-hidden">
        Loading policy details
      </span>
      <Card
        ariaHidden={true}
        className={styles['policy-details-skeleton']}
        renderHeader={() => <DriverIcon />}
        title="Policy Details">
        <ul>
          <li>
            <strong></strong>
            <span></span>
          </li>
          <li>
            <strong></strong>
            <span></span>
          </li>
          <li>
            <strong></strong>
            <span></span>
          </li>
          <li>
            <strong></strong>
            <span></span>
          </li>
        </ul>
        <span></span>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(Component);
