import yup from 'modules/validation';

export type Model = {
  report: string;
};

export const defaultValues: Model = {
  report: '',
};

export const schema = yup.object().shape({
  report: yup.string().required('Please choose a report'),
});
