import { produce } from 'immer';

import { Policy, PolicyActionType } from '../../../types';
import { PolicyAction } from '../../../constants';

const newTrip = (id: string): Policy['trip'][0] => {
  return {
    actionId: null,
    data: null,
    isError: false,
    isLoading: false,
    isSending: false,
    isSendingError: false,
    tripId: id,
  };
};

function tripGetReducer(state: Policy, action: PolicyActionType) {
  switch (action.type) {
    case PolicyAction.GET_TRIP_FAILURE:
      return produce(state, (draftState: Policy) => {
        const trip = draftState.trip.find(
          trip => trip.tripId === action.request.tripId,
        );

        if (trip) {
          trip.actionId = null;
          trip.isError = action.error;
          trip.isLoading = false;

          trip.data = null;
        }
      });

    case PolicyAction.GET_TRIP_STARTED:
      return produce(state, (draftState: Policy) => {
        let trip = draftState.trip.find(
          trip => trip.tripId === action.request.tripId,
        );

        if (!trip) {
          trip = newTrip(action.request.tripId);
          draftState.trip.push(trip);
        }

        trip.actionId = action.actionId;
        trip.isError = false;
        trip.isLoading = action.controller || true;

        trip.data = null;
      });

    case PolicyAction.GET_TRIP_SUCCESS:
      return produce(state, (draftState: Policy) => {
        const trip = draftState.trip.find(
          trip => trip.tripId === action.data.tripId,
        );

        if (trip) {
          trip.actionId = null;
          trip.isError = false;
          trip.isLoading = false;

          trip.data = action.data;
        }
      });

    default:
      return state;
  }
}

export default tripGetReducer;
