import { useCallback, useEffect, useState } from 'react';

export const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const useRelativeUnits = (ref: React.RefObject<HTMLElement>) => {
  const [largeLineWidth, setLargeLineWidth] = useState(0);
  const [largeTooltipCircle, setLargeTooltipCircle] = useState(0);
  const [lineWidth, setLineWidth] = useState(0);
  const [tooltipCircle, setTooltipCircle] = useState(0);
  const [xAxisHeight, setXAxisHeight] = useState(0);
  const [xAxisPadding, setXAxisPadding] = useState(0);
  const [yAxisWidth, setYAxisWidth] = useState(0);

  const resizeCallback = useCallback(() => {
    setLargeLineWidth(convertRemToPixels(0.2));
    setLargeTooltipCircle(convertRemToPixels(0.6));
    setLineWidth(convertRemToPixels(0.1));
    setTooltipCircle(convertRemToPixels(0.4));
    setXAxisHeight(convertRemToPixels(1.1));
    setXAxisPadding(convertRemToPixels(1.5));
    setYAxisWidth(convertRemToPixels(1.7));
  }, []);

  useEffect(() => {
    const initResizeObserver = (observer: ResizeObserver) => {
      window.requestAnimationFrame(() => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });
    };

    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        const initialSize = ref.current?.getBoundingClientRect();
        resizeCallback();
        const newSize = ref.current?.getBoundingClientRect();

        if (
          initialSize.width !== newSize.width ||
          initialSize.height !== newSize.height
        ) {
          resizeObserver.unobserve(ref.current);
          initResizeObserver(resizeObserver);
        }
      }
    });

    initResizeObserver(resizeObserver);
  }, [ref, resizeCallback]);

  return {
    largeLineWidth,
    largeTooltipCircle,
    lineWidth,
    tooltipCircle,
    xAxisHeight,
    xAxisPadding,
    yAxisWidth,
  };
};
