import { v4 as uuidv4 } from 'uuid';

import { Dispatch } from 'modules/redux';
import {
  Drawer,
  DrawerDestroyAction,
  DrawerHideAction,
  DrawerInsertAction,
} from './types';
import { DrawerAction } from './constants';

export function drawerShowAction(
  drawer: Omit<Drawer, 'id' | 'open'>,
): (dispatch: Dispatch) => string {
  return (dispatch: Dispatch) => {
    (drawer as Drawer).id = uuidv4();
    (drawer as Drawer).open = true;
    dispatch(drawerInsertAction(drawer as Drawer));
    return (drawer as Drawer).id;
  };
}

export const drawerDestroyAction: DrawerDestroyAction = (id: string) => ({
  type: DrawerAction.DESTROY,
  id,
});

export const drawerHideAction: DrawerHideAction = (id: string) => ({
  type: DrawerAction.HIDE,
  id,
});

export const drawerInsertAction: DrawerInsertAction = (drawer: Drawer) => ({
  type: DrawerAction.INSERT,
  drawer,
});
