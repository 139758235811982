import React, { useCallback } from 'react';
import clsx from 'clsx';

import { DrawerCustomProps } from '../../types';
import DrawerOverlay from '../DrawerOverlay';

import templateStyles from '../Drawers/styles.module.scss';

type DrawerProps = Omit<
  DrawerCustomProps,
  | 'destroyDrawer'
  | 'disableEscapeKey'
  | 'hideDrawer'
  | 'id'
  | 'onClose'
  | 'open'
> & {
  active?: boolean;
  onClose: () => void;
  payload?: Record<string, unknown>;
};

const Drawer = React.memo<DrawerProps>(
  ({ active, ariaLabel, onClose, payload, renderContent }) => {
    const classNames = clsx(templateStyles['drawers-template']);
    return (
      <div aria-label={ariaLabel} className={classNames} role="dialog">
        {!!renderContent &&
          renderContent({ active: !!active, onClose, payload })}
      </div>
    );
  },
);

const DrawerCustom: React.FunctionComponent<DrawerCustomProps> = ({
  ariaLabel,
  destroyDrawer,
  disableEscapeKey,
  hideDrawer,
  id,
  onClose,
  open,
  payload,
  renderContent,
}) => {
  const handleClose = useCallback(() => {
    onClose ? open && onClose() : open && hideDrawer();
  }, [hideDrawer, onClose, open]);
  return (
    <DrawerOverlay
      destroyDrawer={destroyDrawer}
      disableEscapeKey={disableEscapeKey}
      id={id}
      onClose={handleClose}
      open={open}>
      <Drawer
        ariaLabel={ariaLabel}
        onClose={handleClose}
        payload={payload}
        renderContent={renderContent}
      />
    </DrawerOverlay>
  );
};

export default React.memo(DrawerCustom);
