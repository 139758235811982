import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { engagementGetBrandAction } from 'modules/redux/states/engagement/brand/actions';
import { ModalConfirmTemplate, useModalConfirm } from 'modules/modals/hooks';
import { ModalErrorText } from 'modules/modals/components/ModalErrorMessage';
import { ROUTE } from 'modules/router/constants';
import { UseHandleError } from './types';

const useHandleError: UseHandleError = ({ dispatch, isError }) => {
  const { showModalConfirm } = useModalConfirm();
  const navigate = useNavigate();

  const handleError = useCallback(() => {
    if (showModalConfirm)
      showModalConfirm({
        onCancel: () => navigate(ROUTE.POLICY_SEARCH, { replace: true }),
        onConfirm: () => dispatch(engagementGetBrandAction()),
        renderContent: () => (
          <ModalErrorText error={isError ? isError : undefined} />
        ),
        template: ModalConfirmTemplate.POLICY_DETAILS_ERROR,
      });
  }, [dispatch, isError, navigate, showModalConfirm]);

  useEffect(() => {
    if (isError) handleError();
  }, [handleError, isError]);
};

export default useHandleError;
