import { API } from 'modules/api';
import { PolicyAction } from '../constants';
import { createDefaultAction } from '../../../factories';
import {
  PolicyGetDetailsAction,
  PolicyGetDetailsStartedAction,
  PolicyGetDetailsSuccessAction,
  PolicyGetDetailsFailureAction,
} from './types';

export const policyGetDetailsAction: PolicyGetDetailsAction = request =>
  createDefaultAction({
    actionStarted: policyGetDetailsStartedAction,
    actionSuccess: policyGetDetailsSuccessAction,
    actionFailure: policyGetDetailsFailureAction,
    endpoint: API.GET_POLICY,
    isActive: state => !!state.policy.details.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) => actionId === state.policy.details.actionId,
    request: request,
  });

const policyGetDetailsStartedAction: PolicyGetDetailsStartedAction =
  payload => ({
    type: PolicyAction.GET_DETAILS_STARTED,
    ...payload,
  });

const policyGetDetailsSuccessAction: PolicyGetDetailsSuccessAction =
  payload => ({
    type: PolicyAction.GET_DETAILS_SUCCESS,
    ...payload,
  });

const policyGetDetailsFailureAction: PolicyGetDetailsFailureAction =
  payload => ({
    type: PolicyAction.GET_DETAILS_FAILURE,
    ...payload,
  });
