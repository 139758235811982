import React from 'react';

import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type EventIdProps = {
  isLoading?: boolean;
  eventId: string | null;
};

const EventId: React.FunctionComponent<EventIdProps> = ({
  isLoading,
  eventId,
}) => {
  return !isLoading ? (
    <React.Fragment>
      {eventId && (
        <p className={styles['event-id']}>
          <span>{eventId}</span>
        </p>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default React.memo(EventId);
