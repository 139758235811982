import React from 'react';

import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { drawerDestroyAction, drawerHideAction } from '../../actions';
import DrawerCustom from '../DrawerCustom';

import styles from './styles.module.scss';

const Drawers: React.FunctionComponent = () => {
  const drawers = useSelector(state => state.drawers, shallowEqual);
  const dispatch: Dispatch = useDispatch();
  return drawers.length > 0 ? (
    <div className={styles['drawers']}>
      {drawers.map(drawer => (
        <DrawerCustom
          destroyDrawer={() => dispatch(drawerDestroyAction(drawer.id))}
          hideDrawer={() => dispatch(drawerHideAction(drawer.id))}
          key={drawer.id}
          {...drawer}
        />
      ))}
    </div>
  ) : null;
};

export default Drawers;
