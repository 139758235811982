import React from 'react';
import clsx from 'clsx';

import { getEventText } from '../helpers';
import { PolicyEventType } from 'modules/redux/states/policy/trip/constants';
import EventIcon from '../PolicyEventIcon';

import styles from './styles.module.scss';

type PolicyEventSummaryItemProps = {
  count: number;
  eventType: PolicyEventType;
};

const PolicyEventsSummaryItem: React.FunctionComponent<
  PolicyEventSummaryItemProps
> = ({ count, eventType }) => {
  const classNames = clsx(
    styles['events-summary-item'],
    count > 0 && styles.active,
  );
  return (
    <li className={classNames}>
      <span>
        <EventIcon eventType={eventType} />
      </span>
      <div>
        <p>{getEventText(eventType)}</p>
        <span>{count}</span>
      </div>
    </li>
  );
};

export default React.memo(PolicyEventsSummaryItem);
