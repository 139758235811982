import React from 'react';

import { MessageMediumType } from 'modules/redux/states/engagement/messages/constants';
import EmailTemplate from './EmailTemplate';
import ItemContent from './ItemContent/Index';
import Toggle from './Toggle';

type Props = {
  activeId?: number | null;
  body: string | null;
  date: string;
  handleEmailTemplate: (id: number) => void;
  isSending: boolean;
  id: number;
  medium: MessageMediumType;
  subject: string;
};

const Item: React.FunctionComponent<Props> = ({
  activeId,
  body,
  date,
  handleEmailTemplate,
  isSending,
  id,
  medium,
  subject,
}) => {
  return medium === MessageMediumType.EMAIL ? (
    <EmailTemplate
      active={activeId === id}
      body={body}
      handleEmailTemplate={handleEmailTemplate}
      id={id}
      isSending={isSending}>
      <ItemContent date={date} description={subject} messageMedium={medium} />
    </EmailTemplate>
  ) : (
    <Toggle label={`Toggle ${medium}`}>
      {({ descRef, handleTransitionEnd }) => (
        <ItemContent
          date={date}
          descRef={descRef}
          description={body || ''}
          handleTransitionEnd={handleTransitionEnd}
          messageMedium={medium}
        />
      )}
    </Toggle>
  );
};

export default React.memo(Item);
