import {
  Dispatch,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'modules/redux';
import { UseModalPoints } from './types';
import useHandleAdd from './useHandleAdd';
import useHandleRemove from './useHandleRemove';
import usePointsToggle from './usePointsToggle';

const useModalPoints: UseModalPoints = onClose => {
  const dispatch: Dispatch = useDispatch();
  const endUserId = useSelector(
    state => state.engagement.details.data?.data.endUserId,
    shallowEqual,
  );
  const { data, isSending, isSendingError, reason, points } = useSelector(
    state => state.engagement.points,
    shallowEqual,
  );

  const { handleAddPoints, handleAddPointsError } = useHandleAdd({
    dispatch,
    endUserId,
    onClose,
    points,
    reason,
  });

  const { handleRemovePoints, handleRemovePointsError } = useHandleRemove({
    dispatch,
    endUserId,
    onClose,
    points,
    reason,
  });

  const { pointType, handleClick, handleTransitionEnd, ref } =
    usePointsToggle();

  return {
    data,
    handleAddPoints,
    handleAddPointsError,
    handleClick,
    handleRemovePoints,
    handleRemovePointsError,
    handleTransitionEnd,
    isSending,
    isSendingError,
    pointType,
    ref,
  };
};

export default useModalPoints;
