import { produce } from 'immer';

import { Events, EventsActionType } from '../../types';
import { EventsAction } from '../../constants';

function eventsPutReducer(state: Events, action: EventsActionType) {
  switch (action.type) {
    case EventsAction.PUT_FAILURE:
      return produce(state, (draftState: Events) => {
        draftState.actionId = null;
        draftState.isSending = false;
        draftState.isSendingError = action.error;
      });

    case EventsAction.PUT_STARTED:
      return produce(state, (draftState: Events) => {
        draftState.actionId = action.actionId;
        draftState.isSending = action.controller || true;
        draftState.isSendingError = false;
      });

    case EventsAction.PUT_SUCCESS:
      return produce(state, (draftState: Events) => {
        draftState.actionId = null;
        draftState.isSending = false;
        draftState.isSendingError = false;

        if (draftState.data) {
          draftState.data.approved = action.request.approved;
          draftState.data.reason = action.request.reason;
        }
      });

    default:
      return state;
  }
}

export default eventsPutReducer;
