import { produce } from 'immer';

import { Policy, PolicyActionType } from '../../../types';
import { PolicyAction } from '../../../constants';

function tripPutReducer(state: Policy, action: PolicyActionType) {
  switch (action.type) {
    case PolicyAction.PUT_TRIP_FAILURE:
      return produce(state, (draftState: Policy) => {
        const trip = draftState.trip.find(
          trip => trip.tripId === action.request.tripId,
        );

        if (trip) {
          trip.actionId = null;
          trip.isSending = false;
          trip.isSendingError = action.error;
        }
      });

    case PolicyAction.PUT_TRIP_STARTED:
      return produce(state, (draftState: Policy) => {
        const trip = draftState.trip.find(
          trip => trip.tripId === action.request.tripId,
        );

        if (trip) {
          trip.actionId = action.actionId;
          trip.isSending = action.controller || true;
          trip.isSendingError = false;
        }
      });

    case PolicyAction.PUT_TRIP_SUCCESS:
      return produce(state, (draftState: Policy) => {
        const trips = draftState.trips.data?.results.find(
          trip => trip.tripId === action.data.tripId,
        );
        if (trips) trips.state = action.data.state;

        const trip = draftState.trip.find(
          trip => trip.tripId === action.data.tripId,
        );
        if (trip) {
          trip.actionId = null;
          trip.isSending = false;
          trip.isSendingError = false;

          if (trip.data) trip.data.state = action.data.state;
        }
      });

    default:
      return state;
  }
}

export default tripPutReducer;
