import { StatusCodes } from 'http-status-codes';

import { ApiConfig } from './types';
import {
  EngagementGetAdditionalDetailsActionApiRequest,
  EngagementPostAdditionalDetailsActionApiRequest,
} from 'modules/redux/states/engagement/additionalDetails/types';
import { EngagementGetDetailsActionApiRequest } from 'modules/redux/states/engagement/details/types';
import {
  EngagementGetMessagesActionApiRequest,
  EngagementGetMessagesEmailTemplateActionApiRequest,
} from 'modules/redux/states/engagement/messages/types';
import { EngagementGetReportActionApiRequest } from 'modules/redux/states/engagement/report/types';
import { EngagementGetUsersActionApiRequest } from 'modules/redux/states/engagement/users/types';
import {
  EngagementGetPointsActionApiRequest,
  EngagementPostAddPointsActionApiRequest,
  EngagementPostRemovePointsActionApiRequest,
} from 'modules/redux/states/engagement/points/types';
import { PolicyGetDetailsActionApiRequest } from 'modules/redux/states/policy/details/types';
import { PolicyGetScoresActionApiRequest } from 'modules/redux/states/policy/scores/types';
import { PolicyGetTripsActionApiRequest } from 'modules/redux/states/policy/trips/types';
import {
  PolicyGetTripActionApiRequest,
  PolicyPutTripActionApiRequest,
} from 'modules/redux/states/policy/trip/types';
import {
  ReportsGetPolicyActionApiRequest,
  ReportsGetStatusActionApiRequest,
  ReportsPostActionApiRequest,
} from 'modules/redux/states/reports/types';
import {
  EventsGetActionApiRequest,
  EventsPutActionApiRequest,
} from 'modules/redux/states/events/types';
import { EngagementGetRewardsActionApiRequest } from 'modules/redux/states/engagement/rewards/types';

export enum ApiAbortCodes {
  BAD_REQUEST = StatusCodes.BAD_REQUEST,
  UNAUTHORIZED = StatusCodes.UNAUTHORIZED,
  UNPROCESSABLE_ENTITY = StatusCodes.UNPROCESSABLE_ENTITY,
  NOT_FOUND = StatusCodes.NOT_FOUND,
}

export enum ApiMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
}

const HOST = process.env.REACT_APP_API_ROOT || '';

export const API = {
  GET_CONFIG: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/v1/configs/users/current`,
  }),

  GET_ENGAGEMENT_ADDITIONAL_DETAILS: (
    request: EngagementGetAdditionalDetailsActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    //url: `https://rewards-integrators-dev.azurewebsites.net/api/v1/EndUserPersistentData/GetDataForUser/${request.endUserExternalId}`,
    url: `${HOST}/v1/engagements/EndUserPersistentData/GetDataForUser/${request.endUserExternalId}`,
    params: {
      page: request.page || 1,
      pageSize: request.pageSize || 20,
      ignoreStatus: true,
    },
  }),

  GET_ENGAGEMENT_BRAND: (): ApiConfig => {
    return {
      method: ApiMethod.GET,
      url: `${HOST}/v1/admin/CurrentBrand`,
    };
  },

  GET_ENGAGEMENT_DETAILS: (
    request: EngagementGetDetailsActionApiRequest,
  ): ApiConfig => {
    return {
      method: ApiMethod.GET,
      //url: `https://rewards-integrators-dev.azurewebsites.net/api/v1/EndUser/${request.endUserId}`,
      url: `${HOST}/v1/engagements/EndUser/${request.endUserId}`,
    };
  },

  GET_ENGAGEMENT_MESSAGES: (
    request: EngagementGetMessagesActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    //url: `https://rewards-webui-dev.azurewebsites.net/api/v1/Message/History`,
    url: `${HOST}/v1/engagements/Message/History`,
    params: {
      page: request.page || 1,
      pageSize: request.pageSize || 10,
      uid: request.endUserId,
    },
  }),

  GET_ENGAGEMENT_MESSAGES_EMAIL_TEMPLATE: (
    request: EngagementGetMessagesEmailTemplateActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    //url: `https://rewards-webui-dev.azurewebsites.net/api/v1/Message/${request.id}`,
    url: `${HOST}/v1/engagements/Message/${request.id}`,
    params: {
      uid: request.endUserId,
    },
  }),

  GET_ENGAGEMENT_POINTS: (
    request: EngagementGetPointsActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    //url: `https://rewards-webui-dev.azurewebsites.net/api/v1/Points/Get`,
    url: `${HOST}/v1/engagements/Points/Get`,
    params: {
      uid: request.endUserId,
    },
  }),

  GET_ENGAGEMENT_REPORT: (
    request: EngagementGetReportActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    //url: `https://rewards-admin-dev1.azurewebsites.net/api/v1/Report/GDPREndUserDataByEndUserId`,
    url: `${HOST}/v1/admin/Report/GDPREndUserDataByEndUserId`,
    params: {
      externalid: request.externalid,
      brandId: request.brandId,
      ignoreStatus: true,
    },
  }),

  GET_ENGAGEMENT_REWARDS: (
    request: EngagementGetRewardsActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    //url: `https://rewards-webui-dev.azurewebsites.net/api/v1/Reward/HistoryPaging`,
    url: `${HOST}/v1/engagements/Reward/HistoryPaging`,
    params: {
      page: request.page || 1,
      pageSize: request.pageSize || 10,
      uid: request.endUserId,
    },
  }),

  GET_ENGAGEMENT_USERS: (
    request: EngagementGetUsersActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    //url: `https://rewards-admin-dev1.azurewebsites.net/api/v1/EndUser/Search`,
    url: `${HOST}/v1/admin/EndUser/Search`,
    params: {
      brandId: request.brandId,
      searchString: request.searchString,
      page: request.page || 1,
      pageSize: request.pageSize || 20,
    },
  }),

  GET_EVENT: (request: EventsGetActionApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: request.eventId
      ? `${HOST}/v1/events/speeding/${request.eventId}`
      : `${HOST}/v1/events/speeding`,
  }),

  GET_POLICY: (request: PolicyGetDetailsActionApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/v1/policies/details`,
    params: {
      id: request.policyId,
      ignoreStatus: true,
    },
  }),

  GET_POLICY_SCORES: (request: PolicyGetScoresActionApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/v1/scores`,
    params: {
      'aggregate-type': request.aggregateType,
      id: request.policyId,
      ignoreStatus: true,
    },
  }),

  GET_POLICY_TRIP: (request: PolicyGetTripActionApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/v1/trips/${request.tripId}`,
    params: {
      'subject-id': request.subjectId,
    },
  }),

  GET_POLICY_TRIPS: (request: PolicyGetTripsActionApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/v1/trips?id=${request.policyId}`,
    params: {
      'end-date': request.endDate,
      'page-after': request.pageAfter,
      'page-limit': request.pageLimit || 10,
      'start-date': request.startDate,
      ignoreStatus: true,
    },
  }),

  GET_REPORT_POLICY: (
    request: ReportsGetPolicyActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/v1/policies/details`,
    params: {
      id: request.policyId,
      ignoreStatus: true,
    },
  }),

  GET_REPORT_STATUS: (
    request: ReportsGetStatusActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/v1/reports/${request.reportId}`,
  }),

  POST_ENGAGEMENT_ADDITIONAL_DETAILS: (
    request: EngagementPostAdditionalDetailsActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.POST,
    //url: `https://rewards-integrators-dev.azurewebsites.net/api/v1/EndUserPersistentData`,
    url: `${HOST}/v1/engagements/EndUserPersistentData`,
    data: {
      endUserExternalId: request.endUserExternalId,
      value: request.value,
      dataKeyName: request.dataKeyName,
    },
  }),

  POST_ENGAGEMENT_ADD_POINTS: (
    request: EngagementPostAddPointsActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.POST,
    //url: `https://rewards-integrators-dev.azurewebsites.net/api/v1/Points/Add`,
    url: `${HOST}/v1/engagements/Points/Add?uid=${request.endUserId}`,
    data: {
      points: request.points,
      endUserId: request.endUserId,
      reason: request.reason,
    },
  }),

  POST_ENGAGEMENT_REMOVE_POINTS: (
    request: EngagementPostRemovePointsActionApiRequest,
  ): ApiConfig => ({
    method: ApiMethod.POST,
    //url: `https://rewards-integrators-dev.azurewebsites.net/api/v1/Points/Remove`,
    url: `${HOST}/v1/engagements/Points/Remove?uid=${request.endUserId}`,
    data: {
      points: request.points,
      endUserId: request.endUserId,
      reason: request.reason,
      removalType: request.removalType,
    },
  }),

  POST_REPORT: (request: ReportsPostActionApiRequest): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${HOST}/v1/reports/create`,
    data: {
      endDate: request.endDate,
      reportName: request.reportName,
      reportTimestamp: Date.now(),
      startDate: request.startDate,
      subjectId: request.subjectId,
      tenantId: request.tenantId,
    },
  }),

  PUT_EVENT: (request: EventsPutActionApiRequest): ApiConfig => ({
    method: ApiMethod.PUT,
    url: `${HOST}/v1/events/validate`,
    data: {
      speedingEventId: request.eventId,
      approved: request.approved,
      reason: request.reason,
    },
  }),

  PUT_POLICY_TRIP: (request: PolicyPutTripActionApiRequest): ApiConfig => ({
    method: ApiMethod.PUT,
    url: `${HOST}/v1/trips/${request.tripId}`,
    data: {
      action: 'invalidate',
    },
  }),
};
