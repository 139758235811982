import React, { useContext, useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { events, eventsWithScroll } from './constants';
import PlatformContext from 'modules/platform';
import { shallowEqual, useSelector } from 'modules/redux';

type SessionProps = {
  children: React.ReactNode;
  handleSessionTimeout: () => void;
  timeout: string | number | undefined;
};

const defaultTimeout = 5;

const getTimeout = (timeout: string | number | undefined) => {
  if (typeof timeout === 'number') return timeout;
  return !!timeout && typeof timeout === 'string' && !isNaN(parseInt(timeout))
    ? parseInt(timeout)
    : defaultTimeout;
};

const Session: React.FunctionComponent<SessionProps> = ({
  children,
  handleSessionTimeout,
  timeout,
}) => {
  const { isAuthenticated } = useSelector(state => state.auth, shallowEqual);
  const { isMacOs, isSafari } = useContext(PlatformContext);

  const handleOnIdle = useCallback(() => {
    isAuthenticated && handleSessionTimeout();
  }, [handleSessionTimeout, isAuthenticated]);

  useIdleTimer({
    crossTab: true,
    events: isMacOs && isSafari ? events : eventsWithScroll,
    eventsThrottle: 500,
    onIdle: handleOnIdle,
    startOnMount: true,
    stopOnIdle: true,
    timeout: getTimeout(timeout) * (60 * 1000),
  });

  return <React.Fragment>{children}</React.Fragment>;
};

export default React.memo(Session);
