import React from 'react';

import Button from 'components/Button';
import {
  ButtonColor,
  ButtonStyle,
  ButtonType,
} from 'components/Button/constants';
import { defaultValues, schema } from './model';
import Form from 'components/Form';
import { Model } from './model';
import { QuestionPolicySearch } from 'components/Questions';

import styles from './styles.module.scss';

export type { Model } from './model';

type PolicySearchProps = {
  active: boolean;
  isError: false | Error;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: Model) => void;
  title: string;
};

const PolicySearch: React.FunctionComponent<PolicySearchProps> = ({
  active,
  isError,
  isLoading,
  onCancel,
  onSubmit,
  title,
}) => {
  return (
    <React.Fragment>
      <header>
        <h2>{title}</h2>
      </header>
      <Form<Model>
        className={styles['modal-template-policy-number']}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        schema={schema}>
        {({ control, dirtyFields, isFormSubmitting }) => {
          return (
            <React.Fragment>
              <div>
                <QuestionPolicySearch
                  control={control}
                  isError={isError}
                  label="Search for policy number or Strata reference"
                  name="policyId"
                  placeholder="e.g. 0000 0000"
                  readOnly={!active || isLoading}
                />
              </div>
              <footer>
                <Button
                  color={ButtonColor.BLUE}
                  disabled={!active || isFormSubmitting || isLoading}
                  label="Cancel"
                  onClick={onCancel}
                  style={ButtonStyle.SECONDARY}
                />
                <Button
                  color={ButtonColor.BLUE}
                  disabled={!active || !dirtyFields?.policyId}
                  label="Search"
                  loading={isFormSubmitting || isLoading}
                  style={ButtonStyle.PRIMARY}
                  type={ButtonType.SUBMIT}
                />
              </footer>
            </React.Fragment>
          );
        }}
      </Form>
    </React.Fragment>
  );
};
export default React.memo(PolicySearch);
