import yup from 'modules/validation';

export type Model = {
  bankedPoints?: number;
  points: number;
  reason: string;
};

export const defaultValues: Model = {
  bankedPoints: 0,
  points: 0,
  reason: '',
};

export const schema = yup.object().shape({
  points: yup
    .number()
    .min(1)
    .max(5000)
    .required()
    .lessThan(
      yup.ref('bankedPoints'),
      'Can not remove more points than the end user has',
    ),
  bankedPoints: yup.number(),
  reason: yup.string().required(),
});
