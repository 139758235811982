import React, { useCallback, useMemo, useState } from 'react';
import { UseFormReset } from 'react-hook-form';

import {
  ButtonColor,
  ButtonStyle,
  ButtonType,
} from 'components/Button/constants';
import { defaultValues, Model, schema } from './model';
import { ModalInfoTemplate } from 'modules/modals/constants';
import { QuestionSize } from 'components/Questions/constants';
import { QuestionSelect } from 'components/Questions';
import { useModalInfo } from 'modules/modals/hooks';
import Button from 'components/Button';
import Fieldset from 'components/Fieldset';
import Form from 'components/Form';
import logger from 'modules/logger';

const QuestionsSelects: React.FunctionComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showModalInfo } = useModalInfo();

  const handleSubmit = useCallback(
    async (data: Model, reset: UseFormReset<Model>) => {
      logger.debug('Submit:', data);
      setIsSubmitting(true);
      setTimeout(
        () =>
          !!showModalInfo &&
          showModalInfo({
            onClose: () => {
              setIsSubmitting(false);
              reset();
            },
            template: ModalInfoTemplate.DEBUG_SUCCESSFUL_SUBMISSION,
          }),
        2000,
      );
    },
    [showModalInfo],
  );

  const options = useMemo(
    () => [
      {
        label: 'Option 1',
        value: 'option-1',
      },
      {
        label: 'Option 2',
        value: 'option-2',
      },
      {
        label: 'Option 3',
        value: 'option-3',
      },
      {
        label: 'Option 4',
        value: 'option-4',
      },
    ],
    [],
  );

  return (
    <Form<Model>
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      schema={schema}>
      {({ control, isFormSubmitting }) => (
        <React.Fragment>
          <Fieldset title="Selects">
            <QuestionSelect
              control={control}
              label="Label"
              name="debugSelect"
              options={options}
              placeholder="-- Select an option --"
              readOnly={isSubmitting}
            />
            <QuestionSelect
              control={control}
              label="Label"
              name="debugSelect"
              options={options}
              placeholder="-- Select an option --"
              readOnly={isSubmitting}
              size={QuestionSize.SMALL}
            />
            <QuestionSelect
              control={control}
              label="Label"
              name="debugSelectEmpty"
              options={options}
              placeholder="-- Select an option --"
              readOnly={isSubmitting}
            />
            <QuestionSelect
              control={control}
              label="Label"
              name="debugSelectEmpty"
              options={options}
              placeholder="-- Select an option --"
              readOnly={isSubmitting}
              size={QuestionSize.SMALL}
            />
          </Fieldset>

          <Button
            color={ButtonColor.BLUE}
            label="Submit"
            loading={isFormSubmitting || isSubmitting || !showModalInfo}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.SUBMIT}
          />

          <Fieldset title="Selects - Disabled">
            <QuestionSelect
              control={control}
              disabled={true}
              label="Label"
              placeholder="-- Select an option --"
              name="debugSelectDisabled"
              options={options}
              readOnly={isSubmitting}
            />
            <QuestionSelect
              control={control}
              disabled={true}
              label="Label"
              name="debugSelectDisabled"
              options={options}
              placeholder="-- Select an option --"
              readOnly={isSubmitting}
              size={QuestionSize.SMALL}
            />
            <QuestionSelect
              control={control}
              disabled={true}
              label="Label"
              placeholder="-- Select an option --"
              name="debugSelectDisabledEmpty"
              options={options}
              readOnly={isSubmitting}
            />
            <QuestionSelect
              control={control}
              disabled={true}
              label="Label"
              name="debugSelectDisabledEmpty"
              options={options}
              placeholder="-- Select an option --"
              readOnly={isSubmitting}
              size={QuestionSize.SMALL}
            />
          </Fieldset>

          <Fieldset title="Selects - Read Only">
            <QuestionSelect
              control={control}
              label="Label"
              name="debugSelectReadOnly"
              options={options}
              placeholder="-- Select an option --"
              readOnly={true}
            />
            <QuestionSelect
              control={control}
              label="Label"
              name="debugSelectReadOnly"
              options={options}
              placeholder="-- Select an option --"
              readOnly={true}
              size={QuestionSize.SMALL}
            />
            <QuestionSelect
              control={control}
              label="Label"
              name="debugSelectEmptyReadOnly"
              options={options}
              placeholder="-- Select an option --"
              readOnly={true}
            />
            <QuestionSelect
              control={control}
              label="Label"
              name="debugSelectEmptyReadOnly"
              options={options}
              placeholder="-- Select an option --"
              readOnly={true}
              size={QuestionSize.SMALL}
            />
          </Fieldset>
        </React.Fragment>
      )}
    </Form>
  );
};

export default React.memo(QuestionsSelects);
