import { produce } from 'immer';

import { Auth, AuthActionType } from './types';
import { AuthAction } from './constants';

const initialState = (): Auth => {
  return {
    isAuthenticated: false,
    isError: false,
    isReady: false,
    tokens: {},
    user: null,
  };
};

function authReducer(state = initialState(), action: AuthActionType) {
  switch (action.type) {
    case AuthAction.CONFIG_FAILURE:
      return produce(state, (draftState: Auth) => {
        draftState.isError = action.error;
      });

    case AuthAction.CONFIG_STARTED:
      return produce(state, (draftState: Auth) => {
        draftState.isReady = false;
      });

    case AuthAction.CONFIG_SUCCESS:
    case AuthAction.LOGIN_SUCCESS:
      return produce(state, (draftState: Auth) => {
        draftState.isAuthenticated = action.isAuthenticated;
        draftState.isReady = true;
      });

    case AuthAction.GET_USER_FAILURE:
      return produce(state, (draftState: Auth) => {
        draftState.user = null;
      });

    case AuthAction.GET_USER_SUCCESS:
      return produce(state, (draftState: Auth) => {
        draftState.user = action.user || null;
      });

    case AuthAction.TOKEN:
      return produce(state, (draftState: Auth) => {
        draftState.tokens[action.origin] = {
          expires: action.expires,
          origin: action.origin,
          token: action.token,
        };
      });

    case AuthAction.LOGOUT:
      return produce(state, (draftState: Auth) => {
        draftState.isAuthenticated = false;
        draftState.isReady = false;
        draftState.tokens = {};
        draftState.user = null;
      });

    default:
      return state;
  }
}

export default authReducer;
