import React from 'react';

import { PolicyEventType } from 'modules/redux/states/policy/trip/constants';
import { ReactComponent as AccelerationSVG } from 'modules/theme/images/icons/events/acceleration-events.svg';
import { ReactComponent as BrakingSVG } from 'modules/theme/images/icons/events/braking-events.svg';
import { ReactComponent as CorneringSVG } from 'modules/theme/images/icons/events/cornering-events.svg';
import { ReactComponent as DistractedDrivingSVG } from 'modules/theme/images/icons/events/distracted-driving-events.svg';
import { ReactComponent as OverallSVG } from 'modules/theme/images/icons/events/overall.svg';
import { ReactComponent as OverSpeedSVG } from 'modules/theme/images/icons/events/over-speed-events.svg';
import { ReactComponent as SpedingSVG } from 'modules/theme/images/icons/events/speeding-events.svg';
import { ReactComponent as SmoothnessSVG } from 'modules/theme/images/icons/events/smoothness-events.svg';
import { ReactComponent as TimeOfDaySVG } from 'modules/theme/images/icons/events/time-of-day-events.svg';

type EventIconProps = {
  eventType: PolicyEventType;
};

const PolicyEventIcon: React.FunctionComponent<EventIconProps> = ({
  eventType,
}) => {
  switch (eventType) {
    case PolicyEventType.ACCELERATION:
      return <AccelerationSVG />;
    case PolicyEventType.BRAKING:
      return <BrakingSVG />;
    case PolicyEventType.CORNERING:
      return <CorneringSVG />;
    case PolicyEventType.DISTRACTED_DRIVING:
      return <DistractedDrivingSVG />;
    case PolicyEventType.OVERALL:
      return <OverallSVG />;
    case PolicyEventType.OVER_SPEED:
      return <OverSpeedSVG />;
    case PolicyEventType.SMOOTHNESS:
      return <SmoothnessSVG />;
    case PolicyEventType.SPEEDING:
      return <SpedingSVG />;
    case PolicyEventType.TIME_OF_DAY:
      return <TimeOfDaySVG />;
    default:
      return null;
  }
};

export default React.memo(PolicyEventIcon);
