import { useCallback, useEffect, useRef, useState } from 'react';

import { getTemplate } from '../templates/custom';
import { ModalCustomTemplate, ModalType } from '../constants';
import { ModalCustomProps } from '../types';
import useModalAction from './useModalAction';

export type ShowModalCustomProps = Omit<
  ModalCustomProps,
  'destroyModal' | 'hideModal' | 'id' | 'open'
> & { template?: ModalCustomTemplate };

export type UseModalCustom = () => {
  hideModalCustom: () => void;
  showModalCustom?: (props: ShowModalCustomProps) => void;
};

const useModalCustom: UseModalCustom = () => {
  const { modalShow, modalHide } = useModalAction();
  const [modal, setModal] = useState<string | null>();
  const modalRef = useRef<() => void>();

  useEffect(() => {
    modalRef.current = () => {
      modal && modalHide(modal);
      setModal(null);
    };
  }, [modal, modalHide]);

  const hideModal = useCallback(
    () => modalRef.current && modalRef.current(),
    [],
  );

  const showModal = useCallback(
    ({ onClose, template, ...props }: ShowModalCustomProps) => {
      if (!modal) {
        setModal(
          modalShow({
            onClose: () => {
              onClose && onClose();
              hideModal();
            },
            type: ModalType.CUSTOM,
            ...getTemplate(template),
            ...props,
          }),
        );
      }
    },
    [hideModal, modal, modalShow],
  );

  useEffect(() => {
    return hideModal;
  }, [hideModal]);

  return {
    hideModalCustom: hideModal,
    showModalCustom: !modal ? showModal : undefined,
  };
};

export default useModalCustom;
