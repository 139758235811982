import { API } from 'modules/api';

import { createDefaultAction } from 'modules/redux/factories';
import { EngagementAction } from 'modules/redux/states/engagement/constants';
import {
  EngagementGetUsersAction,
  EngagementGetUsersFailureAction,
  EngagementGetUsersStartedAction,
  EngagementGetUsersSuccessAction,
} from './types';

export const engagementGetUsersAction: EngagementGetUsersAction = request =>
  createDefaultAction({
    actionStarted: engagementGetUsersStartedAction,
    actionSuccess: engagementGetUsersSuccessAction,
    actionFailure: engagementGetUsersFailureAction,
    endpoint: API.GET_ENGAGEMENT_USERS,
    isActive: state => !!state.engagement.users.isLoading,
    isAuthorized: state => state.auth.isAuthenticated,
    isPresent: (actionId, state) =>
      actionId === state.engagement.users.actionId,
    request: request,
  });

const engagementGetUsersStartedAction: EngagementGetUsersStartedAction =
  payload => ({
    type: EngagementAction.GET_USERS_STARTED,
    ...payload,
  });

const engagementGetUsersSuccessAction: EngagementGetUsersSuccessAction =
  payload => ({
    type: EngagementAction.GET_USERS_SUCCESS,
    ...payload,
  });

const engagementGetUsersFailureAction: EngagementGetUsersFailureAction =
  payload => ({
    type: EngagementAction.GET_USERS_FAILURE,
    ...payload,
  });
