import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { decodePolyline } from 'modules/polyline/decode';
import { getJourneyMarkers } from './helpers';
import { getSpeedingEventId } from 'modules/redux/states/events/utils';
import { useConfigSelector } from 'modules/redux/states/config/hooks';
import { UseEvent } from 'modules/redux/states/events/hooks';
import Card from 'components/Card';
import ErrorMessage from './ErrorMessage';
import GoogleMarker from './Marker';
import GooglePolyline from './Polyline';
import JourneyMarker from './JourneyMarker';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type GoogleMapProps = {
  chartIndex?: number;
  data: UseEvent['data'];
  isLoading: boolean;
};

const Map: React.FunctionComponent<GoogleMapProps> = ({
  chartIndex,
  data,
  isLoading,
}) => {
  const mapRef = useRef<GoogleMap | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS || '',
  });

  const {
    eventsApplication: { showGeometricLine },
  } = useConfigSelector();

  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);

  const { googleMapCoords } = useMemo(
    () => decodePolyline(data ? data.polyLine : ''),
    [data],
  );

  const journey = useMemo(
    () => getJourneyMarkers(googleMapCoords),
    [googleMapCoords],
  );

  const activeMarkerIndex = useMemo(
    () => data?.travelSpeeds.findIndex(el => el.speedingEventId),
    [data],
  );
  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      googleMapCoords.map(coord => {
        return bounds.extend({
          lat: coord.lat,
          lng: coord.lng,
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, googleMapCoords]);

  return loadError ? (
    <ErrorMessage loadError={loadError} />
  ) : isLoaded && !isLoading ? (
    <Card className={styles['google-map']}>
      <div aria-hidden={true}>
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          onLoad={onLoad}
          ref={mapRef}>
          <JourneyMarker
            position={journey?.start as google.maps.LatLngLiteral}
            start
          />
          {activeMarkerIndex != null &&
            data?.travelSpeeds.map((marker, index) => {
              return (
                <GoogleMarker
                  isHover={index === chartIndex}
                  isActive={!!marker.speedingEventId}
                  key={index}
                  label={index - activeMarkerIndex}
                  position={{
                    lat: marker.lat,
                    lng: marker.lon,
                  }}
                  zIndex={
                    index === chartIndex ? 999 : googleMapCoords.length - index
                  }
                />
              );
            })}
          <JourneyMarker position={journey?.end as google.maps.LatLngLiteral} />
          {!!showGeometricLine && <GooglePolyline polyline={googleMapCoords} />}
        </GoogleMap>
      </div>
      <section aria-label="Map" className="visually-hidden">
        <h2>Event map for {getSpeedingEventId(data)}.</h2>
        <ul>
          {journey && (
            <li>{`Journey start. latitude: ${journey.start.lat} and longitude: ${journey.start.lng}`}</li>
          )}
          {googleMapCoords.slice(1, -1).map((coord, index) => (
            <li key={`accessibility-${coord}-${index}`}>{`Event ${
              index + 1
            } occurred at latitude: ${coord.lat} and longitude: ${
              coord.lng
            }`}</li>
          ))}
          {journey && (
            <li>{`Journey end. latitude: ${journey.end.lat} and longitude: ${journey.end.lng}`}</li>
          )}
        </ul>
      </section>
    </Card>
  ) : (
    <Skeleton />
  );
};

export default React.memo(Map);
