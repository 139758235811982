import { getPreviousRoute, matchRoute } from 'modules/router/utils';
import { RootState } from 'modules/redux';
import { ROUTE } from 'modules/router/constants';

const isPolicyTripCurrentRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.POLICY_SUMMARY_TRIP, router.location?.pathname);

const isPolicySummaryCurrentRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.POLICY_SUMMARY, router.location?.pathname);

const isPolicySearchPreviousRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.POLICY_SEARCH, getPreviousRoute(router));

const isPolicySummaryPreviousRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.POLICY_SUMMARY, getPreviousRoute(router));

const isPolicyTripPreviousRoute = (router: RootState['router']) =>
  matchRoute(ROUTE.POLICY_SUMMARY_TRIP, getPreviousRoute(router));

export const shouldResetPolicy = (router: RootState['router']) =>
  (isPolicySearchPreviousRoute(router) &&
    !isPolicySummaryCurrentRoute(router)) ||
  (isPolicySummaryPreviousRoute(router) && !isPolicyTripCurrentRoute(router)) ||
  (isPolicyTripPreviousRoute(router) &&
    !isPolicySummaryCurrentRoute(router) &&
    !isPolicyTripCurrentRoute(router));
