import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE } from 'modules/router/constants';
import { useAuthAccount } from 'modules/auth/hooks';
import Template from './Template';

const Route: React.FunctionComponent = () => {
  const { handleLogout } = useAuthAccount();
  const navigate = useNavigate();

  const handleGetEvent = useCallback(() => {
    navigate(ROUTE.EVENTS, { replace: true });
  }, [navigate]);

  return (
    <Template handleGetEvent={handleGetEvent} handleLogout={handleLogout} />
  );
};

export default Route;
