import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import { AuthAction } from 'modules/auth/constants';
import { AuthActionType } from 'modules/auth/types';
import {
  buildEventsPath,
  matchRoute,
  middlewareNavigate,
} from 'modules/router/utils';
import { dispatchAbort, Dispatch, RootState } from 'modules/redux';
import { EventsAction } from './constants';
import { EventsActionType } from './types';
import { eventsLogoutAction, eventsResetAction } from './actions';
import { getSpeedingEventId, shouldResetEvents } from './utils';
import { ROUTE } from 'modules/router/constants';
import { RouterActionType } from 'modules/router/types';

const createEventsMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | EventsActionType | RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case LOCATION_CHANGE: {
        nextAction = next(action);
        shouldResetEvents(getState().router) && dispatch(eventsResetAction());
        break;
      }

      case EventsAction.GET_SUCCESS: {
        nextAction = next(action);
        const id = getSpeedingEventId(action.data);
        if (
          id &&
          matchRoute(ROUTE.EVENTS, getState().router.location?.pathname)
        )
          middlewareNavigate(dispatch, buildEventsPath(id), 'replace');
        break;
      }

      case EventsAction.RESET: {
        const { auth, events } = getState();
        if (auth.isAuthenticated) dispatchAbort(events);
        else return;
        break;
      }

      case AuthAction.LOGOUT: {
        const { events } = getState();
        dispatchAbort(events);
        nextAction = next(action);
        dispatch(eventsLogoutAction());
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default createEventsMiddleware;
