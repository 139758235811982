import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { eventsGetAction } from '../../../actions';
import { ModalConfirmTemplate } from 'modules/modals/constants';
import { ModalErrorText } from 'modules/modals/components/ModalErrorMessage';
import { ROUTE } from 'modules/router/constants';
import { UseHandleError } from './types';

const useHandleError: UseHandleError = ({
  dispatch,
  eventId,
  isError,
  showModalConfirm,
}) => {
  const navigate = useNavigate();

  const handleError = useCallback(() => {
    if (showModalConfirm)
      showModalConfirm({
        onCancel: () => navigate(ROUTE.EVENTS, { replace: true }),
        onConfirm: () => dispatch(eventsGetAction({ eventId })),
        renderContent: () => (
          <ModalErrorText error={isError ? isError : undefined} />
        ),
        template: ModalConfirmTemplate.EVENTS_ERROR,
      });
  }, [dispatch, eventId, isError, navigate, showModalConfirm]);

  useEffect(() => {
    if (isError && showModalConfirm) handleError();
  }, [handleError, isError, showModalConfirm]);
};

export default useHandleError;
