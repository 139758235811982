import { PolicyEventType } from 'modules/redux/states/policy/trip/constants';

export const getEventText = (eventType: PolicyEventType) => {
  switch (eventType) {
    case PolicyEventType.ACCELERATION:
      return 'Acceleration';
    case PolicyEventType.BRAKING:
      return 'Braking';
    case PolicyEventType.CORNERING:
      return 'Cornering';
    case PolicyEventType.DISTRACTED_DRIVING:
      return 'Distraction';
    case PolicyEventType.OVERALL:
      return 'Overall';
    case PolicyEventType.OVER_SPEED:
      return 'Over Speed';
    case PolicyEventType.SMOOTHNESS:
      return 'Smoothness';
    case PolicyEventType.SPEEDING:
      return 'Speeding';
    case PolicyEventType.TIME_OF_DAY:
      return 'Time Of Day';
    default:
      return null;
  }
};
